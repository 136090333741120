import angular from "angular";

import 'angular/app/client/taxes/section-interactions/assistant.style.css';
import 'angular/app/client/taxes/section-interactions/assistant-shared.style.css';

import template from './separate-property.template.html';

angular.module('app.clients.taxes').component('cpAssistantQuestionSeparateProperty', {
  bindings: {},

  controllerAs: 'vm',

  controller: function () {
    const vm = this;
    vm;
  },

  template: template
});
