import angular from "angular";
import template from "./tax-year-range-selector.template.html";

import "./tax-year-range-selector.controller.js";
import "./tax-year-range-selector.style.css";

angular.module('app.transcripts')
  .directive('cpTaxYearRangeSelector', [
    function cpTaxYearRangeSelector() {
      return {
        restrict: 'E',
        scope: {
          rangeStart: '=',
          rangeEnd: '=',
          disabled: '@',
          enabled: '@',
          failed: '@',
          selected: '=',
          noBackground: '@'
        },
        template: template,
        controller: 'TaxYearRangeSelectorController',
        controllerAs: 'vm',
        bindToController: true
      }
    }
  ]);
