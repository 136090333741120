import angular from "angular";
import {filter} from 'lodash';
import { catchAsyncStacktrace } from 'auto-trace';

import './outcomes.style.css';
import './assistant-shared.style.css';
import template from './outcomes.template.html';

import * as assistantConstants from 'angular/app/client/taxes/programs/assistant/assistant.constants.js';

import 'angular/app/client/taxes/programs/assistant/assistant-answers.service.js';

import 'angular/app/client/taxes/section-interactions/common-actions/change-status.directive.js';
import 'angular/app/client/taxes/section-interactions/common-actions/assign-section-envelope.component.js';
import 'angular/app/client/taxes/section-interactions/common-actions/due-date.directive.js';

import 'angular/app/client/taxes/programs/innocent-spouse/assistant-outcomes.component.js';
import 'angular/app/client/taxes/programs/penalty-abatements/assistant-outcomes.component.js';
import 'angular/app/client/taxes/programs/liens/assistant-outcomes.component.js';
import 'angular/app/client/taxes/programs/levies/assistant-outcomes.component.js';
import 'angular/app/client/taxes/programs/trust-fund/assistant-outcomes.component.js';

angular.module('app.clients.taxes').component('cpOutcomes', {
  bindings: {
    sectionId: '@',
    engagement: '=',
  },

  controllerAs: 'vm',

  controller: function ($scope, $stateParams, ContactService, ProgramSectionsService, EngagementService, SectionEnvelopesService) {
    const vm = this;

    vm.assistantConstants = assistantConstants;
    vm.$stateParams = $stateParams;
    vm.regetActiveTeamMembers = regetActiveTeamMembers;

    ContactService.getClient({clientId: $stateParams.clientId, include: 'users'})
      .then(function (successResponse) {
        vm.activeTeamMembers = filter(successResponse.users, {
          'is_activated': true,
          'is_deleted': false,
          'role': 'TeamMember'
        });
      })
      .catch(catchAsyncStacktrace())

    $scope.$watch('vm.engagement', () => {
      if (vm.engagement) {
        ProgramSectionsService.get({
          engagementId: $stateParams.engagementId,
          clientId: $stateParams.clientId,
          sectionId: EngagementService.getSectionFromEngagementWithSlugs(vm.engagement, $stateParams.programSlug, assistantConstants.assistantResources[$stateParams.programSlug].assistantOutcomeSlug).id
        }).then((section) => {
          vm.section = section;
          SectionEnvelopesService.getEnvelope({
            ...$stateParams,
            sectionId: section.id,
            envelopeId: section.relationships.children[0].references
          })
          .then((envelope) => {
            vm.sectionEnvelope = envelope;
          });
        });
      }
    });
    function regetActiveTeamMembers() {
      ContactService.getClient({clientId: $stateParams.clientId, include: 'users'})
        .then(function (successResponse) {
          vm.activeTeamMembers = filter(successResponse.users, {
            'is_activated': true,
            'is_deleted': false,
            'role': 'TeamMember'
          });
        })
        .catch(catchAsyncStacktrace())
    }
  },

  template: template
});
