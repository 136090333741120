import angular from 'angular';
import { navContentPostLink } from './nav-content-postlink.js';
import { partial } from 'lodash';

angular.module('app').directive('navContent', [
  function NavContentDirective() {
    return {
      restrict: 'A',
      compile: function (element, attrs) {
        const newHeightCallback = (el, height) => {
          if (attrs.clientBillingNavContent) {
            el.css('marginLeft', '256px');
            el.css('width', `calc(100% - 256px)`);
          }
          el.addClass('cps-nav-content-new')
        };

        return {
          post: partial(navContentPostLink, 'margin-top', newHeightCallback),
        };
      },
    };
  },
]);
