import angular from 'angular';
import { fetchAsObservable } from 'fetcher!sofe'
import { asyncStacktrace } from 'auto-trace';

export const headers = (typeof Headers !== "undefined") ? new Headers({
  'Content-Type': 'application/json',
}) : {};

angular.module('app.clients.taxes')
  .factory('ClientRequestsResource', ['$http',
    function ClientRequestsResource($http) {

      return {
        getEngagementIndex: (clientId, engagementId, params = {}) => $http.get(`/api/clients/${clientId}/engagements/${engagementId}/requests`, {params: params}),

        getClientIndex: (clientId, completed) => $http.get(`/api/clients/${clientId}/requests?complete=${completed}`),

        get: (clientId, engagementId, clientRequestId) => $http.get(`/api/clients/${clientId}/engagements/${engagementId}/requests/${clientRequestId}`),

        getNonEngagementRequest: request_tool_id => $http.get(`/api/request-tools/${request_tool_id}?includes=relations`),

        toggleComplete: (clientRequestID, body) => {
          return new Promise((resolve, reject) => {
            fetchAsObservable(`/api/request-tools/${clientRequestID}`, {
              method: 'PATCH',
              headers,
              body: JSON.stringify(body)
            }).subscribe(resolve, err => {
              asyncStacktrace(err)
              reject(err)
            })
          })
        },

        post: (clientId, engagementId, body) => $http.post(`/api/clients/${clientId}/engagements/${engagementId}/requests`, body),

        put: (clientId, engagementId, clientRequestId, body) => $http.put(`/api/clients/${clientId}/engagements/${engagementId}/requests/${clientRequestId}`, body),

        delete: (clientId, engagementId, clientRequestId) => $http.delete(`/api/clients/${clientId}/engagements/${engagementId}/requests/${clientRequestId}`),

        bulkSend: (clientId, body) => $http.put(`/api/clients/${clientId}/requests`, body),
      };
    }
  ]);
