import angular from 'angular';

import 'angular/app/client/taxes/section-interactions/assistant.style.css';
import 'angular/app/client/taxes/section-interactions/assistant-shared.style.css';
import './circumstance.style.css';

angular.module('app.clients.taxes').component('cpAssistantQuestionCircumstance', {
  bindings: {
    answer: '='
  },

  controllerAs: 'vm',

  controller: function() {
    const vm = this;

    vm.answer = vm.answer || {
      illness: false,
      disaster: false,
      incapable: false,
      mistake: false,
      erroneous_advice: false,
      blame_irs: false,
      ignorant: false,
      hardship: false,
      reasonable: false
    };

    // instantiate all the show/hide items to false
    vm.showCheckbox0TextDetail = vm.showCheckbox1TextDetail = vm.showCheckbox2TextDetail = vm.showCheckbox3TextDetail = false;
    vm.showCheckbox4TextDetail = vm.showCheckbox5TextDetail = vm.showCheckbox6TextDetail = vm.showCheckbox7TextDetail = false;
    vm.showCheckbox8TextDetail = false;
  },

  template: ($element, $attrs) => {
    const TOOLTIP_ACCURACY =
      'IRC 6662. You may wish to refer to IRM 20.1.1.3.3.4.3 for additional information if you think this may apply.';
    const TOOLTIP_ADVICE =
      'The IRS is required under IRC 6404(f) and Treas. Reg. 301.6404-3 to abate any portion of any penalty attributed to erroneous written advice furnished by an officer or employee of the IRS acting in their official capacity. They have extended this relief to include oral advice when appropriate. However, if they do not meet these criteria, they may still qualify if they show ordinary business care and prudence in relying on the written advice.';
    const TOOLTIP_EXTENSION = 'Form 1127, Application for Extension of Time for Payment of Tax Due to Undue Hardship';
    const TOOLTIP_INCONVENIENCE = 'Treas. Reg. 1.6161­1(b)';

    return `<div class="cps-padding-bottom-32">
          <strong>In the list below, select any situations that apply to your client (you may select more than one):</strong>
        </div>
        <div class="cps-card-table cps-padding-16">
          <form name="assistantQuestion[circumstance]">
            <table style="width: 100%; text-align: left;" style="margin-left: auto; margin-right: auto;">
              <tr>
                <td class="cps-card-table__select-column cp-question-circumstance__td" style="padding-top: 10px;">
                  <label class="cps-checkbox">
                    <input type="checkbox" ng-model="vm.answer['illness']" id="cp-assistant-circumstance-illness"><span></span>
                  </label>
                </td>
                <td class="cp-question-circumstance__td">
                  <label for="cp-assistant-circumstance-illness" class="cps-cursor-pointer">
                    Your client experienced a death, serious illness, or unavoidable absence
                  </label>
                </td>
                <td class="cp-question-circumstance__td cps-padding-right-0" style="text-align: right;">
                  <div class="cps-btn-icon">
                    <a class="cps-link" ng-click="vm.showCheckbox0TextDetail =! vm.showCheckbox0TextDetail"><span class="cps-icon" ng-class="{'cps-icon-up-caret': vm.showCheckbox0TextDetail === true, 'cps-icon-down-caret': vm.showCheckbox0TextDetail === false}"></span></a>
                  </div>
                </td>
              </tr>
              <tr ng-show="vm.showCheckbox0TextDetail">
                <td class="cp-question-circumstance__td">&nbsp;</td>
                <td class="cp-question-circumstance__td" colspan="2">
                  <span style="margin-left:21px">Consider the following:</span>
                  <ul>
                    <li>This is for the taxpayer or their immediate family only.</li>
                    <li>The Issue must be addressed in a reasonable time after the death or illness.</li>
                    <li>They should show that this caused other obligations to also be impaired – not just taxes.</li>
                    <li>You need written proof of the illness or death.</li>
                    <li>Some potential examples of this could be terminal illness, surgery, substance or physical abuse, depression, disability, and emotional or psychological distress.</li>
                  </ul>
                </td>
              </tr>

              <tr>
                <td class="cps-card-table__select-column cp-question-circumstance__td" style="padding-top: 10px;">
                  <label class="cps-checkbox">
                    <input type="checkbox" id="cp-assistant-circumstance-disaster" ng-model="vm.answer['disaster']"><span></span>
                  </label>
                </td>
                <td class="cp-question-circumstance__td">
                  <label for="cp-assistant-circumstance-disaster" class="cps-cursor-pointer">
                    Your client experienced a fire, casualty, or natural disaster
                  </label>
                </td>
                <td class="cp-question-circumstance__td cps-padding-right-0" style="text-align: right;">
                  <div class="cps-btn-icon">
                    <a class="cps-link" ng-click="vm.showCheckbox1TextDetail =! vm.showCheckbox1TextDetail"><span class="cps-icon" ng-class="{'cps-icon-up-caret': vm.showCheckbox1TextDetail === true, 'cps-icon-down-caret': vm.showCheckbox1TextDetail === false}"></span></a>
                  </div>
                </td>
              </tr>
              <tr ng-show="vm.showCheckbox1TextDetail">
                <td class="cp-question-circumstance__td">&nbsp;</td>
                <td class="cp-question-circumstance__td" colspan="2">
                  <span style="margin-left:21px">Consider the following:</span>
                  <ul>
                    <li>What steps were taken to comply?</li>
                    <li>Did the taxpayer comply as soon as it was possible to?</li>
                    <li>Major disasters include hurricane, tornado, earthquake, flood, riot, casualty, or other 'emergency' or disturbance.</li>
                    <li>Consider how this may create other reasonable causes – for example a fire could cause them to be unable to access his or her records (See unable to obtain records next) or if it is a declared natural disaster – the IRS issues special instructions which may make penalties eligible for abatement through specific rules or process.</li>
                  </ul>
                </td>
              </tr>

              <tr>
                <td class="cps-card-table__select-column cp-question-circumstance__td" style="padding-top: 10px;">
                  <label class="cps-checkbox">
                    <input type="checkbox" id="cp-assistant-circumstance-incapable" ng-model="vm.answer['incapable']"><span></span>
                  </label>
                </td>
                <td class="cp-question-circumstance__td">
                  <label class="cps-cursor-pointer" for="cp-assistant-circumstance-incapable">
                    Your client was unable to obtain records
                  </label>
                </td>
                <td class="cp-question-circumstance__td cps-padding-right-0" style="text-align: right;">
                  <div class="cps-btn-icon">
                    <a class="cps-link" ng-click="vm.showCheckbox2TextDetail =! vm.showCheckbox2TextDetail"><span class="cps-icon" ng-class="{'cps-icon-up-caret': vm.showCheckbox2TextDetail === true, 'cps-icon-down-caret': vm.showCheckbox2TextDetail === false}"></span></a>
                  </div>
                </td>
              </tr>
              <tr ng-show="vm.showCheckbox2TextDetail">
                <td class="cp-question-circumstance__td">&nbsp;</td>
                <td class="cp-question-circumstance__td" colspan="2">
                  <span style="margin-left:21px">Consider the following:</span>
                  <ul>
                    <li>Why were the records unavailable?</li>
                    <li>Why were the records needed to comply?</li>
                    <li>When and how did the taxpayer become aware that they didn't have the necessary records?</li>
                    <li>What other means were explored to secure the information?</li>
                    <li>Why didn't the taxpayer estimate the information?</li>
                    <li>Did the taxpayer comply once the missing information was obtained?</li>
                    <li>Consider how the taxpayer's specific facts show they exercised ordinary business care and prudence but due to circumstances beyond their control, they were unable to comply.</li>
                  </ul>
                </td>
              </tr>

              <tr>
                <td class="cps-card-table__select-column cp-question-circumstance__td" style="padding-top: 10px;">
                  <label class="cps-checkbox">
                    <input type="checkbox" id="cp-assistant-circumstance-mistake" ng-model="vm.answer['mistake']"><span></span>
                  </label>
                </td>
                <td class="cp-question-circumstance__td">
                  <label for="cp-assistant-circumstance-mistake" class="cps-cursor-pointer">
                    Your client was subject to a mistake that was made
                  </label>
                </td>
                <td class="cp-question-circumstance__td cps-padding-right-0" style="text-align: right;">
                  <div class="cps-btn-icon">
                    <a class="cps-link" ng-click="vm.showCheckbox3TextDetail =! vm.showCheckbox3TextDetail"><span class="cps-icon" ng-class="{'cps-icon-up-caret': vm.showCheckbox3TextDetail === true, 'cps-icon-down-caret': vm.showCheckbox3TextDetail === false}"></span></a>
                  </div>
                </td>
              </tr>
              <tr ng-show="vm.showCheckbox3TextDetail">
                <td class="cp-question-circumstance__td">&nbsp;</td>
                <td class="cp-question-circumstance__td" colspan="2">
                  <span style="margin-left:21px">Consider the following:</span>
                  <ul>
                    <li>When and how did the taxpayer become aware of the mistake?</li>
                    <li>How did the taxpayer attempt to correct the mistake?</li>
                    <li>What was the relationship between the taxpayer and the person they relied on (if the mistake was not the taxpayer's own and they delegated their duty)?</li>
                    <li>Did the taxpayer then take timely steps to correct the mistake after it was discovered?</li>
                    <li>Generally a mistake does not keep with the idea of 'ordinary business care and prudence'. It will be important to rely on the facts stated above to show compliance even though a mistake could have caused a taxpayer to fail in meeting their obligations if due to circumstances beyond their control they were unable to comply.</li>
                  </ul>
                </td>
              </tr>

              <tr>
                <td class="cps-card-table__select-column cp-question-circumstance__td" style="padding-top: 10px;">
                  <label class="cps-checkbox">
                    <input type="checkbox" id="cp-assistant-circumstance-erroneous-advice" ng-model="vm.answer['erroneous_advice']"><span></span>
                  </label>
                </td>
                <td class="cp-question-circumstance__td">
                  <label for="cp-assistant-circumstance-erroneous-advice" class="cps-cursor-pointer">
                    Your client was provided or relied on erroneous advice
                  </label>
                </td>
                <td class="cp-question-circumstance__td cps-padding-right-0" style="text-align: right;">
                  <div class="cps-btn-icon">
                    <a class="cps-link" ng-click="vm.showCheckbox4TextDetail =! vm.showCheckbox4TextDetail"><span class="cps-icon" ng-class="{'cps-icon-up-caret': vm.showCheckbox4TextDetail === true, 'cps-icon-down-caret': vm.showCheckbox4TextDetail === false}"></span></a>
                  </div>
                </td>
              </tr>
              <tr ng-show="vm.showCheckbox4TextDetail">
                <td class="cp-question-circumstance__td">&nbsp;</td>
                <td class="cp-question-circumstance__td" colspan="2" style="position:relative">
                  <span style="margin-left:21px">Consider the following:</span>
                  <ul>
                    <li>This relates to tax issues that are considered too technical or complicated for a layperson.  Did they hire a tax preparer or consultant, and what is their level of understanding?</li>
                    <li>Even if a taxpayer hires someone to prepare their taxes, they are still responsible for reporting all income to the person they hired. However, there is a specific relief from certain penalties due to erroneous advice from a tax advisor for the <cps-tooltip cp-custom-element html="'${TOOLTIP_ACCURACY}'"><a>accuracy-related penalty</a></cps-tooltip>.</li>
                    <li>Was the failure to comply due to a change in the tax law that the taxpayer could not be reasonably expected to know? (you may also want to explore Ignorance of the Law as another potential reasonable cause).</li>
                    <li>Was the taxpayer unable to comply because they did not have access to their own records?</li>
                    <li>You may also wish to consider their educational level for their potential level of understanding.</li>
                    <li>Generally a taxpayer is responsible for meeting their own tax obligations and that responsibility cannot be delegated, but there can be circumstances beyond their control preventing them from being able to comply.</li>
                    <li>Additionally, there is specific relief for reliance on written or oral advice from the IRS. (See written/oral Advice from IRS next.)</li>
                  </ul>
                </td>
              </tr>

              <tr>
                <td class="cps-card-table__select-column cp-question-circumstance__td" style="padding-top: 10px;">
                  <label class="cps-checkbox">
                    <input type="checkbox" id="cp-assistant-circumstance-blame-irs" ng-model="vm.answer['blame_irs']"><span></span>
                  </label>
                </td>
                <td class="cp-question-circumstance__td" style="position:relative">
                  <label class="cps-cursor-pointer" for="cp-assistant-circumstance-blame-irs">
                    Your client relied on <cps-tooltip cp-custom-element html="'${TOOLTIP_ADVICE}'"><a>written or oral advice</a></cps-tooltip> from the IRS
                  </label>
                </td>
                <td class="cp-question-circumstance__td cps-padding-right-0" style="text-align: right;">
                  <div class="cps-btn-icon">
                    <a class="cps-link" ng-click="vm.showCheckbox5TextDetail =! vm.showCheckbox5TextDetail"><span class="cps-icon" ng-class="{'cps-icon-up-caret': vm.showCheckbox5TextDetail === true, 'cps-icon-down-caret': vm.showCheckbox5TextDetail === false}"></span></a>
                  </div>
                </td>
              </tr>
              <tr ng-show="vm.showCheckbox5TextDetail">
                <td class="cp-question-circumstance__td">&nbsp;</td>
                <td class="cp-question-circumstance__td" colspan="2">
                  <span style="margin-left:21px">Consider the following:</span>
                  <ul>
                    <li>Was it reasonable for them to rely on that advice?</li>
                    <li>Was there a clear connection between the taxpayer's situation, the advice provided, and the penalty assessed?</li>
                    <li>What was the taxpayer's prior history of tax compliance?</li>
                    <li>What type of supporting documentation is available?</li>
                    <li>If the advice is oral – this can be very difficult to prove but it depends on the circumstances and supporting documentation which could include:
                      <ul>
                        <li>A notation of the taxpayer's question to the IRS.</li>
                        <li>Documentation regarding the advice provided by the IRS.</li>
                        <li>Information regarding the office and method by which the advice was obtained.</li>
                        <li>The date the advice was provided, and</li>
                        <li>The name of the employee who provided the information.</li>
                        <li>While it isn't specifically required, you may wish to include the badge (ID) number of the IRS employee also.</li>
                      </ul>
                    </li>
                  </ul>
                </td>
              </tr>

              <tr>
                <td class="cps-card-table__select-column cp-question-circumstance__td" style="padding-top: 10px;">
                  <label class="cps-checkbox">
                    <input type="checkbox" id="cp-assistant-circumstance-ignorant" ng-model="vm.answer['ignorant']"><span></span>
                  </label>
                </td>
                <td class="cp-question-circumstance__td">
                  <label for="cp-assistant-circumstance-ignorant" class="cps-cursor-pointer">
                    Your client was ignorant of the law
                  </label>
                </td>
                <td class="cp-question-circumstance__td cps-padding-right-0" style="text-align: right;">
                  <div class="cps-btn-icon">
                    <a class="cps-link" ng-click="vm.showCheckbox6TextDetail =! vm.showCheckbox6TextDetail"><span class="cps-icon" ng-class="{'cps-icon-up-caret': vm.showCheckbox6TextDetail === true, 'cps-icon-down-caret': vm.showCheckbox6TextDetail === false}"></span></a>
                  </div>
                </td>
              </tr>
              <tr ng-show="vm.showCheckbox6TextDetail">
                <td class="cp-question-circumstance__td">&nbsp;</td>
                <td class="cp-question-circumstance__td" colspan="2">
                  <span style="margin-left:21px">Consider the following:</span>
                  <ul>
                    <li>What is the taxpayer's educational level?</li>
                    <li>Has the taxpayer ever been previously subject to the tax?</li>
                    <li>Has the taxpayer been penalized before?</li>
                    <li>Were there any recent changes to the tax forms or the law that the taxpayer could not reasonably expected to know?</li>
                    <li>What is the level of complexity of the tax or compliance issue in question?</li>
                    <li>Did the taxpayer show a reasonable good faith effort to comply with the law?</li>
                    <li>What steps did they take to comply?</li>
                    <li>In some instances taxpayers may not be aware of specific obligations to file or pay taxes, but they are still required to make reasonable efforts to determine their tax obligations. They must prove a good faith effort to comply with the law and that under their circumstances they could not have been reasonably expected to know the requirement.</li>
                  </ul>
                </td>
              </tr>

              <tr>
                <td class="cps-card-table__select-column cp-question-circumstance__td" style="padding-top: 10px;">
                  <label class="cps-checkbox">
                    <input type="checkbox" id="cp-assistant-circumstance-hardship" ng-model="vm.answer['hardship']"><span></span>
                  </label>
                </td>
                <td class="cp-question-circumstance__td">
                  <label for="cp-assistant-circumstance-hardship" class="cps-cursor-pointer">
                    Your client experienced an undue financial hardship
                  </label>
                </td>
                <td class="cp-question-circumstance__td cps-padding-right-0" style="text-align: right;">
                  <div class="cps-btn-icon">
                    <a class="cps-link" ng-click="vm.showCheckbox7TextDetail =! vm.showCheckbox7TextDetail"><span class="cps-icon" ng-class="{'cps-icon-up-caret': vm.showCheckbox7TextDetail === true, 'cps-icon-down-caret': vm.showCheckbox7TextDetail === false}"></span></a>
                  </div>
                </td>
              </tr>
              <tr ng-show="vm.showCheckbox7TextDetail">
                <td class="cp-question-circumstance__td">&nbsp;</td>
                <td class="cp-question-circumstance__td" colspan="2" style="position:relative">
                  <span style="margin-left:21px">Consider the following:</span>
                  <ul>
                    <li>An undue hardship may support the granting of an <cps-tooltip cp-custom-element html="'${TOOLTIP_EXTENSION}'"><a>extension of time</a></cps-tooltip> for paying a tax or deficiency, but it must be <cps-tooltip cp-custom-element html="'${TOOLTIP_INCONVENIENCE}'"><a>more than an inconvenience</a></cps-tooltip> to the taxpayer.</li>
                    <li>The taxpayer must show that they would sustain substantial financial loss if required to pay the tax on the due date.</li>
                    <li>Since undue hardship generally does not affect a taxpayer's ability to file, it is not generally considered reasonable cause for a Failure to File (FTF) penalty, but will be at least considered if the facts and circumstances contribute.</li>
                    <li>Financial records are required such as bank statements, bills, payments, and assets and liabilities.</li>
                    <li>Did the taxpayer make any "discretionary expenditures" prior to paying the tax on the due date; for example vacations, new cars, kids' tuition costs, a new home, unnecessary remodeling, charity, IRA or 401k contributions, etc.?</li>
                    <li>The taxpayer may claim that they had enough funds on hand but as a result of unanticipated events, was unable to pay the tax.</li>
                    <li>Was there a bankruptcy? If so, consider the inability to pay a factor if the insolvency occurred before the due date of the tax payment.</li>
                    <li>When did the taxpayer realize they couldn't pay?</li>
                    <li>Why was the taxpayer unable to pay?</li>
                    <li>What other means did the taxpayer explore to secure the necessary funds?</li>
                    <li>Did the taxpayer pay when the funds did become available?</li>
                  </ul>
                </td>
              </tr>

              <tr>
                <td class="cps-card-table__select-column cp-question-circumstance__td" style="padding-top: 10px;">
                  <label class="cps-checkbox">
                    <input type="checkbox" id="cp-assistant-circumstance-reasonable" ng-model="vm.answer['reasonable']"><span></span>
                  </label>
                </td>
                <td class="cp-question-circumstance__td">
                  <label for="cp-assistant-circumstance-reasonable" class="cps-cursor-pointer">
                    Your client exercised reasonable cause and ordinary business care
                  </label>
                </td>
                <td class="cp-question-circumstance__td cps-padding-right-0" style="text-align: right;">
                  <div class="cps-btn-icon">
                    <a class="cps-link" ng-click="vm.showCheckbox8TextDetail =! vm.showCheckbox8TextDetail"><span class="cps-icon" ng-class="{'cps-icon-up-caret': vm.showCheckbox8TextDetail === true, 'cps-icon-down-caret': vm.showCheckbox8TextDetail === false}"></span></a>
                  </div>
                </td>
              </tr>
              <tr ng-show="vm.showCheckbox8TextDetail">
                <td class="cp-question-circumstance__td">&nbsp;</td>
                <td class="cp-question-circumstance__td" colspan="2">
                  <span style="margin-left:21px">Consider the following:</span>
                  <ul>
                    <li>Did the taxpayer "exercise ordinary business care and prudence" but was still unable to comply with the law?</li>
                    <li>The events and explanations must correspond with the dates of the penalty.</li>
                    <li>Was the taxpayer able to handle other affairs in their life during this time, or were taxes the only thing that the taxpayer put off?</li>
                    <li>When circumstances changed, what attempt did the taxpayer make to become compliant? Remember - if too much time has passed between the event and compliance, it weakens the argument.</li>
                    <li>What is the taxpayer's overall compliance history? Are there problems in preceding years as well? If there are continual problems, it shows lack of business care and prudence.</li>
                    <li>The taxpayer must have supporting documentation.</li>
                  </ul>
                </td>
              </tr>

              <tr>
                <td class="cps-card-table__select-column cp-question-circumstance__td" style="padding-top: 10px;">
                  <label class="cps-checkbox">
                    <input type="checkbox" id="cp-assistant-circumstance-not_applicable" ng-model="vm.answer['not_applicable']"><span></span>
                  </label>
                </td>
                <td class="cp-question-circumstance__td">
                  <label for="cp-assistant-circumstance-not_applicable" class="cps-cursor-pointer">
                    None of the above
                  </label>
                </td>
                <td class="cp-question-circumstance__td">
                  &nbsp;
                </td>
              </tr>

            </table>
          </form>
        </div>`;
  }
});
