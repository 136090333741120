import angular from 'angular';
import './client-request-reminders.style.css';
import template from './client-request-reminders.template.html';
import 'angular/common/directives/dotted-underline.directive.js';
import * as reminderHelpers from './client-request-reminders.helper.js';
import { isNaN, isNumber, has } from 'lodash';

angular
.module('app.clients.taxes')
.component('cpClientRequestReminders', {
  bindings: {
    clientRequest: '=',
    editingReminders: '='
  },

  template,

  controllerAs: 'vm',

  controller: ['$scope', 'ClientRequestsService',
    function($scope, ClientRequestsService) {
      const vm = this;
      vm.editingReminders = false;
      vm.reminderFrequencies = reminderHelpers.reminderFrequencies;
      vm.newReminderFrequency = null;
      vm.reminderExpirations = reminderHelpers.expirationOptions;
      vm.newReminderExpiration = null;
      vm.isSaving = false;

      $scope.$watch('vm.clientRequest', remindersChanged);

      vm.save = () => {
        if (!vm.isSaving) {
          vm.isSaving = true;
          vm.editingReminders = false;
          vm.clientRequest.reminders.reminder_interval = vm.newReminderFrequency.key;
          vm.clientRequest.reminders.expiration_interval_days = vm.newReminderExpiration.key;
          vm.clientRequest.reminders.days_expires_in = Number(vm.newReminderExpiration);
          //reset all the other things
          vm.clientRequest.reminders.start_at = null;
          vm.clientRequest.reminders.expiration = null;
          remindersChanged();
          ClientRequestsService.updateClientRequest(vm.clientRequest, 'reminders')
          .finally(() => vm.isSaving = false);
        }
      }

      vm.removeReminder = () => {
        vm.clientRequest.reminders.reminder_interval = null;
        vm.clientRequest.reminders.expiration_interval_days = null;
        vm.clientRequest.reminders.days_expires_in = null;
        vm.clientRequest.reminders.start_at = null;
        vm.clientRequest.reminders.expiration = null;
        remindersChanged();
        ClientRequestsService.updateClientRequest(vm.clientRequest, 'reminders')
        .finally(() => vm.isSaving = false);
      }

      vm.isSaveDisabled = () => {
        return vm.isSaving || !vm.clientRequest || !vm.newReminderExpiration || !vm.newReminderFrequency || (vm.newReminderFrequency.key === vm.clientRequest.reminders.reminder_interval && vm.newReminderExpiration.key === vm.clientRequest.reminders.expiration_interval_days);
      }

      function remindersChanged() {
        //update the reminder text
        if (vm.clientRequest && vm.clientRequest.reminders && !!vm.clientRequest.reminders.reminder_interval) {
          switch(vm.clientRequest.reminders.reminder_interval) {
            case 'weekday':
              vm.reminderText = 'Weekdays';
            break;
            case 'every-mwf':
              vm.reminderText = 'Mon, Wed, Fri';
            break;
            case 'every-tth':
              vm.reminderText = 'Tue, Thu';
            break;
            case 'every-m':
              vm.reminderText = 'Mondays';
            break;
            default:
              vm.reminderText = 'Add a reminder';
          }

          //make sure that the correct menu items are selected
          if (!vm.editingReminders) {
            vm.newReminderExpiration = reminderHelpers.expirationOptions.find((option) => vm.clientRequest.reminders.expiration_interval_days == option.key);
            vm.newReminderFrequency = reminderHelpers.reminderFrequencies.find((frequency) => vm.clientRequest.reminders.reminder_interval == frequency.key);
          }
        } else {
          vm.reminderText = 'Add a reminder';
        }
      }

      vm.validDaysExpiresIn = () => {
        return has(vm, 'clientRequest.reminders.days_expires_in') && !isNaN(vm.clientRequest.reminders.days_expires_in) && isNumber(vm.clientRequest.reminders.days_expires_in);
      }
    }
  ],
});
