import * as stateMachine_penaltyAbatement from 'angular/app/client/taxes/programs/penalty-abatements/assistant.statemachine.js';
import * as constants_penaltyAbatement from 'angular/app/client/taxes/programs/penalty-abatements/assistant.constants.js';
import * as questionHelper_penaltyAbatement from 'angular/app/client/taxes/programs/penalty-abatements/questions.helper.js';

import * as stateMachine_innocentSpouse from 'angular/app/client/taxes/programs/innocent-spouse/assistant.statemachine.js';
import * as constants_innocentSpouse from 'angular/app/client/taxes/programs/innocent-spouse/assistant.constants.js';
import * as questionHelper_innocentSpouse from 'angular/app/client/taxes/programs/innocent-spouse/questions.helper.js';

import * as stateMachine_liens from 'angular/app/client/taxes/programs/liens/assistant.statemachine.js';
import * as constants_liens from 'angular/app/client/taxes/programs/liens/assistant.constants.js';
import * as questionHelper_liens from 'angular/app/client/taxes/programs/liens/questions.helper.js';

import * as stateMachine_levies from 'angular/app/client/taxes/programs/levies/assistant.statemachine.js';
import * as constants_levies from 'angular/app/client/taxes/programs/levies/assistant.constants.js';
import * as questionHelper_levies from 'angular/app/client/taxes/programs/levies/questions.helper.js';

import * as stateMachine_trustFund from 'angular/app/client/taxes/programs/trust-fund/assistant.statemachine.js';
import * as constants_trustFund from 'angular/app/client/taxes/programs/trust-fund/assistant.constants.js';
import * as questionHelper_trustFund from 'angular/app/client/taxes/programs/trust-fund/questions.helper.js';

// new assistant-type services that wish to use the existing framework should register their set of resources in this object array

export const assistantResources = {
  'penalty_abatement': {
    constants : constants_penaltyAbatement,
    stateMachine : stateMachine_penaltyAbatement,
    questionHelper : questionHelper_penaltyAbatement.default,
    total_num_questions : 12,
    assistantSlug: 'abatement_assistant',
    assistantOutcomeSlug: 'abatement_resolution',
  },
  'innocent_spouse': {
    constants : constants_innocentSpouse,
    stateMachine : stateMachine_innocentSpouse,
    questionHelper : questionHelper_innocentSpouse.default,
    total_num_questions : 18,
    assistantSlug: 'innocent_spouse_assistant',
    assistantOutcomeSlug: 'innocent_spouse_resolution',
  },
  'liens': {
    constants : constants_liens,
    stateMachine : stateMachine_liens,
    questionHelper : questionHelper_liens.default,
    total_num_questions : 22,
    assistantSlug: 'liens_assistant',
    assistantOutcomeSlug: 'liens_resolution',
  },
  'levies': {
    constants : constants_levies,
    stateMachine : stateMachine_levies,
    questionHelper : questionHelper_levies.default,
    total_num_questions : 18,
    assistantSlug: 'levies_assistant',
    assistantOutcomeSlug: 'levies_resolution',
  },
  'trust_fund_recovery': {
    constants : constants_trustFund,
    stateMachine : stateMachine_trustFund,
    questionHelper : questionHelper_trustFund.default,
    total_num_questions : 17,
    assistantSlug: 'trust_fund_recovery_assistant',
    assistantOutcomeSlug: 'trust_fund_recovery_resolution',
  }
};

// Services / Programs
export const PENALTY_ABATEMENTS_SERVICE_SLUG = 'penalty_abatement';
export const INNOCENT_SPOUSE_SERVICE_SLUG = 'innocent_spouse';
export const LIENS_SERVICE_SLUG = 'liens';
export const LEVIES_SERVICE_SLUG = 'levies';
export const TRUST_FUND_SERVICE_SLUG = 'trust_fund_recovery';

export const service_slugs = [PENALTY_ABATEMENTS_SERVICE_SLUG, INNOCENT_SPOUSE_SERVICE_SLUG, LIENS_SERVICE_SLUG, LEVIES_SERVICE_SLUG, TRUST_FUND_SERVICE_SLUG];


// Steps / Sections

// these are the same for the intro and the bank of questions
export const PENALTY_ABATEMENT_SECTION_SLUG = 'abatement_assistant';
export const INNOCENT_SPOUSE_SECTION_SLUG = 'innocent_spouse_assistant';
export const LIENS_SECTION_SLUG = 'liens_assistant';
export const LEVIES_SECTION_SLUG = 'levies_assistant';
export const TRUST_FUND_SECTION_SLUG = 'trust_fund_recovery_assistant';

export const assistant_slugs = [PENALTY_ABATEMENT_SECTION_SLUG, INNOCENT_SPOUSE_SECTION_SLUG, LIENS_SECTION_SLUG, LEVIES_SECTION_SLUG, TRUST_FUND_SECTION_SLUG];


// outcomes
export const PENALTY_ABATEMENT_OUTCOMES_SLUG = 'abatement_resolution';
export const INNOCENT_SPOUSE_OUTCOMES_SLUG = 'innocent_spouse_resolution';
export const LIENS_OUTCOMES_SLUG = 'liens_resolution';
export const LEVIES_OUTCOMES_SLUG = 'levies_resolution';
export const TRUST_FUND_OUTCOMES_SLUG = 'trust_fund_recovery_resolution';

export const outcome_slugs = [PENALTY_ABATEMENT_OUTCOMES_SLUG, INNOCENT_SPOUSE_OUTCOMES_SLUG, LIENS_OUTCOMES_SLUG, LEVIES_OUTCOMES_SLUG, TRUST_FUND_OUTCOMES_SLUG];
