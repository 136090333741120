import angular from "angular";
import moment from "moment";

angular.module('app.transcripts')
  .controller('TaxYearRangeSelectorController', ['$scope',
    function TaxYearRangeSelectorController($scope) {
      var vm = this;


      vm.toggled = toggled;
      vm.toggledSelectAll = toggledSelectAll;
      let currentTaxYear = moment().year() - 1;

      init()

      function init() {
        vm.range = [];
        vm.selectAll = true;
        if (!vm.selected) vm.selected = [];
        if (!vm.disabled) vm.disabled = [];
        if (!vm.failed) vm.failed = [];
        if (!vm.rangeStart) vm.rangeStart = 1990;
        if (!vm.rangeEnd) vm.rangeEnd = currentTaxYear;

        //Only enable specified boxes
        if(vm.enabled){
          vm.disabled = [];
          for (var j = vm.rangeStart; j <= vm.rangeEnd; j++) {
            if(!~vm.enabled.indexOf(j))
              vm.disabled.push(j);
          }
        }

        vm.numColumns = Math.round((vm.rangeEnd - 5) / 10) - Math.round((vm.rangeStart - 5) / 10) + 1;

        var i = 0;
        for(var col = 0; col < vm.numColumns; col++){
          var column = [];
          //var i = 0;
          do{
            var checkbox = {year: vm.rangeEnd - i};
            checkbox.selected = (vm.selected.indexOf(checkbox.year) > -1);
            checkbox.disabled = (vm.disabled.indexOf(checkbox.year) > -1);
            checkbox.failed = (vm.failed.indexOf(checkbox.year) > -1);

            column.push(checkbox);
            i++;
          }
          while ((vm.rangeEnd - i) % 10 != 9 && vm.rangeEnd - i >= vm.rangeStart);
          vm.range.push(column);
        }

        checkIfAllEnabledAreSelected();
      }

      $scope.$watch('vm.selected', () => {
        if(!!vm.selected){
          init();
        }
      });

      function toggledSelectAll() {
        for(var i = 0; i < vm.range.length; i++){
          for(var j = 0; j < vm.range[i].length; j++){
            if(!vm.range[i][j].disabled){
              vm.range[i][j].selected = vm.selectAll;
              toggled(vm.range[i][j], true);
            }
          }
        }
      }

      function checkIfAllEnabledAreSelected() {
        vm.selectAll = true;
        for(var i = 0; i < vm.range.length; i++){
          for(var j = 0; j < vm.range[i].length; j++){
            if (!vm.range[i][j].disabled && !vm.range[i][j].selected) {
              vm.selectAll = false;
            }
          }
        }
      }

      function toggled(checkbox, allChecked) {
        //Only add if not already there
        if(checkbox.selected && vm.selected.indexOf(checkbox.year) === -1){
          vm.selected.push(checkbox.year);
        }
        //only remove if it's already there
        else if (!checkbox.selected && vm.selected.indexOf(checkbox.year) > -1){
          vm.selected.splice(vm.selected.indexOf(checkbox.year),1);
        }

        if (!allChecked) {
          checkIfAllEnabledAreSelected();
        }
      }



    }
  ]);