import angular from "angular";

import 'angular/app/client/taxes/section-interactions/assistant.style.css';
import 'angular/app/client/taxes/section-interactions/assistant-shared.style.css';

import * as constants from 'angular/app/client/taxes/programs/trust-fund/assistant.constants.js';

import template from './client-type.template.html';

angular.module('app.clients.taxes').component('cpAssistantQuestionClientType', {
  bindings: {
    answer: '=?'
  },

  controllerAs: 'vm',

  controller: function (AssistantAnswersService, $scope) {
    const vm = this;

    vm.selectOption = (answer) => {
      $scope.$emit('cp:assistant-answer:selected', constants.CLIENT_TYPE, answer);
    };
  },

  template
});
