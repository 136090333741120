import angular from "angular";

angular.module('app.helpers')
  .directive('validEmail', ['emailFilter',
    function(emailFilter) {

      return {
        restrict: 'A',
        require: 'ngModel',
        link: function(scope, el, attrs, ctrl) {
          var valid;

          // add a parser that will process each time the value is
          // parsed into the model when the user updates it.
          ctrl.$parsers.unshift(function(value) {
            // test and set the validity after update.
            valid = emailFilter(value);
            ctrl.$setValidity('regexValidate', valid);

            // if it's valid, return the value to the model,
            // otherwise return undefined.
            return valid ? value : undefined;
          });

          // add a formatter that will process each time the value
          // is updated on the DOM element.
          ctrl.$formatters.unshift(function(value) {
            // validate.
            ctrl.$setValidity('regexValidate', emailFilter(value));

            // return the value or nothing will be written to the DOM.
            return value;
          });


        }
      }
    }
  ]);
