import angular from "angular";

/* An attribute directive that must also have the ng-model attribute on the element */
angular.module('app')
  .directive('cpFocusWhen', function($timeout, $parse) {
    //see http://stackoverflow.com/questions/14833326/how-to-set-focus-on-input-field
    return {
      link: function(scope, element, attrs) {
        var model = $parse(attrs.cpFocusWhen);
        scope.$watch(model, function(value) {
          if(value === true) {
            $timeout(function() {
              element[0].focus();
            });
          }
        });
      }
    };
  });
