import angular from "angular";

import 'angular/app/client/taxes/section-interactions/assistant.style.css';
import 'angular/app/client/taxes/section-interactions/assistant-shared.style.css';

import template from './able-to-satisfy.template.html';

angular.module('app.clients.taxes').component('cpAssistantQuestionAbleToSatisfy', {
  bindings: {},

  controllerAs: 'vm',

  controller: function () {
    const vm = this;
    vm.modalInstallmentAgreement = false;
    vm.modalOfferInCompromise = false;
  },

  template: template
});
