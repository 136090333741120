import angular from "angular";
import "angular/common/directives/show-errors/show-errors.config.js";
/*
* See https://github.com/paulyoder/angular-bootstrap-show-errors
 */

angular.module('app')
  .directive('showErrors', ['$timeout', '$interpolate', 'showErrorsConfig',
    function($timeout, $interpolate, showErrorsConfig) {

      var getShowSuccess, getTrigger, linkFn;
      getTrigger = function(options) {
        var trigger;
        trigger = showErrorsConfig.trigger;
        if (options && (options.trigger != null)) {
          trigger = options.trigger;
        }
        return trigger;
      };
      getShowSuccess = function(options) {
        var showSuccess;
        showSuccess = showErrorsConfig.showSuccess;
        if (options && (options.showSuccess != null)) {
          showSuccess = options.showSuccess;
        }
        return showSuccess;
      };
      linkFn = function(scope, el, attrs, formCtrl) {
        var blurred, inputEl, inputName, inputNgEl, options, showSuccess, toggleClasses, trigger;
        blurred = false;
        options = scope.$eval(attrs.showErrors);
        showSuccess = getShowSuccess(options);
        trigger = getTrigger(options);
        inputEl = el[0].querySelector('.cps-form-control[name]') || el[0].querySelector('.cps-form-control-resize[name]');
        inputNgEl = angular.element(inputEl);
        inputName = $interpolate(inputNgEl.attr('name') || '')(scope);

        if (!inputName) {
          throw new Error("show-errors element has no child input elements with a 'name' attribute and a 'form-control' class");
        }
        inputNgEl.bind(trigger, function() {
          blurred = true;
          return toggleClasses(formCtrl[inputName].$invalid);
        });
        scope.$watch(function() {
          return formCtrl[inputName] && formCtrl[inputName].$invalid;
        }, function(invalid) {
          if (!blurred) {
            return;
          }
          return toggleClasses(invalid);
        });
        scope.$on('show-errors-check-validity', function() {
          return toggleClasses(formCtrl[inputName].$invalid);
        });

        scope.$on('show-errors-reset', function() {
          return $timeout(function() {
            el.removeClass('cps-has-error');
            el.removeClass('cps-has-success');
            blurred = false;
          }, 0, false);
        });

        toggleClasses = function(invalid) {
          el.toggleClass('cps-has-error', invalid);
          if (showSuccess) {
            return el.toggleClass('cps-has-success', !invalid);
          }
        };

        return toggleClasses;
      };


      return {
        restrict: 'A',
        require: '^form',
        compile: function(elem, attrs) {

          if (attrs.showErrors.indexOf('skipFormGroupCheck') === -1) {
            if (!(elem.hasClass('cps-form-group') || elem.hasClass('cps-input-group') || elem.hasClass('cps-form-group-resize'))) {
              throw new Error("show-errors element does not have the 'form-group' or 'input-group' class");
            }
          }
          return linkFn;
        }
      };
    }
  ]);


