import PermissionHelpers from "angular/common/helpers/permission.helpers.js";

export function addHasAccessFunction(loggedInUser) {
  loggedInUser.hasAccess = (permissionsArray, user) => {
    if (!user) {
      //default to the logged in user
      user = loggedInUser;
    }
    return PermissionHelpers.hasAccess(permissionsArray, user);
  }

  return loggedInUser;
}
