import angular from "angular";
import template from "./engagement-create.template.html";
import "./engagement-create.controller.js";
import "./engagement-create.style.css";

angular.module('app.clients.taxes')
  .directive('engagementCreate', [

    function() {
      return {
        restrict: 'E',
        scope: {
          client: '='
        }, // necessary so we don't overwrite parent scope
        template: template,
        controller: 'EngagementCreateController',
        controllerAs: 'vm',
        bindToController: true
      }
    }
  ]);
