import angular from "angular";
import _ from "lodash";
import template from "./time.template.html";

angular.module('app')
  .directive('cpTime', [

    function() {

      return {
        restrict: "E",
        scope: {
          date: '=',
        },
        template: template,
        link: function(scope, el, attr) {
          scope.options = [];
          _.each(_.range(0, 24), function(i) {
            // Calculate hour times
            var key = i * 60 * 60 * 1000;
            var value = (i - 12) + ":00 PM";

            if(i === 0) value = "12:00 AM";
            else if(i === 12) value = "12:00 PM";
            else if(i < 12) value = i + ":00 AM";

            scope.options.push({
              key: key,
              value: value
            });

            // Calculate 30 minute times
            key = (i * 60 * 60 * 1000) + (60 * 30 * 1000);
            value = (i - 12) + ":30 PM";

            if (i === 0) value = "12:30 AM";
            else if (i === 12) value = "12:30 PM";
            else if (i < 12) value = i + ":30 AM";

            scope.options.push({
              key: key,
              value: value
            });
          });

          scope.$watch('selectedTime', function(option) {
            if(option) {
              updateDateFromOption(scope.date, option);
            }
          });

          scope.$watch('date', function(date) {
            if(date) {
              var option = getOptionFromDate(date);
              scope.selectedTime = option;
              updateDateFromOption(date, option);
            }
          })

          function getOptionFromDate(d) {
            var ms = (d.getHours() * 1000 * 60 * 60) + (d.getMinutes() * 1000 * 60);
            var fifteenMinutes = 15 * 1000 * 60;

            return _.find(scope.options, function(option) {
              var key = option.key;
              return Math.abs(key - ms) <= fifteenMinutes;
            }) || scope.options[0];
          }

          function updateDateFromOption(d, option) {
            var midnight = new Date(d.getFullYear(), d.getMonth(), d.getDate());
            var newDate = new Date(midnight.getTime() + option.key);
            if(newDate.getTime() !== d.getTime()) {
              scope.date = newDate;
            }
          }
        }
    }
  }]);
