import angular from 'angular';
import { asyncStacktrace } from 'auto-trace';

import 'angular/resources/service-templates.resource.js';

angular.module('app.admin').factory('ServiceTemplatesService', [
  '$q',
  'ServiceTemplatesResource',
  '$sanitize',
  function ServiceTemplatesService($q, ServiceTemplatesResource, $sanitize) {
    function sanitizeServiceObjects(thing) {
      thing.name = $sanitize(thing.name).replace(/&amp;/g, '&');
      thing.description = $sanitize(thing.description)
        .replace(/&#10;/g, ' | ')
        .replace(/&amp;/g, '&');

      if (thing.template_steps) {
        thing.template_steps = thing.template_steps.map(step => ({
          ...step,
          description: step.description.replace(/&#10;/g, ' | ').replace(/&amp;/g, '&'),
        }));
      }
    }

    return {
      getServiceTemplates: function(params) {
        var deferred = $q.defer();

        ServiceTemplatesResource.getList(params)
          .then(function success(response) {
            let serviceTemplates = response.data.service_templates.map(template => ({
              ...template,
              description: template.description.replace(/&#10;/g, ' | ').replace(/&amp;/g, '&'),
            }));
            deferred.resolve(serviceTemplates);
          })
          .catch(
            asyncStacktrace(response => {
              deferred.reject(response);
            })
          );

        return deferred.promise;
      },

      addServiceTemplate: function(params, service_template) {
        var deferred = $q.defer();

        sanitizeServiceObjects(service_template);

        ServiceTemplatesResource.post(params, service_template)
          .then(function success(response) {
            deferred.resolve(response.data.service_templates);
          })
          .catch(
            asyncStacktrace(response => {
              deferred.reject(response);
            })
          );

        return deferred.promise;
      },

      getServiceTemplate: function(params, service_template) {
        var deferred = $q.defer();

        ServiceTemplatesResource.get(params, service_template)
          .then(function success(response) {
            sanitizeServiceObjects(response.data.service_templates);

            deferred.resolve(response.data.service_templates);
          })
          .catch(
            asyncStacktrace(response => {
              deferred.reject(response);
            })
          );

        return deferred.promise;
      },

      updateServiceTemplate: function(params, service_template) {
        var deferred = $q.defer();

        sanitizeServiceObjects(service_template);

        ServiceTemplatesResource.put(params, service_template)
          .then(function success(response) {
            deferred.resolve(response.data.service_templates);
          })
          .catch(
            asyncStacktrace(response => {
              deferred.reject(response);
            })
          );

        return deferred.promise;
      },

      deleteServiceTemplate: function(params, service_template) {
        var deferred = $q.defer();

        ServiceTemplatesResource.delete(params, service_template)
          .then(function success(response) {
            deferred.resolve(response.data.service_templates);
          })
          .catch(
            asyncStacktrace(response => {
              deferred.reject(response);
            })
          );

        return deferred.promise;
      },

      // Service Template Steps operations

      addServiceTemplateStep: function(params, service_template, step) {
        var deferred = $q.defer();

        sanitizeServiceObjects(step);

        ServiceTemplatesResource.postStep(params, service_template, step)
          .then(function success(response) {
            deferred.resolve(response.data.service_template_steps);
          })
          .catch(
            asyncStacktrace(response => {
              deferred.reject(response);
            })
          );

        return deferred.promise;
      },

      getServiceTemplateStep: function(params, service_template, step) {
        var deferred = $q.defer();

        ServiceTemplatesResource.getStep(params, service_template, step)
          .then(function success(response) {
            sanitizeServiceObjects(response.data.service_template_steps);

            deferred.resolve(response.data.service_template_steps);
          })
          .catch(
            asyncStacktrace(response => {
              deferred.reject(response);
            })
          );

        return deferred.promise;
      },

      updateServiceTemplateStep: function(params, service_template, step) {
        var deferred = $q.defer();

        sanitizeServiceObjects(step);

        ServiceTemplatesResource.putStep(params, service_template, step)
          .then(function success(response) {
            deferred.resolve(response.data.service_template_steps);
          })
          .catch(
            asyncStacktrace(response => {
              deferred.reject(response);
            })
          );

        return deferred.promise;
      },

      deleteServiceTemplateStep: function(params, service_template, step) {
        var deferred = $q.defer();

        ServiceTemplatesResource.deleteStep(params, service_template, step)
          .then(function success(response) {
            deferred.resolve(response.data.service_template_steps);
          })
          .catch(
            asyncStacktrace(response => {
              deferred.reject(response);
            })
          );

        return deferred.promise;
      },
    };
  },
]);
