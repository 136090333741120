import angular from "angular";
import { catchAsyncStacktrace } from 'auto-trace';

import 'angular/app/client/taxes/section-interactions/common-actions/change-status.directive.js';
import 'angular/app/client/taxes/section-interactions/common-actions/assign-section-envelope.component.js';
import 'angular/app/client/taxes/section-interactions/common-actions/due-date.directive.js';

import 'angular/app/client/taxes/programs/assistant/assistant-answers.service.js';

import './outcomes/appeals.component.js';
import './outcomes/lien-discharge.component.js';
import './outcomes/lien-release.component.js';
import './outcomes/lien-subordination.component.js';
import './outcomes/lien-withdrawal.component.js';

import * as outcomesHelper from './assistant-outcomes.helper.js';
import * as assistantConstants from './assistant.constants.js';

import template from './assistant-outcomes.template.html';

angular.module('app.clients.taxes').component('cpOutcomesLiens', {
  bindings: {},

  controllerAs: 'vm',

  controller: function ($scope, $stateParams, AssistantAnswersService) {
    const vm = this;

    vm.assistantConstants = assistantConstants;

    // when vm.outcomes === null, it means there are definitely no outcomes. undefined means we aren't sure yet
    vm.outcomes = undefined;

    let init = () => {
      vm.appealAvailable = () => outcomesHelper.appealAvailable(vm.outcomes);
      vm.dischargeAvailable = () => outcomesHelper.dischargeAvailable(vm.outcomes);
      vm.releaseAvailable = () => outcomesHelper.releaseAvailable(vm.outcomes);
      vm.subordinationAvailable = () => outcomesHelper.subordinationAvailable(vm.outcomes);
      vm.withdrawalAvailable = () => outcomesHelper.withdrawalAvailable(vm.outcomes);

      vm.reliefUnlikely = () => outcomesHelper.reliefUnlikely(vm.outcomes);
      vm.cantCalculateOutcomes = () => outcomesHelper.cantCalculateOutcomes(vm.outcomes);

      AssistantAnswersService.getOutcomes()
        .then((outcomes) => {
          vm.outcomes = outcomes;

          vm.resolutionType = assistantConstants.APPEAL;

          if (vm.appealAvailable()) {
            vm.resolutionType = assistantConstants.APPEAL;
          }
          else if (vm.dischargeAvailable()) {
            vm.resolutionType = assistantConstants.DISCHARGE;
          }
          else if (vm.releaseAvailable()) {
            vm.resolutionType = assistantConstants.RELEASE;
          }
          else if (vm.subordinationAvailable()) {
            vm.resolutionType = assistantConstants.SUBORDINATION;
          }
          else if (vm.withdrawalAvailable()) {
            vm.resolutionType = assistantConstants.WITHDRAWAL;
          }
        })
        .catch(catchAsyncStacktrace());

    };

    init();
  },

  template: template
});
