import angular from 'angular';
import { catchAsyncStacktrace } from 'auto-trace';
import './client-request-due-date.style.css';
import template from './client-request-due-date.template.html';
import 'angular/common/directives/dotted-underline.directive.js';

angular
.module('app.clients.taxes')
.component('cpClientRequestDueDate', {
  bindings: {
    clientRequest: '=',
    isEditable: '=',
  },

  template,

  controllerAs: 'vm',

  controller: ['$scope', '$filter', 'ClientRequestsService',
    function($scope, $filter, ClientRequestsService) {
      const vm = this;

      vm.onDueAtDateChange = onDueAtDateChange;
      vm.adjustDatepicker = {bottom: 24, left: -108};

      if (vm.isEditable) {

        $scope.$watch('vm.clientRequest', (newVal, oldVal) => {
          if (vm.clientRequest && newVal.due_at !== oldVal.due_at && newVal.id == oldVal.id) {
            ClientRequestsService.updateClientRequest(vm.clientRequest, 'due_at')
            .catch(catchAsyncStacktrace());
          }
        }, true);
      }

      vm.customDatepickerFooter = `<div class="cp-due-date__remove" ng-click="vm.removeDueDate()">Remove due date</div>`;

      vm.datepickerCustomClass = ($date) => {
        if ($date.toDateString() === (new Date()).toDateString()) {
          return 'selected';
        }
        if (vm.clientRequest && vm.clientRequest.due_at && $date.getTime() === vm.clientRequest.due_at) {
          return 'active';
        }
      };

      vm.removeDueDate = () => {
        if (vm.isEditable) {
          vm.clientRequest.due_at = null;
          ClientRequestsService.updateClientRequest(vm.clientRequest, 'due_at')
          .catch(catchAsyncStacktrace());
        }
      }

      vm.getDueDateText = () => {
        return (vm.clientRequest && vm.clientRequest.due_at) ? vm.clientRequest.due_at : (vm.isEditable ? 'Add a due date' : 'No due date');
      }
      function onDueAtDateChange(e) {
        vm.clientRequest.due_at = e.detail ? e.detail.getTime() : null;
      }
    }
  ],
});
