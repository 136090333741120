import angular from "angular";
import { catchAsyncStacktrace, asyncStacktrace } from 'auto-trace';
import { showInviteClientModal } from 'contacts-ui!sofe';
import template from "./engagement-client-requests.template.html";
import context from 'angular/bootstrap/context.service.js';
import "./engagement-client-requests.style.css";
import './side-pane/unsent-client-request.component.js';
import './side-pane/practitioner-sent-client-request.component.js';
import "angular/app/clients/contact.service.js";
import * as ClientRequestsHelper from 'angular/app/client/taxes/client-requests/engagement-client-requests.helper.js';
import 'angular/app/client/taxes/client-requests/engagement-client-requests.service.js';
import _ from 'lodash';
import { hasAccess } from 'cp-client-auth!sofe';

angular.module('app.clients.taxes')
.component('cpEngagementClientRequests', {

  bindings: {
    engagement: '='
  },

  template,

  controllerAs: 'vm',

  controller: ['$rootScope', '$stateParams', '$scope', '$state', '$timeout', 'ClientRequestsService', 'ContactService',
    function EngagementClientRequestsController($rootScope, $stateParams, $scope, $state, $timeout, ClientRequestsService, ContactService) {
      const vm = this;

      vm.horizontalMargin = '0rem';
      vm.context = context.getContext();
      vm.isLoaded = false;
      vm.userHasAccess = hasAccess(vm.context.loggedInUser);
      if (!vm.userHasAccess('tasks_engagements')) {
        $state.go('403');
      }

      $scope.$watch('vm.activeClientRequest', activeClientRequestChanged);

      $scope.$watch('vm.editingSentRequest', sidePaneToggled);
      $scope.$watch('vm.editingUnsentRequest', sidePaneToggled);

      $scope.$on('cp:right-side-pane:is-hiding', () => vm.horizontalMargin = 0)

      $scope.$on('cp:right-side-pane:was-hidden', () => {
        vm.editingSentRequest = false;
        vm.editingUnsentRequest = false;
        if (!vm.doingSentAnimation)
          delete vm.activeClientRequest;
        $state.go('engagement.layout.client-requests');
      });

      $scope.$on('cp:client-request:send-now', () => {
        vm.sendSingleRequest();
      });

      $scope.$on('cp:client-request:grouping-changed', () => {
        vm.groupedRequests = ClientRequestsHelper.groupRequests(vm.clientRequests);
        fancyBlinkAnimation(vm.activeClientRequest.id, false);
      });

      $scope.$on('cp:client-request:deleted', (oldRequest, {id}) => {
        if (vm.activeClientRequest && vm.activeClientRequest.id === id) {
          delete vm.activeClientRequest;
        }
        vm.clientRequests = vm.clientRequests.filter((request) => request.id != id);
        vm.groupedRequests = ClientRequestsHelper.groupRequests(vm.clientRequests);
      });

      $scope.$on('cp-client-request:description:saving', () => {
        $scope.$broadcast('cp-client-request:saving');
      });

      $scope.$on('cp-client-request:description:save-complete', () => {
        $scope.$broadcast('cp-client-request:save-complete');
      });

      $scope.$on('cp:clients-added', (event, newIds, clientMessage) => {
        ContactService.getClient({
          clientId: $stateParams.clientId,
          include: 'users'
        })
        .then(function(successResponse) {
          loadParticipants(successResponse);

          vm.clientMessage = clientMessage;
          vm.newIds = newIds;

          // If in process of sending a request, send it
          if (vm.sendingRequest === 'single') {
            vm.sendSingleRequest();
          } else if (vm.sendingRequest === 'bulk') {
            vm.checkSendBulkRequests();
          }
        })
        .catch(catchAsyncStacktrace());
      });

      ContactService.getClient({
        clientId: $stateParams.clientId,
        include: 'users'
      })
      .then(loadParticipants)
      .catch(catchAsyncStacktrace());

      vm.goToClientRequest = (clientRequestId) => {
        vm.activeClientRequest = vm.clientRequests.find((request) => request.id == clientRequestId);

        if (vm.activeClientRequest) {
          $state.go('engagement.layout.client-requests.instance', {clientRequestId}, {notify: false})
          .then(() => {
            ClientRequestsService.getClientRequest(vm.activeClientRequest.id, vm.activeClientRequest.relationships.for.id)
            .then((request) => {
              let currentClientRequest = vm.clientRequests.find((req) => req.id == request.id);
              // These both need to trigger a digest, separately. This way, the footer can capture the actual unread comment count
              $timeout(() => {
                Object.assign(currentClientRequest, request);
                // Update grouping in case statuses changed
                vm.groupedRequests = ClientRequestsHelper.groupRequests(vm.clientRequests);
              });
              // Set to 0 so green icon turns gray in client requests table
              $timeout(() => currentClientRequest.unread_comments = 0);
            })
            .catch(catchAsyncStacktrace());
          })
          .catch(catchAsyncStacktrace());
        }
      };

      vm.createNewRequest = () => {
        //double click proofing
        if (!vm.creatingClientRequest) {
          vm.creatingClientRequest = true;
          ClientRequestsService.createClientRequest()
          .then((clientRequest) => {
            vm.clientRequests.push(clientRequest);
            vm.activeClientRequest = clientRequest;
            vm.groupedRequests = ClientRequestsHelper.groupRequests(vm.clientRequests);
            $state.go('engagement.layout.client-requests.instance', {clientRequestId: clientRequest.id}, {notify: false});
            vm.editingUnsentRequest = true;
            vm.editingSentRequest = false;
          })
          .catch(catchAsyncStacktrace())
          .finally(() => vm.creatingClientRequest = false);
        }
      }

      vm.checkSendBulkRequests = () => {
        vm.sendingRequest = 'bulk';
        // Check if client users exist
        if (!vm.activeClientIds.length) {
          showInviteClientModal(
            vm.engagement.relationships.for.id,
            (newUserIds) => {
              vm.activeClientIds = newUserIds;
              vm.checkSendBulkRequests();
            },
            'Your client hasn\'t joined you on Canopy yet. If you want to send this request, you\'ll need to invite them to Canopy\'s client portal.'
          );
        } else {
          // Check if there are any unsent requests missing titles
          let unsentRequests = _.groupBy(vm.groupedRequests.unsent, (request) => _.isEmpty(request.title));
          vm.unsentUntitledRequests = unsentRequests[true];
          vm.unsentTitledRequests = unsentRequests[false];

          if (vm.unsentUntitledRequests && vm.unsentUntitledRequests.length) {
            vm.showConfirmSendRequestsModal = true;
          } else {
            vm.sendBulkRequests(vm.groupedRequests.unsent);
          }
        }
      };

      vm.sendBulkRequests = (requests) => {
        vm.showConfirmSendRequestsModal = false;
        vm.showSendRequestsModal = true;

        // Specify notifications for new users
        let notifications = null;
        if (!_.isEmpty(vm.newIds)) {
          notifications = buildNotifications(vm.newIds, vm.clientMessage);
        }

        ClientRequestsService.sendRequests(requests.map((request) => request.id), notifications)
        .then(() => {
          requests.forEach((request) => request.first_sent_at = new Date().getTime());
          vm.groupedRequests = ClientRequestsHelper.groupRequests(vm.clientRequests);
          vm.doneSendingRequests();
        })
        .catch(asyncStacktrace((ex) => {
          vm.closeSendRequests();
          setTimeout(() => {throw ex});
        }));
      };

      vm.sendSingleRequest = () => {
        if (!vm.activeClientIds.length) {
          showInviteClientModal(
            vm.engagement.relationships.for.id,
            (newUserIds) => {
              vm.activeClientIds = newUserIds;
              vm.sendSingleRequest();
            },
            'Your client hasn\'t joined you on Canopy yet. If you want to send this request, you\'ll need to invite them to Canopy\'s client portal.'
          );
        } else {
          vm.sendingRequest = 'single';
          vm.showSendRequestsModal = true;

          // Specify notifications for new users
          let notifications = null;
          if (!_.isEmpty(vm.newIds)) {
            notifications = buildNotifications(vm.newIds, vm.clientMessage);
          }

          ClientRequestsService.sendRequests([vm.activeClientRequest.id], notifications)
          .then((request) => {
            let clientRequest = vm.clientRequests.find((request) => request.id == vm.activeClientRequest.id);
            clientRequest.first_sent_at = new Date().getTime();
            clientRequest.aggregate_updated_at = new Date().getTime();
            vm.groupedRequests = ClientRequestsHelper.groupRequests(vm.clientRequests);
            fancyBlinkAnimation(vm.activeClientRequest.id, true);
            vm.doneSendingRequests();

            $scope.$broadcast('cp:right-side-pane:do-hide');
          });
        }
      };

      vm.doneSendingRequests = () => {
        vm.requestsSent = true;
        vm.newIds = null;
        vm.clientMessage = null;
      };

      vm.closeSendRequests = () => {
        vm.showSendRequestsModal = vm.requestsSent = false;
      };

      vm.closeConfirmSendRequests = () => {
        vm.showConfirmSendRequestsModal = vm.requestsSent = false;
      };

      vm.slatIsActive = (id) => {
        return vm.activeClientRequest && vm.activeClientRequest.id == id && !vm.blinkingActiveSlat;
      };

      vm.commentClass = (totalCommentCount, unreadCommentCount) => {
        if (totalCommentCount) {
          if (unreadCommentCount) {
            return 'cps-color-primary';
          }
          return 'cps-gray-10';
        }
      };

      vm.shouldSquish = () => {
        return (vm.editingSentRequest || vm.editingUnsentRequest);
      };

      if ($stateParams.clientRequests) {
        vm.clientRequests = $stateParams.clientRequests;
        vm.groupedRequests = ClientRequestsHelper.groupRequests(vm.clientRequests);
      } else {
        vm.clientRequests = []; //empty until loaded
        ClientRequestsService.getEngagementRequests().then((requests) => {
          vm.isLoaded = true;
          vm.clientRequests = requests;
          vm.groupedRequests = ClientRequestsHelper.groupRequests(vm.clientRequests);
          if ($stateParams && $stateParams.clientRequestId) {
            vm.goToClientRequest($stateParams.clientRequestId);
          }
        })
        .catch(catchAsyncStacktrace());
      }

      function sidePaneToggled() {
        $timeout(() => {
          vm.horizontalMargin = (!!vm.editingSentRequest || !!vm.editingUnsentRequest) ? '33.6rem' : '0';
        }, 0);
      }

      function activeClientRequestChanged() {
        if (vm.activeClientRequest) {
          vm.editingSentRequest = !!vm.activeClientRequest.first_sent_at;
          vm.editingUnsentRequest = !vm.activeClientRequest.first_sent_at;
        }
      }

      function fancyBlinkAnimation(clientRequestId, unfocusWhenDone = false, numPrevBlinks = 0) {
        const millisBetweenBlink = 300;
        if (numPrevBlinks < 3) {
          vm.doingSentAnimation = true;
          vm.blinkingActiveSlat = true;
          $timeout(() => {
            vm.blinkingActiveSlat = false;
            $timeout(() => fancyBlinkAnimation(clientRequestId, unfocusWhenDone, numPrevBlinks + 1), millisBetweenBlink);
          }, millisBetweenBlink);
        } else {
          // get rid of the blue highlight
          if (unfocusWhenDone && clientRequestId === vm.activeClientRequest.id)
            delete vm.activeClientRequest;
          vm.blinkingActiveSlat = vm.doingSentAnimation = false;
        }
      }

      function loadParticipants(clientServiceResponse) {
        vm.client = clientServiceResponse;

        vm.activeClientIds = _.map(_.filter(clientServiceResponse.users, (user) =>
          user.is_activated && !user.is_deleted && user.role === 'Client'
        ), 'id');

        vm.activeTeamMembers = _.filter(clientServiceResponse.users, {
          'is_activated': true,
          'is_deleted': false,
          'role': 'TeamMember'
        });
      }

      function buildNotifications(userIds, clientMessage = null, teamMessage = null) {
        return {
          'users': userIds,
          'messages': {
            'teamMessage': teamMessage,
            'clientMessage': clientMessage
          }
        };
      }
    }
  ]
});
