import angular from "angular";
import { filter, isEmpty } from "lodash";

import template from "./assistant-intro.template.html";
import "./assistant-intro.style.css";
import "./assistant-shared.style.css";
import "angular/app/client/taxes/program-sections.service.js";

import 'angular/app/client/taxes/programs/assistant/assistant-answers.service.js';

import 'angular/app/client/taxes/section-interactions/common-actions/assign-section-envelope.component.js';
import "angular/app/client/taxes/section-interactions/common-actions/change-status.directive.js";
import "angular/app/client/taxes/section-interactions/common-actions/due-date.directive.js";

import * as assistantConstants from 'angular/app/client/taxes/programs/assistant/assistant.constants.js';

import 'angular/app/client/taxes/programs/penalty-abatements/assistant-intro.component.js';
import 'angular/app/client/taxes/programs/innocent-spouse/assistant-intro.component.js';
import 'angular/app/client/taxes/programs/liens/assistant-intro.component.js';
import 'angular/app/client/taxes/programs/levies/assistant-intro.component.js';
import 'angular/app/client/taxes/programs/trust-fund/assistant-intro.component.js';
import { catchAsyncStacktrace } from 'auto-trace';

angular.module('app.clients.taxes').component('cpAssistantIntro', {
  bindings: {
    engagement: '=',
    questionSlugs: '='
  },

  controllerAs: 'vm',

  controller: ['$scope', '$stateParams', 'ContactService', 'EngagementService', 'ProgramSectionsService', 'SectionEnvelopesService',
    function AssistantIntroController($scope, $stateParams, ContactService, EngagementService, ProgramSectionsService, SectionEnvelopesService) {
      const vm = this;

      vm.assistantConstants = assistantConstants;
      vm.$stateParams = $stateParams;
      vm.regetActiveTeamMembers = regetActiveTeamMembers;

      ContactService.getClient({clientId: $stateParams.clientId, include: 'users'})
        .then(function (successResponse) {
          vm.activeTeamMembers = filter(successResponse.users, {
            'is_activated': true,
            'is_deleted': false,
            'role': 'TeamMember'
          });
        })
        .catch(catchAsyncStacktrace())

      function regetActiveTeamMembers() {
        ContactService.getClient({clientId: $stateParams.clientId, include: 'users'})
          .then(function (successResponse) {
            vm.activeTeamMembers = filter(successResponse.users, {
              'is_activated': true,
              'is_deleted': false,
              'role': 'TeamMember'
            });
          })
          .catch(catchAsyncStacktrace())
      }

      $scope.$watch('vm.engagement', () => {
        if (vm.engagement) {
          ProgramSectionsService.get({
            engagementId: $stateParams.engagementId,
            clientId: $stateParams.clientId,
            sectionId: EngagementService.getSectionFromEngagementWithSlugs(vm.engagement, $stateParams.programSlug, assistantConstants.assistantResources[$stateParams.programSlug].assistantSlug).id
          }).then((section) => {
            vm.section = section;
            if (isEmpty($stateParams)) {
              // Jan 2021
              // this whole http in $watch is apparently a bad idea https://stackoverflow.com/a/24826053
              // however, atm I'm simply trying to stop a toast when 403s are redirecting (also, plans for migrating to react with deprecation of angularJS this year).
              // the $stateParams is switching from {stuff} to {} as the former promise resolves
              // so there is no client ID for getEnvelope()
              return;
            }
            SectionEnvelopesService.getEnvelope({
              ...$stateParams,
              sectionId: section.id,
              envelopeId: section.relationships.children[0].references
            })
            .then((envelope) => {
              vm.sectionEnvelope = envelope;
            });
          });
        }
      });

    }
  ],

  template
});
