import angular from "angular";
import { applicationProps } from 'entries/workflow-ui'
import _ from "lodash";
import "angular/app/client/general/files/files.service.js";
import "angular/resources/docs.resource.js";
import * as fileHelper from "angular/common/helpers/file.helper.js";
import toasts from "toast-service!sofe";
import { catchAsyncStacktrace } from 'auto-trace';

import "./files-index.style.css";

angular.module('app.clients.files')
  .controller('FilesIndexController', ['$scope', '$stateParams', 'FilesService', 'DocsResource', '$timeout', '$location',
    function FilesIndexController($scope, $stateParams, FilesService, DocsResource, $timeout, $location) {

      var vm = this;

      // Redirect to new docs ui
      if (vm.template === 'index') {
        $location.path(`/docs/clients/${$stateParams.clientId}/files`);
      }

      vm.loaded = false;
      vm.showingDeleted = false;
      vm.showFiles = true;
      vm.containers = {};

      vm.populateFiles = () => {
        vm.showFiles = false;
        DocsResource.getRecent($stateParams.clientId)
          .subscribe(
            files => {
              $timeout(() => {
                vm.files = files || [];
                vm.loaded = true;
                vm.addFiles = false;
                vm.showingDeleted = false;
                vm.showFiles = true;
              });
            },
            catchAsyncStacktrace()
          );
      }

      vm.populateDeletedFiles = () => {
        vm.showFiles = false;
        FilesService.getDeletedIndex({
          clientId: $stateParams.clientId
        })
          .then((resp) => {
            vm.files = resp;
            vm.loaded = true;
            vm.addFiles = false;
            vm.showingDeleted = true;
            vm.showFiles = true;
          })
          .catch(catchAsyncStacktrace());
      }

      vm.addFiles = $stateParams.addFiles == 'true' ? true : false;
      vm.loadState = 'loaded';
      vm.alerts = {};

      vm.showUploadDialog = () => {
        FilesService.showUploadDialog();
      }

      vm.getIcon = fileHelper.getFileIconPath;

      vm.restoreFile = (file) => {
        vm.files = _.without(vm.files, file);
        FilesService.restoreFile({
          clientId: $stateParams.clientId,
          fileId: file.id
        })
          .then((resp) => {
            toasts.successToast('File restored successfully');
          })
          .catch(catchAsyncStacktrace());
      };

      vm.downloadFile = downloadFile;

      vm.removeFile = (file) => {
        _.remove(vm.files, file);
        confirmRemoveFile(file);
      }

      vm.uploadFile = uploadFile;

      function uploadFile() {
        window.location.href = '/#/docs/clients/' + vm.client.id + '/files';
      }

      function downloadFile(file) {
        SystemJS.import('docs-ui!sofe').then(m => {
          m.isFileSafeToOpen(file.id).then(({trust, virusStatuses, file: freshFile}) => {
            if(trust){
              window.location.href = freshFile.download_link
            }
            else {
              vm.containers[file.id] = document.getElementById(`file-${file.id}`)
              const parcel = applicationProps.mountParcel(m.VirusModalParcel, {
                virusFound: virusStatuses.infectedDocs.length > 0,
                domElement: vm.containers[file.id],
                handleCloseModal() {
                  parcel.unmount()
                }
              })
            }
          })
        })
      }

      function confirmRemoveFile(file) {
        toasts.successToast('File deleted successfully', 'Undo', () => {
          clearTimeout(timeout);
          $timeout(() => {
            $scope.$apply(() => {
              vm.files = _.union(vm.files, [file]);
              toasts.successToast('File restored successfully');
            })
          }, 0, false);
        });

        var timeout = setTimeout(() => {
          FilesService.deleteFile({
            clientId: $stateParams.clientId,
            fileId: file.id
          })
        }, 3000);
      }

      // Listen for when the files are uploaded and repopulate the file list
      $scope.$on('cp:filesUploaded', () => {
        vm.populateFiles();
      });

      vm.populateFiles(); // initialize the file list
    }
  ]);
