import angular from "angular";
import template from "./comment.template.html";
import "./comment.style.css";
import "angular/common/services/comments.service.js";
import context from "angular/bootstrap/context.service.js";

angular.module('app')
.component('cpComment', {
  bindings: {
    'comment': '=',
    'isEditable': '=',
    'deleteComment': '&',
    'deletable': '<',
  },

  template,

  transclude: true,

  controllerAs: 'vm',

  controller: ['CommentsService',
    function(CommentsService) {
      const vm = this;
      vm.loggedInUser = context.getContext().loggedInUser;

      let init = () => {
        vm.revertCommentEdit();
        vm.editMode = false;
      };

      vm.revertCommentEdit = () => {
        vm.tempCommentBody = vm.comment.body;
      };

      init();

      vm.saveComment = () => {
        vm.comment.body = vm.tempCommentBody;
        CommentsService.update({commentId: vm.comment.id}, vm.comment);
      };
    }
  ],
});
