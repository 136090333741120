import angular from "angular";

import "angular/app/clients/contact.service.js";
import { catchAsyncStacktrace } from 'auto-trace';
import {redirectOrCatch} from 'fetcher!sofe'

angular.module('app.clients.taxes')
  .controller('EngagementsPermissionAndBreadcrumbController', ['ContactService', '$stateParams', '$state', 'EngagementService', '$scope', '$timeout',
    function EngagementsPermissionAndBreadcrumbController(ContactService, $stateParams, $state, EngagementService, $scope, $timeout) {
      var vm = this;
      vm.initialized = false;

      vm.$state = $state;

      let engagementSubscription;

      $scope.$on('$destroy', () => {
        if (engagementSubscription) {
          engagementSubscription.unsubscribe();
        }
      });

      init();

      function init() {
        if (!vm.client) {
          ContactService.getClient({
            clientId: $stateParams.clientId,
            include: 'users'
          })
          .then((successResponse) => {
            // Make sure client is active before fetching engagement
            if (successResponse.is_archived || successResponse.is_deleted) {
              $state.go('404');
            } else {
              vm.client = successResponse;

              if ($stateParams.engagementId) {
                engagementSubscription = EngagementService
                  .getActiveEngagement()
                  .subscribe(
                    engagement => {
                      if ($state.is('engagement')) {
                        //TODO delete when it's possible to have empty engagements
                        $state.go('engagement.layout.program.section', {
                          programSlug: engagement.program_data.programs[0].slug,
                          sectionSlug: engagement.program_data.programs[0].sections[0].slug
                        })
                      }
                      $timeout(() => {
                        vm.engagement = engagement;
                        vm.initialized = true;
                      });
                    }, redirectOrCatch()
                  );
              }
            }
          })
          .catch(catchAsyncStacktrace())
        } else {
          vm.initialized = true;
        }
      }

    }
  ]);
