import angular from "angular";
import _ from 'lodash';

angular.module('app.clients.notes')
  .controller('EditNoteController', ['$timeout',
    function EditNoteController($timeout) {
      var vm = this;

      vm.loadState = 'loaded';
      vm.alerts = {};
      vm.isEditing = true;
      vm.toolbarName = _.uniqueId('toolbar');

      vm.save = save;
      vm.saveAndClose = saveAndClose;
      vm.keyDown = keyDown;

      var timeout;

      // Save after 10 seconds of inactivity
      function keyDown() {
        if (timeout) {
          clearTimeout(timeout);
        }
        timeout = setTimeout(save, 10000);
      }

      function save() {
        if (!vm.note.id && !vm.note.title && !vm.note.body) return;
        if (!vm.saving) {
          vm.saving = true;
          vm.saveNote(vm.note, false, () => {
            vm.saving = false;
            vm.allChangesSaved = true;
            $timeout(() => {vm.allChangesSaved = false}, 3000);
          });
        }
      }

      function saveAndClose() {
        if (timeout) {
          clearTimeout(timeout);
        }
        if (!vm.saving) {
          vm.saving = true;
          vm.saveNote(vm.note, true, () => {
            vm.saving = false
          });
        }
      }

    }
  ]);
