import angular from "angular";

angular.module('app.clients.taxes')
  .controller('DueDateController', ['$scope', '$stateParams', 'SectionEnvelopesService',
    function ($scope, $stateParams, SectionEnvelopesService) {
      let vm = this;
      vm.initialized = false;

      vm.onDueAtDateChange = onDueAtDateChange;
      vm.toggleCalendar = toggleCalendar;
      vm.showCalendar = false;

      $scope.$watch('vm.sectionEnvelope', () => {
        if (vm.sectionEnvelope) {
          vm.due_at = vm.sectionEnvelope.due_at;
          vm.initialized = true;
        }
      });

      $scope.$watch('vm.due_at', () => {
        if (vm.sectionEnvelope && vm.due_at && vm.sectionEnvelope.due_at !== new Date(vm.due_at).getTime()) {
          vm.sectionEnvelope.due_at = new Date(vm.due_at).getTime();
          updateEnvelope();
          vm.initialized = true;
        }
      });

      vm.datepickerCustomClass = ($date) => {
        if ($date.toDateString() === (new Date()).toDateString()) {
          return 'selected';
        }
        if (vm.sectionEnvelope && $date.getTime() === vm.sectionEnvelope.due_at) {
          return 'active';
        }
      };

      vm.removeDueDate = () => {
        vm.sectionEnvelope.due_at = 0;
        updateEnvelope();

        //hide the datepicker
        let popups = document.querySelectorAll('.datepicker.datepicker-dropdown');
        for (let i = 0; i < popups.length; i++) {
          if (popups[i].style.display !== 'none') {
            popups[i].style.display = 'none';
            break;
          }
        }
      };

      function onDueAtDateChange(e) {
        if (e.detail) {
          vm.due_at = e.detail;
        } else {
          vm.removeDueDate();
        }
        vm.showCalendar = false;
      }

      function toggleCalendar() {
        if (!vm.showCalendar) {
          vm.showCalendar = true;
          document.body.addEventListener('click', hideCalendar)
        }
        function hideCalendar() {
          vm.showCalendar = false;
          document.body.removeEventListener('click', hideCalendar);
        }
      }

      function updateEnvelope() {
        SectionEnvelopesService.updateEnvelope({
          ...$stateParams,
          sectionId: vm.sectionEnvelope.relationships.belongs_to.id,
          envelopeId: vm.sectionEnvelope.id,
          envelope: {
            description: vm.sectionEnvelope.description,
            due_at: vm.sectionEnvelope.due_at,
            id: vm.sectionEnvelope.id,
            relationships: vm.sectionEnvelope.relationships,
            status: vm.sectionEnvelope.status,
            title: vm.sectionEnvelope.title,
            type: vm.sectionEnvelope.type
          }
        });
      }
    }
  ]);
