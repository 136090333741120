import {isString, each, reduce, isNumber, map, indexOf, sortBy} from 'lodash';
import moment from 'moment';
import { columnTitles } from "./transcript.constants.js";

const allUpperCaseList = ['csed'];

var colTitles = columnTitles;

export function snakeCaseToText(str) {
  var frags = str.split('_');
  for (var i=0; i<frags.length; i++) {
    frags[i] = allUpperCaseList.findIndex((listItem) => listItem == frags[i]) >= 0 ? frags[i].toUpperCase() : frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }
  return frags.join(' ');
}

export function lookupColumnTitle(key){
  return colTitles[key] || snakeCaseToText(key);
}

export function getColumnTitles(firstRow){
  let keys = Object.keys(firstRow);
  return keys.map(lookupColumnTitle);
}

export function getRowData(row){
  let new_row = map(row, format);
  return new_row;
}

export function format(value, key){
  if(!isString(key)){
    return value;
  }
  else if(key.indexOf('date') > -1 && Number.isInteger(value)){
    return moment(value).format('L');
  }
  else if(isString(value) && value.match(/\d+\.\d\d/)){
    return toCurrency(value);
  }

  return value;
}

export function toCurrency(value){
  var currency = '$' + parseFloat(value).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
  if (currency == '$-0.00') {
    currency = '$0.00';
  }
  return currency.replace('$-', '-$');
}

export function findColumsToTotal(row){
  var keys = [];
  each(row, (value, key) => {
    if (isString(value) && value.match(/\d+\.\d\d/)) keys.push(key);
  });
  return keys;
}

export function calcTotals(data_object){
  let colsToTotal = findColumsToTotal(data_object[0]);
  if(colsToTotal.length == 0) return null;

  var totals_row = [];
  each(data_object[0], (value, key) =>{
    if(colsToTotal.includes(key)){
      totals_row.push(reduce(data_object, (result, row) => {
        if (row[key] != '')
          return result += parseFloat(row[key]);
        else
          return result;
      }, 0));
    }
    else totals_row.push("");
  });
  totals_row[0] = 'Total';
  let formated_totals = map(totals_row, (val) => {
    if(isNumber(val))
      return toCurrency(val);
    else{
      return val
    }
  });
  return formated_totals;

}

export function toTableFormat(data_object, doCalcTotals=true){
  if(data_object.length == 0) return null;
  let headers = getColumnTitles(data_object[0]);

  if (doCalcTotals) {
    let totals = calcTotals(data_object);
    if(totals) data_object.push(totals);
  }

  let row_data = data_object.map(getRowData);
  return sortColumns(data_object, {headers, row_data});
}

export function sortColumns(data_object, data_table){
  //create array with correct order of headers for comparison against data_table
  let headers = [];
  each(data_object["columns"], (column) => { headers.push(column.label); });

  //sort the data_table against the headers array.
  let indexArray = [];
  let tempData = [];
  each(headers, (key) => {
    let found = false;
    data_table.headers = data_table.headers.filter((header) => {
      if(!found && header === key) {
        //create an array of the indices of each column before being sorted
        //so that I can move each column in the "data" portion of the data_table
        //to the new index. Ex: [4, 0, 3, 1, 2] is used to sort old columns to new indices.
        indexArray.push(indexOf(data_table.headers, header));
        found = true;
        return false;
      } else {
        return true;
      }
    })

    //now I'll sort the "data" portion by moving each value at the old index
    //to the new location from the indexArray

    each(indexArray, (index) => { tempData.push( data_table.splice(index, 1) ) })
    data_table.data = tempData;
  })
  //Sort rows by decsending years
  data_table.row_data = sortBy(data_table.row_data, (row) => {return row[0] * -1})
  return data_table;
}

//Desired Output Format
// accountOverview = {
//  header: ['Year',  'Return filed', 'Filing Date',  'Filling status', 'Liability',  'Interest', 'Penalty',  'Total Liability',  'Examination Active', 'Lein Active'],
//  data: [
//    ['2010',  'Original', '3/25/2011',  'MFJ',    '$9,179.20',  '$26.44',   '$35.87',   '$9,241.51',  '',   ''],
//    ['2009',  'SFR',    '',       'MFJ',    '$5,780.00',  '$2,230.00',  '$1,200.00',  '$9,210.00',  'Yes',  ''],
//    ['2008',  'Amended',  '6/10/2009',  'S',    '$2,785.00',  '$1,100.00',  '$500.00',    '$4,385.00',  '',   ''],
//    ['2007',  'Original', '3/15/2008',  'S',    '$0.00',    '',       '',       '$0.00',    '',   ''],
//    ['2006',  'Original', '3/25/2007',  'S',    '$0.00',    '',       '',       '$0.00',    '',   ''],
//    ['2005',  'Original', '4/2/2006',   'S',    '$0.00',    '',       '',       '$0.00',    '',   ''],
//    ['Total', '',     '',       '',     '$17,744.20', '$3,356.44',  '$1,735.87',  '$22,836.51', '',   '']
//  ]
// };
