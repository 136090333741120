import angular from 'angular';
import context from 'angular/bootstrap/context.service.js';
import "angular/common/services/letters.service.js";
import "angular/common/services/esign.service";
import { catchAsyncStacktrace } from 'auto-trace';
import { includes, noop, get} from 'lodash';

import template from './client-request-signing-request.template.html';
import './client-request-signing-request.style.css';

angular.module('app.clients.taxes')
.component('cpClientRequestSigningRequest', {
  bindings: {
    clientRequest: '=',
  },

  controllerAs: 'vm',

  controller: function (LettersService, $stateParams, $scope, $state, EsignService) {
    const vm = this;

    vm.loading = false;
    vm.shouldShowSigningModal = $stateParams.signing;
    vm.hasSigned = false;
    vm.completedSigningNum = 0;

    vm.$onInit = () => {
      vm.currentContext = context.getContext();
      vm.currentUserId = vm.currentContext.loggedInUser.directory_user_id;
      vm.isClient = !!vm.currentContext.loggedInUser.role === "Client";

      vm.buildLetterBadgeText();
    }

    $scope.$watch('vm.clientRequest.id', () => {
      if (vm.clientRequest) vm.buildLetterBadgeText();
    });

    vm.buildLetterBadgeText = async () => {
      vm.signers = {};
        try {
          vm.loading = true;
          const signatures = !vm.clientRequest.task_id ? await LettersService.getSignatures({signingId: vm.clientRequest.pivot_id}) : await EsignService.getEsignDoc(vm.clientRequest.tool_type_id);
          if(vm.clientRequest.task_id){
            vm.clientRequest.esignDocument = signatures;
          }
          signatures.signing_locations.map(signingLocation => {
            const signatorId = signingLocation.signatory && signingLocation.signatory.id ? signingLocation.signatory.id : signingLocation.signatory_user_id;
              vm.signers[signatorId] = signingLocation.signed ? signingLocation.signed + 0 : !!signingLocation.completed_at + 0;
          });

          vm.hasSigned = vm.signers[vm.currentUserId];
          vm.shouldSign = includes(vm.signers, vm.currentUserId);
          vm.completedSigningNum = Object.values(vm.signers).reduce((a,b) => a+b, 0);
          vm.loading = false;
          $scope.$apply();
        }
        catch(ex){
          SystemJS.import('sentry-error-logging!sofe')
              .then((sentry) => {
                sentry.setUser(vm.currentContext.loggedInUser);
                sentry.captureException(ex);
              })
              .catch(catchAsyncStacktrace());
        }
    }

    vm.getSignersLength = () => {
      return Object.keys(vm.signers).length;
    }


    vm.launchSigningModal = () => {
      if(vm.clientRequest.esignDocument) {
        //Logs a view when user clicks on the blue button.
        //This can't be logged above because they're not actually opening signing-ui...here they are.
        EsignService.getEsignDoc(vm.clientRequest.esignDocument.id, true).then(noop);
      }
      SystemJS.import('signing-ui!sofe')
        .then(m => {
          if(vm.clientRequest.task_id){
            m.EsignSigningParcel.showSigningModal(vm.getSigningModalProps())
          }
          else {
            m.LetterSigningParcel.showSigningModal(vm.getSigningModalProps())
          }
        })
        .catch(err => setTimeout(() => { throw err }));
    }

    vm.getSigningModalProps = () => {
      return {
        context: vm.currentContext,
        clientId: $stateParams.clientId,
        modalClosed: vm.signingModalClosed.bind(this),
        signingId: vm.clientRequest.pivot_id,
        title: vm.clientRequest.title,
        clientRequestId: vm.clientRequest.id,
        documentType: vm.clientRequest.task_id ? 'file' : 'letter',
        documentId: vm.clientRequest.esignDocument ? vm.clientRequest.esignDocument.id : vm.clientRequest.pivot_id,
        esignDocument: vm.clientRequest.esignDocument,
        engagementId: get(vm.clientRequest, 'relationships.for.id', undefined),
      }
    }

    vm.signingModalClosed = (client_request_status) => {
      // update the currently selected client request with an updated status
      if (vm.clientRequest && vm.clientRequest.status !== client_request_status && includes(["in_progress", "needs_review", "complete"], client_request_status)) {
        vm.clientRequest.status = client_request_status;
      }

      if (vm.isClient) {
        $state.go('client.general.requests.instance', {...$stateParams, signing: null, clientRequestId: vm.clientRequest.id}, {notify: true, reload: true});
      }
    }

  },

  template,
});
