import angular from "angular";

import template from './assistant.template.html';

import './questions/community-property-state.component.js';
import './questions/erroneous-items.component.js';
import './questions/know.component.js';
import './questions/separate-property.component.js';
import './questions/multi-select.component.js';
import './questions/unfair-13.component.js';
import './questions/unfair-18.component.js';
import './questions/unfair-7.component.js';

angular.module('app.clients.taxes').component('cpAssistantInnocentSpouse', {
  bindings: {
    question: '='
  },

  controller: function ($state, $stateParams) {
    const vm = this;
    vm;
  },

  controllerAs: 'vm',

  template: template
});