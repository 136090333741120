import angular from "angular";
import template from "./engagement-summary.template.html";
import "./engagement-summary.controller.js";
import "./engagement-summary.style.css";

angular.module('app.clients.taxes')
  .directive('cpEngagementSummary', [
    function cpEngagementSummary() {
      return {
        restrict: 'E',
        scope: {
          engagement: '='
        },
        template: template,
        controller: 'EngagementSummaryController',
        controllerAs: 'vm',
        bindToController: true
      }
    }
  ]);
