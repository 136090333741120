import angular from "angular";
import template from "./progress-bar.template.html";

import "./progress-bar.controller.js";
import "./progress-bar.style.css";

angular.module('app')
  .directive('cpProgressBar', [
    function cpProgressBar() {
      return {
        restrict: 'E',
        scope: {
          minLabel: '@',
          maxLabel: '@',
          progressPercent: '@',
          backgroundColor: '@',
          borderColor: '@',
          progressMarker: '@'
        },
        template: template,
        controller: 'ProgressBarController',
        controllerAs: 'vm',
        bindToController: true
      }
    }
  ]);
