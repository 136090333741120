import angular from 'angular';
import './dotted-underline.style.css';

angular
.module('app')
.directive('cpDottedUnderline', function() {
  return {
    restrict: 'A',
    link: function(scope, element, attrs, ngModel) {
      element.addClass('cp-dotted-underline');
    }
  };
});
