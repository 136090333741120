import * as questionSlugs from './assistant.constants.js';

// Questions
let questions = {};

questions[questionSlugs.UNPAID_EMPL_TAX] = {
  questionText: 'Are there unpaid employment taxes that are owed by a business that your client is responsible for, or could be held responsible for?',
  type: 'boolean'
};

questions[questionSlugs.CLIENT_TYPE] = {
  questionText: 'Who is your client?',
  type: 'radio'
};

questions[questionSlugs.CLIENT_PRINCIPAL] = {
  questionText: `Is your client also an owner, officer or operator of the business?`,
  type: 'boolean'
};

questions[questionSlugs.CAN_PREVENT] = {
  questionText: 'Can the business prevent a TFRP investigation by paying the entire taxes owed in full now or at least the Trust Fund portion of the taxes owed in full?',
  type: 'boolean'
};

questions[questionSlugs.PAY_BALANCE_24_MO] = {
  questionText: 'Can the business pay the balance in full within 24 months?',
  type: 'boolean'
};

questions[questionSlugs.BALANCE_LESS] = {
  questionText: 'Is the total unpaid balance less than $25,000?',
  type: 'boolean'
};

questions[questionSlugs.FULLY_COMPLIANT] = {
  questionText: 'Is the business fully compliant with filings and current payments (for this purpose, consider payment compliance for the current quarter or period, not prior periods)?',
  type: 'boolean'
};

questions[questionSlugs.RECEIVED_1058] = {
  questionText: 'Has the business received a 1058, Final Notice of Intent to Levy and Notice of your Right to a hearing?',
  type: 'boolean'
};

questions[questionSlugs.RECEIPT_OF_NOTICE] = {
  questionText: 'Is it within 30 days of the receipt of the notice, or alternatively, within one year?',
  type: 'boolean'
};

questions[questionSlugs.FINANCIALLY_INCAPABLE] = {
  questionText: 'Is your client financially incapable of paying the TFRP regardless of whether a responsible / willful party?',
  type: 'boolean'
};

questions[questionSlugs.RECEIVED_1153] = {
  questionText: 'Did your client receive a letter 1153 (Proposed Assessment of the Trust Fund Recovery Penalty)?',
  type: 'boolean'
};

questions[questionSlugs.DAYS_LAPSED] = {
  questionText: 'Has the 60 days from the date on the letter lapsed to file an appeal of the Trust Fund Recovery Penalty?',
  type: 'boolean'
};

questions[questionSlugs.DISPUTE_ASSESSED_PENALTY] = {
  questionText: 'Does your client dispute the proposed or already assessed Trust Fund Recovery Penalty?',
  type: 'boolean'
};

questions[questionSlugs.RESPONSIBLE_PERSON] = {
  questionText: 'Is your client a responsible person required to collect, account for, and pay over any taxes held in trust, and willfully failed to do so?',
  type: 'boolean'
};

questions[questionSlugs.COMPLETED_4180] = {
  questionText: 'Has your client completed a Form 4180 Interview with a Revenue Officer?',
  type: 'boolean'
};

questions[questionSlugs.OTHER_PERSON] = {
  questionText: 'Are there other potentially responsible person(s) that could be liable for the Trust Fund taxes?',
  type: 'boolean'
};

questions[questionSlugs.SIGNER_ON_ACCOUNT] = {
  questionText: 'Was your client a signer on the bank account and/or did your client have authority to sign checks on behalf of the business?',
  type: 'boolean'
};

questions[questionSlugs.RECEIVED_3586] = {
  questionText: 'Has your client received a Letter 3586 indicating he/she is under civil investigation for failure to pay trust fund taxes to the IRS or is there any other belief that your client is being investigated or will be soon?',
  type: 'boolean'
};

export default { questions };
