import angular from 'angular';
import template from './archived-notes-list.template.html';
import './archived-notes-list.style.css';

import './archived-notes-single-note.component.js';

angular
.module('app.clients.notes')
.component('archivedNotesList', {
  bindings: {
    notes: '=',
    expanded: '=',
  },
  template,
  controllerAs: 'vm',
})
