import angular from "angular";

angular.module('app')
  .directive('ngClick', function ($timeout) {
    var delay = 500;   // min milliseconds between clicks

    return {
      restrict: 'A',
      priority: -1,   // cause out postLink function to execute before native `ngClick`'s
              // ensuring that we can stop the propagation of the 'click' event
              // before it reaches `ngClick`'s listener

      link: function (scope, elem) {
        var debouncing = {};      //Map of the elements that are being debounced

        function onClick(evt) {
          if (!debouncing[evt.target]) {                      //Allow the click to propigate if the event target is not already being debounced
            debouncing[evt.target] = true;                    //Mark the element as being debounced
            setTimeout(function () { delete debouncing[evt.target] }, delay);   //After the timeout set remove the elemnent from the debouncing list
          } 
          else {                  //Ignore aditional clicks until the debounce timeout has ended          
            evt.preventDefault();        //Prevent default event from firing
            evt.stopImmediatePropagation();  //Prevent other directives from picking up the event  
          }
        }

        scope.$on('$destroy', function () { elem.off('click', onClick); });
        elem.on('click', onClick);
      }
    };
  });
