import angular from 'angular';
import { catchAsyncStacktrace } from 'auto-trace';

/* Topnav secondary is th secondary navbar at the top.
 * When present, the contact-menu should not be present.
 */
angular
.module('app')
.directive('topnavSecondary', [
  function TopnavSecondary() {
    return {
      restrict: 'A',
      compile: function(element, attrs) {
        element.addClass('cps-topnav-secondary');
        return {
          post(scope, element, attrs) {
            let pnhDisposable, pnwDisposable, isDestroyed = false;

            SystemJS
            .import('primary-navbar!sofe')
            .then(primaryNavbar => {
              if (isDestroyed)
                return;

              pnhDisposable = primaryNavbar
                .primaryNavHeightObs
                .subscribe(height =>  {
                  if (!isDestroyed) {
                    element.css('top', height);
                  }
                });

              pnwDisposable = primaryNavbar
                .primaryNavWidthObs
                .subscribe(width => {
                  if (attrs.addLeftMargin && width && !isDestroyed) {
                    element.css('marginLeft', width)
                    element.css('width', `calc(100% - ${width}px)`)
                  }
                })

            })
            .catch(catchAsyncStacktrace());

            scope.$on('$destroy', () => {
              isDestroyed = true;
              if (pnhDisposable) {
                pnhDisposable.unsubscribe();
              }
              if (pnwDisposable) {
                pnwDisposable.unsubscribe()
              }
            });
          }
        }
      }
    }
  }
]);
