export function stripHTML(htmlString) {
  const doc = new DOMParser().parseFromString(htmlString, 'text/html')
  if (doc.body.innerHTML == undefined || doc.body.children.length === 0) {
    return htmlString
  } else {
    const fragment = recursiveCleanHTML(doc.body)
    const newBody = document.createElement('body')
    newBody.appendChild(fragment)
    return newBody.innerHTML
  }
}

export function recursiveCleanHTML (el) {
  const acceptedTags = ['UL', 'OL', 'LI', 'B', 'U', 'I', 'H1', 'H2', 'H3', 'P']
  if (el.children.length === 0) {
    if (acceptedTags.includes(el.tagName)) {
      while(el.attributes.length > 0) {
        // remove all attributes from the supported element
        el.removeAttribute(el.attributes[0].name)
      }
      return el
    } else if (el.innerText.length > 0) {
      // In the case that the unsupported element has inner text
      //  we want to keep the text.
      const newElement = document.createElement('p')
      newElement.innerText = el.innerText
      return newElement
    }
  } else {
    const children = [...el.children].map(c => recursiveCleanHTML(c)).filter(c => c !== undefined)
    let parent = recursiveCleanHTML(el.cloneNode(false))
    if (parent === undefined) {
      parent = document.createDocumentFragment()
    }
    children.forEach(child => {
      parent.appendChild(child)
    })
    return parent
  }
}
