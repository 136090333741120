import angular from 'angular';
import { asyncStacktrace } from 'auto-trace';
import _ from 'lodash';
import 'angular/app/client/participants/client-participant.service.js';
import { hasAccess as userHasAccess } from 'cp-client-auth!sofe';

angular.module('app.clients').controller('ClientController', [
  '$q',
  '$state',
  '$stateParams',
  'context',
  '$scope',
  '$timeout',
  'client',
  'ClientParticipantService',
  'ContactService',
  function ClientController(
    $q,
    $state,
    $stateParams,
    context,
    $scope,
    $timeout,
    client,
    ClientParticipantService,
    ContactService
  ) {
    var vm = this;
    vm.context = context;
    vm.hasAccess = userHasAccess(vm.context.loggedInUser);

    vm.client = client;
    vm.client.deleteParticipant = deleteParticipant;
    vm.billingUiRoutes = billingUiRoutes;
    vm.clientIfDash = clientIfDash;
    vm.notesUiEnabled = notesUiEnabled

    // set the currentstep in the tour
    $scope.currentStep = 0;

    $scope.$on('cp:contact-data-updated', (event, contact) => {
      $timeout(() => {
        vm.client = ContactService.scaffoldClient(
          contact,
          false,
          vm.context.loggedInUser.role.toLowerCase() === 'client'
        );
        vm.client.deleteParticipant = deleteParticipant;
      });
    });

    function deleteParticipant(params) {
      var deferred = $q.defer();

      ClientParticipantService.deleteClientParticipant(params).then(
        function(successResponse) {
          vm.client.users = _.reject(vm.client.users, function(user) {
            return user.id == params.userId;
          });
          deferred.resolve(successResponse);
        },
        asyncStacktrace(response => {
          deferred.reject(response);
        })
      );

      return deferred.promise;
    }

    function billingUiRoutes() {
      return (
        $state.includes('client.billing.time_spent') ||
        $state.includes('client.billing.invoices.index') ||
        $state.includes('client.billing.dashboard.index') ||
        $state.includes('client.billing.payments.index') ||
        $state.includes('client.billing.payments.upcoming') ||
        $state.includes('client.billing.payments.recurring') ||
        $state.includes('client.billing.payments.refunds') ||
        $state.includes('client.billing.payments.overpayments') ||
        $state.includes('client.billing.payments.receipt') ||
        $state.includes('client.billing.payments') ||
        $state.includes('client.billing.credits.index') ||
        $state.includes('client.billing.payment-settings')
      );
    }

    function notesUiEnabled() {
      if (
        $state.includes('client.general.notes') ||
        $state.includes('client.general.notes.index') ||
        $state.includes('client.general.archived-notes') ||
        $state.includes('edit-note')
      ) {
        return true
      }
      return false;
    }

    function clientIfDash() {
      return $state.includes('client.general.dashboard')
        ? vm.context.loggedInUser.role === 'Client'
        : true
    }
  },
]);
