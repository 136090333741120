import angular from "angular";

angular.module('app')
.factory('AttachmentsResource', ['$http',
  function AttachmentsResource($http) {

    return {
      getList: (params) => $http.get(`/api/clients/${params.clientId}/attachments?pivot_type=${params.pivot_type}&pivot_id=${params.pivot_id}`),

      get: (clientId, attachmentId) => $http.get(`/api/clients/${clientId}/attachments/${attachmentId}`),

      targetPathEnum: {CLIENTS: 'clients', TENANTS: 'tenants'},

      // this endpoint should be used for
      // /api/clients/{id}/attachments or
      // /api/tenants/{id}/attachments
      post: (params, attachment) => {
        const { targetPath, targetId, pivotId, pivotType } = params;

        return $http.post(
          `/api/${targetPath}/${targetId}/attachments?pivot_id=${pivotId}&pivot_type=${pivotType}`,
          {
            attachments: attachment,
          },
          { headers: { 'content-type': 'application/json' } }
        );
      },

      delete: (params) => $http.delete(`/api/clients/${params.clientId}/attachments/${params.attachmentId}`),

      patch: (clientId, attachmentId, payload) => $http.patch(`/api/clients/${clientId}/attachments/${attachmentId}`, payload),

      getListForTenant: (params) => $http.get(`/api/tenants/${params.tenant_id}/attachments?pivot_type=${params.pivot_type}&pivot_id=${params.pivot_id}`),

      postForTenant: (params, attachment) => $http.post(`/api/tenants/${params.tenant_id}/attachments`, {"attachments": attachment}),

      deleteForTenant: (params) => $http.delete(`/api/tenants/${params.tenant_id}/attachments/${params.attachmentId}`)
    };
  }
]);
