import angular from 'angular';
import context from 'angular/bootstrap/context.service.js';
import { catchAsyncStacktrace } from 'auto-trace';
import { truncateFilename } from 'docs-ui!sofe';

import Dropzone from "dropzone";
import * as dropzoneHelper from 'angular/common/helpers/dropzone.helper.js';

import * as fileHelper from "angular/common/helpers/file.helper.js";
import canopyUrls from "canopy-urls!sofe";

import template from "./client-request-description.template.html";
import "./client-request-description.style.css";
import "./client-request-description.service.js";
import './client-request-file.component.js';
import './client-request-signing-request.component.js';
import './client-request-file-uploading.style.css';
import { includes, trim, isNull, get, forEach } from 'lodash';

angular.module('app.clients.taxes')
  .component('cpClientRequestDescription', {

    bindings: {
      clientRequest: '=',
      attachments: '='
    },

    template,

    controllerAs: 'vm',

    controller: ['$rootScope', '$scope', '$stateParams', '$timeout', 'ClientRequestDescriptionService', 'ClientRequestsService', 'DocsResource', 'AttachmentsResource', '$filter', '$sce',
      function ($rootScope, $scope, $stateParams, $timeout, ClientRequestDescriptionService, ClientRequestsService, DocsResource, AttachmentsResource, $filter, $sce) {

        let descriptionChangedTimer;
        let loadedDescription;

        const vm = this;
        vm.loggedInUserId = context.getContext().loggedInUser.id;

        $scope.$watch('vm.attachments', () => {
          if (vm.attachments)
            vm.descriptionFiles = vm.attachments.filter((attachment) => attachment.relationships.ancestor.type !== 'comment');
        });

        $scope.$watch('vm.clientRequest.id', () => {
          if (vm.clientRequest) {
            vm.clientRequest.description = $sce.trustAsHtml($filter('htmlSanitize')(vm.clientRequest.description))
            vm.isSigningRequest = includes(vm.clientRequest.pivot_type, 'esign_doc');

            loadedDescription = vm.clientRequest.description;

            vm.descriptionFiles = [];
            vm.editable = !context.getContext().loggedInUser.hasAccess(['client']) && vm.clientRequest.status !== 'complete';

            // This is required so dropzone does not try to load before the elements are available
            $timeout(() => {
              if (vm.editable) initDropzone();
            }, 0);
          }
        });

        vm.getAttachmentIcon = (fileName) => {
          return fileHelper.getFileIconPath(fileName);
        };

        vm.deleteAttachment = (id) => {
          ClientRequestDescriptionService.deleteAttachment(id)
            .then(() => {
              vm.descriptionFiles.splice(vm.descriptionFiles.findIndex((temp) => temp.id === id), 1);
            })
            .catch(catchAsyncStacktrace());
        };

        vm.showDescriptionForClient = () => {
          if (!vm.editable) {
            // If there is a description or some files, show it (return true)
            if (vm.clientRequest.description !== null || vm.descriptionFiles.length) {
              return true;
            }
          }
        };

        // Using ngChange allows capturing other description edits besides just typing (bold, italics, etc)
        vm.descriptionChanged = () => {
          // When the client request is created, we need to watch and see if either the newValue is null,
          // or the oldValue trimmed is available. Otherwise, when a request is created, it triggers the save
          // which will override any values currently being added
          if (!vm.clientRequest || (!trim(vm.clientRequest.description) && isNull(loadedDescription))) {
            return;
          }

          clearTimeout(descriptionChangedTimer);
          descriptionChangedTimer = setTimeout(() => {
            vm.saveRequest();
          }, 3000);
        };

        vm.saveRequest = () => {
          clearTimeout(descriptionChangedTimer);
          if (vm.clientRequest && loadedDescription != vm.clientRequest.description && vm.editable) {
            loadedDescription = vm.clientRequest.description;

            $scope.$emit('cp-client-request:description:saving');
            ClientRequestsService.updateClientRequest(vm.clientRequest, 'description')
            .then(() => {
              $scope.$emit('cp-client-request:description:save-complete');
            })
            .catch(catchAsyncStacktrace());
          }
        };

        function initDropzone() {
          let dropzone;

          try {
            dropzone = Dropzone.forElement('#cp-client-request-description-dz').destroy();
          } catch (e) {
            // Do nothing
          }

          // Build out new S3 link
          DocsResource.getS3AttachmentTargetEndpoint(`CON${$stateParams.clientId}`).then(response => {
            const fields = get(response, 'data.s3.fields', {})
            const s3URL = get(response, 'data.s3.url', '')
            const s3Prefix = fields.key.replace('${filename}','');

            // build dropzone
            // on Success, let file service know about file
            dropzone = new Dropzone('#cp-client-request-description-dz', {
              url: s3URL,
              withCredentials: false,
              uploadMultiple: false,
              parallelUploads: 1,
              maxFilesize: 100, //MB
              clickable: '#cp-client-request-description-dz-trigger',
              previewTemplate: document.getElementById('cp-client-request-description-dropzone-preview').innerHTML,
              dictFileTooBig: 'File is too large.',
              dictResponseError: 'Upload Failed...',
              headers: {'Cache-Control': ''}
            });

            dropzoneHelper.setup401Retries(dropzone);

            dropzone.on("sending", (file, xhr, formData) => {
              forEach(fields, (value, key) => {
                formData.append(key, value);
              })

              formData.set('key', s3Prefix + `${get(file, 'upload.uuid')}` + truncateFilename(file.name))
            });

            dropzone.on("complete", (file) => {
              if (file.status !== 'error') {
                dropzone.removeFile(file);
              }

              const path_to_s3 = s3Prefix + `${get(file, 'upload.uuid')}` + truncateFilename(file.name)

              DocsResource.reportFileUploaded(`CON${$stateParams.clientId}`, {
                description: '',
                filesize: file.size,
                hidden: false,
                inbox: false,
                is_visible: false,
                mimetype: file.type,
                name: truncateFilename(file.name),
                path_to_s3: path_to_s3,
              })
                .then(resp =>
                  AttachmentsResource.post(
                    {
                      targetPath: AttachmentsResource.targetPathEnum.CLIENTS,
                      targetId: $stateParams.clientId,
                      pivotId: $stateParams.clientRequestId,
                      pivotType: 'client_requests',
                    },
                    {
                      file: {
                        ...resp.data.file,
                        path_to_s3,
                      },
                    }
                  )
                )
                .then(resp => {
                  vm.descriptionFiles.push(resp.data.attachments[0]);
                })
                .catch(catchAsyncStacktrace());
            });
          })
        }
      }
    ]
  });
