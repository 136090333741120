import angular from 'angular';
import template from './logged-in-user-deleted.template.html';
import './logged-in-user-deleted.style.css';

angular
.module('app')
.component('loggedInUserDeleted', {
  bindings: {
  },

  template,

  controllerAs: 'vm',

  controller() {
  }
})
