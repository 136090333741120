import angular from "angular";
import template from "./engagement-section.template.html";

import "./engagement-section.controller.js";
import "./engagement-section.style.css";

import "angular/app/client/taxes/section-interactions/workpapers.directive.js";
import "angular/common/directives/comments/comments-list.component.js";
import "angular/app/client/taxes/section-interactions/assistant.component.js";
import "angular/app/client/taxes/section-interactions/outcomes.component.js";

angular.module('app.clients.taxes')
  .directive('cpEngagementSection', [
    function cpEngagementSection() {
      return {
        restrict: 'E',
        scope: {
          engagement: '='
        },
        template: template,
        controller: 'EngagementSectionController',
        controllerAs: 'vm',
        bindToController: true
      }
    }
  ]);

