import angular from 'angular';
import { applicationProps } from 'entries/workflow-ui'
import { catchAsyncStacktrace } from 'auto-trace';
import context from 'angular/bootstrap/context.service.js';
import canopyUrls from "canopy-urls!sofe";

import Dropzone from "dropzone";
import * as dropzoneHelper from 'angular/common/helpers/dropzone.helper.js';

import template from './request-tool-footer.template.html';
import './request-tool-add-comment.component'
import './request-tool-comments-service'
import '../client-request-footer.style.css';
import '../client-request-file.component.js';
import '../client-request-file-uploading.style.css';
import 'angular/resources/docs.resource'
import _ from 'lodash';

angular
.module('app.clients.taxes')
.component('cpRequestToolFooter', {
  bindings: {
    clientRequest: '=',
  },

  template,

  controllerAs: 'vm',

  controller: ['$timeout', 'AttachmentsService', '$scope', '$stateParams', '$q', 'DocsResource',
    function($timeout, AttachmentsService, $scope, $stateParams, $q, DocsResource) {

      const vm = this;
      vm.filesOnComment = []
      vm.tempFilesOnComment = []
      vm.loggedInUserId = context.getContext().loggedInUser.id;

      $scope.$on('cp:request-tool:add-files', (event, files) => {
        vm.tempFilesOnComment = vm.tempFilesOnComment.concat(files.map(f => ({name: f.name, filesize: f.filesize})))
        $scope.$apply()
      })

      $scope.$on('cp:request-tool:loading-placeholder-files', (event, files) => {
        vm.tempFilesOnComment = vm.tempFilesOnComment.concat(Array.apply(null, Array(files)).map(() => ({name: 'placeholder', isPlaceholder: true})))
        $scope.$apply()
      })

      $scope.$on('cp:request-tool:files-done', (event, files) => {
        vm.tempFilesOnComment = vm.tempFilesOnComment.filter(obj => !obj.isPlaceholder)
        vm.filesOnComment = vm.filesOnComment.concat(files)
        $scope.$apply()
      })

      $scope.$on('cp:add-comment:canceled', () => {
        vm.tempFilesOnComment = []
        vm.filesOnComment = []
      })

      $scope.$on('cp:request-tool:remove-file', (index) => {
        vm.tempFilesOnComment.splice(index, 1)
        vm.filesOnComment.splice(index, 1)
      })

      vm.commentCreated = (comment, notification) => {
        comment.files = vm.filesOnComment
        $scope.$emit('cp:request-tool:new-comment', comment);
        vm.tempFilesOnComment = []
        vm.filesOnComment = []
      };
    }
  ]
});
