import angular from "angular";
import { asyncStacktrace } from 'auto-trace';
import "angular/resources/engagement-letters.resource.js";

angular.module('app.clients.taxes')
  .factory('EngagementLettersService', ['$q', 'EngagementLettersResource',
    function EngagementLettersService($q, EngagementLettersResource) {
      return {
        getLetters: (params) => {
          if (!params.clientId || !params.engagementId) {
            throw new Error(`Cannot get letters without a clientId and engagementId`);
          }

          let deferred = $q.defer();

          EngagementLettersResource.index(params)
            .then(function(response) {
              deferred.resolve(
                response.data.letters.map(toCacheBustedPreviewUrl)
              );
            })
            .catch(asyncStacktrace(response => {deferred.reject(response)} ));

          return deferred.promise;

          function toCacheBustedPreviewUrl(letter) {
            return {
              ...letter,
              preview_image_url: `${letter.preview_image_url}?q=${new Date().getTime()}`
            };
          }
        },

        getLetter: (params) => {
          if (!params.letter_id) {
            throw new Error(`Cannot get a letter without a letter_id`);
          }

          let deferred = $q.defer();

          EngagementLettersResource.get(params)
            .then(function(response) {
              deferred.resolve(response.data.letters);
            })
            .catch(asyncStacktrace(response => {deferred.reject(response)} ));

          return deferred.promise;
        },

        addLetter: (params, letter) => {
          let deferred = $q.defer();

          EngagementLettersResource.post(params, letter)
            .then(function(response) {
              deferred.resolve(response.data.letters);
            })
            .catch(asyncStacktrace(response => {deferred.reject(response)} ));

          return deferred.promise;
        },

        updateLetter: (params, letter) => {
          if (!letter.id) {
            throw new Error(`Cannot update a letter without an id`);
          }

          let deferred = $q.defer();

          EngagementLettersResource.put(params, letter)
            .then(function(response) {
              deferred.resolve(response.data.letters);
            })
            .catch(asyncStacktrace(response => {deferred.reject(response)} ));

          return deferred.promise;
        },

        saveLetter: (params, letter) => {
          return (letter.id) ? this.updateLetter(params, letter) : this.addLetter(params, letter);
        },

        deleteLetter: (params) => {
          if (!params.letter_id) {
            throw new Error(`Cannot delete a letter without a letter_id`);
          }

          EngagementLettersResource.delete(params)
            .catch(function(response) {
              throw new Error('Problem occurred while deleting the letter');
            });

        },
      };
    }]
  )
