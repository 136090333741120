import angular from 'angular';
import { pluck } from 'rxjs/operators'
import { fetchAsObservable } from 'fetcher!sofe'

angular
.module('app')
.factory('SurveysForClientResource', [
  function SurveysForClientResource() {
    return {
      get(clientId) {
        return fetchAsObservable(`/clients/${clientId}/surveys`)
          .pipe(pluck("surveys"))
      }
    }
  }
]);
