import angular from 'angular';

import template from './change-status-dialog.template.html';
import './change-status-dialog.controller.js';

angular.module('app.clients.taxes')
  .directive('cpChangeStatusDialog', [
    function cpChangeStatusDialog() {
      return {
        restrict: 'E',
        scope: {
          requiredPermission: '=',
          statusNotificationsMode: "=",
          activeTeamMembers: "=",
          loggedInUserId: "=",
          teamMembersGoingToReceiveNotification: "=",
          atLeastOneNotificationRecipient: "=",
          dontNotifyAnyoneOfStatusChange: "&",
          recalculateNotificationCount: "&",
          changeStatusAndNotifyPeople: "&",
        },
        controller: 'ChangeStatusDialogController',
        controllerAs: 'vm',
        bindToController: true,
        template: template
      }
    }
  ]);
