import angular from 'angular';
import { catchAsyncStacktrace } from 'auto-trace';
import template from './grid.template.html';
import _ from 'lodash';
import $ from 'jquery';
import './grid.style.css';

import checkboxCellTemplate from './checkbox-cell.template.html';
import checkboxHeaderTemplate from './checkbox-header.template.html';
import rowTemplate from './row.template.html';

/**
 * cp-grid
 *
 * A grid that shows data from a service. Supports infinite scrolling and server-side sorting
 * (which can be disabled on a per-column basis within columnDefs).
 *
 * Caveats:
 *
 * 1) If using infinite scroll, your specified fetchData function must support paging ('limit'
 *   and 'page' params, as well as a 'paginator' object in the response) and sorting ('sort'
 *   param - for each column enabled). It should return a promise.
 *   example: EngagementListService.getEngagementList().
 *
 * 2) A grid height must be defined. A static height can be passed as a string to gridHeight
 *   (i.e. '400px', '50%', '40rem') or a function to calculate a dynamic height can be passed
 *   to gridStyles.
 *
 * 3) Custom templates can be passed into columnDefs. You can access the parent scope from
 *   those templates using grid.appScope.$parent. example: name-cell.template.html
 *
 * 4) Server-side filtering is done by setting filters in buildParams and then broadcasting
 *   'cp:reset-grid'. Client-side filtering is done in the 'filters' field of a column in
 *   columnDefs. For examples, see http://ui-grid.info/docs/#/tutorial/103_filtering or
 *   vm.columnDefs in invoices.controller.js.
 *
 * 5) If you want to update columnDefs after the initial render, you'll need to broadcast the
 *    'cp:update-column-defs' event, with the new column defs passed as the data.
 */
angular.module('app').component('cpGrid', {
  bindings: {
    data: '=', // Binding to the data in the grid (will be populated by this component)
    infiniteScroll: '=', // Whether to enable infinite scroll
    loadState: '=', // Binding to load state of grid
    columnDefs: '=', // Column defs for ui-grid (see https://github.com/angular-ui/ui-grid/wiki/Defining-columns)
    fetchData: '&', // Function to fetch data for the grid, should take params as the first argument
    buildParams: '&?', // Function to build params for fetching data (will be passed into fetchData)
    processData: '&?', // Optional, function to process the response from fetchData before appending it to the grid (must return an array)
    pageSize: '=?', // Optional, size of page. Only used if infinite scroll is enabled. Default is 100.
    gridHeight: '@?', // Optional, string representing the grid height, i.e. '400px', '50%', '40rem'
    sortBy: '@?', // Optional, field to sort by on initial load (must match the `field` attribute in columnDefs). Use '-[field]' to sort DESC by default, i.e. '-due_date'
    gridStyles: '&?', // Optional, function to calculate grid styles
    checkboxes: '=', // Show checkboxes in the table
    checkboxColumnWidth: '@?', // Optional, string representing width of checkbox column, i.e. "50" (pixels) or "5%", default is 5%
    selectedItems: '=?', // Binding to items selected with checkboxes
    disableCheckbox: '&?', // Function called with an item that should return true if that item's checkbox should be disabled
    gridApi: '=?', // Binding to grid API
    moveColumns: '=?', // Whether to enable column moving/dragging
    showXScrollbar: '=?', // Whether to show the horizontal scrollbar
    waitToLoad: '=?', // Option to wait to load the grid until told to reset
  },

  template,

  controllerAs: 'vm',

  controller: function Grid($scope, $timeout, $q, $compile, uiGridConstants) {
    const vm = this;

    const ROW_HEIGHT = 40;
    const HEADER_HEIGHT = 55;

    vm.initialized = false;
    vm.pageSize = vm.infiniteScroll ? vm.pageSize || 100 : 999999999; // disable infinite scroll by setting page size very large
    vm.selectedItems = [];

    // Data loading fns
    vm.loadNextData = loadNextData;
    vm.loadPrevData = loadPrevData;
    vm.reload = reload;
    vm.reset = reset;
    vm.checkDataLength = checkDataLength;
    vm.sortChanged = sortChanged;

    // Checkbox selection fns
    vm.checkboxClicked = checkboxClicked;
    vm.masterCheckboxClicked = masterCheckboxClicked;
    vm.checkboxIsChecked = checkboxIsChecked;
    vm.masterCheckboxIsChecked = masterCheckboxIsChecked;

    let firstPage = 1;
    let lastPage = 1;
    let maxPages = 3; // Max number of pages to keep in the DOM

    if (vm.checkboxes) {
      vm.columnDefs.unshift(getCheckboxColumn());
    }

    $scope.$on('cp:reset-grid', () => {
      vm.reset();
    });

    $scope.$on('cp:update-column-defs', (event, args) => {
      // Hack to update column defs. ui-grid doesn't update if you just reassign them :(
      // Clear current columns
      vm.gridOptions.columnDefs = [];
      $timeout(() => {
        // And now add them all again
        vm.gridOptions.columnDefs = vm.checkboxes
          ? [getCheckboxColumn(), ...args.columnDefs]
          : args.columnDefs;
        if (args.resetGrid) {
          reset();
        }
      });
    });

    $scope.$watch('vm.gridApi', () => {
      // wait for gridApi to initialize
      if (!vm.initialized && !_.isEmpty(vm.gridApi)) {
        init();

        vm.gridApi.core.on.rowsRendered($scope, () => {
          $timeout(() => {
            angular.element(window).trigger('resize'); // Make sure grid styles are rendered correctly
          });
        });
      }
    });

    vm.gridOptions = {
      enableSorting: true, // Sorting can be disabled on a per-column basis by adding `enableSorting: false` to the column def
      enableFiltering: true,
      enableHorizontalScrollbar: uiGridConstants.scrollbars.ALWAYS,
      data: 'vm.data',
      rowHeight: ROW_HEIGHT,
      rowTemplate: rowTemplate,
      infiniteScrollRowsFromEnd: 5,
      virtualizationThreshold: vm.pageSize,
      infiniteScrollUp: true,
      infiniteScrollDown: true,
      enableColumnMoving: vm.moveColumns || false,
      onRegisterApi: gridApi => {
        gridApi.infiniteScroll.on.needLoadMoreData(
          $scope,
          vm.loadNextData
        );
        gridApi.infiniteScroll.on.needLoadMoreDataTop(
          $scope,
          vm.loadPrevData
        );
        gridApi.core.on.sortChanged($scope, vm.sortChanged);
        vm.gridApi = gridApi;
      },
      columnDefs: vm.columnDefs,
    };

    function init() {
      // If no height or style provided, give a default height function
      if (
        _.isUndefined(vm.gridHeight) &&
        typeof vm.gridStyles !== 'function'
      ) {
        vm.gridStyles = () => {
          if (vm.loadState === 'loaded') {
            // Calculate height of grid.
            let numRows = vm.gridApi.grid.getVisibleRows().length;
            let gridHeight =
              HEADER_HEIGHT + numRows * ROW_HEIGHT + numRows; // height of grid with all rows, borders, and header

            return { height: gridHeight + 'px' };
          }
        };
      }

      // Set up default sort in column defs
      if (vm.sortBy) {
        let sortField = vm.sortBy.replace('-', '');
        let column = _.find(vm.columnDefs, { field: sortField });
        if (column) {
          column.sort = {
            direction: vm.sortBy[0] === '-' ? uiGridConstants.DESC : uiGridConstants.ASC,
            priority: 1,
          };
        }
      }

      if (!vm.waitToLoad) {
        vm.reload().then(() => {
          $timeout(() => {
            finishInit();
          });
        });
      }
    }

    function loadNextData() {
      let deferred = $q.defer();
      vm.loadState = 'loading-more-down';

      let params =
        typeof vm.buildParams === 'function' ? vm.buildParams() : {};
      params.limit = vm.pageSize;
      params.page = lastPage + 1;
      if (!_.isEmpty(vm.sortBy)) {
        params.sort = vm.sortBy;
      }

      vm
        .fetchData({ $params: params })
        .then(data => {
          $scope.$emit('cp:grid-data-loaded', data);
          vm.loadState = 'loaded';
          if (!_.isEmpty(data)) {
            lastPage++;

            vm.gridApi.infiniteScroll.saveScrollPercentage();
            if (typeof vm.processData === 'function') {
              data = vm.processData({ $data: data });
            }
            vm.data = vm.data.concat(data);

            vm.gridApi.infiniteScroll
              .dataLoaded(firstPage > 1, lastPage < vm.totalPages)
              .then(() => {
                vm.checkDataLength('up');
              })
              .then(() => {
                deferred.resolve();
              });
          } else {
            vm.gridApi.infiniteScroll.dataLoaded(
              firstPage > 1,
              lastPage < vm.totalPages
            );
            deferred.reject();
          }
          $timeout(() => {
            angular.element(window).trigger('resize');
          });
        })
        .catch(ex => {
          vm.gridApi.infiniteScroll.dataLoaded();
          deferred.reject();
        });

      return deferred.promise;
    }

    function loadPrevData() {
      let deferred = $q.defer();
      vm.loadState = 'loading-more-up';

      let params =
        typeof vm.buildParams === 'function' ? vm.buildParams() : {};
      params.limit = vm.pageSize;
      params.page = firstPage - 1;
      if (!_.isEmpty(vm.sortBy)) {
        params.sort = vm.sortBy;
      }

      vm
        .fetchData({ $params: params })
        .then(data => {
          $scope.$emit('cp:grid-data-loaded', data);
          vm.loadState = 'loaded';
          if (!_.isEmpty(data)) {
            firstPage--;

            vm.gridApi.infiniteScroll.saveScrollPercentage();
            if (typeof vm.processData === 'function') {
              data = vm.processData({ $data: data });
            }
            vm.data = data.concat(vm.data);

            vm.gridApi.infiniteScroll
              .dataLoaded(firstPage > 1, lastPage < vm.totalPages)
              .then(() => {
                vm.checkDataLength('down');
              })
              .then(() => {
                deferred.resolve();
              });
          } else {
            vm.gridApi.infiniteScroll.dataLoaded(
              firstPage > 1,
              lastPage < vm.totalPages
            );
            deferred.reject();
          }
        })
        .catch(ex => {
          vm.gridApi.infiniteScroll.dataLoaded();
          deferred.reject();
        });

      return deferred.promise;
    }

    function reload() {
      vm.loadState = 'loading';

      let deferred = $q.defer();

      vm.gridApi.infiniteScroll.setScrollDirections(false, false);

      firstPage = 1;
      lastPage = 1;

      let params =
        typeof vm.buildParams === 'function' ? vm.buildParams() : {};
      params.limit = vm.pageSize;
      params.page = firstPage;
      if (!_.isEmpty(vm.sortBy)) {
        params.sort = vm.sortBy;
      }

      vm
        .fetchData({ $params: params })
        .then(data => {
          $scope.$emit('cp:grid-data-loaded', data);
          // If infinite scroll is disabled, we functionally have just one page
          vm.totalPages = vm.infiniteScroll
            ? data.paginator.total_pages
            : 1;
          if (typeof vm.processData === 'function') {
            data = vm.processData({ $data: data });
          }
          vm.data = data;
          vm.gridApi.infiniteScroll.dataLoaded(
            firstPage > 1,
            vm.totalPages > 1
          );

          $timeout(() => {
            // timeout needed to allow digest cycle to complete, and grid to finish ingesting the data
            vm.gridApi.infiniteScroll.resetScroll(
              firstPage > 1,
              lastPage < vm.totalPages
            );
            vm.loadState = 'loaded';
            deferred.resolve();
          });
        })
        .catch(ex => {
          deferred.reject();
        });

      return deferred.promise;
    }

    function reset() {
      vm.sortBy = null;
      vm
        .reload()
        .then(() => {
          // If we waited to initialize, finish the init here
          if (!vm.initialized) {
            finishInit();
          }
        })
        .catch(catchAsyncStacktrace());
    }

    function checkDataLength(discardDirection) {
      // determine whether we need to discard a page, if so discard from the direction passed in
      if (lastPage - firstPage > maxPages) {
        vm.gridApi.infiniteScroll.saveScrollPercentage();

        if (discardDirection === 'up') {
          // discard the first page
          vm.data = vm.data.slice(vm.pageSize);
          firstPage++;
          $timeout(() => {
            // wait for grid to ingest data changes
            vm.gridApi.infiniteScroll.dataRemovedTop(
              firstPage > 1,
              lastPage < vm.totalPages
            );
          });
        } else {
          // discard the last page
          vm.data = vm.data.slice(0, vm.pageSize * (maxPages + 1));
          lastPage--;
          $timeout(() => {
            // wait for grid to ingest data changes
            vm.gridApi.infiniteScroll.dataRemovedBottom(
              firstPage > 1,
              lastPage < vm.totalPages
            );
          });
        }
      }
    }

    function sortChanged(grid, sortColumns) {
      $scope.$emit('cp:grid-sort-changed', { sortColumns: sortColumns });

      if (vm.totalPages > 1) {
        // If there are 1 or fewer pages, we can sort client-side
        firstPage = 1;
        lastPage = 1;

        if (!_.isEmpty(sortColumns) && sortColumns.length === 1) {
          vm.sortBy =
            (sortColumns[0].sort.direction === 'desc' ? '-' : '') +
            (_.get(sortColumns[0], 'colDef.sortField') || _.get(sortColumns[0], 'field'));
        } else {
          vm.sortBy = null;
        }
        vm
          .reload()
          .then(() => { })
          .catch(catchAsyncStacktrace());
      }
    }

    function getCheckboxColumn() {
      return {
        displayName: '',
        field: 'checkboxes', // a field is required, this is just a placeholder
        pinnedLeft: vm.showXScrollbar,
        width: vm.checkboxColumnWidth || '5%',
        cellTemplate: checkboxCellTemplate,
        headerCellTemplate: checkboxHeaderTemplate,
        enableSorting: false,
        enableColumnMenu: false,
        enableColumnMoving: false,
      };
    }

    function checkboxClicked(item) {
      if (_.includes(vm.selectedItems, item)) {
        vm.selectedItems = _.without(vm.selectedItems, item);
      } else {
        vm.selectedItems = [...vm.selectedItems, item];
      }
    }

    function masterCheckboxClicked() {
      if (vm.selectedItems.length === vm.data.length) {
        vm.selectedItems = [];
      } else {
        vm.selectedItems = vm.data;
      }
    }

    function checkboxIsChecked(item) {
      return _.includes(vm.selectedItems, item);
    }

    function masterCheckboxIsChecked() {
      return vm.data && vm.selectedItems.length === vm.data.length;
    }

    function finishInit() {
      $scope.$emit('cp:grid-initialized');
      vm.initialized = true;
      vm.loadState = 'loaded';

      $timeout(() => {
        angular.element(window).trigger('resize');
      });
    }
  },
});
