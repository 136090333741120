import angular from "angular";

import template from './assistant.template.html';

import './questions/able-to-satisfy.component.js';
import './questions/against-exempt-property.component.js';
import './questions/issued-erroneously.component.js';
import './questions/issued-wrongfully.component.js';
import './questions/oic-or-installment.component.js';
import './questions/prove-financial-hardship.component.js';

angular.module('app.clients.taxes').component('cpAssistantLevies', {
  bindings: {
    question: '='
  },

  controller: function ($state, $stateParams) {
    const vm = this;
    vm;
  },

  controllerAs: 'vm',

  template: template
});