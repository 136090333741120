import angular from "angular";
import { cloneDeep } from "lodash";
import {hasAccess as userHasAccess} from 'cp-client-auth!sofe';

import {taxResServiceSlugs} from 'angular/app/client/taxes/engagement-summary.helper.js';
import context from 'angular/bootstrap/context.service.js';

import * as assistantConstants from 'angular/app/client/taxes/programs/assistant/assistant.constants.js';

angular.module('app.clients.taxes')
  .controller('ManageProgramsController', ['$scope', 'EngagementService', '$stateParams',
    function ManageProgramsController($scope, EngagementService, $stateParams) {
      var vm = this;

      vm.newProgramName = '';
      vm.createCustomProgram = createCustomProgram;
      vm.visibleProgramsChanged = visibleProgramsChanged;
      vm.programToggled = programToggled;
      vm.editCustomProgramName = editCustomProgramName;
      vm.hasAccessToService = hasAccessToService;
      vm.finishedEditingCustomProgram = finishedEditingCustomProgram;
      vm.documentBody = document.body;

      $scope.$watch('vm.engagement', initialDataChanged);

      const hasAccess = userHasAccess(context.getContext().loggedInUser);
      vm.hasAccess = hasAccess;

      function hasAccessToService(slug) {
        if (slug === 'planning') return false;

        if (taxResServiceSlugs.includes(slug)) return hasAccess('tax_resolution_services');
        else return hasAccess('custom_services');
      }

      function initialDataChanged() {
        if (vm.engagement) {

          let collections_service = vm.engagement.program_data.programs.find((program) => program.slug === 'collections');
          if (collections_service) {
            collections_service.description = 'This will guide you through the Collections process for your client.';
          }

          for (let serviceSlug in assistantConstants.service_slugs) {
            let service = vm.engagement.program_data.programs.find((program) => program.slug === assistantConstants.service_slugs[serviceSlug]);
            if (service) {
              service.description = `This will guide you through the ${service.name} process for your client and provide helpful tips and resources.`;
            }
          }

          vm.programTurnedOn = vm.engagement.program_data.programs.reduce((prev, next) => {
            prev[next.uid] = next.visible;
            return prev;
          }, {});

          vm.customPrograms = vm.engagement.program_data.programs.filter((program) => program.is_removable);
        }
      }

      function programToggled(uid) {
        let program = vm.engagement.program_data.programs.find((program) => program.uid === uid);
        if (!program) {
          throw new Error(`Something weird is happening, we're trying to turn off/on a program (with uid ${uid}) but we can't find that program`);
        }
        program.visible = vm.programTurnedOn[uid];
        EngagementService.saveEngagement($stateParams, vm.engagement);
        $scope.$emit('cp:engagementChanged', vm.engagement);
      }

      function visibleProgramsChanged() {
        if (vm.engagement) {
          let atLeastOneChange = false;
          vm.engagement.program_data.programs.forEach((program) => {
            if (program.visible !== vm.programTurnedOn[program.uid] && typeof vm.programTurnedOn[program.uid] !== 'undefined') {
              program.visible = vm.programTurnedOn[program.uid];
              atLeastOneChange = true;
            }
          })

          if (atLeastOneChange) {
            EngagementService.saveEngagement($stateParams, vm.engagement);
          }
        }
      }

      function createCustomProgram() {
        if (vm.engagement) {
          $scope.$broadcast('show-errors-check-validity');
          if (vm.createCustomProgramForm.$valid) {
            let program = EngagementService.createBlankProgram(vm.engagement.program_data.programs, vm.newProgramName);
            let tempEngagement = cloneDeep(vm.engagement);
            tempEngagement.program_data.programs.push(program);
            EngagementService.saveEngagement($stateParams, tempEngagement)
            .then(() => {
              vm.engagement.program_data.programs.push(program);
              vm.customPrograms.push(program);
              vm.programTurnedOn[program.uid] = program.visible;
              $scope.$emit('cp:engagementChanged', vm.engagement);
            });

            vm.newProgramName = '';
            vm.creatingCustomProgram = false;
          }
        }
      }

      function editCustomProgramName(customProgram) {
        vm.customProgramBeingEdited = customProgram;
        vm.customProgramName = customProgram.name
      }

      function finishedEditingCustomProgram(save) {
        if (save) {
          vm.customProgramBeingEdited.name = vm.customProgramName;
          EngagementService.saveEngagement($stateParams, vm.engagement)
          .then(() => {
            $scope.$emit('cp:engagementChanged', vm.engagement);
          });
        }
        delete vm.customProgramBeingEdited;
        delete vm.customProgramName;
      }

    }
  ]);
