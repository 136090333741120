import fetcher from 'fetcher!sofe';

export default ['$provide', $provide => {
  // Overwrite the $http service
  $provide.provider({
    $httpBackend: function FetcherHttpBackendProvider() {
      this.$get = [function() {
        return function $fetcherHttpBackend(method, url, body, callback, headers, timeout, withCredentials, responseType, eventHandlers, uploadEventHandlers) {
          if (responseType) {
            throw new Error(`Canopy fetcher $httpBackend does not support the responseType config option`);
          }

          if (eventHandlers && Object.keys(eventHandlers).length > 0) {
            throw new Error(`Canopy fetcher $httpBackend does not support the eventHandlers config option`);
          }

          if (uploadEventHandlers && Object.keys(uploadEventHandlers).length > 0) {
            throw new Error(`Canopy fetcher $httpBackend does not support the uploadEventHandlers config option`);
          }

          const fetchConfig = {
            method,
            body,
            headers,
          };

          if (typeof withCredentials === 'boolean') {
            fetchConfig.credentials = withCredentials ? 'include' : 'omit';
          }

          let timeoutId, isCanceled = false;

          function cancel() {
            isCanceled = true;
            callback(-1, null, null, '', 'timeout');
          }

          if (timeout > 0) {
            timeoutId = setTimeout(cancel, timeout);
          } else if (timeout && typeof timeout.then === 'function' && typeof timeout.catch === 'function') {
            timeout.then(cancel);
          }

          fetcher(url, fetchConfig)
            .then(response => {
              if (isCanceled) {
                return;
              }
              if (response.ok) {
                if (response.status === 204) {//204 No Content
                  const responseHeadersString = stringifyResponseHeaders(response.headers);
                  callback(response.status, {}, responseHeadersString, response.statusText, 'complete');
                }
                else {
                  return response
                    .json()
                    .then(json => {
                      clearTimeout(timeoutId); // if timeoutId is undefined, it's a no-op and is fine

                      if (isCanceled) {
                        return;
                      }

                      const responseHeadersString = stringifyResponseHeaders(response.headers);
                      callback(response.status, json, responseHeadersString, response.statusText, 'complete');
                    })
                }
              } else {
                throw response;
              }
            })
            .catch(err => {
              clearTimeout(timeoutId); // if timeoutId is undefined, it's a no-op and is fine

              if (isCanceled) {
                return;
              }

              const responseHeadersString = err.headers ? stringifyResponseHeaders(err.headers) : null;
              callback(err.status || -1, null, responseHeadersString, err.statusText || '', 'error');
            })
        }
      }]
    }
  })
}]

function stringifyResponseHeaders(headers) {
  let res = '', prefix = '';

  for (let header of headers) {
    const [name, value] = header;
    res += prefix + name + ': ' + value;
    prefix = '\r';
  }

  return res;
}
