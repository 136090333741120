import angular from 'angular';
import { catchAsyncStacktrace } from 'auto-trace';
import template from './unsent-client-request.template.html';
import context from 'angular/bootstrap/context.service.js';
import './unsent-client-request.style.css';
import './right-side-pane.component.js';
import '../engagement-client-requests.service.js';
import './client-request-header.component.js';
import './client-request-due-date.component.js';
import './client-request-reminders.component.js';

angular
.module('app.clients.taxes')
.component('cpUnsentClientRequest', {
  bindings: {
    clientRequest: '=',
    activeTeamMembers: '=',
    engagement: '=',
  },

  template,

  controllerAs: 'vm',

  controller: ['ClientRequestsService', 'AttachmentsService', '$scope', '$stateParams',
    function (ClientRequestsService, AttachmentsService, $scope, $stateParams) {
      const vm = this;
      vm.sendingNow = false;
      vm.editingReminders = false;

      $scope.$on('cp:client-request-component:do-hide', () => {
        $scope.$broadcast('cp:right-side-pane:do-hide');
      });

      $scope.$watch('vm.clientRequest', () => {
        if (vm.clientRequest) {
          AttachmentsService.getList({
            clientId: $stateParams.clientId,
            pivot_type: 'client_requests',
            pivot_id: $stateParams.clientRequestId,
          })
          .then((attachments) => {
            vm.attachments = attachments;
          })
          .catch((ex) => {
            vm.attachments = [];

            SystemJS.import('sentry-error-logging!sofe')
            .then((sentry) => {
              sentry.setUser(context.getContext().loggedInUser);
              sentry.captureException(ex);
            })
            .catch(catchAsyncStacktrace());
          });
        }
      });

      vm.sendNow = () => {
        if (!vm.sendingNow) {
          vm.sendingNow = true;
          $scope.$emit('cp:client-request:send-now');
        }
      }

      vm.sendLater = () => {
        $scope.$broadcast('cp:right-side-pane:do-hide');
      }
    }
  ],
});
