import angular from "angular";
import context from 'angular/bootstrap/context.service.js';
import toasts from 'toast-service!sofe';

angular.module('app.clients.taxes')
  .controller('ChangeStatusController', ['$scope', '$stateParams', 'ProgramSectionsService',
    function ($scope, $stateParams, ProgramSectionsService) {

      let vm = this;

      vm.loggedInUserId = context.getContext().loggedInUser.id;
      vm.documentBody = document.body;

      vm.getTooltipText = () => {
        if (vm.section && vm.section.status) {
          if (vm.section.status === 'in_progress') {
            return 'In progress';
          } else if (vm.section.status === 'needs_review') {
            return 'Needs review';
          } else if (vm.section.status === 'complete') {
            return 'Complete';
          }
        }

        return 'Status';
      }

      vm.goingToAssignNewStatus = (newStatus) => {
        if (newStatus == vm.section.status) {
          return;
        }
        vm.newStatus = newStatus;

        // teammates is an array of the assigned team members excluding the current user
        let teammates = vm.activeTeamMembers ? vm.activeTeamMembers.filter((teammate) => {
          return teammate.id !== vm.loggedInUserId;
        }) : [];

        if (teammates.length) {
          vm.teamMembersGoingToReceiveNotification = teammates.reduce((prev, next) => {
            prev[next.id] = false;
            return prev;
          }, {});

          vm.recalculateNotificationCount();

          if (Object.keys(vm.teamMembersGoingToReceiveNotification).length) {
            vm.statusNotificationsMode = true;
          }
          return;
        }
        vm.dontNotifyAnyoneOfStatusChange();
      };

      vm.recalculateNotificationCount = () => {
        if (vm.teamMembersGoingToReceiveNotification) {
          for (let id in vm.teamMembersGoingToReceiveNotification) {
            if (vm.teamMembersGoingToReceiveNotification[id]) {
              vm.atLeastOneNotificationRecipient = true;
              return;
            }
          }
          vm.atLeastOneNotificationRecipient = false;
        } else {
          vm.atLeastOneNotificationRecipient = false;
        }
      };


      vm.dontNotifyAnyoneOfStatusChange = () => {
        vm.updateStatus(vm.newStatus);
        delete vm.newStatus;
        vm.statusNotificationsMode = false;
      };


      vm.changeStatusAndNotifyPeople = () => {
        let notifications;
        let usersToNotify = [];
        for (let userId in vm.teamMembersGoingToReceiveNotification) {
          if (vm.teamMembersGoingToReceiveNotification[userId]) {
            usersToNotify.push(userId);
          }
        }
        if (usersToNotify.length) {
          notifications = {
            users: usersToNotify,
            url: window.location.href
          }
        }
        vm.updateStatus(vm.newStatus, notifications);
        delete vm.newStatus;
        vm.statusNotificationsMode = false;
      };


      vm.updateStatus = (status, notifications) => {
        const oldStatus = vm.section.status;

        //so that the menu is also updated
        vm.section.status = status;

        if (!vm.customUpdateSection) {
          vm.engagement.program_data
            .programs.find((program) => $stateParams.programSlug === program.slug)
            .sections.find((section) => section.id === vm.section.id)
            .status = status;
          $scope.$emit('cp:engagementChanged', vm.engagement);

          const notificationsMessage = notifications ? ' and notifications were sent' : '';
          const successMessage = `Status changed successfully${notificationsMessage}.`;

          ProgramSectionsService.update({...$stateParams, sectionId: vm.section.id}, vm.section, notifications)
          .then(() => {toasts.successToast(successMessage)});
        }

        vm.onUpdate({
          $oldStatus: oldStatus,
          $status: status,
          $notifications: notifications,
        });
      };
    }
  ]);
