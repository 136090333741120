import angular from 'angular';

import template from './due-date.template.html';
import './due-date.controller.js';
import './due-date.style.css';

angular.module('app.clients.taxes')
  .directive('cpDueDate', [
    function cpDueDate() {
      return {
        restrict: 'E',
        scope: {
          sectionEnvelope: '='
        },
        template: template,
        controller: 'DueDateController',
        controllerAs: 'vm',
        bindToController: true
      }
    }
  ]);
