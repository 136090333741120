// Outcomes
export const RELIEF_UNLIKELY = 'RELIEF_UNLIKELY';
export const REASONABLE_CAUSE = 'REASONABLE_CAUSE';
export const FIRST_TIME_ABATEMENT = 'FIRST_TIME_ABATEMENT';

export const NON_ASSESSMENT = 'NON_ASSESSMENT';
export const OUTCOME_NOT_YET_CALCULABLE = null;

// Question slugs
export const INTRO = 'intro';             //0
export const AUDIT = 'audit';             //1
export const PENALTY = 'penalty';           //1.a.i
export const LATE = 'late';               //2
export const NOTIFICATION = 'notification';       //3
export const LIABILITY = 'liability';         //6
export const PAYMENTS = 'payments';           //7
export const PENALTY_REASON = 'penalty_reason';     //8
export const PREVIOUS_PENALTY = 'previous_penalty';   //9
export const COMPLIANCE = 'compliance';         //10
export const INSTALLMENT = 'installment';       //11
export const PENALTY_TYPE = 'penalty_type';       //12
export const CIRCUMSTANCE = 'circumstance';       //13
