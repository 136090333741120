import angular from "angular";
import { asyncStacktrace } from 'auto-trace';
import "angular/resources/engagement-history.resource.js";
import { formatEngagementHistory } from "./engagement-history.service.helper.js";
import context from "angular/bootstrap/context.service.js";

angular.module('app.clients.taxes')
  .factory('EngagementHistoryService', ['$q', '$stateParams', 'EngagementHistoryResource',
    function EngagementHistoryService($q, $stateParams, EngagementHistoryResource) {

      return {
        getEngagementHistory: function getEngagementHistory(engagement){
          let params = $stateParams;
          if (!params.clientId)
            throw new Error(`Cannot get engagement history without a client id param`);
          if (!params.engagementId)
            throw new Error(`Cannot get engagement history without an engagement id param`);
          
          let deferred = $q.defer();
          EngagementHistoryResource.get(params)
            .then((response) => {
              let history = response.data.object_history;
              let engagementHistory = formatEngagementHistory(engagement, history, context.getContext().loggedInUser);
              deferred.resolve(engagementHistory);
            })
            .catch(asyncStacktrace(response => {deferred.reject(response)} ))

          return deferred.promise;
        }     
      }
    }
  ]);
