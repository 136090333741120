import angular from "angular";
import _ from 'lodash';

/**
 * A filter for reducing down an array of dates from dates.service.js based upon what is before or after today
 *
 * Usage:
 * Reduce the array of dates down to only what is today and beyond
 * ng-repeat="date in dates | filterToday"
 *
 * Reduce the array of dates down to only what is before today
 * ng-repeat="date in dates | filterToday:true"
 *
 */
'use strict';
angular.module('app.helpers')
  .filter('filterToday', function() {
    return function(dates, before) {
      var now = new Date();
      var today = new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime();

      return _.filter(dates, function(d) {
        if (before) {
          return d.end_date < today;
        } else {
          return d.end_date >= today;
        }
      });
    }
  });
