import angular from "angular";
import canopyUrls from 'canopy-urls!sofe';

angular.module('app')
  .factory('UserClientResource', ['$http',
    function UserClientResource($http) {
      return {
        get(params) {
          return $http.get(`${canopyUrls.getAPIUrl()}/users/${params.userId}/clients`)
        }
      }
    }
  ]);
