import angular from 'angular';
import { omit } from 'lodash';
import { catchSyncStacktrace } from 'auto-trace';

angular.module('app.clients')
  .factory('ContactResource', ['$http',
  function ContactResource($http) {
    return {
      get: params => $http.get(`/api/contacts/${params.clientId}`, { params: params }),

      post: (params, payload) => $http.post(`/api/contacts`, payload, { params: params }),

      patch: (params, payload) =>
        $http.patch(`/api/contacts/${params.clientId}`, payload, { params: omit(params, 'clientId') }),

      put: (params, payload) => {
        if (!params.clientId) {
          const error = new Error(
            'Client Id is undefined: ' + 'params: ' + JSON.stringify(params) + ' payload :' + JSON.stringify(payload)
          );
          catchSyncStacktrace(error);
        }
        return $http.put(`/api/contacts/${params.clientId}`, payload, { params: omit(params, 'clientId') });
      },

      index: params => {
        if (params.limit) {
          return $http.get(`/api/contacts?limit=${params.limit}`, { params: params });
        } else {
          return $http.get(`/api/contacts`, { params: params });
        }
      },

      delete: params => $http.delete(`/api/contacts/${params.clientId}`, { params: omit(params, 'clientId') }),

      patchIndex: (action, ids, params = {}) =>
        $http.patch(`/api/contacts`, { contact_ids: ids }, { params: { ...params, action: action } }),
    };
  },
]);
