import angular from "angular";

angular.module('app')
  .directive('preventDefault', function() {
      return {
        restrict: "A",
        link: function(scope, el, attr) {
          if (attr.preventDefault) {
            el.bind(attr.preventDefault, (e) => {
              e.preventDefault();
            });
          }
        }
      }
    }
  );
