import angular from "angular";

import "./engagements-permission-and-breadcrumb.controller.js";
import template from "./engagements-permission-and-breadcrumb.layout.html";
import "./engagements-permission-and-breadcrumb.style.css";

angular.module('app.clients.taxes')
  .directive('cpEngagementsPermissionAndBreadcrumb', [
    function cpEngagementsPermissionAndBreadcrumb() {
      return {
        restrict: 'E',
        scope: {
          client: '=?'
        },
        template: template,
        controller: 'EngagementsPermissionAndBreadcrumbController',
        controllerAs: 'vm',
        bindToController: true
      }
    }
  ]);
