import angular from 'angular';
import _ from 'lodash';

import template from './date-input.template.html';

angular.module('app').directive('cpDateInput', [
  'dateFilter',
  function(dateFilter) {
    return {
      restrict: 'E',

      scope: {
        date: '=',
        onChange: '=',
        placeholder: '@',
        icon: '@',
        iconPosition: '@',
        orientation: '@',
      },

      template: template,

      compile: function(tElem, tAttrs) {
        if (_.has(tAttrs, 'name')) {
          tElem.find('input').attr('name', tAttrs.name);
        }

        if (_.has(tAttrs, 'required')) {
          tElem.find('input').attr('required', true);
        }

        return {
          post: function(scope, el, attrs) {
            scope.onDateChange = onDateChange;

            attrs.$observe('disabled', function(value) {
              if (_.has(attrs, 'ngDisabled')) {
                scope.disabled = value;
              } else {
                scope.disabled = _.has(attrs, 'disabled');
              }
            });

            function onDateChange(e) {
              scope.date = e.detail ? e.detail.getTime() : new Date().getTime();
              if (scope.onChange) {
                scope.onChange(e);
              }
            }
          },
        };
      },
    };
  },
]);
