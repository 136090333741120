import angular from "angular";

angular.module('app.admin.users')
  .factory('AvailableEmailsResource', ['$http',
    function AvailableEmailsResource($http) {
      return {
        get: function(params){
          return $http.get(
            '/api/available-emails/' + params 
          );
        }
      };
    }
  ]);
