import angular from "angular";
import { hasAccess } from 'cp-client-auth!sofe';

angular.module('app.clients.taxes')
  .controller('ChangeStatusDialogController', ['$scope',
    function ChangeStatusDialogController($scope) {
      var vm = this;
      vm.$onInit = setFilteredTeamMembers;
      $scope.$watch('vm.activeTeamMembers', setFilteredTeamMembers);

      function setFilteredTeamMembers() {
        if (!vm.activeTeamMembers) return;
        let permissionsRequired = ['tasks_client_requests', 'tasks_engagements']
        if(vm.requiredPermission && !permissionsRequired.includes(vm.requiredPermission)) {
          permissionsRequired.push(vm.requiredPermission)
        }
        vm.filteredTeamMembers = vm.activeTeamMembers.filter(user => hasAccess(user, true)([...permissionsRequired]));
      }
    }
  ]);
