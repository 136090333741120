import * as constants from './assistant.constants.js';

export function onlyFTA(outcomes) {
  return firstTimeAbatementAvailable(outcomes) && !reasonableCauseAvailable(outcomes);
}

export function onlyReasonableCause(outcomes) {
  return !firstTimeAbatementAvailable(outcomes) && reasonableCauseAvailable(outcomes);
}

export function bothFTAAndReasonableCause(outcomes) {
  return firstTimeAbatementAvailable(outcomes) && reasonableCauseAvailable(outcomes);
}

export function cantCalculateOutcomes(outcomes) {
  return outcomes === constants.OUTCOME_NOT_YET_CALCULABLE;
}

export function reliefUnlikely(outcomes) {
  return !!(outcomes && outcomes.indexOf(constants.RELIEF_UNLIKELY) >= 0);
}

export function firstTimeAbatementAvailable(outcomes) {
  return !!(outcomes && outcomes.indexOf(constants.FIRST_TIME_ABATEMENT) >= 0);
}

export function reasonableCauseAvailable(outcomes) {
  return !!(outcomes && outcomes.indexOf(constants.REASONABLE_CAUSE) >= 0);
}

export function nonAssessment(outcomes) {
  return !!(outcomes && outcomes.indexOf(constants.NON_ASSESSMENT) >= 0);
}
