import angular from "angular";

angular.module('app')
  .factory('ClientParticipantsResource', ['$resource',
    function ClientParticipantsResource($resource) {

      return $resource('/api/clients/:clientId/users/:userId', {}, {
        update: {
          method: 'PUT'
        },
        post: {
          method: 'POST'
        }
      });
    }
  ]);
