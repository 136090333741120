import angular from 'angular';
import template from './transcripts-onboarding.template.html';
import './transcripts-onboarding.style.css';

angular.module('app.transcripts')
  .component('cpTranscriptsOnboarding', {
    template,
    controllerAs: 'vm',

    controller($state) {
      const vm = this;
      vm.steps = steps;
      vm.step = steps[0];
      vm.advanceStep = advanceStep;

      function advanceStep(num) {
        if (num === 4) {
          localStorage.setItem("cp:transcripts:onboarded", "true");
          $state.go("transcripts.create");
        }
        else vm.step = steps[num + 1]
      }
    }
  })

const steps = [
  {
    image: "https://cdn.canopytax.com/static/transcripts/transcripts-onboarding-1.svg",
    header: "Welcome to Transcripts",
    description: "Thank you for signing up for our FREE Transcripts service. Before you pull your first transcript, let's make sure you have everything you need.",
    buttonText: "Okay",
    number: 0,
  },
  {
    image: "https://cdn.canopytax.com/static/transcripts/transcripts-onboarding-2.svg",
    header: "Do You Have a Transcripts Enabled IRS E-Service Account?",
    description: "In order for Canopy to request transcripts, you'll need an IRS E-Services login with \"Transcripts Delivery System\" enabled. Please visit <a href=\"https://www.irs.gov/tax-professionals/e-services-online-tools-for-tax-professionals\" target=\"_blank\">IRS E-Services</a> for more information.",
    buttonText: "Yes, I have an account",
    number: 1,
  },
  {
    image: "https://cdn.canopytax.com/static/transcripts/transcripts-onboarding-3.svg",
    header: "Do You Have Power of Attorney?",
    description: "You'll also need to have Power of Attorney registered with your IRS E-Services account to pull error-free transcripts. Please make sure you have already obtained this.",
    buttonText: "Yes, I have it",
    number: 2,
  },
  {
    image: "https://cdn.canopytax.com/static/transcripts/transcripts-onboarding-4.svg",
    header: "Do You Have an IRS CAF Number",
    description: "To request a transcript, Canopy will first check to see if you have the proper IRS identification number (CAF) to pull your requested individual transcripts.",
    buttonText: "Yes, I have the CAF",
    number: 3,
  },
  {
    image: "https://cdn.canopytax.com/static/transcripts/transcripts-onboarding-5.svg",
    header: "Get Started!",
    description: "It looks like you have everything you need to request your first IRS transcript. Let's get started with a quick CAF Check.",
    buttonText: "Get Started",
    number: 4,
  },
]
