import angular from 'angular';
import _ from 'lodash';
import { catchAsyncStacktrace } from 'auto-trace';
import toasts from "toast-service!sofe";
import canopyUrls from "canopy-urls!sofe";

import context from "angular/bootstrap/context.service.js";
import "angular/app/transcripts/transcript-reports.service.js"
import "angular/app/clients/contact.service.js"
import * as TableHelper from "./transcript-reports-table.helper.js";
import PermissionHelpers from "angular/common/helpers/permission.helpers.js";
import { REPORT_TYPE, TRANSCRIPT_TYPE, REPORTS_HELPER, BUSINESS_REPORT_SUBTYPES } from "./transcript.constants.js";

import "./transcript-reports.style.css";
import template from './transcript-reports-table.template.html';

angular.module('app.transcripts')
.component('cpTranscriptReportsTable', {
  bindings: {
    version: '=',
    client: '=',
    selectedReportName: '@',
    selectedTable: '=',
    selectedVersion: '=',
    printing: '=',
  },

  controllerAs: 'vm',

  controller: function ($scope, $state, $stateParams, TranscriptReportsService, $timeout, $filter, ContactService) {
    const vm = this;

    vm.clientId = $stateParams.clientId;
    vm.WORKFLOW_URL = canopyUrls.getWorkflowUrl();
    vm.TRANSCRIPT_TYPE = TRANSCRIPT_TYPE;
    vm.printableAreaId = Math.random().toString(36).substring(7);

    vm.printReport = printReport;
    vm.download = download;
    vm.isLoaded = false;

    vm.$onInit = () => {
      if (PermissionHelpers.hasAccess(['transcripts'], context.getContext().loggedInUser)) {
        if (!vm.selectedTable) {
          getReportOfVersion(vm.version, vm.selectedReportName);
          getVersions();
        }
      } else {
        $state.go('404');
      }
    }

    function getVersions() {
      vm.versions = [];

      TranscriptReportsService.getVersions($stateParams.clientId)
      .then((versions) => {
        vm.versionsData = versions.transcripts;
        _.each(vm.versionsData, (obj) => {
          obj.dateString = $filter('date')(obj.name, 'M/d/yy h:mm a')
        });
        vm.versions = _.map(versions.transcripts, (obj) => {
          return obj.dateString;
        });

        vm.selectedVersion = (vm.version !== 'undefined') ? lookupVersionTimestamp(vm.version) : vm.versions[0].dateString;
      })
      .catch(catchAsyncStacktrace());
    }

    function lookupVersionTimestamp(id) {
      return vm.versionsData ? _.filter(vm.versionsData, (version) => version.id == id)[0].dateString : '';
    }

    function getReportOfVersion(versionId, reportType) {
      let clientId = $stateParams.clientId;

      let report_type_url = REPORTS_HELPER.find((report) => report.title == reportType).report_type_url;

      TranscriptReportsService.getReport(clientId, versionId, report_type_url)
        .then((record) => {

          if(reportType == REPORT_TYPE.CSED_CALCULATIONS){
            vm.selectedTable = TableHelper.toTableFormat(record.report, false);
          } else {
            vm.selectedTable = TableHelper.toTableFormat(record.report);
          }

          vm.isLoaded = true;
        })
        .catch(catchAsyncStacktrace());
    }

    function printReport() {
      // Content and headers (for the stylesheets)
      let printContents = document.getElementById(vm.printableAreaId);
      let head = document.getElementsByTagName("head")[0];
      if(!printContents || !head) {
        toasts.warningToast('The selected report could not be printed');
        return;
      }

      //Make a copy so that the actual html isn't changed
      printContents = printContents.cloneNode(true);
      head = head.cloneNode(true);

      //Remove script tags from header
      let scriptTagCollection = head.getElementsByTagName("script");
      let scriptTagsArr = [].slice.call(scriptTagCollection);
      scriptTagsArr.forEach((remove) => {
        remove.parentElement.removeChild(remove);
      });

      // Open a new window
      // Fixes dual-screen position                         Most browsers      Firefox
      let dualScreenTop = window.screenTop != undefined ? window.screenTop : screen.top;
      let screenWidth = window.screenWidth != undefined ? window.screenWidth : screen.width;
      let printWindow = window.open("", '_blank', "top=" + dualScreenTop + ", left=" + screenWidth + ", width=800, height=800");

      //Write the report content
      if (printWindow) {
        printWindow.document.write(
          `<html>
            <head>
              ${head.innerHTML}
              <style type="text/css" media="print">@page { size: landscape; }</style>
            </head>
            <body style="background-color: white !important"> ${printContents.innerHTML} </body>
            <footer>
              <script>
                var printIconToRemove = document.getElementsByClassName("cp-hide-during-print")[0];
                printIconToRemove.parentElement.removeChild(printIconToRemove);

                setTimeout(function(){ window.stop(); window.print(); }, 300);

              </script>
            </footer>
          </html>`
        );
      } else {
        toasts.infoToast("The window didn't open! If you are using a pop-up blocking utility then it may have blocked it!", null, null, 6000);
      }
    }

    function download() {
      var report_type_url = REPORTS_HELPER.find((report) => report.title === vm.selectedReportName).report_type_url;
      var report_sub_type = BUSINESS_REPORT_SUBTYPES.find((subtype) => subtype.title === vm.selectedTable.subtype);

      window.location= `${vm.WORKFLOW_URL}/api/clients/${vm.clientId}/transcripts/${vm.version}/reports/${report_type_url}/csv${report_sub_type ? '/?reportType=' + report_sub_type.url: ''}`;
    }

    $scope.$watch('vm.isLoaded', () => {
      if (vm.isLoaded && vm.printing) {
        $timeout(() => { window.print() }, 300);
      }
    });
  },

  template,
});