import angular from 'angular';
import template from './client-dashboard.template.html';
import './client-dashboard.controller.js';
import 'angular/app/client/taxes/surveys/survey-list.component.js';
import '../notes/active-notes-dashboard-list.component.js';
import '../../../../resources/tasks.resource';
import '../tasks/tasks-index.component.js';
import '../tax-returns/tax-return-rows.component.js';

angular.module('app.clients').directive('clientDashboard', [
  function clientDashboard() {
    return {
      restrict: 'E',
      scope: {
        client: '=',
        state: '=',
      }, // necessary so we don't overwrite parent scope
      template: template,
      controller: 'ClientDashboardController',
      controllerAs: 'vm',
      bindToController: true,
    };
  },
]);
