import angular from "angular";
import { catchAsyncStacktrace } from 'auto-trace';

import 'angular/app/client/taxes/section-interactions/outcomes.style.css';
import 'angular/app/client/taxes/section-interactions/assistant-shared.style.css';
import 'angular/app/client/taxes/section-interactions/common-actions/change-status.directive.js';
import 'angular/app/client/taxes/section-interactions/common-actions/assign-section-envelope.component.js';
import 'angular/app/client/taxes/section-interactions/common-actions/due-date.directive.js';

import 'angular/app/client/taxes/programs/assistant/assistant-answers.service.js';

import './outcomes/first-time-abatement.component.js';
import './outcomes/reasonable-cause.component.js';

import * as outcomesConstants from './assistant.constants.js';
import * as outcomesHelper from './assistant-outcomes.helper.js';
import template from './assistant-outcomes.template.html';

angular.module('app.clients.taxes').component('cpOutcomesPenaltyAbatement', {
  bindings: {},

  controllerAs: 'vm',

  controller: function ($stateParams, AssistantAnswersService) {
    const vm = this;

    vm.outcomesConstants = outcomesConstants;

    vm.summaryRCExpanded = vm.summaryFTAExpanded = vm.bestPracticeFTAExpanded = false;

    AssistantAnswersService.getOutcomes()
    .then((outcomes) => {
      vm.outcomes = outcomes;

      vm.resolutionType = outcomesConstants.FIRST_TIME_ABATEMENT;

      if (!vm.firstTimeAbatementAvailable() && vm.reasonableCauseAvailable()) {
        vm.resolutionType = outcomesConstants.REASONABLE_CAUSE;
      }
    })
    .catch(catchAsyncStacktrace());

    vm.firstTimeAbatementAvailable = () => outcomesHelper.firstTimeAbatementAvailable(vm.outcomes);
    vm.reasonableCauseAvailable = () => outcomesHelper.reasonableCauseAvailable(vm.outcomes);
    vm.reliefUnlikely = () => outcomesHelper.reliefUnlikely(vm.outcomes);
    vm.nonAssessment = () => outcomesHelper.nonAssessment(vm.outcomes);
    vm.cantCalculateOutcomes = () => outcomesHelper.cantCalculateOutcomes(vm.outcomes);
  },

  template: template
});
