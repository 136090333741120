import angular from "angular";

angular.module('app.clients.taxes')
  .factory('EngagementLettersResource', ['$http', '$window',
    function EngagementLettersResource($http, $window) {
      $window.$http = $http;
      return {
        index: (params) => {
          return $http.get(
            `/api/clients/${params.clientId}/engagements/${params.engagementId}/letters`
          );
        },

        get: (params) => {
          return $http.get(
            `/api/clients/${params.clientId}/engagements/${params.engagementId}/letters/${params.letter_id}`
          );
        },

        post: (params, letter) => {
          return $http.post(
            `/api/clients/${params.clientId}/engagements/${params.engagementId}/letters`, {letters: letter}
          );
        },

        put: (params, letter) => {
          return $http.put(
            `/api/clients/${params.clientId}/engagements/${params.engagementId}/letters/${letter.id}`, {letters: letter}
          );
        },

        delete: (params) => {
          return $http.delete(
            `/api/clients/${params.clientId}/engagements/${params.engagementId}/letters/${params.letter_id}`
          );
        }
      };
    }
  ]);