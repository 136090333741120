import angular from "angular";
import _ from 'lodash';
import { asyncStacktrace } from 'auto-trace';
import context from "angular/bootstrap/context.service.js";
import { fetchWithSharedCache, forceBustCache } from 'fetcher!sofe';
import { pluck } from 'rxjs/operators'
import canopyUrls from 'canopy-urls!sofe';
import "angular/resources/contact.resource.js";
import "angular/app/admin/users/user.service.js"
import "angular/common/services/sources.service.js";

angular.module('app.clients')
  .factory('ContactService', ['$q', 'ContactResource', 'UserService', '$state', 'SourcesService',
    function ContactService($q, ContactResource, UserService, $state, SourcesService) {

      var service = {};
      service.getDetailedIndex = getDetailedIndex;
      service.searchIndexByName = searchIndexByName;
      service.getClient = getClient;
      service.saveClient = saveClient;
      service.saveRelatedContact = saveRelatedContact;
      service.patchClient = patchClient;
      service.putClient = putClient;
      service.deleteClient = deleteClient;
      service.archiveClients = archiveClients;
      service.deleteClients = deleteClients;
      service.deleteContactRelationship = deleteContactRelationship;
      service.deleteContactRelationships = deleteContactRelationships;
      service.transformSaveRequest = transformSaveRequest;
      service.transformPatchClient = transformPatchClient;
      service.scaffoldClient = scaffoldClient;
      service.scaffoldClientPhones = scaffoldClientPhones;
      service.scaffoldClientEmails = scaffoldClientEmails;
      service.scaffoldClientAddresses = scaffoldClientAddresses;
      service.scaffoldRelatedContact = scaffoldRelatedContact;
      service.getUsers = getUsers;
      service.getClientUsers = getClientUsers;
      service.isAddressEmpty = isAddressEmpty;
      service.cachedGetActiveClient = cachedGetActiveClient;
      service.bustContactCache = bustContactCache;
      service.hasPhoneEmailResource = hasPhoneEmailResource;
      service.hasAddressResource = hasAddressResource;

      return service;

      // JQL info: https://github.com/CanopyTax/canopy/wiki/Contacts#query-language
      // Gets the index with more data fields (everything needed for the Contact List) and pagination metadata
      function getDetailedIndex(params={}, excludeArchived=false, onlyActive=true) {
        var deferred = $q.defer();

        // The jql param needs to exist to get the detailed response
        // The jql param must be wrapped in an array
        // Once the old API has been phased out and the jql param no longer needs to exist for the new API, we can safely remove this
        if (!params['jql']) {
          params['jql'] = [[]];
        }
        if (excludeArchived) {
          params['jql'] = [[
            ...(params['jql'][0]),
            {
              field: 'is_archived',
              operator: 'eq',
              value: false,
            },
          ]];
        }
        if (onlyActive) {
          params['jql'] = [[
            ...(params['jql'][0]),
            {
              field: 'is_active',
              operator: 'eq',
              value: true,
            },
          ]];
        }

        ContactResource.index(params)
          .then(function success(response) {
            deferred.resolve({
              contacts: response.data.contacts,
              paginator: response.data.meta.paginator
            });
          })
          .catch(asyncStacktrace(response => {deferred.reject(response)} ));

        return deferred.promise;
      }

      function searchIndexByName(searchString='', excludeArchived=true, onlyActive=true, otherParams={}) {
        let jql = [];

        otherParams.limit = 10;

        searchString = searchString.toLowerCase();

        if (!_.isEmpty(searchString)) {
          jql = [...jql, {
            OR: [
              {
                field: 'person_name',
                operator: 'beginswith',
                value: searchString,
              },
              {
                field: 'business_name',
                operator: 'beginswith',
                value: searchString,
              },
              {
                field: 'last_name',
                operator: 'beginswith',
                value: searchString,
              },
            ]
          }];
        }

        return service.getDetailedIndex({
          ...otherParams,
          jql: [jql],
        }, excludeArchived, onlyActive);
      }

      function getClient(params) {
        var deferred = $q.defer();

        ContactResource.get(params)
          .then(function success(response) {
            deferred.resolve(response.data.clients);
          })
          .catch(asyncStacktrace(response => {
            const {originalErrorObject} = response
            if (originalErrorObject.status === 403) {
              $state.go('403')
            } else deferred.reject(response)
          }))

        return deferred.promise;
      }

      function getUsers(params, role = 'TeamMember') {
        var deferred = $q.defer();
        getClient(_.extend(params, {include: 'users'}))
          .then(function(clients) {
            deferred.resolve(_.filter(clients.users, {role: role}));
          })
          .catch(function(error) {
            deferred.reject(error);
          });
        return deferred.promise;
      }

      function getClientUsers(params) {
        return getUsers(params, 'Client');
      }

      function saveClient(params, payload) {
        var deferred = $q.defer();

        // If there are related contacts, tags, or sources, we need to create them after the base contact is created
        let relatedContacts = [];
        let tags = [];
        let sources = [];

        if (payload.clients.relationships) {
          if (payload.clients.relationships.contacts) {
            relatedContacts = _.cloneDeep(payload.clients.relationships.contacts);
            delete payload.clients.relationships.contacts;
          }

          if (payload.clients.relationships.tags) {
            tags = _.cloneDeep(payload.clients.relationships.tags);
            delete payload.clients.relationships.tags;
          }

          if (!_.isEmpty(payload.clients.relationships.contact_sources) && payload.clients.relationships.contact_sources[0].name) {
            sources = _.cloneDeep(payload.clients.relationships.contact_sources);
            delete payload.clients.relationships.contact_sources;
          }
        }

        ContactResource.post(params, payload)
          .then(function success(contactResponse) {
            let contactId = contactResponse.data.clients.id;

            // Create and associate any related contacts, tags, and sources
            let promises = [];
            if (!_.isEmpty(relatedContacts)) {
              promises.push(_saveRelatedContacts(contactId, relatedContacts));
            }
            if (!_.isEmpty(sources)) {
              promises.push(SourcesService.saveSourcesForOne(_.map(sources, 'name'), contactId));
            }

            if (!_.isEmpty(promises)) {
              $q.all(promises)
              .then(function(response) {
                deferred.resolve(contactResponse.data.clients)
              })
              .catch(deferred.reject);
            } else {
              deferred.resolve(contactResponse.data.clients);
            }

          })
          .catch(deferred.reject);

        return deferred.promise;
      }

      function putClient(params, payload) {
        var deferred = $q.defer();

        ContactResource.put(params, payload)
          .then(function success(response) {
            deferred.resolve(response.data.clients);
            forceBustCache(`${canopyUrls.getWorkflowUrl()}/api/contacts/${params.clientId}`);
          })
          .catch(deferred.reject);

        return deferred.promise;
      }

      function patchClient(params, payload) {
        var deferred = $q.defer();

        ContactResource.patch(params, payload)
          .then(function success(response) {
            deferred.resolve(response.data.clients);
            forceBustCache(`${canopyUrls.getWorkflowUrl()}/api/contacts/${params.clientId}`);
          })
          .catch(deferred.reject);

        return deferred.promise;
      }

      function deleteClient(params, payload) {
        var deferred = $q.defer();

        ContactResource.delete(params)
          .then(function success(response) {
            deferred.resolve(response.data.clients);
          })
          .catch(asyncStacktrace(response => {deferred.reject(response)} ));

        return deferred.promise;
      }

      // Remove a specified relationship from a base contact.
      function deleteContactRelationship(baseContactId, relatedContactId, relationshipType, params) {
        return service.patchClient({
          ...params,
          clientId: baseContactId
        }, {
          contacts: {
            relationships: {
              contacts: [
                {
                  id: relatedContactId,
                  relationship_type: relationshipType,
                  delete: true,
                }
              ]
            }
          }
        });
      }

      // Remove multiple relationships (of one type) from a single base contact.
      function deleteContactRelationships(baseContactId, relatedContactIds, relationshipType) {
        var deferred = $q.defer();
        var promises = _.map(relatedContactIds, (contactId) => service.deleteContactRelationship(baseContactId, contactId, relationshipType));

        $q.all(promises)
        .then((resp) => {
          deferred.resolve(resp);
        })
        .catch(asyncStacktrace(response => {deferred.reject(response)} ))

        return deferred.promise;
      }

      function archiveClients(ids) {
        var deferred = $q.defer();

        ContactResource.patchIndex('archive', ids)
          .then(function success(response) {
            deferred.resolve(response.data.clients);
          })
          .catch(asyncStacktrace(response => {deferred.reject(response)} ));

        return deferred.promise;
      }

      function deleteClients(ids) {
        var deferred = $q.defer();

        ContactResource.patchIndex('delete', ids)
          .then(function success(response) {
            deferred.resolve(response.data.clients);
          })
          .catch(asyncStacktrace(response => {deferred.reject(response)} ));

        return deferred.promise;
      }

      // Sets up related contacts within a base contact
      // Related contacts that do not yet exist will be created, and existing records will be linked
      function _saveRelatedContacts(clientId, contacts) {
        var deferred = $q.defer();
        var promises = [];

        for (let i=0; i<contacts.length; i++) {
          let contact = contacts[i];
          if (_.isEmpty(contact.relationships) || _.isEmpty(contact.relationships.contact_for))
            throw new Error(`Related contacts must have relationships.contact_for, for contact ${JSON.stringify(contact)}`);

          // Set up relationship ID
          contact.relationships.contact_for[0].id = clientId;

          if (!!contact.id) {
            promises.push(service.saveRelatedContact(contact, clientId, true));
          } else {
            promises.push(service.saveClient({}, {clients: service.transformPatchClient(contact)}));
          }
        }

        $q.all(promises).then(function success(response) {
          deferred.resolve(response);
        })
        .catch(deferred.reject);

        return deferred.promise;
      }

      function transformSaveRequest(emailArray, role, permissions) {
        var transformedData = {
          "permissions": permissions
        };

        return {
          "clients": transformedData
        };
      }


      function transformPatchClient(client) {
        client = _.cloneDeep(client);

        client = _cleanUpClientJson(client);
        client = _transformPatchClientPhoneNumbers(client);
        client = _transformPatchClientAddresses(client);

        // Copy the contact_owner object's id to contact_owner_id
        if (!_.isEmpty(client.contact_owner)) {
          client.contact_owner_id = client.contact_owner[0].id;
        }

        delete client.contact_owner;
        delete client.id;
        delete client.deleteParticipant;
        delete client.initials;
        delete client.irs;
        delete client.users;
        delete client.name;
        delete client.relationship_type;
        delete client.relationship_to_contact;
        delete client.relationship_notes;
        delete client.user_has_access;
        delete client.referredBy;

        return client;
      }

      // Handles saving a related contact.
      // If the contact already exists, and the current user has access to it, its record is updated.
      // If the contact already exists, but the user doesn't have access to it, ONLY its relationship to the main contact is updated.
      // If the contact does not yet exist, its record is created.
      // The relationship data to associate the contact with the mainContact should exist inside of contact.
      function saveRelatedContact(contact, mainContactId, saveRelationshipOnly=false) {
        let deferred = $q.defer();

        // Omit top-level relationship fields since this data is already inside the `relationships` field
        let transformedContact = service.transformPatchClient(_.omit(contact, ['relationship_type', 'relationship_to_contact', 'relationship_notes']));

        // If the related contact has an ID, it already exists and the existing record should be updated.
        // Otherwise, we create a new record.
        if (contact.id) {
          // If the user doesn't have access to the related contact, they can only update the relationship through the base contact record.
          // Or if saveRelationshipOnly is true, we just care about saving the relationship, so we do it through the base contact record.
          // Otherwise, we can update the relationship (and its other data) through its own contact record.
          if (!contact.user_has_access || saveRelationshipOnly) {
            const contactFor = _.has(contact, 'relationships.contact_for[0]') && contact.relationships.contact_for[0];
            service.patchClient({
              clientId: mainContactId,
            }, {
              contacts: {
                relationships: {
                  contacts: [
                    {
                      id: contact.id,
                      relationship_type: contactFor ? contactFor.relationship_type : contact.relationship_type,
                      relationship_to_contact: contactFor ? contactFor.relationship_to_contact : contact.relationship_to_contact,
                      relationship_notes: contactFor ? contactFor.relationship_notes : contact.relationship_notes,
                    }
                  ]
                }
              }
            })
            .then((resp) => {
              deferred.resolve(resp);
            })
            .catch((ex) => {
              deferred.reject(ex);
            });
          } else {
            service.putClient({
              clientId: contact.id,
            }, {
              contacts: transformedContact
            })
            .then((resp) => {
              deferred.resolve(resp);
            })
            .catch((ex) => {
              deferred.reject(ex);
            });
          }
        } else {
          service.saveClient({}, {
            clients: transformedContact,
          })
          .then((resp) => {
            resp.isNew = true;
            deferred.resolve(resp);
          })
          .catch((ex) => {
            deferred.reject(ex);
          });
        }

        return deferred.promise;
      }

      /**
       *
       * @param address
       * @param ignoreUS ignore default 'US' value in country when checking for emptiness
       * @returns {boolean}
       */
      function isAddressEmpty(address, ignoreUS = true) {
        if (_.isEmpty(address)) { return true; }
        let addressVal = _.cloneDeep(address.value);
        if (ignoreUS && addressVal.country === 'US') {
          addressVal = _.omit(address.value, ['country']);
        }
        return _.isEmpty(addressVal) || _.isEmpty(_.omitBy(addressVal, _.isEmpty));
      }

      function _transformPatchClientAddresses(client) {
        // If the mailing address is empty (other than country, which is set by default),
        // set it to be the same as the main address
        let mailingAddress = _.find(client.addresses, {key: "mailing"});
        if (mailingAddress && isAddressEmpty(mailingAddress)) {
          let key = client.is_business ? 'physical' : 'home';
          let mainAddress = _.find(client.addresses, {key: key});
          if (mainAddress) {
            mailingAddress.value = _.cloneDeep(mainAddress.value);
          }
        }
        return client;
      }

      function _transformPatchClientPhoneNumbers(client) {
        client.phones = _.map(client.phones, (phone) => {
          phone.value += (!_.isEmpty(phone.extension) ? ' x ' + phone.extension : '');
          delete phone.extension;
          return phone;
        });
        return client;
      }

      function _cleanUpClientJson(client) {
        // if values are empty, remove the entire phone, email, or address resource to avoid putting an empty value in the database
        client.phones = _.filter(client.phones,
          function(item) {
            if (item.value.length > 0) {
              return true;
            } else {

              if (!!item.id) {
                // delete the item if it has an id but no value
                item.delete = true;
                return true;
              }
              return false;
            }
          });

        client.emails = _.filter(client.emails,
          function(item) {
            if (item.value.length > 0) {
              return true;
            } else {

              if (!!item.id) {
                // delete the item if it has an id but no value
                item.delete = true;
                return true;
              }
              return false;
            }
          });

        client.addresses = _.filter(client.addresses,
          function(item) {
            var hasAddress = !isAddressEmpty(item, false);

            if (hasAddress) {
              return true;
            } else {

              if (!!item.id) {
                // delete the item if it has an id but no value
                item.delete = true;
                return true;
              }
              return false;
            }
          });

        if(client.phones.length === 0) delete client.phones;
        if(client.emails.length === 0) delete client.emails;
        if(client.addresses.length === 0) delete client.addresses;

        return client;
      }

      function scaffoldClient(client, isNewClient=false, isClientPortal=false) {
        // set up new client
        if (isNewClient) {
          client.created_on = Date.now();
          client.birthdate = null;
          client.client_since = Date.now();
          client.contact_owner_id = context.getContext().loggedInUser.id;
        }

        if (_.isEmpty(client.relationships)) {
          client.relationships = {
            contacts: [],
            tags: [],
          };
        }

        if (!isClientPortal) {
          // TODO: ask backend to return a contact_owner field
          // Get contact owner info
          if (client.contact_owner_id) {
            UserService.getUser({ userId: client.contact_owner_id })
              .then((successResponse) => {
                client.contact_owner = [successResponse];
              })
              .catch((ex) => {
                client.contact_owner = [];
              });
          }
        }

        client.phones = scaffoldClientPhones(client.phones, client.is_business);
        client.emails = scaffoldClientEmails(client.emails, client.is_business);
        client.addresses = scaffoldClientAddresses(client.addresses, client.is_business);

        return client;
      }

      // Scaffold the related contact specifically for this main contact, so the relationships.contact_for will only have the one contact in it
      function scaffoldRelatedContact(contact, mainContact, relationshipType, isNewlyRelated=false) {
        if (typeof contact.is_business == 'undefined') {
          contact.is_business = false;
        }

        // This is a new relationship, set up the relationship info based on the given default
        if (isNewlyRelated) {
          contact.relationship_type = contact.relationship_type || relationshipType;
        }

        // Scaffold the relationship to only be the one we're interested in
        if (_.has(contact, 'relationships.contact_for'))
          contact.relationships.contact_for = _.filter(contact.relationships.contact_for, contact => contact.id === mainContact.id && contact.relationship_type === relationshipType);

        // Make sure relationship is set up both in the contact itself and in the relationships
        if (!_.has(contact, 'relationships.contact_for') || _.isEmpty(contact.relationships.contact_for) || contact.relationships.contact_for[0].id != mainContact.id) {
          contact.relationships = {
            contact_for: [
              {
                type: 'contact',
                id: mainContact.id,
                relationship_type: contact.relationship_type || '',
                relationship_to_contact: contact.relationship_to_contact || '',
                relationship_notes: contact.relationship_notes || '',
              }
            ]
          }
        }

        if (!contact.relationship_type)
          contact.relationship_type = contact.relationships.contact_for[0].relationship_type || '';
        if (!contact.relationship_to_contact)
          contact.relationship_to_contact = contact.relationships.contact_for[0].relationship_to_contact || '';
        if (!contact.relationship_notes)
          contact.relationship_notes = contact.relationships.contact_for[0].relationship_notes || '';

        // Scaffold contact info
        contact.phones = service.scaffoldClientPhones(contact.phones, contact.is_business);
        contact.emails = service.scaffoldClientEmails(contact.emails, contact.is_business);
        contact.addresses = service.scaffoldClientAddresses(contact.addresses, contact.is_business);

        // Copy base contact's home address for spouse
        if (isNewlyRelated && relationshipType === 'spouse') {
          let baseContactHomeAddress = _.find(mainContact.addresses, {key: 'home'})
          let relatedContactHomeAddress = _.find(contact.addresses, {key: 'home'}) || {key: 'home', value: {}};
          let relatedContactMailingAddress = _.find(contact.addresses, {key: 'mailing'}) || {key: 'mailing', value: {}};

          if (service.isAddressEmpty(relatedContactHomeAddress)) {
            relatedContactHomeAddress.value = _.cloneDeep(baseContactHomeAddress.value);
          }

          if (service.isAddressEmpty(relatedContactMailingAddress)) {
            relatedContactMailingAddress.value = _.cloneDeep(baseContactHomeAddress.value);
          }
        }

        return contact;
      }

      function hasPhoneEmailResource(array) {
        return _.reduce(array, function(hasValue, item) {
          if (hasValue === true) return true;
          return item.value.length > 0;
        }, false);
      }

      function hasAddressResource(array) {
        return _.reduce(array, function(hasValue, item) {
          if (hasValue === true) return true;
          return !service.isAddressEmpty(item);
        }, false);
      }

      function scaffoldClientPhones(phones, isBusiness) {
        if (!phones) {
          if (isBusiness) {
            phones = [
              {
                key: 'main',
                value: '',
                extension: ''
              }, {
                key: 'fax',
                value: '',
                extension: ''
              }, {
                key: 'other',
                value: '',
                extension: ''
              }
            ];
          } else {
            phones = [
              {
                key: 'home',
                value: '',
                extension: ''
              }, {
                key: 'mobile',
                value: '',
                extension: ''
              }, {
                key: 'work',
                value: '',
                extension: ''
              }, {
                key: 'fax',
                value: '',
                extension: ''
              }, {
                key: 'other',
                value: '',
                extension: ''
              }
            ];
          }

        } else {
          if (isBusiness) {
            if (!_.find(phones, { key: "main" })) {
              phones.push({
                key: 'main',
                value: '',
                extension: ''
              });
            }
            if (!_.find(phones, { key: "fax" })) {
              phones.push({
                key: 'fax',
                value: '',
                extension: ''
              });
            }
            if (!_.find(phones, { key: "other" })) {
              phones.push({
                key: 'other',
                value: '',
                extension: ''
              });
            }

          } else {
            // set default phone types
            if (!_.find(phones, { key: "home" })) {
              phones.push({
                key: 'home',
                value: '',
                extension: ''
              });
            }
            if (!_.find(phones, { key: "mobile" })) {
              phones.push({
                key: 'mobile',
                value: '',
                extension: ''
              });
            }
            if (!_.find(phones, { key: "work" })) {
              phones.push({
                key: 'work',
                value: '',
                extension: ''
              });
            }
            if (!_.find(phones, { key: "fax" })) {
              phones.push({
                key: 'fax',
                value: '',
                extension: ''
              });
            }
            if (!_.find(phones, { key: "other" })) {
              phones.push({
                key: 'other',
                value: '',
                extension: ''
              });
            }
          }
        }

        return _.map(phones, (phone) => {
          // Parse extension field (for frontend use only)
          let extParts = phone.value.split(' x ');
          if (extParts.length === 2) {
            phone.value = extParts[0];
            phone.extension = extParts[1];
          } else {
            phone.extension = '';
          }
          // Strip everything but digits
          phone.value = phone.value.replace(/[\D\s]/g, '');
          return phone;
        });
      }

      function scaffoldClientEmails(emails, isBusiness) {
        if (!emails) {
          emails = [];
          if (isBusiness) {
            emails = [
              {
                key: 'main',
                value: ''
              }, {
                key: 'other',
                value: ''
              }, {
                key: 'other',
                value: ''
              }
            ];
          } else {
            emails = [
              {
                key: 'personal',
                value: ''
              }, {
                key: 'work',
                value: ''
              }, {
                key: 'other',
                value: ''
              }
            ];
          }
        } else {
          if (isBusiness) {
            // set default email types
            if (!_.find(emails, {
              key: "main"
            })) {
              emails.push({
                key: 'main',
                value: ''
              })
            }
            if (!_.find(emails, {
              key: "other"
            })) {
              emails.push({
                key: 'other',
                value: ''
              })
            }
            if (_.find(emails, {
                key: "other"
              }).length === 1) {
              emails.push({
                key: 'other',
                value: ''
              })
            }

          } else {
            // set default email types
            if (!_.find(emails, {
              key: "personal"
            })) {
              emails.push({
                key: 'personal',
                value: ''
              })
            }
            if (!_.find(emails, {
              key: "work"
            })) {
              emails.push({
                key: 'work',
                value: ''
              })
            }
            if (!_.find(emails, {
              key: "other"
            })) {
              emails.push({
                key: 'other',
                value: ''
              })
            }
          }

        }

        return emails;
      }

      function scaffoldClientAddresses(addresses, isBusiness) {
        if (!addresses) {

          if (isBusiness) {
            addresses = [
              {
                key: 'physical',
                value: {
                  country: 'US'
                }
              }, {
                key: 'mailing',
                value: {
                  country: 'US'
                }
              },  {
                key: 'other',
                value: {
                  country: 'US'
                }
              }
            ];
          } else {
            addresses = [
              {
                key: 'home',
                value: {
                  country: 'US'
                }
              }, {
                key: 'mailing',
                value: {
                  country: 'US'
                }
              }, {
                key: 'work',
                value: {
                  country: 'US'
                }
              }, {
                key: 'other',
                value: {
                  country: 'US'
                }
              }
            ];
          }
        } else {
          if (isBusiness) {
            // set default address types
            if (!_.find(addresses, {
              key: "physical"
            })) {
              addresses.push({
                key: 'physical',
                value: ''
              })
            }
            if (!_.find(addresses, {
              key: "mailing"
            })) {
              addresses.push({
                key: 'mailing',
                value: ''
              })
            }

            if (!_.find(addresses, {
              key: "other"
            })) {
              addresses.push({
                key: 'other',
                value: ''
              })
            }

          } else {
            // set default address types
            if (!_.find(addresses, {
              key: "home"
            })) {
              addresses.push({
                key: 'home',
                value: ''
              })
            }
            if (!_.find(addresses, {
              key: "mailing"
            })) {
              addresses.push({
                key: 'mailing',
                value: ''
              })
            }
            if (!_.find(addresses, {
              key: "work"
            })) {
              addresses.push({
                key: 'work',
                value: ''
              })
            }
            if (!_.find(addresses, {
              key: "other"
            })) {
              addresses.push({
                key: 'other',
                value: ''
              })
            }

          }

        }

        return _.map(addresses, (address) => {
          // Make sure all addresses have a country, US is default
          if (_.isEmpty(address.value)) {
            address.value = {
              country: 'US'
            }
          } else if (_.isEmpty(address.value.country)) {
            address.value.country = 'US';
          }

          // Make sure the "main" key is mapped to "physical" or "home"
          if (address.key === 'main') {
            address.key = isBusiness ? 'physical' : 'home';
          }

          return address;
        });
      }

      /* clientId: optional (will default to what is in the $state.params)
       * RETURN_VALUE: an observable that will produce multiple values as the active client is changed.
      */
      function cachedGetActiveClient(clientId) {
        clientId = clientId || $state.params.clientId;

        function cacheUntil() {
          return window.location.hash.indexOf(`client/${clientId}`) < 0 && window.location.hash.indexOf(`clients/${clientId}`) < 0;
        }

        return fetchWithSharedCache(`${canopyUrls.getWorkflowUrl()}/api/clients/${clientId}?include=users,contacts,tags,contact_for,contact_sources`, cacheUntil)
          .pipe(pluck('clients'))
      }

      function bustContactCache(contactId) {
        forceBustCache(`${canopyUrls.getWorkflowUrl()}/api/clients/${contactId}`);
        forceBustCache(`${canopyUrls.getWorkflowUrl()}/api/contacts/${contactId}`);
      }

    }
  ]);
