import angular from "angular";

import 'angular/app/client/taxes/section-interactions/assistant.style.css';
import 'angular/app/client/taxes/section-interactions/assistant-shared.style.css';

import template from './issued-erroneously.template.html';

angular.module('app.clients.taxes').component('cpAssistantQuestionIssuedErroneously', {
  bindings: {},

  controllerAs: 'vm',

  controller: function () {
    const vm = this;
    vm.modalIssuedErroneously = false;
  },

  template: template
});
