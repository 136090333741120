import angular from 'angular';
import template from './active-notes.template.html';
import './active-notes.style.css';
import { catchAsyncStacktrace } from 'auto-trace';
import './active-notes-list.component.js';
import './active-notes-pinned.component.js';
import './active-notes-single-note.component.js';
import './notes-search.component.js';
import { isArchived, isDeleted } from './notes.helpers.js';

angular
  .module('app.clients.notes')
  .component('activeNotes', {
    bindings: {
      client: '=',
    },
    template,
    controllerAs: 'vm',
    controller(NotesService, $state, $stateParams) {
      const vm = this;
      vm.isLoaded = false;
      vm.expandAll = false;

      NotesService.getIndex({clientId: $state.params.clientId})
            .then(notes => {
              vm.searchNotes = vm.notes = notes;
              vm.isLoaded = true;
            })
            .catch(catchAsyncStacktrace());

      vm.newNote = () => {
        NotesService
          .createNote({clientId: $stateParams.clientId}, {title: '', body: '', visible_to_client: false})
          .then((note) => {
            $state.go('edit-note', {clientId: vm.client.id, noteId: note.id});
          })
          .catch(catchAsyncStacktrace());
      }

      vm.noActiveNotes = () => vm.notes ? !vm.notes.some(isActive) : false;
  },
});

function isActive(note) {
  return !isArchived(note) && !isDeleted(note);
}
