import angular from "angular";
import template from "./engagement-lock.template.html";
import "./engagement-lock.controller.js";

angular.module('app.clients.taxes')
  .directive('engagementLock', [

    function() {
      return {
        restrict: 'E',
        scope: {
          client: '=',
          engagement: '='
        }, // necessary so we don't overwrite parent scope
        template: template,
        controller: 'EngagementLockController',
        controllerAs: 'vm',
        bindToController: true
      }
    }
  ]);
