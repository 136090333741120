import angular from "angular";
import { asyncStacktrace } from 'auto-trace';
import "angular/resources/notes.resource.js";
import toasts from "toast-service!sofe";

angular.module('app.clients.notes')
  .factory('NotesService', ['$q', 'NotesResource',
    function NotesService($q, NotesResource) {
      return {
        getIndex: function getIndex(params, query) {
          var deferred = $q.defer();

          NotesResource.index(params, query)
            .then(function success(response) {
              deferred.resolve(response.data.notes);
            })
            .catch(asyncStacktrace(response => {
              deferred.reject(response)
              toasts.warningToast("Unable to retrieve the list of notes, please try again");
            }));

          return deferred.promise;
        },

        getDeletedIndex: function getDeletedIndex(params) {
          params.onlyTrashed = true;
          return this.getIndex(params);
        },

        restore: function(params) {
          return this.saveNote(params, {
            is_deleted: false
          });
        },

        getNote: function getNote(params) {
          var deferred = $q.defer();

          NotesResource.get(params)
            .then(function success(response) {
              deferred.resolve(response.data.notes);
            })
            .catch(asyncStacktrace(response => {
              deferred.reject(response)
              toasts.warningToast("Unable to retrieve the note, please try again");
            }));

          return deferred.promise;
        },

        saveNote: function saveNote(params, data) {
          var deferred = $q.defer();

          NotesResource.save(params, {
              notes: data
            })
            .then(function success(response) {
              deferred.resolve(response.data.notes);
            })
            .catch(asyncStacktrace(response => {
              deferred.reject(response)
              toasts.warningToast("Unable to save the note, please try again");
            }));

          return deferred.promise;
        },

        createNote: function createNote(params, data) {

          var deferred = $q.defer();

          NotesResource.create(params, {
              notes: data
            })
            .then(function success(response) {
              deferred.resolve(response.data.notes);
            })
            .catch(asyncStacktrace(response => {
              deferred.reject(response)
              toasts.warningToast("Unable to create the note, please try again");
            }));

          return deferred.promise;
        },

        deleteNote: function deleteNote(params) {
          var deferred = $q.defer();

          NotesResource.remove(params)
            .then(function success(response) {
              deferred.resolve(response.data.notes);
            })
            .catch(asyncStacktrace(response => {
              deferred.reject(response)
              toasts.warningToast("Unable to delete the note, please try again");
            }));

          return deferred.promise;
        },

        getBlankNote: function() {
          return {
            body: "",
            title: "",
            visible_to_client: false
          }
        }
      };

    }
  ]);
