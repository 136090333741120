import angular from "angular";
import contextService from "angular/bootstrap/context.service.js";
import { addGlobalMiddleware } from 'auto-trace';
import responseHelpers from "angular/common/helpers/response.helpers.js";

angular.module('app')
  .controller('AppController', ['$scope', 'context', 'ErrorHandlingService',
    function AppController($scope, context, ErrorHandlingService) {
      var vm;
      vm = this;

      $scope.$on('$destroy', () => {
        context.tenantUserSubscription.unsubscribe();
      })

      function init() {
        vm.loggedInUser = context.loggedInUser;
        vm.tenant = context.tenant;

        // for Ambassador SSO
        window.mbsy_username = vm.loggedInUser.email;

        contextService.setContext(context);

        ErrorHandlingService.startHandling(context);
        addGlobalMiddleware(responseHelpers.setErrors);
      }

      init();
    }
  ]);
