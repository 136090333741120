import angular from "angular";

/**
 * Show Errors
 * AngularJS directive for displaying server side and client side validation errors
 * **NOTE CUSTOM CODE BELOW**
 */
angular.module('app')
  .provider('showErrorsConfig', function() {
    var _showSuccess, _trigger;
    _showSuccess = false;
    _trigger = 'blur';
    this.showSuccess = function(showSuccess) {
      _showSuccess = showSuccess;
      return showSuccess;
    };
    this.trigger = function(trigger) {
      _trigger = trigger;
      return trigger;
    };
    this.$get = function() {
      return {
        showSuccess: _showSuccess,
        trigger: _trigger
      };
    };
  });


