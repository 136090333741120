import angular from "angular";

angular.module('app')
  .controller('ProgressBarController', ['$scope', '$element', '$timeout',
    function ProgressBarController($scope, $element, $timeout) {
      var vm = this;
      vm.setProgress = setProgress;

      function init() {
        vm.minLabel = vm.minLabel || "0%";
        vm.maxLabel = vm.maxLabel || "100%";
        vm.backgroundColor = vm.backgroundColor || "var(--cps-color-primary)";
        vm.borderColor = vm.borderColor || "var(--cps-color-primary);";
        $timeout(() => {
          vm.progressPercent ? vm.setProgress(vm.progressPercent) : vm.setProgress(0);
        }, 0, false);
      }

      init();

      $scope.$watch('vm.progressPercent', (newVal, oldVal) => {
        vm.setProgress(newVal);
      });

      function setProgress(val) {
        $timeout(() => {
          let newVal;

          if (typeof val === "string") {
            newVal = Number(val);
          }
          else {
            newVal = val;
          }

          if (newVal >= 0 && newVal <= 100) {
            vm.progressBarWidth = newVal + "%";
            if (newVal !== 0 && newVal !== 100) {
              let progressBarLabelNumber = Math.floor(($element[0].querySelector(".cp-progress-bar__progress-container").getBoundingClientRect().width * (newVal/100)) - ($element[0].querySelector(".cp-progress-bar__progress-label").getBoundingClientRect().width/2));

              if (progressBarLabelNumber < 0) {
                vm.progressBarLabelPosition = "0px";
              }
              else if (progressBarLabelNumber > ($element[0].querySelector(".cp-progress-bar__progress-container").getBoundingClientRect().width - $element[0].querySelector(".cp-progress-bar__progress-label").getBoundingClientRect().width)) {
                vm.progressBarLabelAlign = "right";
                vm.progressBarLabelWidth = $element[0].querySelector(".cp-progress-bar__progress-container").getBoundingClientRect().width;
              }
              else {
                vm.progressBarLabelPosition = progressBarLabelNumber + "px";
              }
            }
            else if (newVal === 0) {
              vm.progressBarLabelAlign = "left";
              vm.progressBarWidth = "3px";
            }
            else {
              vm.progressBarLabelAlign = "right";
              vm.progressBarLabelWidth = $element[0].querySelector(".cp-progress-bar__progress-container").getBoundingClientRect().width;
            }
          }
        }, 1100);
      }
    }
  ]);
