export const reminderFrequencies = [
  {
    key: 'weekday',
    value: 'Every Weekday',
  },
  {
    key: 'every-mwf',
    value: 'Every Monday, Wednesday, Friday',
  },
  {
    key: 'every-tth',
    value: 'Every Tuesday, Thursday',
  },
  {
    key: 'every-m',
    value: 'Every Monday',
  },
];

export const expirationOptions = [
  {
    key: '15',
    value: '15 days',
  },
  {
    key: '30',
    value: '30 days',
  },
  {
    key: '45',
    value: '45 days',
  },
  {
    key: '60',
    value: '60 days',
  },
];
