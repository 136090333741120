import angular from "angular";

angular.module('app')
  .directive('cpDecimalNumber', function($filter) {
      return {
        require: 'ngModel',
        restrict: 'A',
        link: function(scope, element, attrs, ngModel) {
          let precision;
          if (attrs.precision) {
            precision = parseInt(attrs.precision);
          }
          function parse(value) {
            var clean = value.replace(/[^0-9\.]+/g, '');
            const firstDecimal = clean.indexOf('.');
            if (firstDecimal >= 0) {
              //only one decimal point
              let secondDecimal = clean.indexOf('.', firstDecimal + 1);
              if (secondDecimal >= 0) {
                clean = clean.substring(0, secondDecimal);
              } else if (precision) {
                //precision
                const endIndex = Math.min(clean.length, clean.indexOf('.') + precision + 1);
                clean = clean.substring(0, endIndex);
              }
            }
            if (value !== clean) {
              ngModel.$setViewValue(clean);
              ngModel.$render();
            }
            return clean;
          }
          ngModel.$parsers.push(parse);
        }
      }
    });
