import angular from 'angular';
import {filter, clone} from 'lodash';
import { catchAsyncStacktrace } from 'auto-trace';

import template from './assistant.template.html';
import './assistant.style.css';
import './assistant-shared.style.css';

import * as assistantConstants from 'angular/app/client/taxes/programs/assistant/assistant.constants.js';

import 'angular/app/client/taxes/programs/assistant/assistant-answers.service.js';

import 'angular/app/client/taxes/programs/penalty-abatements/assistant.component.js';
import 'angular/app/client/taxes/programs/innocent-spouse/assistant.component.js';
import 'angular/app/client/taxes/programs/liens/assistant.component.js';
import 'angular/app/client/taxes/programs/levies/assistant.component.js';
import 'angular/app/client/taxes/programs/trust-fund/assistant.component.js';

import 'angular/app/client/taxes/section-interactions/common-actions/change-status.directive.js';
import 'angular/app/client/taxes/section-interactions/common-actions/due-date.directive.js';

angular.module('app.clients.taxes').component('cpAssistant', {
  bindings: {
    engagement: '=',
    questionSlugs: '=',
    questionTypeConsts: '='
  },

  controllerAs: 'vm',

  controller: function (AssistantAnswersService, $scope, $stateParams, $location, $timeout, ContactService, ProgramSectionsService, EngagementService, SectionEnvelopesService) {
    const vm = this;

    vm.transitioningYes = vm.transitioningNo = false;

    vm.$stateParams = $stateParams;
    vm.assistantConstants = assistantConstants;
    vm.regetActiveTeamMembers = regetActiveTeamMembers;

    let questionSlugs = vm.questionSlugs;
    let questionTypeConsts = vm.questionTypeConsts;

    let init = () => {
      if ($stateParams.questionSlug) {
        if ($stateParams.questionSlug == questionSlugs.INTRO) {
          AssistantAnswersService.submitAnswer(questionSlugs.INTRO, true);
        } else {
          vm.stateQuestionSlug = $stateParams.questionSlug;
          vm.showResumeBanner = !!$stateParams.resumeBanner;
          delete $stateParams.resumeBanner; //we only want to show this for one question

          AssistantAnswersService.getQuestion(vm.stateQuestionSlug)
            .then((question) => {
              vm.questionSlug = question.questionSlug;
              let questionDetails = questionTypeConsts.questions[vm.questionSlug];

              vm.question = Object.assign({}, question, questionDetails);
              vm.question.newAnswer = clone(vm.question.answer);

              let progress_bar = document.querySelector('#percentageComplete');

              if (progress_bar) {
                progress_bar.style.width = `${question.percentage * 100}%`;
              }
            });

          if (!$stateParams.activeTeamMembers) {
            ContactService.getClient({clientId: $stateParams.clientId, include: 'users'})
              .then(function (successResponse) {
                vm.activeTeamMembers = filter(successResponse.users, {
                  'is_activated': true,
                  'is_deleted': false,
                  'role': 'TeamMember'
                });
              })
              .catch(catchAsyncStacktrace())
          } else {
            vm.activeTeamMembers = $stateParams.activeTeamMembers;
          }

          if (!$stateParams.section || !$stateParams.sectionEnvelope) {
            $scope.$watch('vm.engagement', () => {
              if (vm.engagement && $stateParams.sectionSlug) {
                ProgramSectionsService.get({
                  engagementId: $stateParams.engagementId,
                  clientId: $stateParams.clientId,
                  sectionId: EngagementService.getSectionFromEngagementWithSlugs(vm.engagement, $stateParams.programSlug, assistantConstants.assistantResources[$stateParams.programSlug].assistantSlug).id
                }).then((section) => {
                  vm.section = section;
                  SectionEnvelopesService.getEnvelope({
                    ...$stateParams,
                    sectionId: section.id,
                    envelopeId: section.relationships.children[0].references
                  })
                  .then((envelope) => {
                    vm.sectionEnvelope = envelope;
                  });
                });
              }
            });
          } else {
            vm.section = $stateParams.section;
            vm.sectionEnvelope = $stateParams.sectionEnvelope;
          }
        }
      } else {
        //no question slug in url means we should just resume assistant
        AssistantAnswersService.resumeAssistant($stateParams.sectionSlug);
      }
    };

    function regetActiveTeamMembers() {
      ContactService.getClient({clientId: $stateParams.clientId, include: 'users'})
        .then(function (successResponse) {
          vm.activeTeamMembers = filter(successResponse.users, {
            'is_activated': true,
            'is_deleted': false,
            'role': 'TeamMember'
          });
        })
        .catch(catchAsyncStacktrace())
    }

    vm.restartAssistant = AssistantAnswersService.restartAssistant;

    vm.submitAnswer = (answer) => {
      //detect if the answer has really changed
      if (vm.question.answer !== answer || !vm.question.nextQuestionSlug) {
        //do some fancy css transitions
        vm.transitioningAny = true;

        $timeout(() => {
          //perform the transition
          if (answer) vm.transitioningYes = true;
          else vm.transitioningNo = true;
          $timeout(() => {
            //actually save and then reroute
            AssistantAnswersService.submitAnswer(vm.questionSlug, answer, {
              sectionEnvelope: vm.sectionEnvelope,
              section: vm.section,
              activeTeamMembers: vm.activeTeamMembers
            });
          }, 200);
        }, 0);
      } else {
        //just go to the next state
        AssistantAnswersService.goToQuestion(vm.question.nextQuestionSlug);
      }
    };

    vm.hideResumeBanner = () => {
      vm.showResumeBanner = false; //this triggers fade out
    }

    vm.goToNextQuestion = () => {
      if (vm.question.nextQuestionIsOutcome)
        AssistantAnswersService.goToOutcome();
      else
        AssistantAnswersService.goToQuestion(vm.question.nextQuestionSlug, {
          sectionEnvelope: vm.sectionEnvelope,
          section: vm.section,
          activeTeamMembers: vm.activeTeamMembers
        });
    };

    vm.goToPrevQuestion = () => {
      AssistantAnswersService.goToQuestion(vm.question.previousQuestionSlug, {
        sectionEnvelope: vm.sectionEnvelope,
        section: vm.section,
        activeTeamMembers: vm.activeTeamMembers
      });
    };

    vm.questionHasBeenAnswered = () => {
      return vm.question && (vm.question.answer || vm.question.answer === false);
    }

    $scope.$on('cp:assistant-answer:selected', (event, questionSlug, answer) => {
      if (vm.questionSlug === questionSlug) {
        vm.submitAnswer(answer);
      }
    });

    init();
  },

  template: template
});
