import angular from "angular";
import _ from 'lodash';
import template from "./edit-note.template.html";
import "./edit-note.controller.js";
import "./edit-note.style.css";

angular.module('app.clients.notes')
  .directive('cpEditNote', [

    function editNote() {
      return {
        restrict: 'E',
        scope: {
          client: '=',
          note: '=',
          deleteNote: '=',
          restoreNote: '=',
          saveNote: '=',
          isDeleted: '='
        }, // necessary so we don't overwrite parent scope
        template: template,
        controller: 'EditNoteController',
        controllerAs: 'vm',
        bindToController: true,
        link: function(scope, el, attr) {
          if(_.has(attr, 'autoFocus')) {
            focusEl();
          }

          scope.$on('cpEditNoteFocus', focusEl);

          function focusEl() {
            setTimeout(function() {
              el.find('.ta-bind').eq(0).focus().focus();
            })
          }
        }
      }
    }
  ]);
