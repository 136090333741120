import angular from "angular";

import 'angular/app/client/taxes/section-interactions/assistant.style.css';
import 'angular/app/client/taxes/section-interactions/assistant-shared.style.css';

angular.module('app.clients.taxes').component('cpAssistantQuestionPayments', {
  bindings: {},

  controllerAs: 'vm',

  controller: function () {
    const vm = this;
    vm;
  },

  template: ($element, $attrs) => {
    const TOOLTIP = 'Per IRC 6511';

    return `<strong>Were there <cps-tooltip cp-custom-element html="'${TOOLTIP}'"><a>payments</a></cps-tooltip> made against balances due within the last two years?</strong>`;
  }
});
4
