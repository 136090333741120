import angular from 'angular';
import moment from 'moment';
import { catchAsyncStacktrace } from 'auto-trace';

import 'angular/app/admin/letters/letter-templates.service.js';
import './engagement-letters.service.js';

import { navigateToLetters } from './letters.helper.js';

import './create-new-letter.style.css';
import template from './create-new-letter.template.html';

angular.module('app.clients.taxes')
.component('cpCreateNewLetter', {
  bindings: {
    engagement : '<',
    setShowCreateLetterModal : '&',
  },

  controllerAs: 'vm',

  controller: function (EngagementLettersService, LetterTemplatesService, $stateParams) {
    const vm = this;

    vm.initialized = false;

    vm.navigateToLetters = navigateToLetters;

    vm.$onInit = () => {
      vm.letter_templates = [];

      LetterTemplatesService.getTemplates({...$stateParams, template_type: 'letters'})
      .then(
        (letter_templates) => {
          vm.letter_templates = letter_templates;
          vm.initialized = true;
        }
      ).catch(catchAsyncStacktrace())

      vm.initialized = true;
    }

    vm.createNewLetter = (template = null) => {
      let currentDateString = moment().format("M/DD/YYYY");

      let letter = {
        title: `Engagement Letter ${currentDateString}`,
        body: '',
      }

      if (template) {
        LetterTemplatesService.getTemplate({template_type: 'letters', template_id: template.id}).then(
          resp => {
            const temp = resp.letters;
            letter = {...temp, title: `${temp.title} - ${currentDateString}`}
            delete letter.id;
            EngagementLettersService.addLetter({...$stateParams}, letter).then(
              newLetter => vm.navigateToLetters({...$stateParams, letter_id: newLetter.id}),
              catchAsyncStacktrace()
            )
          },
          catchAsyncStacktrace()
        )
      } else {
        EngagementLettersService.addLetter({...$stateParams}, letter).then(
          newLetter => vm.navigateToLetters({...$stateParams, letter_id: newLetter.id}),
          catchAsyncStacktrace()
        )
      }
    }

  },

  template,
});
