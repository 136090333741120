import angular from 'angular';

angular.module('app.clients.taxes')
.factory('AssistantAnswersResource', ['$http',
  function AssistantAnswersResource($http) {
    return {
      fetchAnswers: (clientId, engagementId, assistant_type) => $http.get(`/api/clients/${clientId}/engagements/${engagementId}/services/${assistant_type}/answers`),

      putAnswers: (clientId, engagementId, assistant_type, answers) => $http.put(`/api/clients/${clientId}/engagements/${engagementId}/services/${assistant_type}/answers`, answers),
    }
  }
])
