import { prettyList } from "angular/common/helpers/grammar.helpers.js";
import { isEmpty } from 'lodash';
import { hasAccess } from 'cp-client-auth!sofe';
import { taxResServiceSlugs } from './engagement-summary.helper.js';

const taxResolutionServices = ["Collections", "Penalty Abatement", "Innocent Spouse", "Liens", "Levies", "Trust Fund Recovery"];

export function formatEngagementHistory(engagement, history, loggedInUser){

  var sectionIdToService = [];
  var sectionsById = {};

  for(var program of engagement.program_data.programs){
    for (var section of program.sections){
      sectionIdToService[section.id] = program;
      sectionsById[section.id] = section;
    }
  }

  const userHasAccess = hasAccess(loggedInUser);

  return history.map((action) => {
    //Name
    if(action.performed_by_id == window.getLoggedInUser().$$state.value.id) action.performed_by_name = "You";

    //Format actions
    switch(action.name){
      case 'created':
        //Pretty Print Created this engagement
        if(action.pivot_type == 'App\\Engagement' && action.pivot_id === engagement.id){
          action.name = "created this engagement";
          action.value = null;
        } else if (action.pivot_type === 'App\\MasterSurvey') {
          action.name = 'created the';
          action.value.name = 'client survey';
          action.link_type = 'survey';
          action.no_link = !userHasAccess('client_survey');
        } else {
          action.name = "created the"
        }
        break;
      case 'created_letter':
        action.name = "created the letter,";
        action.link_type = 'engagement_letter';
        action.no_link = !userHasAccess('letters');
        action.letterId = action.value.id;
        action.pivot_id = action.value.id;
        break;
      case 'updated_letter':
        action.name = "updated the letter,";
        action.link_type = 'engagement_letter';
        action.no_link = !userHasAccess('letters');
        action.letterId = action.value.id;
        action.pivot_id = action.value.id;
        break;
      case 'sent_to_files':
        action.name = "sent the letter,";
        action.link_type = 'engagement_letter';
        action.no_link = !userHasAccess('letters');
        action.letterId = action.value.id;
        action.pivot_id = action.value.id;
        break;
      case 'updated_letter_title':
        action.name = "renamed the letter,";
        action.link_type = 'engagement_letter';
        action.no_link = !userHasAccess('letters');
        action.letterId = action.value.id;
        action.pivot_id = action.value.id;
        action.value.old_name = action.value.old_title;
        action.value.new_name = action.value.title;
        action.value.title = '';
        break;
      case 'deleted_letter':
        action.name = "deleted the letter,";
        action.pivot_id = action.value.id;
        break;
      case 'edit':
        action.name = "edited the";
        break;
      case 'sent':
        action.name = "sent the";
        if (action.pivot_type.indexOf('ClientRequest') > -1) {
          action.name += ' client request,';
        }
        if (action.pivot_type === `App\\MasterSurvey`) {
          action.value.name = 'client survey';
          action.link_type = 'survey';
          action.no_link = !userHasAccess('client_survey');
        }
        break;
      case 'sent_bulk':
        action.name = 'sent';
        if (action.pivot_type.indexOf('ClientRequest') > -1) {
          action.name += ' ' + String(action.value.number_of_requests) + ' client requests';
        }
        action.link_type = 'client_requests';
        action.no_link = !userHasAccess('tasks_client_requests');
        break;
      case 'added':
        if(action.pivot_type.indexOf("EngagementSection") > -1){
          action.name = "added the step";
          if(!!sectionIdToService[action.pivot_id]){
            action.value.parent_service_name = sectionIdToService[action.pivot_id].name;
            action.value.parent_service_deleted = !sectionIdToService[action.pivot_id].visible;
          }
        } else {
          action.name = "added";
        }
        break;
      case 'service_created':
        action.name = "created the service";
        action.program = {slug: action.value.slug};
        break;
      case 'workpaper_uploaded': {
        action.name = "uploaded";
        action.value.name = action.value.filename;
        const service = sectionIdToService[action.value.section_id];
        const section = sectionsById[action.value.section_id];
        if(!!service && !!section) {
          action.program = {slug: service.slug};
          action.section = {slug: section.slug};
          action.link_type = 'step';
          action.no_link = shouldHideLink(service, userHasAccess);
        }
        break;
      }
      case 'due_at':
        action.name = "changed the engagement due date to"
        if(action.value == 0) action.name = "removed the engagement due date"
        break;
      case 'status':
        action.name = "changed the status of"
        action.value.name = action.value.section_name;

        if(!!sectionsById[action.pivot_id] && action.value.section_name) action.value.title = action.value.section_name;

        if (action.value.status == 'in_progress') action.value.status = 'In Progress';
        if (action.value.status == 'needs_review') action.value.status = 'Needs Review';
        if (action.value.status == 'complete') action.value.status = 'Complete';

        break;
      case 'program_data.visible':
        action.name = action.value.visible ? "restored the service" : "removed the service";
        action.value.name = action.value.service_name;
        action.no_link = taxResolutionServices.includes(action.value.service_name)
          ? !userHasAccess('tax_resolution_services')
          : !userHasAccess('custom_services');
        if (action.name == "removed the service") action.link_type = 'services';
        break;
      case 'title': {
        action.name = "renamed"
        action.value.old_name = action.value.step_old_name;
        action.value.new_name = action.value.step_name;
        const service = sectionIdToService[action.pivot_id];
        const section = sectionsById[action.pivot_id];
        if (!!service && !!section && !action.value.parent_service_deleted) {
          action.program = {slug: service.slug};
          action.section = {slug: section.slug};
          action.link_type = 'step';
          // If it's a custom step, we need to hide the link behind the permission
          action.no_link = shouldHideLink(service, userHasAccess);
        }
        break;
      }
      case 'program_data.name':
        action.name = "renamed"
        action.value.old_name = action.value.service_old_name;
        action.value.new_name = action.value.service_name;
        break;
      case 'description':
        action.name = "changed the description of";
        action.value.title = action.value.step_name;
        break;
      case 'deleted':
        action.name = 'deleted';
        if (action.pivot_type.indexOf('EngagementSection') > -1) {
          action.name += ' the step';
          action.value.title = action.value.section_name;
        } else if (action.pivot_type.indexOf('ClientRequest') > -1) {
          if (!isEmpty(action.value.title)) {
            action.name += ' the client request,';
          } else {
            action.name += ' an untitled client request';
          }
        }
        break;
      case 'submitted':
        if (action.pivot_type === 'App\\MasterSurvey') {
          action.name = 'submitted the'
          action.value.name = 'client survey'
          action.link_type = 'survey'
          action.no_link = !userHasAccess('client_survey');
        }
        break;
    }

    //Sections / Steps - links
    if(!!action.value && !!action.value.title && action.pivot_type.indexOf("EngagementSection") > -1) {
      action.value.name = action.value.title;
      const service = sectionIdToService[action.pivot_id];
      const section = sectionsById[action.pivot_id];
      if(!!service && !!section && !action.value.parent_service_deleted){
        action.program = {slug: service.slug};
        action.section = {slug: section.slug};
        action.link_type = 'step';
        // If it's a custom step, we need to hide the link behind the permission
        action.no_link = shouldHideLink(service, userHasAccess);
      }
    }

    // Surveys
    if(!!action.value && !!action.value.survey_name){
      action.value.name = action.value.survey_name;
      action.value.surveyId =  action.pivot_id;
      action.link_type = 'survey';
      action.no_link = !userHasAccess('client_survey');
    }

    // Client Request
    if(!!action.value && action.pivot_type.indexOf('ClientRequest') > -1) {
      // Instance link
      if (!!action.value.title && action.name.indexOf('deleted') == -1) {
        action.link_type = 'client_request';
        action.no_link = !userHasAccess('tasks_client_requests');
      }

      // Recipients
      if (action.name.indexOf('sent') > -1 && !isEmpty(action.value.recipients)) {
        action.value.requestId = action.pivot_id;
        action.value.recipient = prettyList(action.value.recipients, 3, 'recipient');
      }
    }

    //The pretty printing of survey names and links are hard coded, this is because the same thing is done on the sidebar section links
    if(!!action.value && (!!action.value.survey_form_id || !!action.value.master_survey_id) && !!action.value.name){
      if(action.value.name.indexOf('poa-') > -1 || action.value.name.indexOf('Poa ') > -1) {
        action.value.name = 'Power of Attorney Survey';
        if(action.name.indexOf('edit') == -1){ //Link to the parent section instead of the survey on every survey action except edit
          action.program = {slug: 'planning'};
          action.section = {slug: 'poa_survey'};
          action.link_type = 'step';
          action.no_link = !userHasAccess('tax_resolution_services');
        }
      }
      if(action.value.name.indexOf('collection') == 0 || action.value.name.indexOf('Collection') == 0 || action.value.name.indexOf('business-collection') == 0 || action.value.name.indexOf('Business Collection') == 0){
        action.value.name = 'Client Survey';
        if(action.name.indexOf('edit') == -1){ //Link to the parent section instead of the survey on every survey action except edit
          action.program = {slug: 'collections'};
          action.section = {slug: 'collection_survey'};
          action.link_type = 'step';
          action.no_link = !userHasAccess('tax_resolution_services');
        }
      }
    }
    return action;
  });
}

function shouldHideLink(service, userHasAccess) {
  return taxResServiceSlugs.find(slug => slug === service.slug)
    ? !userHasAccess('tax_resolution_services')
    : !userHasAccess('custom_services');
}
