// Outcomes
export const APPEAL = 'APPEAL';
export const DISCHARGE = 'DISCHARGE';
export const RELEASE = 'RELEASE';
export const SUBORDINATION = 'SUBORDINATION';
export const WITHDRAWAL = 'WITHDRAWAL';

export const RELIEF_UNLIKELY = 'RELIEF_UNLIKELY';
export const OUTCOME_NOT_YET_CALCULABLE = null;

// Question slugs
export const INTRO = 'intro';                       //0
export const PAID_IN_FULL = 'paid_in_full';                 //1
export const PLANS_AND_MEANS = 'plans_and_means';             //2
export const LIEN_WITHDRAWAL = 'lien_withdrawal';             //3
export const INSTALLMENT_LESS_THAN_25000 = 'installment_less_than_25000'; //4
export const INVALID_LIEN = 'invalid_lien';                 //5
export const DISPUTE_TAX = 'dispute_tax';                 //6
// this question was eliminated from the flow               //7
export const PROPOSE_ALTERNATIVE = 'propose_alternative';         //8
export const REASONABLE_CAUSE = 'reasonable_cause';             //9
export const BANKRUPTCY = 'bankruptcy';                   //10
export const SURETY_BOND = 'surety_bond';                 //11
export const STATUTE_EXPIRED = 'statute_expired';             //12
export const PURCHASE_PROPERTY = 'purchase_property';           //13
export const DOUBLE_VALUE = 'double_value';                 //14
export const SELL_PROPERTY = 'sell_property';               //15
export const PROCEEDS_TO_IRS = 'proceeds_to_irs';             //16
export const LESS_THAN_ZERO = 'less_than-zero';               //17
export const PROCEEDS_IN_ESCROW = 'proceeds_in_escrow';           //18
export const RESTRUCTURE = 'restructure';                 //19
export const REFINANCING = 'refinancing';                 //20
export const LINE_OF_CREDIT = 'line_of_credit';               //21
