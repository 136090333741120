import angular from "angular";
import _ from "lodash";
import { catchAsyncStacktrace } from 'auto-trace';
import "angular/app/client/taxes/engagement.service.js";
import { hasAccess } from 'cp-client-auth!sofe';
import context from "angular/bootstrap/context.service.js";

angular.module('app.clients.taxes')
  .controller('EngagementDetailsController', ['EngagementService', '$stateParams', '$scope', '$window','$filter', '$timeout',
    function EngagementDetailsController(EngagementService, $stateParams, $scope, $window, $filter, $timeout) {
      var vm = this;

      vm.goBack = goBack;

      vm.currentlySaving = false;
      vm.context = context.getContext();
      vm.userHasAccess = hasAccess(vm.context.loggedInUser);

      const INITIAL_SAVE_BUTTON_TEXT = "Save changes"

      function init() {
        vm.saveButtonText = INITIAL_SAVE_BUTTON_TEXT;
        vm.saveDetails = saveDetails;
        vm.setInitialValues = setInitialValues;
      }

      function saveDetails() {
        vm.currentlySaving = true;
        vm.saveButtonText = "Saving...";

        let saveButton = document.getElementById('cp-engagement-details__save');
        if (saveButton) {
          saveButton.blur();
        }

        let tempEngagement = _.cloneDeep(vm.engagement);

        tempEngagement.name = vm.initialName;
        tempEngagement.forms_version = vm.initialFormsVersion;
        tempEngagement.description = vm.initialDescription;
        tempEngagement.due_date = vm.initialDueDate;

        EngagementService.saveEngagement({
          engagementId: vm.engagement.id,
          clientId: $stateParams.clientId
        }, tempEngagement)
        .then ((result) => {
            vm.engagement = tempEngagement;

            $scope.$emit('cp:engagementChanged', vm.engagement);

            vm.saveButtonText = "Save complete!";

            $timeout(() => {
              vm.currentlySaving = false;
              vm.saveButtonText = INITIAL_SAVE_BUTTON_TEXT;
              vm.goBack();
            }, 500, true);
          }
        )
        .catch(catchAsyncStacktrace());
      }

      function setInitialValues() {
        if (vm.engagement) {
          vm.initialName = vm.engagement.name;
          vm.initialFormsVersion = vm.engagement.forms_version;
          vm.initialDescription = vm.engagement.description;
          vm.initialDueDate = vm.engagement.due_date;
        }
      }

      function goBack(){
        $window.history.back();
      }

      init();

      $scope.$watch('vm.engagement',
        (newValue, oldValue) => {
          if (vm.engagement) {
            vm.setInitialValues();
          }
        }
      );
      $scope.$watch(
        'vm.initialDueDate',
        (newValue, oldValue) => {
          if (newValue !== oldValue) {
            vm.dateChanged = true;
          }
        }
      )

    }
  ]);
