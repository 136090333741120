import angular from "angular";
import $ from 'jquery';

angular.module("app")
  .directive("cpFocusOnClick", [
    function() {
      return {
        restrict: "A",
        link: function(scope, el, attr) {
          el.click(function() {
            setTimeout(() => $(attr.cpFocusOnClick).focus());
            var el = $(attr.cpFocusOnClick)[0];
            el.setSelectionRange(0, el.value.length);
          })
        }
      }
    }
  ]);
