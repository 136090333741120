import angular from "angular";
import template from "./manage-programs.template.html";

import "./manage-programs.controller.js";
import "./manage-programs.style.css";

angular.module('app.clients.taxes')
  .directive('cpManagePrograms', [
    function cpManagePrograms() {
      return {
        restrict: 'E',
        scope: {
          engagement: '='
        },
        template: template,
        controller: 'ManageProgramsController',
        controllerAs: 'vm',
        bindToController: true
      }
    }
  ]);
