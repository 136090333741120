import angular from "angular";

angular.module('app.clients.taxes')
  .factory('ProgramSectionsResource', ['$http',
    function ProgramSectionsResource($http) {

      return {

        getList: (params) => $http.get(`/api/clients/${params.clientId}/engagements/${params.engagementId}/sections`),

        post: (params, section) => $http.post(`/api/clients/${params.clientId}/engagements/${params.engagementId}/sections`, {"engagement_sections": section}),

        put: (params, section, notifications) => {
          let payload = {
            engagement_sections: section
          };
          if (notifications) {
            payload.notifications = notifications;
          }
          return $http.put(`/api/clients/${params.clientId}/engagements/${params.engagementId}/sections/${params.sectionId}`, payload)
        },

        get: (params) => $http.get(`/api/clients/${params.clientId}/engagements/${params.engagementId}/sections/${params.sectionId}`),

        delete: (params) => $http.delete(`/api/clients/${params.clientId}/engagements/${params.engagementId}/sections/${params.sectionId}`)

      };
    }
  ]);
