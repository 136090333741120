import angular from "angular";
import { catchAsyncStacktrace } from 'auto-trace';

import 'angular/app/client/taxes/section-interactions/common-actions/change-status.directive.js';
import 'angular/app/client/taxes/section-interactions/common-actions/assign-section-envelope.component.js';
import 'angular/app/client/taxes/section-interactions/common-actions/due-date.directive.js';

import 'angular/app/client/taxes/programs/assistant/assistant-answers.service.js';

import "angular/app/client/taxes/section-interactions/assistant-shared.style.css";
import "angular/app/client/taxes/section-interactions/outcomes.style.css";

import './outcomes/appeals.component.js';
import './outcomes/preventing-levy.component.js';
import './outcomes/releasing-levy.component.js';
import './outcomes/returning-property.component.js';

import * as outcomesHelper from './assistant-outcomes.helper.js';
import * as assistantConstants from './assistant.constants.js';

import template from './assistant-outcomes.template.html';

angular.module('app.clients.taxes').component('cpOutcomesLevies', {
  bindings: {},

  controllerAs: 'vm',

  controller: function ($scope, $stateParams, AssistantAnswersService) {
    const vm = this;

    vm.assistantConstants = assistantConstants;

    // when vm.outcomes === null, it means there are definitely no outcomes. undefined means we aren't sure yet
    vm.outcomes = undefined;

    let init = () => {
      vm.appealsAvailable = () => outcomesHelper.appealsAvailable(vm.outcomes);
      vm.preventingLevyAvailable = () => outcomesHelper.preventingLevyAvailable(vm.outcomes);
      vm.releasingLevyAvailable = () => outcomesHelper.releasingLevyAvailable(vm.outcomes);
      vm.returningPropertyAvailable = () => outcomesHelper.returningPropertyAvailable(vm.outcomes);

      vm.reliefUnlikely = () => outcomesHelper.reliefUnlikely(vm.outcomes);
      vm.cantCalculateOutcomes = () => outcomesHelper.cantCalculateOutcomes(vm.outcomes);

      AssistantAnswersService.getOutcomes()
        .then((outcomes) => {
          vm.outcomes = outcomes;

          vm.resolutionType = assistantConstants.APPEALS;

          if (vm.appealsAvailable()) {
            vm.resolutionType = assistantConstants.APPEALS;
          }
          else if (vm.preventingLevyAvailable()) {
            vm.resolutionType = assistantConstants.PREVENTING_LEVY;
          }
          else if (vm.releasingLevyAvailable()) {
            vm.resolutionType = assistantConstants.RELEASING_LEVY;
          }
          else if (vm.returningPropertyAvailable()) {
            vm.resolutionType = assistantConstants.RETURNING_PROPERTY;
          }
        })
        .catch(catchAsyncStacktrace());

    };

    init();
  },

  template: template
});
