import * as constants from './assistant.constants.js';

export function cantCalculateOutcomes(outcomes) {
  return outcomes === constants.OUTCOME_NOT_YET_CALCULABLE;
}

export function reliefUnlikely(outcomes) {
  return !!(outcomes && outcomes.length == 1 && outcomes.indexOf(constants.RELIEF_UNLIKELY) >= 0);
}

export function withdrawalAvailable(outcomes) {
  return !!(outcomes && outcomes.indexOf(constants.WITHDRAWAL) >= 0);
}

export function releaseAvailable(outcomes) {
  return !!(outcomes && outcomes.indexOf(constants.RELEASE) >= 0);
}

export function dischargeAvailable(outcomes) {
  return !!(outcomes && outcomes.indexOf(constants.DISCHARGE) >= 0);
}

export function appealAvailable(outcomes) {
  return !!(outcomes && outcomes.indexOf(constants.APPEAL) >= 0);
}

export function subordinationAvailable(outcomes) {
  return !!(outcomes && outcomes.indexOf(constants.SUBORDINATION) >= 0);
}