import angular from "angular";

import template from './assistant.template.html';

import './questions/circumstance.component.js';
import './questions/compliance.component.js';
import './questions/payments.component.js';
import './questions/penalty_reason.component.js';
import './questions/penalty_type.component.js';

angular.module('app.clients.taxes').component('cpAssistantPenaltyAbatements', {
  bindings: {
    question: '='
  },

  controller: function ($state, $stateParams) {
    const vm = this;
    vm;
  },

  controllerAs: 'vm',

  template: template
});