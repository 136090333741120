import angular from "angular";
import $ from "jquery";

// This fixes an issue where when the mouse is hovered over a grid, the parent container cannot scroll.
// See https://github.com/angular-ui/ui-grid/issues/1926
angular.module('app')
  .config(function($provide) {
    $provide.decorator('Grid', function ($delegate,$timeout) {
      $delegate.prototype.renderingComplete = function() {
        if (angular.isFunction(this.options.onRegisterApi)) {
          this.options.onRegisterApi(this.api);
        }
        this.api.core.raise.renderingComplete(this.api);
        $timeout(function () {
          var $viewport = $('.ui-grid-render-container');
          ['touchstart', 'touchmove', 'touchend','keydown', 'wheel', 'mousewheel', 'DomMouseScroll', 'MozMousePixelScroll'].forEach(function (eventName) {
            $viewport.unbind(eventName);
          });
        });
      };
      return $delegate;
    });
  });
