/* Jdee and our resolution experts have drawn up a state machine for the questions and outcomes of penalty abatements.
 * This is our implementation of the state machine. Each state is an object with a function that, when called with a value, returns
 * the next state. For sanity's sake, the names of the states are the answers that led to that state (Y/N), followed by
 * a somewhat human readable string that represents the question. If you don't like this, then feel free to hate me.
 * However, I felt it made sense at the time :D
 *
 * Additionally, when you reach an outcome state, the outcomes are returned as part of the state, as an array of string
 * constants. If no outcome state is reached because not enough answers have been provided, then nextState will be null
 * and outcomes will be null.
 */

import * as constants from './assistant.constants.js';

const states = {};
const NA = 'not_applicable';

export function traverseStateMachine(answers, questionToStopAt) {
  const initialState = {
    reachedOutcome: false,
    outcomes: constants.OUTCOME_NOT_YET_CALCULABLE,
    nextState: states.INITIAL_STATE,
    nextQuestionSlug: constants.INTRO,
    currentQuestionSlug: null,
    previousQuestionSlug: null,
    nextQuestionIsOutcome: false,
  };
  answers = JSON.parse(JSON.stringify(answers));
  if (answers[constants.CIRCUMSTANCE]) {
    answers[constants.CIRCUMSTANCE] = !!Object.keys(answers[constants.CIRCUMSTANCE]).find((key) => (key != NA) ? answers[constants.CIRCUMSTANCE][key] : false);
  }
  return traverse(answers, questionToStopAt, initialState);
}

function traverse(answers, questionToStopAt, currentState) {
  const newState = currentState.nextState(answers[currentState.nextQuestionSlug], currentState.currentQuestionSlug);
  if (currentState.nextQuestionSlug === questionToStopAt) {
    return createResultForNonOutcomeState(currentState, newState);
  } else {
    if (newState.reachedOutcome) {
      newState.previousQuestionSlug = newState.currentQuestionSlug;
      newState.currentQuestionSlug = null;
      return newState;
    } else if (!newState.nextState) {
      return newState;
    } else if (!newState.nextState) {
      return createResultForNonOutcomeState(newState);
    } else {
      return traverse(answers, questionToStopAt, newState);
    }
  }
}

function createResultForNonOutcomeState(state, newState) {
  return {
    reachedOutcome: false,
    outcomes: null,
    nextState: null,
    nextQuestionSlug: newState ? newState.nextQuestionSlug : null,
    nextQuestionIsOutcome: !!newState.reachedOutcome,
    currentQuestionSlug: state.nextQuestionSlug,
    previousQuestionSlug: state.currentQuestionSlug,
  };
}

states.INITIAL_STATE =
  createBinaryState(constants.INTRO, 'Y_AUDIT', 'Y_AUDIT')

states.Y_AUDIT =
  createBinaryState(constants.AUDIT, 'YY_LATE', 'YN_PENALTY')

states.YY_LATE =
  createBinaryState(constants.LATE, 'YYY_CIRCUMSTANCE', 'YYN_NOTIFICATION')

states.YYN_NOTIFICATION =
  createBinaryState(constants.NOTIFICATION, 'YYNY_LIABILITY', [constants.RELIEF_UNLIKELY])

states.YYY_CIRCUMSTANCE = states.YNN_CIRCUMSTANCE =
  createBinaryState(constants.CIRCUMSTANCE, [constants.REASONABLE_CAUSE, constants.NON_ASSESSMENT], [constants.RELIEF_UNLIKELY])

states.YN_PENALTY =
  createBinaryState(constants.PENALTY, 'YNY_LIABILITY', 'YNN_CIRCUMSTANCE')

states.YNY_LIABILITY = states.YYNY_LIABILITY =
  createBinaryState(constants.LIABILITY, 'YNYY_PENALTY_REASON', 'YNYN_PAYMENTS')

states.YNYN_PAYMENTS =
  createBinaryState(constants.PAYMENTS, 'YNYNY_PENALTY_REASON', 'YNYNN_PENALTY_TYPE');

states.YNYY_PENALTY_REASON = states.YNYY_PENALTY_REASON = states.YNYNY_PENALTY_REASON =
  createBinaryState(constants.PENALTY_REASON, 'YNYYY_PREVIOUS_PENALTY', 'YNYYN_PENALTY_TYPE')

states.YNYYY_PREVIOUS_PENALTY =
  createBinaryState(constants.PREVIOUS_PENALTY, 'YNYYYY_PENALTY_TYPE', 'YNYYYN_COMPLIANCE')

states.YNYYYY_PENALTY_TYPE = states.YNYYYNN_PENALTY_TYPE = states.YNYYYNYN_PENALTY_TYPE = states.YNYNN_PENALTY_TYPE = states.YNYYN_PENALTY_TYPE =
  createBinaryState(constants.PENALTY_TYPE, 'YNYYYYY_CIRCUMSTANCE', [constants.RELIEF_UNLIKELY])

states.YNYYYN_COMPLIANCE =
  createBinaryState(constants.COMPLIANCE, 'YNYYYNY_INSTALLMENT', 'YNYYYNN_PENALTY_TYPE')

states.YNYYYNY_INSTALLMENT =
  createBinaryState(constants.INSTALLMENT, 'YNYYYNYY_PENALTY_TYPE', 'YNYYYNYN_PENALTY_TYPE')

states.YNYYYNYY_PENALTY_TYPE =
  createBinaryState(constants.PENALTY_TYPE, 'YNYYYNYYY_CIRCUMSTANCE', [constants.RELIEF_UNLIKELY])

states.YNYYYNYYY_CIRCUMSTANCE =
  createBinaryState(constants.CIRCUMSTANCE, [constants.FIRST_TIME_ABATEMENT, constants.REASONABLE_CAUSE], [constants.FIRST_TIME_ABATEMENT]);

states.YNYYYYY_CIRCUMSTANCE =
  createBinaryState(constants.CIRCUMSTANCE, [constants.REASONABLE_CAUSE], [constants.RELIEF_UNLIKELY])


function createBinaryState(questionSlug, stateIfYes, stateIfNo) {
  const result = function (value, previousQuestionSlug) {
    let nextPlaceToGo;
    if (value === true) nextPlaceToGo = stateIfYes;
    else if (value === false) nextPlaceToGo = stateIfNo;
    else return {
      reachedOutcome: false,
      outcomes: null,
      nextState: null,
      nextQuestionSlug: null,
      currentQuestionSlug: questionSlug,
      previousQuestionSlug,
    };

    if (Array.isArray(nextPlaceToGo)) {
      return {
        reachedOutcome: true,
        outcomes: nextPlaceToGo,
        nextState: null,
        nextQuestionSlug: null,
        currentQuestionSlug: questionSlug,
        previousQuestionSlug,
      };
    } else if (typeof nextPlaceToGo === 'string') {
      if (!states[nextPlaceToGo]) {
        throw new Error(`Could not find state by name of '${nextPlaceToGo}'`);
      }
      return {
        reachedOutcome: false,
        outcomes: null,
        nextState: states[nextPlaceToGo],
        nextQuestionSlug: states[nextPlaceToGo].questionSlug,
        currentQuestionSlug: questionSlug,
        previousQuestionSlug,
      };
    } else {
      throw new Error(`Don't know how to create next state -- was given a(n) '${typeof nextPlaceToGo}' of value '${nextPlaceToGo}'`);
    }
  }

  result.questionSlug = questionSlug;
  return result;
}
