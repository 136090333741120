import angular from "angular";
import urls from "canopy-urls!sofe";
import auth from "cp-client-auth!sofe";

angular.module('app.helpers')
  .factory('cpLoginService', ['$injector', '$q', '$rootScope',
    function ($injector, $q, $rootScope) {

      let service = {};

      service.redirectToLogin = () => {
        const redirectUrl = encodeURIComponent(window.location.href);
        window.location.href = `${urls.getAuthUrl()}/#/login?redirect_url=${redirectUrl}`;
      };

      service.redirectToLogout = () => {
        const redirectUrl = encodeURIComponent(window.location.href);
        window.location = `${urls.getAuthUrl()}/#/logout?redirect_url=${redirectUrl}`;
      };

      service.redirectToTimout = () => {
        const redirectUrl = encodeURIComponent(window.location.href);
        window.location.href = `${urls.getAuthUrl()}/#/login/timed-out?redirect_url=${redirectUrl}`;
      };

      service.refreshAuthToken = () => {
        return auth.refreshAuthToken({clientSecret: 'TaxUI:f7fsf29adsy9fg'});
      };

      return service;
    }
  ]);
