import angular from 'angular';
import _ from 'lodash'
import { asyncStacktrace } from 'auto-trace'
import * as fileHelper from "angular/common/helpers/file.helper.js";
import "angular/resources/docs.resource.js";
import "angular/resources/comments.resource.js";

angular.module('app.clients.taxes')
  .factory('RequestToolCommentsService', ['DocsResource', 'CommentsResource', 'ClientRequestsResource', function ClientRequestDescriptionService(DocsResource, CommentsResource, ClientRequestsResource) {
    const requestToolMap = {}
    const userMap = {}
    return {
      getRequestToolMap: () => requestToolMap,
      getFile: (fileId) => requestToolMap[fileId],
      addFiles: function(files){
        files.forEach(f => {
          if(!requestToolMap[f.id]){
            requestToolMap[f.id] = f
            requestToolMap[f.id].iconPath = fileHelper.getFileIconPath(f.name);
          }
        })
      },
      getAllFilesRelatedTo: function(relatedMap){
        return new Promise((resolve, reject) => {
          DocsResource.getFilesRelatedTo(relatedMap).subscribe(resp => {
            Object.keys(resp.request_tool).forEach(key => {
              requestToolMap[key] = resp.request_tool[key]
              requestToolMap[key].forEach(file => {
                file.iconPath = fileHelper.getFileIconPath(file.name)
              })
            })

            if(resp.comment){
              Object.keys(resp.comment).forEach(key => {
                requestToolMap[key] = resp.comment[key]
                requestToolMap[key].forEach(file => {
                  file.iconPath = fileHelper.getFileIconPath(file.name)
                })
              })
            }

            resolve(resp)
          }, err => {
            reject(err)
            asyncStacktrace(err)
          })
        })
      },
      createComment: function(clientRequestId, comment){
         return new Promise((resolve, reject) => {
           CommentsResource.createRequestToolComment(clientRequestId, {comment}).then(resolve).catch(err => {
            reject(err)
            asyncStacktrace(err)
           })
         })
      },
      setUsers: function(users, loggedInUser){
        users.forEach(u => {
          if(!userMap[u.id]){
            userMap[u.id] = u
            var re = /^(\w+ ).*?(\w+)$/;
            u.name.search(re);
            userMap[u.id].initials = `${RegExp.$1[0] + RegExp.$2[0]}`
          }
        })
        userMap[loggedInUser.id] = loggedInUser
        userMap[loggedInUser.id].initials = loggedInUser.firstName[0].toUpperCase() + loggedInUser.lastName[0].toUpperCase()
      },
      getUser: function(userId){
        return userMap[userId]
      },
      getUserMap: function(){
        return userMap
      },
      toggleComplete: function(requestToolId, status){
        return ClientRequestsResource.toggleComplete(requestToolId, {request_tool: {status}})
      }
    }
  }
]);
