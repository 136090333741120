import moment from "moment";
import { find } from "lodash";

export const STATE = {
  NONE : 0,
  LOGIN : 1,
  CREDENTIALS : 2,
  CHOOSE_ACCOUNT : 3,
  LOADING : 4,
  PASSWORD_EXPIRATION_WARNING : 5,
};

let currentTaxYear = moment().year() - 1;

export const TRANSCRIPT_TYPE = {
  ACCOUNT   : "Account Transcript",
  WAGE    : "Wage and Income",
  TAX_RETURN  : "Tax Return Transcript",
  RECORD    : "Record of Account",
  NON_FILING  : "Verification of Non-Filing",
};

export const REQUEST_TYPES = {CAF: 'CAF', PULL: 'PULL'};

export const transcriptTypes = [
  {title: TRANSCRIPT_TYPE.ACCOUNT,  order: 1, rangeStart: 1990,         rangeEnd: moment().year(), selected: false, selectedYears:[]},
  {title: TRANSCRIPT_TYPE.WAGE,   order: 2, rangeStart: 2006,         rangeEnd: currentTaxYear, selected: false, selectedYears:[]},
  {title: TRANSCRIPT_TYPE.TAX_RETURN, order: 3, rangeStart: currentTaxYear - 3, rangeEnd: currentTaxYear, selected: false, selectedYears:[]},
  {title: TRANSCRIPT_TYPE.RECORD,   order: 4, rangeStart: currentTaxYear - 3,   rangeEnd: currentTaxYear, selected: false, selectedYears:[]},
  {title: TRANSCRIPT_TYPE.NON_FILING, order: 5, rangeStart: currentTaxYear - 3,   rangeEnd: currentTaxYear, selected: false, selectedYears:[]},
];

export const REPORT_TYPE = {
  ACCOUNT_OVERVIEW    : "Account Overview",
  CSED_CALCULATIONS     : "CSED Calculations",
  ACCOUNT_TRANSACTIONS  : "Account Transactions",
  PENALTIES_AND_INTEREST  : "Penalties and Interest",
  TAX_RETURN_SUMMARY    : "Tax Return Summary",
  NOTICES_OVERVIEW    : "Notices Overview",
  PAYMENT_HISTORY     : "Payment History",
  TOLLING_EVENTS      : "Tolling Events",
};

export const BUSINESS_REPORT_SUBTYPES = [
  {title: 'Entity', url: 'entity',  sort:1},
  {title: 'Payroll', url: 'payroll', sort:2},
  {title: 'Unemployment', url: 'unemployment', sort:3},
  {title: 'Civil Penalties', url: 'civil_penalty', sort:4},
];

export const REPORTS_HELPER = [
  {title: REPORT_TYPE.ACCOUNT_OVERVIEW    , report_type_url : 'overview'    , available_for_business : true  },
  {title: REPORT_TYPE.CSED_CALCULATIONS     , report_type_url : 'csed'      , available_for_business : false },
  {title: REPORT_TYPE.ACCOUNT_TRANSACTIONS  , report_type_url : 'transactions'  , available_for_business : true  },
  {title: REPORT_TYPE.PENALTIES_AND_INTEREST  , report_type_url : 'pile'      , available_for_business : true  },
  {title: REPORT_TYPE.NOTICES_OVERVIEW    , report_type_url : 'notices'     , available_for_business : true  },
  {title: REPORT_TYPE.PAYMENT_HISTORY     , report_type_url : 'payments'    , available_for_business : true  },
  {title: REPORT_TYPE.TOLLING_EVENTS      , report_type_url : 'csed_events'   , available_for_business : false },
];

export function mergePreferencesWithTranscriptTypes(transcript_data = [], transcriptTypes = transcriptTypes) {
  transcriptTypes.forEach((transcriptType) => {
    let values_to_merge_with_transcript = find(transcript_data, {'type': transcriptType.title});

    if (values_to_merge_with_transcript) {
      transcriptType.selectedYears = values_to_merge_with_transcript.years || [];
      transcriptType.selected = !!transcriptType.selectedYears.length;
    }
  });

  return transcriptTypes;
}

export var columnTitles = [];
columnTitles['year'] =            'Year';
columnTitles['return_filed'] =        'Return Filed';
columnTitles['filing_date'] =       'Filing Date';
columnTitles['filing_status'] =       'Filing Status';
columnTitles['liability'] =         'Liability';
columnTitles['interest'] =          'Accrued Interest';
columnTitles['penalty'] =         'Accrued Penalty';
columnTitles['total_liability'] =     'Total Liability';
columnTitles['examination_active'] =    'Examination Active';
columnTitles['lein_active'] =       'Lein Active';
columnTitles['code'] =            'Code';
columnTitles['desc'] =            'Description';
columnTitles['date'] =            'Date';
columnTitles['amount'] =          'Amount';
columnTitles['estimated_tolling_days'] =  'Estimated Tolling Days';
columnTitles['estimated_csed_date'] =   'Estimated CSED Date';
columnTitles['failure_to_file_penalties'] = 'Failure to File Penalties';
columnTitles['failure_to_pay_penalties'] =  'Failure to Pay Penalties';
columnTitles['accuracy_related_penalties'] ='Accuracy Related Penalties';
columnTitles['other_penalties'] =     'Other Penalties';
columnTitles['total_penalties'] =     'Total Penalties';
columnTitles['charged_interest'] =      'Charged Interest';
columnTitles['total_interest'] =      'Total Interest';
