import angular from "angular";

angular.module('app')
  .factory('cpUrlState', [function() {
    return {
      getUrlFromState: function(state, params) {
        return window.location.origin + '/#/redirect?state=' +
          encodeURI(state) + '&params=' +
          encodeURI(JSON.stringify(params || {}));
      },

      getStateFromUrl: function(url) {
        var regex = /.*?state=(.*)&params=(.*)/g
        var m = regex.exec(url);
        return {
          state: decodeURI(m[1]),
          params: JSON.parse(decodeURI(m[2]))
        }
      }
    }
  }]);


