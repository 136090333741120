import * as questionSlugs from './assistant.constants.js';

// Questions
let questions = {};

questions[questionSlugs.PAID_IN_FULL] = {
  questionText: 'Has the tax liability been paid in full?',
  type: 'boolean'
};

questions[questionSlugs.PLANS_AND_MEANS] = {
  questionText: 'Does your client have plans to pay the tax liability in full and does she/he have the means to do so?',
  type: 'boolean'
};

questions[questionSlugs.LIEN_WITHDRAWAL] = {
  questionText: `Would a lien withdrawal allow your client to pay the tax liability balance in full?`,
  type: 'boolean'
};

questions[questionSlugs.INSTALLMENT_LESS_THAN_25000] = {
  questionText: 'Has your client entered into an installment agreement in which the balance due for the installment agreement $25,000 or less?',
  type: 'boolean'
};

questions[questionSlugs.INVALID_LIEN] = {
  questionText: 'Did the IRS file a lien against your client prematurely or not according to IRS procedure?',
  type: 'boolean'
};

questions[questionSlugs.DISPUTE_TAX] = {
  questionText: 'Does your client want to dispute the tax owed that is secured by a federal tax lien?',
  type: 'boolean'
};

questions[questionSlugs.PROPOSE_ALTERNATIVE] = {
  questionText: 'Does your client wish to propose a collection alternative?',
  type: 'boolean'
};

questions[questionSlugs.REASONABLE_CAUSE] = {
  questionText: 'Does your client have reasonable cause to request abatement of failure to file or failure to pay penalties?',
  type: 'boolean'
};

questions[questionSlugs.BANKRUPTCY] = {
  questionText: 'Was the balance of the tax liability discharged in bankruptcy?',
  type: 'boolean'
};

questions[questionSlugs.SURETY_BOND] = {
  questionText: 'Is the client able to obtain a surety bond?',
  type: 'boolean'
};

questions[questionSlugs.STATUTE_EXPIRED] = {
  questionText: 'Has the collection statute expired and is the balance due no longer collectable?',
  type: 'boolean'
};

questions[questionSlugs.PURCHASE_PROPERTY] = {
  questionText: 'Did your client purchase property that is subject to a lien, and is your client able to furnish a bond or deposit to satisfy the lien?',
  type: 'boolean'
};

questions[questionSlugs.DOUBLE_VALUE] = {
  questionText: 'Is the fair market value of other property owned by your client at least double the total of the tax liability owed to the IRS (plus other remaining encumbrances)?',
  type: 'boolean'
};

questions[questionSlugs.SELL_PROPERTY] = {
  questionText: 'Would your client like to sell property held by a lien?',
  type: 'boolean'
};

questions[questionSlugs.PROCEEDS_TO_IRS] = {
  questionText: 'Will the proceeds from the sale of property be given to the IRS?',
  type: 'boolean'
};

questions[questionSlugs.LESS_THAN_ZERO] = {
  questionText: 'Will the proceeds from the sale of property be equal to or less than zero, and will selling decrease monthly payments and facilitate the payment of tax?',
  type: 'boolean'
};

questions[questionSlugs.PROCEEDS_IN_ESCROW] = {
  questionText: 'Will the proceeds from the sale of property be held in escrow?',
  type: 'boolean'
};

questions[questionSlugs.RESTRUCTURE] = {
  questionText: `Does your client wish to restructure or consolidate existing loans to decrease monthly payments?`,
  type: 'boolean'
};

questions[questionSlugs.REFINANCING] = {
  questionText: `Will refinancing a loan provide your client with cash to be used for the payment of tax?`,
  type: 'boolean'
};

questions[questionSlugs.LINE_OF_CREDIT] = {
  questionText: `Does your client wish to obtain a line of credit?`,
  type: 'boolean'
};

export default { questions };
