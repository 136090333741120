import angular from 'angular';

import template from './comments-list-with-add-new.template.html';
import './comments-list.component.js';
import './add-comment.component.js';
import "angular/common/services/comments.service.js";

angular
.module('app')
.component('cpCommentsListWithAddNew', {
  bindings: {
    parentObjType: '@',
    parentObjId: '@',
    isEditable: '='
  },

  template,

  controllerAs: 'vm',

  controller: ['CommentsService',
    function(CommentsService) {
      const vm = this;
      vm.comments = []; //initial value

      CommentsService.getList({pivotType: vm.parentObjType, pivotId: vm.parentObjId}).then(
        (comments) => {
          vm.comments = comments;
        }
      );
    }
  ],
});
