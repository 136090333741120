import angular from "angular";
import { asyncStacktrace } from "auto-trace";
import "angular/resources/tax-returns.resource.js";

angular.module("app.clients.taxes").factory("ClientTaxReturnsService", [
  "$q",
  "TaxReturnsResource",
  function ClientTaxReturnsService($q, TaxReturnsResource) {
    return { getTaxReturnsForClient };

    function getTaxReturnsForClient(clientId) {
      if (!clientId) {
        throw new Error(`clientId is required`);
      }

      // We wrap the observable in a $q promise so that digest cycle is triggered.
      const deferred = $q.defer();
      const { resolve, reject, promise } = deferred;

      TaxReturnsResource.fetchClientReturns(clientId).subscribe(
        resolve,
        asyncStacktrace(reject)
      );

      return promise;
    }
  },
]);
