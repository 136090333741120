import { isEmpty, map } from 'lodash';

/**
 * Helpers to build human-readable strings.
 */

/**
 * Returns a pretty, human-readable string representing an array.
 *
 * @param arr : Array : array to format
 * @param max : int : max number of items to show before "... and X more things". if 0, there will be no limit.
 * @param noun : String : noun describing the type of items in array.
 * @param pluralNoun : String : plural noun of items in array. defaults to noun with 's' appended.
 * @returns String
 *
 * Examples:
 *
 * prettyList(['dog', 'cat'], 0, 'animal') => 'dog & cat'
 * prettyList(['dog', 'cat', 'horse'], 0, 'animal') => 'dog, cat, & horse'
 * prettyList(['dog', 'cat', 'horse', 'pig'], 3, 'animal') => 'dog, cat, horse, & 1 more animal'
 * prettyList(['dog', 'cat', 'horse', 'pig', 'duck'], 3, 'animal') => 'dog, cat, horse, & 2 more animals'
 * prettyList([], 0, 'animal') => 'no animals'
 */
export function prettyList(arr, max, noun, pluralNoun=null, formatter=null) {
  if (typeof formatter === 'function') arr = map(arr, formatter);
  pluralNoun = pluralNoun || noun + 's';
  if (max == 0) { max = arr.length }

  if (isEmpty(arr)) { return 'no ' + pluralNoun }
  else if (arr.length == 1) { return arr[0]; }
  else if (arr.length == 2 && max >= 2) { return arr.join(' & '); }
  else if (arr.length <= max) { return arr.slice(0, arr.length - 1).join(', ') + ', & ' + arr[arr.length - 1]; }
  else {
    let more = arr.length - max;
    return arr.slice(0, max).join(', ') + (max > 1 ? ',' : '') + ' & ' + String(more) + ' more ' + (more == 1 ? noun : pluralNoun);
  }
}

/**
 * Formats a 10-digit US phone number. Handles explicit extensions in the form of 'number x extension'
 *
 * @param phoneNumber
 * @param USonly: If true, assume digits past 10 are part of an extension. If false, assume >10 digit numbers are international, and do not attempt to format them.
 * @returns String
 *
 * Examples:
 *
 * formatPhoneNumber('8001234567') => '(800) 123-4567'
 * formatPhoneNumber('800 123 4567') => '(800) 123-4567'
 * formatPhoneNumber('18001234567') => '(800) 123-4567'
 * formatPhoneNumber(8001234567) => '(800) 123-4567'
 * formatPhoneNumber('800-123-4567') => '(800) 123-4567'
 * formatPhoneNumber('800123456789', true) => '(800) 123-4567 x 89'
 * formatPhoneNumber('800123456789') => '800123456789'
 * formatPhoneNumber('8001234567 x 89') => '(800) 123-4567 x 89'
 */
export function formatPhoneNumber(phoneNumber, USonly = false) {
  phoneNumber = phoneNumber.toString();
  let extension = '';

  // Check for an explicit extension
  let extParts = phoneNumber.split('x');
  if (extParts.length === 2) {
    phoneNumber = extParts[0].trim();
    extension = extParts[1].trim();
  }

  // Remove all non-digits and whitespace
  let strippedNumber = phoneNumber.replace(/[\D\s]/g, '');

  if (strippedNumber.charAt(0) === '1') {
    strippedNumber = strippedNumber.substr(1);
  }

  if (USonly && strippedNumber.length > 10) {
    return '(' + strippedNumber.substr(0, 3) + ') ' + strippedNumber.substr(3, 3) + '-' + strippedNumber.substr(6, 4) + ' x ' + strippedNumber.substr(10);
  } else if (strippedNumber.length === 10) {
    return '(' + strippedNumber.substr(0, 3) + ') ' + strippedNumber.substr(3, 3) + '-' + strippedNumber.substr(6, 4) + (extension.length > 0 ? ' x ' + extension : '');
  }
  return phoneNumber + (extension.length > 0 ? ' x ' + extension : '');
}
