import angular from 'angular';
import { navContentPostLink } from './nav-content-postlink.js';
import { partial } from 'lodash';

angular
.module('app')
.directive('useRemainingHeight', [
  function Sidebar() {
    return {
      restrict: 'A',
      compile: function(element, attrs) {
        const cssTopProperty = undefined; // if they need to use the "top" property, they should use the 'use-nav-content-top' attribute directive

        function newHeightCallback(element, newHeight) {
          element.css(`height`, `calc(100% - ${newHeight}px)`);
        }
        return {
          post: partial(navContentPostLink, cssTopProperty, newHeightCallback)
        };
      },
    };
  },
]);
