import angular from "angular";

import * as outcomesHelper from 'angular/app/client/taxes/programs/liens/assistant-outcomes.helper.js';
import 'angular/app/client/taxes/section-interactions/outcomes.style.css';

import template from './lien-release.template.html';

angular.module('app.clients.taxes').component('cpOutcomesLienRelease', {
  bindings: {
    outcomes: '='
  },

  controllerAs: 'vm',

  controller: function () {
    const vm = this;

    vm.releaseAvailable = () => outcomesHelper.releaseAvailable(vm.outcomes);

    vm.reliefUnlikely = () => outcomesHelper.reliefUnlikely(vm.outcomes);
    vm.cantCalculateOutcomes = () => outcomesHelper.cantCalculateOutcomes(vm.outcomes);
    
    vm.summaryExpanded = false;
  },

  template: template
});
