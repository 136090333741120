angular.module('cp-money', [])
  .directive('cpMoney', function($filter, $timeout, $parse) {
      return {
        require: 'ngModel',
        restrict: 'A',
        link: function(scope, element, attrs, ngModel) {
          function format(value) {
            return $filter('currency')(value);
          }

          function parse(value) {
            var clean = value.replace(/[^0-9.]+/g, '').replace(/\.{2,}/, '.');
            return clean;
          }
          ngModel.$formatters.push(format);
          ngModel.$parsers.push(parse);

          element.focus(function(e) {
            if(!attrs.readonly)
            {
              element.val(
                ngModel.$modelValue
              );

              if (ngModel.$modelValue === 0){
                element.val("");
              }
            }
          });

          if(_.property('$options.updateOn')(ngModel) === 'blur') {
            scope.$watch(function() {
              return ngModel.$modelValue;
            }, function() {
              element.val(
                format(ngModel.$modelValue)
              )
            })
          }

          scope.$watch(function() {
            return ngModel.$modelValue;
          }, function(newValue, old) {
            // For some reason, the above format gets executed twice when
            // the value is over 1000. The second time it is called it is null.
            // This causes: https://canopytax.atlassian.net/browse/CRM-889
            // This hack reverts it back. The user shouldn't be able to set the
            // value as undefined, so this shouldn't cause problems elsewhere.
            if (newValue === undefined && typeof old === 'number') {
              $parse(attrs.ngModel).assign(scope, old);
            }
          })

          element.blur(function(e) {
            element.val(
              format(ngModel.$modelValue)
            )
          });
        }
      }
    });
