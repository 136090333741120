import angular from "angular";
import _ from 'lodash';
import $ from 'jquery';
import "angular/common/directives/modal-dialog.directive.js";

angular.module('app.helpers')
  .factory('cpModalDialogService', ['$rootScope', '$compile',
    function($rootScope, $compile) {
      var dialog;
      var template = _.template(
        `<cp-modal-dialog action="action" full-page="<%-fullPage%>" on-close="removeDialog" no-hide="<%-noHide%>" width="<%-width%>" height="<%-height%>">
          <% if(templateUrl) { %>
          <div ng-include src="\'<%-templateUrl%>\'"></div>
          <% } else { %>
          <div><%= template %></div>
          <% } %>
        </cp-modal-dialog>`
      );

      return {
        displayDialog: function(options) {
          $('body').css('overflow', 'hidden');
          options = _.extend({
            width: '50%',
            height: 'auto',
            noHide: false,
            templateUrl: false,
            fullPage: false
          }, options);

          var scope = _.extend($rootScope.$new(), options.scope);

          scope.action = options.action;
          scope.removeDialog = this.removeDialog;
          dialog = $compile(template(options))(scope)
          $('body').append(dialog);
        },

        removeDialog: function() {
          $('body').css('overflow', 'auto');
          dialog.remove();
        },

        lockBodyScroll: function() {
          $('body').css('overflow', 'hidden');
        },

        unlockBodyScroll: function() {
          $('body').css('overflow', 'auto');
        }
      }
    }
  ]);
