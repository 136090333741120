import angular from "angular";
import { take, union, debounce, isEmpty, filter } from 'lodash';
import { hasAccess } from 'cp-client-auth!sofe';
import toasts from 'toast-service!sofe';

import "angular/app/client/taxes/engagement.service.js";
import "angular/app/client/taxes/engagement-history.service.js";
import context from "angular/bootstrap/context.service.js";
import { taxResServiceSlugs, getProgramProgress, getRequestsProgress } from "./engagement-summary.helper.js";
import { navigateToLetters } from 'angular/app/client/taxes/letters/letters.helper.js';

angular.module('app.clients.taxes')

.controller('EngagementSummaryController', ['$stateParams', '$scope', '$state', 'EngagementService', 'EngagementHistoryService', 'ClientRequestsService', '$filter', '$timeout', '$rootScope',
  function EngagementSummaryController($stateParams, $scope, $state, EngagementService, EngagementHistoryService, ClientRequestsService, $filter, $timeout, $rootScope) {

    var vm = this;
    const loggedInUser = context.getContext().loggedInUser;
    vm.userHasAccess = hasAccess(loggedInUser);
    if (!vm.userHasAccess('tasks_engagements')) {
      $state.go('403')
    }
    vm.itemsInProgress = [];
    vm.itemsNeedReview = [];
    vm.activity = [];
    vm.dueDatePercent = 0;
    vm.initializationComplete = false;
    vm.updateEngagementHistory = debounce(updateEngagementHistory, 600, {'leading': true,'trailing': false});
    vm.$state = $state;
    vm.$stateParams = $stateParams;
    vm.navigateToLetters = navigateToLetters;
    vm.itemsFilter = itemsFilter;

    $scope.$watch('vm.engagement', () => {
      //we have to wait for the engagement to be loaded
      if (vm.engagement) {
        init();
      }
    });

    function init() {
      const loggedInUser = context.getContext().loggedInUser;
      vm.userHasAccess = hasAccess(loggedInUser);

      let completedSectionCount = 0;
      let totalSectionCount = 0;
      vm.itemsInProgress = [];
      vm.itemsNeedReview = [];

      vm.updateEngagementHistory();

      if (vm.engagement.program_data.programs) {
        vm.engagement.program_data.programs.forEach(function(program) {
          let progressData = getProgramProgress(program, loggedInUser);
          vm.itemsInProgress = vm.itemsInProgress.concat(progressData.inProgress);
          vm.itemsNeedReview = vm.itemsNeedReview.concat(progressData.needReview);
          totalSectionCount += progressData.totalSections;
          completedSectionCount += progressData.completedSections;
        });
      }

      // Fetch Client Requests
      ClientRequestsService.getEngagementRequests().then((requests) => {

        vm.requests = requests;
        let progressData = getRequestsProgress(vm.requests);

        if (vm.userHasAccess('tasks_client_requests')) {
          vm.itemsInProgress = vm.itemsInProgress.concat(progressData.inProgress);
          vm.itemsNeedReview = vm.itemsNeedReview.concat(progressData.needReview);

          vm.itemsInProgress = dedupe(vm.itemsInProgress);
          vm.itemsNeedReview = dedupe(vm.itemsNeedReview);
        }

        totalSectionCount += progressData.totalSections;
        completedSectionCount += progressData.completedSections;

        finishInit(completedSectionCount, totalSectionCount);
      });
    }

    function itemsFilter(item) {
      // If the program slug matches the TR services, check the TR permission
      // otherwise it's a custom service so check the custom services permission
      return taxResServiceSlugs.find(slug => slug === item.programSlug)
        ? vm.userHasAccess('tax_resolution_services')
        : vm.userHasAccess('custom_services');
    }

    function finishInit(completedSectionCount, totalSectionCount) {
      vm.calculatedProgressPercent = totalSectionCount ? Math.floor((completedSectionCount/totalSectionCount) * 100) : 0;
      calculateDueDatePercentComplete();
      vm.totalSectionCount = totalSectionCount;
      vm.dueDateProgressLabel = "Today";
      vm.engagementProgressLabel = completedSectionCount.toString() == "1" ? completedSectionCount + " Item Complete" : completedSectionCount + " Items Complete";
      vm.minSectionCount = 0;

      $timeout(() => {vm.initializationComplete = true;}, 1000);
    }

    vm.goToItem = (item) => {
      if (item.type === 'step') {
        vm.goToSection(item.programSlug, item);
      } else if (item.type === 'request') {
        vm.goToRequest(item.id);
      }
    }

    vm.goToSection = (programSlug, section) => {
      $scope.$emit('cp:engagementChanged', vm.engagement);
      $state.go('engagement.layout.program.section', {
        programSlug: programSlug,
        sectionSlug: section.slug
      });
    }

    vm.goToRequest = (requestId) => {
      $state.go('engagement.layout.client-requests.instance', {
        clientRequestId: requestId
      });
    }

    vm.isDeleted = (id) => {
      return !isEmpty(filter(vm.activity, (action) => {
        return action.pivot_id == id && action.name.indexOf('deleted') > -1;
      }));
    }

    vm.deleteEngagement = () => {
      EngagementService.deleteEngagement({
        engagementId: vm.engagement.id,
        clientId: $stateParams.clientId
      });

      $state.go('engagements.list.active', {clientId: $stateParams.clientId});
    }

    $scope.$watch('vm.engagement.due_date', () => {
      if (!!vm.engagement) {
        calculateDueDatePercentComplete();
      }
    });

    $rootScope.$on('engagement-updated', (evnt, newEngagement) => {
      vm.updateEngagementHistory();
    });

    function updateEngagementHistory(){
      EngagementHistoryService.getEngagementHistory(vm.engagement).then((activity) => {
        let numAdditionalItems = activity.length - vm.activity.length;
        if (numAdditionalItems){
          let additionalItems = take(activity, numAdditionalItems);
          vm.activity = union(additionalItems, vm.activity);
        }
      });
    }

    function calculateDueDatePercentComplete() {
      if (!vm.engagement.due_date || vm.engagement.due_date == null) {
        vm.dueDatePercent = 0;
        return;
      }

      vm.startDate = $filter('date')(vm.engagement.created_at, 'MMM d');
      vm.dueDate = $filter('date')(vm.engagement.due_date, 'MMM d');

      if (vm.engagement.created_at > vm.engagement.due_date) {
        vm.dueDatePercent = 100;
      } else {
        vm.dueDatePercent = Math.floor(((Date.now() - vm.engagement.created_at)/(vm.engagement.due_date - vm.engagement.created_at)) * 100);
        if (vm.dueDatePercent > 100) vm.dueDatePercent = 100;
      }
    }

    function dedupe(items) {
      let arr = [];
      items.forEach((item) => {
        if (typeof arr.find((arrItem) => { return arrItem.id == item.id && arrItem.type == item.type }) === 'undefined') {
          arr.push(item);
        }
      });
      return arr;
    }

    //The query param below is set by sme-qa-ui when pulling test data into an engagement
    let splitHash = window.location.hash.split('?');
    if ($stateParams['smeQaTestName']) {
      if ($stateParams['reload']) {
        window.location.replace(splitHash[0]);
        toasts.successToast(`The values from ${$stateParams['smeQaTestName']} were successfully copied into this engagement!`);
      } else {
        window.location.replace(`${window.location.hash}&reload=true`);
        window.location.reload();
      }
    }
  }
]);
