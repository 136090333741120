import angular from 'angular';

angular.module('app')
.component('cpSystemMessage', {
  bindings: {
    message: '<',
  },

  controllerAs: 'vm',

  template: `<div id="systemStatusMessage" class="cps-bg-warning cps-white cps-padding-12 cps-padding-left-16" ng-if="vm.message.val.length > 0">
          {{vm.message.val}}
        </div>`,
});