import angular from 'angular';

angular.module('app.clients.taxes')
  .factory('ClientRequestDescriptionResource', ['$http',
    function ClientRequestDescriptionResource($http) {

      return {

        getAttachments: (clientId, attachmentId) => $http.get(`/api/clients/${clientId}/attachments?pivot_type=client_requests&pivot_id=${attachmentId}`),

        delete: (clientId, attachmentId) => $http.delete(`/api/clients/${clientId}/attachments/${attachmentId}`),

        getAttachment: (clientId, attachmentId) => $http.get(`/api/clients/${clientId}/attachments/${attachmentId}`),

        put: (clientId, engagementId, clientRequestId, body) => $http.put(`/api/clients/${clientId}/engagements/${engagementId}/requests/${clientRequestId}`, body)

      };
    }
  ]);
