import angular from "angular";
import { filter } from "lodash";
import { catchAsyncStacktrace } from 'auto-trace';

import template from "./assistant-outro.template.html";
import "./assistant-outro.style.css";
import "./assistant-shared.style.css";

import 'angular/app/client/taxes/programs/assistant/assistant-answers.service.js';

import "angular/app/client/taxes/section-interactions/common-actions/change-status.directive.js";
import "angular/app/client/taxes/section-interactions/common-actions/due-date.directive.js";

import 'angular/app/client/taxes/programs/innocent-spouse/assistant-outro.component.js';
import 'angular/app/client/taxes/programs/penalty-abatements/assistant-outro.component.js';
import 'angular/app/client/taxes/programs/liens/assistant-outro.component.js';
import 'angular/app/client/taxes/programs/levies/assistant-outro.component.js';
import 'angular/app/client/taxes/programs/trust-fund/assistant-outro.component.js';

import * as assistantConstants from 'angular/app/client/taxes/programs/assistant/assistant.constants.js';

angular.module('app.clients.taxes').component('cpAssistantOutro', {
  bindings: {
    engagement: "="
  },

  controller: ['$scope', '$state', '$stateParams', 'AssistantAnswersService', 'ContactService', 'ProgramSectionsService', 'EngagementService', 'SectionEnvelopesService',
    function AssistantOutroController($scope, $state, $stateParams, AssistantAnswersService, ContactService, ProgramSectionsService, EngagementService, SectionEnvelopesService) {
      const vm = this;

      vm.assistantConstants = assistantConstants;
      vm.$stateParams = $stateParams;
      vm.regetActiveTeamMembers = regetActiveTeamMembers;

      AssistantAnswersService.getQuestion()
        .then((question) => {
          vm.question = question;
        })
        .catch(catchAsyncStacktrace());

      if (!$stateParams.section || !$stateParams.sectionEnvelope || !$stateParams.activeTeamMembers) {
        ContactService.getClient({clientId: $stateParams.clientId, include: 'users'})
          .then(function (successResponse) {
            vm.activeTeamMembers = filter(successResponse.users, {
              'is_activated': true,
              'is_deleted': false,
              'role': 'TeamMember'
            });
          })
          .catch(catchAsyncStacktrace());

        $scope.$watch('vm.engagement', () => {
          if (vm.engagement) {
            ProgramSectionsService.get({
              engagementId: $stateParams.engagementId,
              clientId: $stateParams.clientId,
              sectionId: EngagementService.getSectionFromEngagementWithSlugs(vm.engagement, $stateParams.programSlug, assistantConstants.assistantResources[$stateParams.programSlug].assistantSlug).id
            }).then((section) => {
              vm.section = section;
              SectionEnvelopesService.getEnvelope({
                ...$stateParams,
                sectionId: section.id,
                envelopeId: section.relationships.children[0].references
              })
              .then((envelope) => {
                vm.sectionEnvelope = envelope;
              })
              .catch(catchAsyncStacktrace());
            })
            .catch(catchAsyncStacktrace());
          }
        });
      } else {
        vm.section = $stateParams.section;
        vm.sectionEnvelope = $stateParams.sectionEnvelope;
        vm.activeTeamMembers = $stateParams.activeTeamMembers;
      }

      function regetActiveTeamMembers() {
        ContactService.getClient({clientId: $stateParams.clientId, include: 'users'})
          .then(function (successResponse) {
            vm.activeTeamMembers = filter(successResponse.users, {
              'is_activated': true,
              'is_deleted': false,
              'role': 'TeamMember'
            });
          })
          .catch(catchAsyncStacktrace())
      }

      vm.restartAssistant = AssistantAnswersService.restartAssistant;

      vm.goToPreviousQuestion = () => {
        $state.go(`engagement.layout.program.assistant`, {questionSlug: vm.question.previousQuestionSlug, sectionSlug: $stateParams.sectionSlug});
      };
    }
  ],

  controllerAs: 'vm',

  template: template
});
