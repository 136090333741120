import angular from "angular";

import 'angular/app/client/taxes/section-interactions/assistant.style.css';
import 'angular/app/client/taxes/section-interactions/assistant-shared.style.css';

import template from './oic-or-installment.template.html';

angular.module('app.clients.taxes').component('cpAssistantQuestionOicOrInstallment', {
  bindings: {},

  controllerAs: 'vm',

  controller: function () {
    const vm = this;
    vm.modalInstallmentAgreement = false;
    vm.modalOIC = false;
  },

  template: template
});
