import angular from 'angular';
import template from './old-survey-landing.template.html';
import './old-survey-landing.style.css';

angular
.module("app.clients.taxes")
.component("oldSurveyLanding", {
  template,
  bindings: {
  },
  controllerAs: 'vm',
  controller() {
  },
});
