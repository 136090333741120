import angular from "angular";
import {set} from "lodash";
import { catchAsyncStacktrace } from 'auto-trace';
import { taxResServiceSlugs } from 'angular/app/client/taxes/engagement-summary.helper.js';
import { hasAccess } from 'cp-client-auth!sofe';

import "angular/app/admin/service-templates/service-templates.service.js";
import context from "angular/bootstrap/context.service.js";

angular.module('app.clients.taxes')
  .controller('EngagementCreateController', ['$state', '$stateParams', 'EngagementService', 'ContactService', '$filter', 'ServiceTemplatesService',
    function($state, $stateParams, EngagementService, ContactService, $filter, ServiceTemplatesService) {

      var vm = this;
      vm.loadState = 'loaded';
      vm.alerts = {};
      vm.datePickerCustomClass = datePickerCustomClass;
      vm.context = context.getContext();
      vm.userHasAccess = hasAccess(vm.context.loggedInUser);
      if (!vm.userHasAccess('tasks_engagements')) {
        $state.go('403');
      }

      vm.engagementName = "";
      vm.isBusiness = vm.client.is_business;
      vm.shouldShowService = shouldShowService;
      vm.formsVersion = "taxRes";

      function getNextLetter(c) {
        return String.fromCharCode(c.charCodeAt(0) + 1);
      }

      vm.availableServices = [{
        "slug": "planning",
        "uid": "a",
        "name": "Planning",
        "is_removable": false,
        "description": "",
        "visible": true
      }];

      let currentLetter = "b";

      ServiceTemplatesService
        .getServiceTemplates({tenantId: vm.context.tenant.id})
        .then((services) => {
          for (let i = 0; i < services.length; i++) {
            services[i].uid = currentLetter;
            services[i].visible = ['collections', 'planning'].includes(services[i].slug) ? true : false;
            delete services[i].id;
            currentLetter = getNextLetter(currentLetter);

            vm.availableServices.push(services[i]);
          }
        })
        .catch(catchAsyncStacktrace());

      vm.createInProgress = false;

      vm.createEngagement = () => {
        if (vm.createInProgress) return;

        vm.createInProgress = true;
        const dueDateLong = vm.engagementDueDate ? vm.engagementDueDate : null;
        let new_engagement = {
          name: vm.engagementName || "",
          description: vm.engagementDescription || "",
          taxpayer_type: getTaxpayerType(vm.isBusiness),
          due_date: dueDateLong
        };

        new_engagement.forms_version = vm.formsVersion;

        let i = 0;
        for (let service in vm.availableServices) {
          vm.availableServices[service].sections = [];

          set(new_engagement, `program_data.programs[${i++}]`, vm.availableServices[service]);
        }

        EngagementService.create($stateParams, new_engagement).then(function(resp) {
          $state.go('engagement.layout.summary', {
            clientId: $stateParams.clientId,
            engagementId: resp.id
          });

          vm.createInProgress = false;
        })
        .catch(error => {
          vm.createInProgress = false;
          throw error;
        })
        .catch(catchAsyncStacktrace());
      }

      function datePickerCustomClass($date) {
        if ($date.toDateString() === (new Date()).toDateString()) {
          return 'selected';
        }
      }

      function getTaxpayerType(isBusiness) {
        return isBusiness ? 'business' : 'individual';
      }

      function shouldShowService(slug) {
        if (slug === 'planning') return false;
        if (taxResServiceSlugs.includes(slug)) return vm.userHasAccess('tax_resolution_services');
        else return vm.userHasAccess('custom_services');
      }
    }
  ]);
