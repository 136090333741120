import angular from 'angular';

import template from './change-status.template.html';
import './change-status.controller.js';
import './change-status.style.css';
import ".//change-status-dialog.directive.js";

angular.module('app.clients.taxes')
  .directive('cpChangeStatus', ['$compile', '$timeout',
    function cpChangeStatus($compile, $timeout) {
      return {
        restrict: 'E',
        scope: {
          engagement: '=',
          section: '=',
          activeTeamMembers: '=',
          customUpdateSection: '=?',
          onUpdate: '&',
          disabled: '=?',
          requiredPermission: '@', // Filters team members by this permission when asking who to notify of the status change
          // This putModalInSelector allows the change status notifications modal to be used from a different
          // area of the page. The modal didn't work as expected on client requests because the right side
          // pane is "fixed" By using $compile we can put the modal into another container with no position conflict
          putModalInSelector: '@'
        },
        template: template,
        controller: 'ChangeStatusController',
        controllerAs: 'vm',
        bindToController: true,
        link: function(scope, element, attrs) {
          if (attrs.putModalInSelector) {
            scope.useDefaultModal = false;
            const changeStatusDialog = `<cp-change-status-dialog
              required-permission="vm.requiredPermission"
              status-notifications-mode="vm.statusNotificationsMode"
              active-team-members="vm.activeTeamMembers"
              logged-in-user-id="vm.loggedInUserId"
              team-members-going-to-receive-notification="vm.teamMembersGoingToReceiveNotification"
              at-least-one-notification-recipient="vm.atLeastOneNotificationRecipient"
              dont-notify-anyone-of-status-change="vm.dontNotifyAnyoneOfStatusChange()"
              recalculate-notification-count="vm.recalculateNotificationCount()"
              change-status-and-notify-people="vm.changeStatusAndNotifyPeople()"></cp-change-status-dialog>`
            let el = $compile(changeStatusDialog)(scope);
            // This $timeout prevents a flash when the modal is appended but has not caught up with binding
            $timeout(() => {
              angular.element(attrs.putModalInSelector).append(el)
            });

            scope.$on('$destroy', () => {
              el.remove();
            });
          }
          else {
            scope.useDefaultModal = true;
          }
        }
      }
    }
  ]);
