import angular from "angular";

angular.module('app.clients.taxes')
.factory('SectionEnvelopesResource', ['$http',
  function SectionEnvelopesResource($http) {
    return {
      getList: (params) => $http.get(`/api/clients/${params.clientId}/engagements/${params.engagementId}/sections/${params.sectionId}/envelopes`),

      post: (params, envelope) => $http.post(`/api/clients/${params.clientId}/engagements/${params.engagementId}/sections/${params.sectionId}/envelopes/`, {"envelopes": envelope}),

      get: (params) =>  $http.get(`/api/clients/${params.clientId}/engagements/${params.engagementId}/sections/${params.sectionId}/envelopes/${params.envelopeId}`),

      put: (params, envelope) => $http.put(`/api/clients/${params.clientId}/engagements/${params.engagementId}/sections/${params.sectionId}/envelopes/${params.envelopeId}`, {"envelopes": envelope}),

      delete: (params) => $http.delete(`/api/clients/${params.clientId}/engagements/${params.engagementId}/sections/${params.sectionId}/envelopes/${params.envelopeId}`),
    };
  }
]);
