import angular from "angular";
import { asyncStacktrace } from 'auto-trace';
// import "angular/resources/docs.resource.js";
import toasts from "toast-service!sofe";

angular.module('app')
  .factory('EsignService', ['$q', 'DocsResource',
    function FilesService($q, DocsResource) {

      return {
        getEsignDoc: (esign_doc_id, logView) => {
          const dfd = $q.defer();

          DocsResource
            .getEsignDoc(esign_doc_id, logView)
            .subscribe(response => {
              dfd.resolve(response)
            }, asyncStacktrace(response => {
              dfd.reject(response);
              toasts.warningToast(`Error getting eSign signature fields`);
            }))

          return dfd.promise;
        }
      }
    }
  ]);
