import angular from 'angular';
import { navContentPostLink } from './nav-content-postlink.js';
import { partial, noop } from 'lodash';

angular
.module('app')
.directive('useNavContentTop', [
  function UseNavContentTop() {
    return {
      restrict: 'A',
      compile: function(element, attrs) {
        const newHeightCallback = noop;
        return {
          post: partial(navContentPostLink, "top", newHeightCallback)
        };
      },
    };
  },
]);
