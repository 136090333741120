import angular from "angular";
import { asyncStacktrace } from 'auto-trace';
import "angular/resources/program-sections.resource.js";
import canopyUrls from 'canopy-urls!sofe';
import { forceBustCache } from 'fetcher!sofe';

angular.module('app.clients.taxes')
  .factory('ProgramSectionsService', ['$q', 'ProgramSectionsResource',
    function ProgramSectionsService($q, ProgramSectionsResource) {
      return {
        get: (params) => {
          let deferred = $q.defer();

          ProgramSectionsResource.get(params)
          .then(function success(response) {
            deferred.resolve({
              ...response.data.engagement_sections,
              description: response.data.engagement_sections.description ? response.data.engagement_sections.description.replace(/&#10;/g, " | ") : null,
            });
          })
          .catch(asyncStacktrace(response => {deferred.reject(response)} ));

          return deferred.promise;
        },

        add: (params, section) => {
          let deferred = $q.defer();

          ProgramSectionsResource.post(params, section)
          .then(function success(response) {
            deferred.resolve(response.data.engagement_sections);
          })
          .catch(asyncStacktrace(response => {deferred.reject(response)} ));

          return deferred.promise;
        },

        update: (params, section, notifications) => {
          let deferred = $q.defer();

          ProgramSectionsResource.put(params, section, notifications)
          .then(function success(response) {
            deferred.resolve(response.data);
            forceBustCache(`${canopyUrls.getWorkflowUrl()}/api/clients/${params.clientId}/engagements/${params.engagementId}`)
          })
          .catch(asyncStacktrace(response => {deferred.reject(response)} ));

          return deferred.promise;
        },

        delete: (params) => {
          let deferred = $q.defer();

          ProgramSectionsResource.delete(params)
          .then(function success(response) {
            deferred.resolve(response.data);
          })
          .catch(asyncStacktrace(response => {deferred.reject(response)} ));

          return deferred.promise;
        },

        getList: (params) => {
          let deferred = $q.defer();

          ProgramSectionsResource.getList(params)
          .then(function success(response) {
            deferred.resolve(response.data);
          })
          .catch(asyncStacktrace(response => {deferred.reject(response)} ));

          return deferred.promise;
        }
      };
    }
  ]);
