import angular from "angular";
import _ from 'lodash';

/**
 * This filter allows you to use any lodash function as a filter
 *
 * Example:
 * {{ data | lodash:"find":{id: 1234} }}
 * {{ vm.client.phones | lodash:'find':{key: 'home'} | valueOf:'value' }}
 */
angular.module('app.helpers')
  .filter('lodash', function() {
    return function(input, method) {
      var args = [input].concat(Array.prototype.slice.call(arguments, 2));
      return _[method].apply(_, args);
    }
  });
