import angular from "angular";
import template from "./survey-list.template.html";
import "angular/common/directives/percentage-round/percentage-round.directive.js";
import { catchAsyncStacktrace } from 'auto-trace';
import './client-survey-metadata.service.js';
import './survey-list.style.css';

angular
.module('app.clients.taxes')
.component('surveyList', {
  bindings: {
    client: '=',
  },
  template,
  controllerAs: 'vm',
  controller: ['$stateParams', 'ClientSurveyMetadataService',
    function SurveyListController($stateParams, ClientSurveyMetadataService) {
      const vm = this;
      vm.open = true;
      vm.isLoaded = false;

      vm.clientId = $stateParams.clientId;

      ClientSurveyMetadataService
      .getSurveysForClient(vm.clientId)
      .then(surveys => {
        vm.surveys = surveys;
        vm.isLoaded = true;
      })
      .catch(catchAsyncStacktrace());
    }
  ],
});
