import auth from "cp-client-auth!sofe";
import Dropzone from 'dropzone'; // Importing or when checking if something is instanceof Dropzone, it breaks app

/**
 * Purpose for this helper:
 * Because we use the directory service, we rely on JSON web tokens. Those tokens have an authentication lifetime of an
 * hour. After an hour, the refresh token has 30 minutes to be used to get a new token.
 * If the first thing that happens after the auth token expires is an attempted file upload through dropzone, it will
 * fail with a 401. Dropzone needs a way to go get the new auth token using the cp-client-auth library which handles
 * the interaction with directory.
 *
 * All Dropzone implementations should be using this helper.
 *
 * When using this helper, DO NOT INCLUDE the 'X-CSRF-TOKEN' header in the Dropzone config header options, otherwise,
 * it will result in the CSRF token being included twice.
 *
 * How to use:
 * 1) Include the helper
 * import * as dropzoneHelper from 'angular/common/helpers/dropzone.helper.js';
 *
 * 2) Pass the dropzone instance to the helper
 * dropzoneHelper.setup401Retries(dropzone);
 */

export function setup401Retries(dropzone) {

  // Just return if not an instance of Dropzone
  if (!(dropzone instanceof Dropzone)) return;

  let csrfTokenSet = false;
  let retries = 0;
  let totalFiles = 0;

  dropzone.on('error', function (file, message, xhr) {
    if ((xhr && xhr.status === 401) && retries <= (totalFiles * 3)) {
      auth.refreshAuthToken({clientSecret: 'TaxUI:f7fsf29adsy9fg'})
        .then(() => {
          dropzone.removeFile(file);
          dropzone.addFile(file);
          csrfTokenSet = false;

          // There is a newer version of Dropzone that does not use this flag, it is called autoProcessQueue
          // This, and our implementations of Dropzone will need to be updated if we use the new version
          if (!dropzone.options.autoQueue) {
            dropzone.enqueueFile(file);
          }
        });

      retries++;
    }
  });

  dropzone.on('complete', () => {
    csrfTokenSet = false;
  });

  dropzone.on('queuecomplete', () => {
    totalFiles = 0;
    retries = 0;
  });

  dropzone.on('sending', (file, xhr, formData) => {
    if (!csrfTokenSet) {
      totalFiles = dropzone.files.length;
      csrfTokenSet = true;
      xhr.setRequestHeader('X-CSRF-Token', auth.getCSRFToken());
    }
  });
}
