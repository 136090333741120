import angular from "angular";
import "angular/app/admin/service-templates/service-templates.service.js";
import context from "angular/bootstrap/context.service.js";
import { catchAsyncStacktrace } from 'auto-trace';
import {hasAccess as userHasAccess} from 'cp-client-auth!sofe';

angular.module('app.admin')
  .controller('ServiceTemplates', ['ServiceTemplatesService', '$state',
    function ServiceTemplates(ServiceTemplatesService, $state) {
      let vm = this;
      vm.context = context.getContext();
      vm.hasAccess = userHasAccess(vm.context.loggedInUser);
      if (!vm.hasAccess('templates_tax_resolution_engagements')) {
        $state.go('403');
      }
      vm.isLoaded = false;

      function init() {
        ServiceTemplatesService.getServiceTemplates({tenantId: vm.context.tenant.id}).then((services) => {
          vm.allServices = services;
          vm.canopyServices = services.filter((element) => element.is_removable === false);

          vm.customServices = services.filter((element) => element.is_removable === true);
        })
        .catch(catchAsyncStacktrace());

        vm.isLoaded = true;
      }

      init();

      vm.launchAddEditServiceModal = (serviceModel) => {
        // make a copy of the input item and use that to bind to the modal
        vm.editing = Object.assign({}, serviceModel);

        vm.showAddEditServiceModal = true;
        vm.submittingService = false;
      };

      vm.cancelEditService = () => {
        // revert service values to initial values
        vm.showAddEditServiceModal = false;
        vm.submittingService = false;
      };

      vm.launchDeleteServiceModal = (serviceModel) => {
        vm.deleting = serviceModel;
        vm.showDeleteServiceModal = true;
      };

      vm.saveService = (serviceModel) => {
        vm.submittingService = true;

        let serviceToSubmit = Object.assign({}, vm.getEmptyServiceTemplate(), serviceModel);

        if (serviceToSubmit.id) {
          ServiceTemplatesService.updateServiceTemplate({tenantId: vm.context.tenant.id}, serviceToSubmit)
          .then(() => {
            vm.customServices.splice(vm.customServices.findIndex((element) => element.id === serviceModel.id), 1, serviceToSubmit);
            vm.showAddEditServiceModal = false;
          })
          .catch((response) => {
            vm.showAddEditServiceModal = false;
            // throw this to the notification service?
          });

        } else {
          ServiceTemplatesService.addServiceTemplate({tenantId: vm.context.tenant.id}, serviceToSubmit)
          .then((service) => {
            vm.customServices.push(service);
            vm.showAddEditServiceModal = false;
            $state.go('admin.service-templates.template', {templateId: service.id, templateName: serviceToSubmit.name});
          })
          .catch((response) => {
            vm.showAddEditServiceModal = false;
            // throw this to the notification service?
          });
        }
      };

      vm.deleteService = (serviceModel) => {
        ServiceTemplatesService.deleteServiceTemplate({tenantId: vm.context.tenant.id}, serviceModel)
        .then(() => {
          vm.customServices.splice(vm.customServices.findIndex((element) => element.id === serviceModel.id), 1);
          vm.showDeleteServiceModal = false;
        })
        .catch((response) => {
          vm.showDeleteServiceModal = false;
        });
      };

      vm.getEmptyServiceTemplate = () => ({
        name: '',
        description: ''
      });

    }]);
