import angular from "angular";

/**
 * This filter allows you to use any lodash function as a filter
 *
 * Example:
 * {{ data | lodash:"find":{id: 1234} }}
 */
angular.module('app.helpers')
  .filter('valueOf', function() {
    return function(input, method) {
      return input ? input[method] : input;
    }
  });
