import angular from "angular";
import template from "./percentage-round.template.html";
import "./percentage-round.style.css";

angular.module('app')
  .directive('cpPercentageRound', ['$timeout',
    function($timeout) {
      return {
        restrict: 'E',
        template: template,
        scope: {
          'percent': '@'
        },
        link: function(scope, el, attr) {
          scope.show1 = false;
          scope.show2 = false;

          scope.inlineStyle1 = {
            transform: 'rotate(0deg)'
          };
          scope.inlineStyle2 = {
            transform: 'rotate(0deg)'
          };

          /**
           * Avoid graphical glitches by making the minimum value 7 or 0 degrees
           */
          function min(val) {
            return val < 7 && val !== 0 ? 7 : val;
          }

          scope.$watch('percent', function(percent) {
            scope.show1 = false;
            scope.show2 = false;

            $timeout(function() {
              var degrees = percent / 100 * 360;

              if (degrees > 180) {
                scope.inlineStyle1.transform = "rotate(180deg)";
                scope.show1 = true;
                $timeout(function() {
                  scope.inlineStyle2.transform = "rotate(" + min(degrees - 180) + "deg)";
                  scope.show2 = true;
                }, 500);
              } else {
                scope.inlineStyle1.transform = "rotate(" + min(degrees) + "deg)";
                scope.show1 = true;
              }
            }, 100);
          });
        }
      }
    }
  ]);
