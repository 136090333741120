import angular from  'angular';
import { catchAsyncStacktrace } from 'auto-trace';
import './client-request-header.style.css';
import template from './client-request-header.template.html';
import 'angular/app/client/taxes/section-interactions/common-actions/change-status.directive.js';
import 'angular/common/directives/auto-grow-textarea.directive.js';
import context from 'angular/bootstrap/context.service.js';
import { isEmpty } from 'lodash';
import toasts from 'toast-service!sofe';

angular
.module('app.clients.taxes')
.component('cpClientRequestHeader', {
  bindings: {
    clientRequest: '=',
    activeTeamMembers: '=',
    engagement: '=',
  },

  template,

  controllerAs: 'vm',

  controller: ['$scope', 'ClientRequestsService', '$rootScope', '$stateParams', 'cpUrlState',
    function ClientRequestHeaderController($scope, ClientRequestsService, $rootScope, $stateParams, cpUrlState) {
      const vm = this;

      $scope.$watch('vm.clientRequest.status', () => {
        vm.editable = !context.getContext().loggedInUser.hasAccess(['client']) && status !== 'complete';
      });

      vm.titleFocused = () => {
        vm.oldTitle = vm.clientRequest ? vm.clientRequest.title : null;
      }

      vm.titleBlurred = () => {
        if (vm.oldTitle !== vm.clientRequest.title) {
          ClientRequestsService.updateClientRequest(vm.clientRequest, 'title');
        }

        delete vm.oldTitle;
      }

      vm.updateStatus = (oldStatus, newStatus, notifications) => {
        if ((oldStatus === 'needs_review' || oldStatus === 'complete') && newStatus === 'in_progress' && context.getContext().loggedInUser.role === "TeamMember" && !!vm.clientRequest.reminders.reminder_interval) {
          toasts.infoToast(`Since your request was moved back to "In Progress" we reset your reminders. You can create a new reminder schedule by clicking on "Add a Reminder."`, null, null, 8000);
        }
        // If moving back to In Progress, make sure notifications block includes team member & client-specific urls
        if (newStatus === 'in_progress') {
          if (isEmpty(notifications)) {
            notifications = {};
          }
          notifications.teammember_url = cpUrlState.getUrlFromState('engagement.layout.client-requests.instance', {clientRequestId: vm.clientRequest.id, clientId: $stateParams.clientId, engagementId: $stateParams.engagementId});
          notifications.client_url = cpUrlState.getUrlFromState('client.general.requests.instance', {clientRequestId: vm.clientRequest.id, clientId: $stateParams.clientId});
        }
        ClientRequestsService.updateClientRequest(vm.clientRequest, 'status', notifications)
        .then((clientRequest) => {
          $scope.$emit('cp:client-request:grouping-changed');
        })
        .catch(catchAsyncStacktrace());
      }

      vm.delete = () => {
        $scope.$emit('cp:client-request-component:do-hide');
        ClientRequestsService.deleteRequest(vm.clientRequest.id)
        .then(() => {
          // IE 11, 12 will not work with $scope.$emit from here, so we have to use $rootScope.$broadcast...
          $rootScope.$broadcast('cp:client-request:deleted', vm.clientRequest);
        })
        .catch(catchAsyncStacktrace());
      }
    }
  ],
});
