import angular from "angular";

import * as outcomesHelper from 'angular/app/client/taxes/programs/trust-fund/assistant-outcomes.helper.js';
import 'angular/app/client/taxes/section-interactions/outcomes.style.css';

import template from './employment-tax.template.html';

angular.module('app.clients.taxes').component('cpOutcomesTrustFundEmploymentTax', {
  bindings: {
    outcomes: '='
  },

  controllerAs: 'vm',

  controller: function ($stateParams) {
    const vm = this;

    vm.employmentTaxAvailable = () => outcomesHelper.employmentTaxAvailable(vm.outcomes);
    vm.employmentTaxWarningAvailable = () => outcomesHelper.employmentTaxWarningAvailable(vm.outcomes);

    vm.reliefUnlikely = () => outcomesHelper.reliefUnlikely(vm.outcomes);
    vm.cantCalculateOutcomes = () => outcomesHelper.cantCalculateOutcomes(vm.outcomes);

    vm.summaryExpanded = false;

    vm.clientId = $stateParams.clientId;
    vm.engagementId = $stateParams.engagementId;
  },

  template
});
