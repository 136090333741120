import angular from 'angular';
import { isOverride as hasSofeOverrides } from 'sofe';

angular
.module('app')
.config(['$compileProvider', function ($compileProvider) {
  if (!hasSofeOverrides()) {
    $compileProvider.debugInfoEnabled(false);
  }
}]);
