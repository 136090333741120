import angular from 'angular';

angular.module('app')
  .directive('cpCompileHtml', ['$compile',
    function($compile) {
      return {
        restrict: 'A',
        link: function(scope, element, attrs) {
          scope.$watch(function() {
            return scope.$eval(attrs.cpCompileHtml);
          }, function(value) {
            element.html(value);
            $compile(element.contents())(scope);
          });
        }
      }
    }
  ]);
