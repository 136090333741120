import angular from "angular";
import { catchAsyncStacktrace } from 'auto-trace';

import 'angular/app/client/taxes/section-interactions/common-actions/change-status.directive.js';
import 'angular/app/client/taxes/section-interactions/common-actions/assign-section-envelope.component.js';
import 'angular/app/client/taxes/section-interactions/common-actions/due-date.directive.js';

import 'angular/app/client/taxes/programs/assistant/assistant-answers.service.js';

import "angular/app/client/taxes/section-interactions/assistant-shared.style.css";
import "angular/app/client/taxes/section-interactions/outcomes.style.css";

import './outcomes/appeals.component.js';
import './outcomes/employment-tax.component.js';
import './outcomes/tfrp-defense.component.js';

import * as outcomesHelper from './assistant-outcomes.helper.js';
import * as assistantConstants from './assistant.constants.js';

import template from './assistant-outcomes.template.html';

angular.module('app.clients.taxes').component('cpOutcomesTrustFund', {
  bindings: {},

  controllerAs: 'vm',

  controller: function ($scope, $stateParams, AssistantAnswersService) {
    const vm = this;

    vm.assistantConstants = assistantConstants;

    // when vm.outcomes === null, it means there are definitely no outcomes. undefined means we aren't sure yet
    vm.outcomes = undefined;

    vm.$onInit = () => {
      vm.readyToGo = false;

      vm.appealAvailable = () => outcomesHelper.appealAvailable(vm.outcomes);
      vm.employmentTaxAvailable = () => outcomesHelper.employmentTaxAvailable(vm.outcomes);
      vm.employmentTaxWarningAvailable = () => outcomesHelper.employmentTaxWarningAvailable(vm.outcomes);
      vm.TFRPDefenseAvailable = () => outcomesHelper.TFRPDefenseAvailable(vm.outcomes);

      vm.reliefUnlikely = () => outcomesHelper.reliefUnlikely(vm.outcomes);
      vm.cantCalculateOutcomes = () => outcomesHelper.cantCalculateOutcomes(vm.outcomes);

      AssistantAnswersService.getOutcomes()
        .then((outcomes) => {
          vm.outcomes = outcomes;

          if (vm.appealAvailable()) {
            vm.resolutionType = assistantConstants.APPEAL;
          }

          else if (vm.employmentTaxAvailable()) {
            vm.resolutionType = assistantConstants.EMPLOYMENT_TAX;
          }

          else if (vm.employmentTaxWarningAvailable()) {
            vm.resolutionType = assistantConstants.EMPLOYMENT_TAX_WARNING;
          }

          else if (vm.TFRPDefenseAvailable()) {
            vm.resolutionType = assistantConstants.TFRP_DEFENSE;

          }

          else {
            vm.resolutionType = assistantConstants.APPEAL;
          }

          vm.readyToGo = true;
        })
        .catch(catchAsyncStacktrace());
    }
  },

  template: template
});
