import angular from 'angular';
import _ from 'lodash';

import '../client-request-comments-list.style.css';
import template from './request-tool-comments.template.html';
import 'angular/common/directives/comments/comments-list.component.js';
import '../client-request-file.component.js';

import "angular/common/services/comments.service.js";
import "angular/resources/docs.resource.js";
import "./request-tool-comments-service"
import context from 'angular/bootstrap/context.service.js';
import * as fileHelper from "angular/common/helpers/file.helper.js";
import "./request-tool-file.component"

angular
.module('app.clients.taxes')
.component('cpRequestToolComments', {
  bindings: {
    comments: '=',
    clientRequestId: '=',
    activeTeamMembers: '<',
  },
  template,
  controllerAs: 'vm',
  controller: ['$scope', '$stateParams', 'RequestToolCommentsService',
    function($scope, $stateParams, RequestToolCommentsService) {
      const vm = this;
      vm.context = context.getContext()
      vm.loggedInUser = context.getContext().loggedInUser
      vm.requestToolMap = RequestToolCommentsService.getRequestToolMap()
      vm.userMap = RequestToolCommentsService.getUserMap()

      if(vm.loggedInUser){
        vm.loggedInUser.initials = vm.loggedInUser.firstName[0].toUpperCase() + vm.loggedInUser.lastName[0].toUpperCase()
      }

      vm.getAttachmentIcon = (fileName) => {
        if(fileName){
          return fileHelper.getFileIconPath(fileName);
        }

        return undefined
      };
    }
  ]
})