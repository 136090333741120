import angular from "angular";

import context from 'angular/bootstrap/context.service.js';
import { hasAccess } from 'cp-client-auth!sofe';

import { showInviteClientModal } from 'contacts-ui!sofe';
import { catchAsyncStacktrace } from 'auto-trace';

angular.module('app.clients')
  .controller('ClientDashboardController', ['$rootScope', '$timeout', '$anchorScroll', '$state', 'PaymentService', 'NotesService', 'ClientTaxReturnsService',
    function ClientDashboardController($rootScope, $timeout, $anchorScroll, $state, PaymentService, NotesService, ClientTaxReturnsService) {
      $anchorScroll();

      if ($state.params.newContact) {
        // Show invite to client portal modal
        showInviteClientModal($state.params.clientId);
        // Remove params from URL to make sure the modal only shows once
        $timeout(() => {
          window.location.hash = window.location.hash.replace('newContact=1', '');
        });
      }

      const vm = this;

      if (vm.client.is_deleted || vm.client.is_archived) {
        window.location.hash = '#/clients';
      }

      vm.userHasAccess = hasAccess(context.getContext().loggedInUser);

      if (vm.userHasAccess('client_billing')) {
        PaymentService.hasCanopyPayments()
          .then(response => {
            vm.hasCanopyPayments = response.isActive;
          }, catchAsyncStacktrace());
      }

      var now = new Date();

      vm.today = new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime();
      vm.loadState = 'loaded';

      vm.createNote = () => {
        const clientId = $state.params.clientId;

        NotesService
          .createNote($state.params, {title: '', body: '', visible_to_client: false})
          .then(note => {
            $state.go('edit-note', {clientId, noteId: note.id});
          })
          .catch(catchAsyncStacktrace())
      }

      vm.showTaxReturns = false;
      if (vm.userHasAccess("client_tax_prep")) {
        ClientTaxReturnsService.getTaxReturnsForClient(vm.client.id)
          .then(taxReturns => {
            if (taxReturns.find(taxReturn => taxReturn.shared)) {
              vm.showTaxReturns = true;
            }
          })
          .catch(catchAsyncStacktrace());
      }
    }
  ]);
