import angular from "angular";
import { featureEnabled } from 'feature-toggles!sofe';
import { hasAccess } from 'cp-client-auth!sofe'
import context from 'angular/bootstrap/context.service.js';
import { includes } from "lodash";

import "angular/app/clients/contact.service.js";
import 'angular/app/client/taxes/programs/assistant/assistant-answers.service.js';

import "angular/app/client/client.controller.js";
import "angular/app/client/taxes/create/engagement-create.directive.js";
import "angular/app/client/taxes/engagement-list.directive.js";
import "angular/app/client/taxes/programs/collection/analytics.directive.js";
import "angular/app/client/taxes/engagement-summary.directive.js";
import "angular/app/client/taxes/lock/engagement-lock.directive.js";
import "angular/app/client/taxes/layouts/engagements-permission-and-breadcrumb.directive.js";
import "angular/app/client/taxes/layouts/engagement-layout.directive.js";
import "angular/app/client/taxes/engagement-section.directive.js";
import "angular/app/client/taxes/engagement-details.directive.js";
import "angular/app/client/taxes/programs/manage-programs.directive.js";
import "angular/app/client/taxes/section-interactions/assistant-intro.component.js";
import "angular/app/client/taxes/section-interactions/assistant.component.js";
import "angular/app/client/taxes/section-interactions/assistant-outro.component.js";
import "angular/app/client/taxes/client-requests/engagement-client-requests.component.js";
import "angular/app/client/taxes/letters/letters-dashboard.component.js";
import "angular/app/client/taxes/surveys/old-survey-landing.component.js";

import engagementsListLayoutTemplate from "angular/app/client/taxes/layouts/engagements-list.layout.html";

import { assistant_slugs, assistantResources } from 'angular/app/client/taxes/programs/assistant/assistant.constants.js';

const reactPortFeature = featureEnabled('engagements_react_port');

angular.module('app.routes')
  .config(function($stateProvider) {
    $stateProvider
      .state('engagements', {
        parent: 'client.general',
        url: '/engagements',
        abstract: true,
        template: '<cp-engagements-permission-and-breadcrumb client="vm.client"></cp-engagements-permission-and-breadcrumb>'
      })
        .state('engagements.create', {
          url: '/create',
          template: '<engagement-create client="vm.client"></engagement-create>'
        })
        .state('engagements.list', {
          abstract: true,
          url: '/list',
          template: engagementsListLayoutTemplate
        })
          .state('engagements.list.active', {
            url: '/active',
            template: '<engagement-list template="index" client="vm.client"></engagement-list>'
          })
          .state('engagements.list.archived', {
            url: '/archived',
            template: '<engagement-list template="index" client="vm.client"></engagement-list>'
          })
          .state('engagements.list.trash', {
            url: '/trash',
            template: '<engagement-list template="index" client="vm.client"></engagement-list>'
          })

      .state('engagement', {
        parent: 'app',
        url: '/taxes/client/{clientId}/engagements/{engagementId}',
        template: '<cp-engagements-permission-and-breadcrumb></cp-engagements-permission-and-breadcrumb>'
      })

        .state('engagement.planning', {
          controller: function($state) {
            $state.go('engagement.layout.program.section', {
              programSlug: 'planning',
              sectionSlug: 'poa_survey'
            });
          }
        })

        .state('engagement.collections', {
          controller: function($state) {
            $state.go('engagement.layout.program.section', {
              programSlug: 'collections',
              sectionSlug: 'collection_survey'
            });
          }
        })

        .state('engagement.layout', {
          abstract: true,
          template: reactPortFeature ? '<ui-view />' : '<cp-engagement-layout engagement="vm.engagement" client="vm.client"></cp-engagement-layout>'
        })

          .state('engagement.layout.program', {
            url: '/program/{programSlug}',
            template: '<ui-view></ui-view>',
            controller: function($injector, $scope, $state) {
              if (!reactPortFeature) {
                const user = context.getContext().loggedInUser
                if (!hasAccess(user)('tasks_engagements')) {
                  console.info('user lacks permission to "tasks_engagements", redirecting from engagements.routes')
                  $state.go('403')
                }
                $scope.$watch('vm.engagement', (engagement) => {
                  const $stateParams = $injector.get('$stateParams'); //if you don't get the stateParams from the injector, you only get the stateParams for this state (which never includes sectionSlug)
                  if (engagement && !$stateParams.sectionSlug && window.location.href.indexOf('assistant') < 0) {
                    const program = engagement.program_data.programs.find((program) => program.slug === $stateParams.programSlug);
                    if (program && program.sections.length > 0) {
                      $state.go('engagement.layout.program.section', {
                        sectionSlug: program.sections[0].slug
                      });
                    }
                  }
                });
              }
            }
          })
            .state('engagement.layout.program.section', {
              url: '/section/{sectionSlug}',
              controller: function($injector, $scope, $state, AssistantAnswersService) {
                if (!reactPortFeature) {
                  const $stateParams = $injector.get('$stateParams'); //if you don't get the stateParams from the injector, you only get the stateParams for this state (which never includes sectionSlug)

                  if (includes(assistant_slugs, $stateParams.sectionSlug)) {
                    AssistantAnswersService.resumeAssistant($stateParams.sectionSlug);
                  }
                }
              },
              template: reactPortFeature
                ? '<ui-view/>'
                : '<cp-engagement-section engagement="vm.engagement"></cp-engagement-section>'
            })

            .state('engagement.layout.program.assistant-intro', {
              url: '/section/{sectionSlug}/intro',
              params: {
                section: null,
                sectionEnvelope: null,
                activeTeamMembers: null
              },
              controller: function($injector, $scope) {
                if (!reactPortFeature) {
                  const $stateParams = $injector.get('$stateParams');
                  $scope.questionSlugs = assistantResources[$stateParams.programSlug].constants;
                }
              },
              template: reactPortFeature
                ? '<ui-view/>'
                : '<cp-assistant-intro question-slugs="questionSlugs" engagement="vm.engagement"></cp-assistant-intro>'
            })

            .state('engagement.layout.program.assistant', {
              url: '/section/{sectionSlug}/question/{questionSlug}',
              params: {
                resumeBanner: false,
                section: null,
                sectionEnvelope: null,
                activeTeamMembers: null
              },
              controller: function($injector, $scope) {
                if (!reactPortFeature) {
                  const $stateParams = $injector.get('$stateParams');
                  $scope.questionSlugs = assistantResources[$stateParams.programSlug].constants;
                  $scope.questionTypeConsts = assistantResources[$stateParams.programSlug].questionHelper;
                }
              },
              template: reactPortFeature
                ? '<ui-view/>'
                : '<cp-assistant engagement="vm.engagement" question-slugs="questionSlugs" question-type-consts="questionTypeConsts"></cp-assistant>'
            })

            .state('engagement.layout.program.assistant-outro', {
              url: '/section/{sectionSlug}/outro',
              params: {
                section: null,
                sectionEnvelope: null,
                activeTeamMembers: null
              },
              template: reactPortFeature
                ? '<ui-view/>'
                : '<cp-assistant-outro engagement="vm.engagement"></cp-assistant-outro>'
            })

          .state('engagement.layout.details', {
            url: '/details',
            template: '<cp-engagement-details engagement="vm.engagement"></cp-engagement-details>'
          })

          .state('engagement.layout.managePrograms', {
            url: '/programs',
            template: reactPortFeature
              ? '<ui-view/>'
              : '<cp-manage-programs engagement="vm.engagement"></cp-manage-programs>',
          })

          .state('engagement.layout.services', {
            url: '/services',
            template: '<ui-view/>'
          })

        .state('engagement.layout.summary', {
          url: '/summary?smeQaTestName&reload',
          template: '<cp-engagement-summary engagement="vm.engagement" client="vm.client"></cp-engagement-summary>'
        })

        // This route is in case there is no trailing slash on /client_requests
        .state('engagement.layout.client-requests', {
          url: '/client_requests',
          template: '<cp-engagement-client-requests engagement="vm.engagement"></cp-engagement-client-requests>',
        })

        .state('engagement.layout.client-requests.instance', {
          url: '/:clientRequestId',
          template: '<cp-engagement-client-requests engagement="vm.engagement"></cp-engagement-client-requests>',
        })

        // Letter Generator
        .state('engagement.layout.letter-generator', {
          url: '/letter-generator',
          template: '<ui-view/>',
        })

        .state('engagement.layout.letter-generator.instance', {
          url: '/{letterId}',
          template: '<ui-view/>'
        })

        .state('engagement.layout.lock', {
          url: '/lock',
          template: '<engagement-lock engagement="vm.engagement" client="vm.client"></engagement-lock>'
        })
  });
