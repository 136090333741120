import * as constants from './assistant.constants.js';

export function cantCalculateOutcomes(outcomes) {
  return outcomes === constants.OUTCOME_NOT_YET_CALCULABLE;
}

export function reliefUnlikely(outcomes) {
  return !!(outcomes && outcomes.indexOf(constants.RELIEF_UNLIKELY) >= 0);
}

export function communityPropertyAvailable(outcomes) {
  return !!(outcomes && outcomes.indexOf(constants.COMMUNITY_PROPERTY) >= 0);
}

export function equitableReliefAvailable(outcomes) {
  return !!(outcomes && outcomes.indexOf(constants.EQUITABLE_RELIEF) >= 0);
}

export function innocentSpouseAvailable(outcomes) {
  return !!(outcomes && outcomes.indexOf(constants.INNOCENT_SPOUSE) >= 0);
}

export function separationOfLiabilityAvailable(outcomes) {
  return !!(outcomes && outcomes.indexOf(constants.SEPARATION_OF_LIABILITY) >= 0);
}
