import angular from "angular";
import _ from 'lodash';
import { asyncStacktrace } from 'auto-trace';
import SelectBoxHelpers from "angular/common/helpers/select-box.helpers.js";
import "angular/resources/tenant.resource.js";

angular.module('app')
  .factory('TenantService', ['$q', 'TenantResource',
    function TenantService($q, TenantResource) {

      var service = {};
      service.getTenant = getTenant;
      service.patchTenant = patchTenant;

      return service;

      function getTenant(params) {
        var deferred = $q.defer();

        TenantResource.get(params)
          .then(function success(response) {
            deferred.resolve(_transformGetRequest(response.tenants));
          })
          .catch(asyncStacktrace(response => {deferred.reject(response)} ));

        return deferred.promise;
      }

      function patchTenant(params, tenant) {

        var payload = _transformSaveRequest(tenant);

        var deferred = $q.defer();

        TenantResource.patch(params, payload)
          .then(function success(response) {
            deferred.resolve(response.tenants);
          })
          .catch(asyncStacktrace(response => {deferred.reject(response)} ));

        return deferred.promise;
      }

      function _transformSaveRequest(tenant) {
        // convert object to string
        if (angular.isDefined(tenant.region) && angular.isDefined(tenant.region.key)) tenant.region = tenant.region.key;
        return {
          "tenants": tenant
        };

      }

      function _transformGetRequest(tenant) {
        // convert string to object
        tenant.region = _.find(SelectBoxHelpers.states, { key: tenant.region });

        return tenant;
      }

    }
  ]);
