import angular from 'angular';

angular.module('app.helpers')
  .filter('formatBytes', function () {
    return function (bytes, precision) {

      if (isNaN(parseFloat(bytes)) || !isFinite(bytes)) {
        return '-';
      }

      if (typeof precision === 'undefined') {
        precision = 1;
      }

      let units = ['B', 'KB', 'MB', 'GB', 'TB'];
      let number = Math.floor(Math.log(bytes) / Math.log(1024));
      let val = (bytes / Math.pow(1024, Math.floor(number))).toFixed(precision);

      if (number === 0) return 'Less than 1KB';

      return (val.match(/\.0*$/) ? val.substr(0, val.indexOf('.')) : val) + units[number];
    }
  });
