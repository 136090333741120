import angular from 'angular';
import _ from 'lodash';
import { catchAsyncStacktrace } from 'auto-trace';

import canopyUrls from 'canopy-urls!sofe';
import toasts from 'toast-service!sofe';
import { featureEnabled } from 'feature-toggles!sofe';

import 'angular/app/transcripts/transcript-reports.service.js';
import 'angular/app/clients/contact.service.js';
import context from 'angular/bootstrap/context.service.js';
import * as TableHelper from './transcript-reports-table.helper.js';
import PermissionHelpers from 'angular/common/helpers/permission.helpers.js';
import {
  transcriptTypes,
  REPORT_TYPE,
  TRANSCRIPT_TYPE,
  REPORTS_HELPER,
  BUSINESS_REPORT_SUBTYPES,
} from './transcript.constants.js';

import './transcript-reports.style.css';
import template from './transcript-reports.template.html';

angular.module('app.transcripts').component('cpTranscriptReports', {
  bindings: {
    version: '@',
    activeTab: '@',
    selectedReportName: '@',
    selectedDocument: '@',
    activeYear: '@',
  },

  controllerAs: 'vm',

  controller: function(
    $rootScope,
    $scope,
    $state,
    $stateParams,
    TranscriptReportsService,
    $anchorScroll,
    $document,
    $timeout,
    $filter,
    ContactService,
    $sanitize,
    $sce
  ) {
    const vm = this;

    vm.selectReport = selectReport;
    vm.selectDocument = selectDocument;
    vm.selectTab = selectTab;
    vm.popOutWindow = popOutWindow;
    vm.print = print;
    vm.download = download;
    vm.downloadAll = downloadAll;
    vm.print_download = print_download;
    vm.getSubDocContentURL = getSubDocContentURL;
    vm.selectVersion = selectVersion;

    vm.loggedInUser = context.getContext().loggedInUser;
    vm.WORKFLOW_URL = canopyUrls.getWorkflowUrl();
    vm.irsDocument = {};
    vm.hasReports = false;
    vm.showDropdown = false;
    vm.isLoaded = false;
    vm.eServicesEnabled = false;
    vm.showPrintDownloadDialog = false;
    vm.REPORT_TYPE = REPORT_TYPE;
    vm.REPORTS_HELPER = REPORTS_HELPER;
    vm.leftScrollSpyMargin = 0;

    vm.clientId = $stateParams.clientId;

    vm.$onInit = () => {
      if (PermissionHelpers.hasAccess(['transcripts'], vm.loggedInUser)) {
        //For display of documents
        vm.documents = _.cloneDeep(transcriptTypes);

        //For Printing Documents
        vm.printTypes = _.cloneDeep(transcriptTypes);

        vm.reports = {
          account_overview: { title: REPORT_TYPE.ACCOUNT_OVERVIEW, table: [] },
          csed_calculations: { title: REPORT_TYPE.CSED_CALCULATIONS, table: [] },
          account_transactions: { title: REPORT_TYPE.ACCOUNT_TRANSACTIONS, table: [] },
          penalties_and_interest: { title: REPORT_TYPE.PENALTIES_AND_INTEREST, table: [] },
          payment_history: { title: REPORT_TYPE.PAYMENT_HISTORY, table: [] },
          notices_overview: { title: REPORT_TYPE.NOTICES_OVERVIEW, table: [] },
          tolling_events: { title: REPORT_TYPE.TOLLING_EVENTS, table: [] },
        };

        vm.eServicesEnabled = featureEnabled('irs_eservices_enabled');
        getVersions();
        getClient();

        vm.selectedReportName = _.isEmpty(vm.selectedReportName) ? vm.selectReportName : REPORT_TYPE.ACCOUNT_OVERVIEW;
        vm.selectedDocument = _.isEmpty(vm.selectedDocument) ? vm.selectedDocument : TRANSCRIPT_TYPE.ACCOUNT;

        vm.selectedYear = vm.activeYear;

        window.addEventListener('click', handleDocumentClick);
      } else {
        $state.go('404');
      }
    };

    vm.$onDestroy = () => {
      window.removeEventListener('click', handleDocumentClick);
    };

    vm.$postLink = () => {
      $timeout(() => {
        let someElement = angular.element(document.getElementById(vm.activeYear));

        if (someElement.length) {
          $document.scrollToElementAnimated(someElement);
          vm.selectedYear = vm.activeYear;
        }
      }, 2000);
    };

    function handleDocumentClick(e) {
      const theSelector = document.querySelector('#transcript-version-select');
      if (theSelector && !theSelector.contains(e.target)) {
        vm.closeVersionsMenu();
        $scope.$apply();
      }
    }

    vm.closeVersionsMenu = () => {
      vm.showDropdown = false;
    };

    function getVersions() {
      vm.versions = [];
      vm.selectedVersion = vm.version !== 'undefined' ? vm.version : vm.versions[0];

      TranscriptReportsService.getVersions($stateParams.clientId).then(versions => {
        vm.versions = versions.transcripts;
        _.each(vm.versions, obj => {
          obj.dateString = $filter('date')(obj.name, 'M/d/yy h:mm a');
        });

        vm.versions.sort((a, b) => b.name - a.name);

        vm.selectedVersion = vm.version !== 'undefined' ? lookupVersion(vm.version) : vm.versions[0];
        vm.version = vm.version !== 'undefined' ? vm.version : vm.versions.length > 0 ? vm.versions[0].id : undefined;

        if (!vm.version) {
          vm.isLoaded = true; // emptystate
          vm.versions = undefined;
          vm.documents = undefined;
        }
      });
    }

    function lookupVersion(id) {
      return vm.versions
        ? _.filter(vm.versions, version => {
            return version.id == id;
          })[0]
        : '';
    }

    function selectVersion(version) {
      vm.selectedVersion = version;
      vm.closeVersionsMenu();
    }

    function getClient() {
      vm.client = {};

      ContactService.getClient({
        clientId: vm.clientId,
      })
        .then(function(client) {
          vm.client = client;
        })
        .catch(catchAsyncStacktrace());
    }

    function getReportsOfVersion(versionId, isBusiness = false) {
      let clientId = $stateParams.clientId;

      _.map(vm.reports, report => {
        let report_helper_data = vm.REPORTS_HELPER.find(helper_report => helper_report.title == report.title);
        let report_type_url = report_helper_data.report_type_url;

        report.hidden = isBusiness && !report_helper_data.available_for_business;

        if (!report.hidden) {
          if (!isBusiness) {
            // Get individual reports
            getReports(clientId, versionId, report_type_url, report);

            if (report.title === REPORT_TYPE.ACCOUNT_OVERVIEW || report.title === REPORT_TYPE.ACCOUNT_TRANSACTIONS) {
              //get civil_penalty reports -- only for overview and transactions
              getReports(clientId, versionId, report_type_url, report, {
                title: 'Civil Penalties',
                url: 'civil_penalty',
                sort: 4,
              });
            }
          } else {
            // Get all business report subtypes
            _.map(BUSINESS_REPORT_SUBTYPES, subtype => {
              getReports(clientId, versionId, report_type_url, report, subtype);
            });
          }
        }
      });
    }

    function getReports(clientId, versionId, report_type_url, report, subtype = {}) {
      TranscriptReportsService.getReport(clientId, versionId, report_type_url, subtype.url).then(record => {
        let newReport = TableHelper.toTableFormat(record.report, !(report.title == REPORT_TYPE.CSED_CALCULATIONS));

        if (newReport) {
          // Give the report a subtype name and push it to the list of reports
          newReport.subtype = subtype.title;
          newReport.sort = subtype.sort || 0;
          report.table.push(newReport);
          report.table.sort((a, b) => a.sort - b.sort);
        }

        if (vm.selectedReportName == report.title) {
          // Display this report if it is the selected report
          selectReport(report);
          vm.isLoaded = true;
        }

        hasReports();
      });
    }

    function getRecordsOfVersion(versionId) {
      let clientId = $stateParams.clientId;

      //Document
      TranscriptReportsService.getRecord(clientId, versionId).then(record => {
        if (record.transcript_records.length > 0) {
          let documentsGroup = _.groupBy(record.transcript_records, record => {
            return record.type;
          });

          vm.documents = _.map(documentsGroup, sortSubDocuments);

          vm.selectDocument(vm.selectedDocument, vm.selectedYear);

          if (vm.activeTab == 'documents') {
            vm.isLoaded = true;
          }

          //vm.irsDocId = record.transcript_records[0].id;
        } else {
          //No Reccords
          vm.documents = null;
          vm.selectedDocTypeData = null;
          vm.irsDocument = '';
        }
      });
    }

    function sortSubDocuments(docType, key) {
      //Remove all ugly yellow boxes
      _.each(docType, doc => {
        doc.content = doc.content.replace(new RegExp('bgcolor="yellow"', 'g'), '');
      });

      var yearGroup = _.groupBy(docType, doc => {
        return doc.year;
      });

      //Convert Object to Array
      yearGroup = _.map(yearGroup, group => {
        //Sort subtypes
        return _.sortBy(group, doc => {
          return doc.subtype;
        });
      });

      //Sort by year
      yearGroup = _.sortBy(yearGroup, group => {
        return group[0].year * -1;
      });

      //preserve order of the groups
      let TYPE = transcriptTypes.find(type => {
        return type.title == key;
      });
      let orderVal = TYPE ? TYPE.order : 10;

      //List of years that can be printed
      var years = yearGroup.map(group => {
        return parseInt(group[0].year);
      });

      let docGroup = {
        title: key,
        order: orderVal,
        documents: yearGroup,
        selectedYears: years,
        availableYears: _.cloneDeep(years),
      };

      return docGroup;
    }

    function hasReports() {
      vm.hasReports = _.filter(vm.reports, x => x.table.length).length > 0;
    }

    function selectReport(report) {
      if (_.has(report, 'table') && _.has(report, 'title') && !_.isEmpty(report.table)) {
        vm.selectedReportName = report.title;
        vm.selectedTables = report.table;
      }
    }

    function selectDocument(doc, year) {
      var selected = doc;
      if (_.isString(doc)) {
        selected = _.find(vm.documents, obj => {
          return obj.title == doc;
        });
      }
      if (!selected) selected = vm.documents[0];
      vm.selectedDocument = selected.title;
      vm.selectedDocTypeData = selected;
      vm.selectedYear = getSelectedYear(year, vm.selectedDocTypeData);
    }

    function getSelectedYear(specifiedYear, selectedDocTypeData) {
      //Returns the specified year (if it exists), or gives the default
      let activeYear = null;
      if (specifiedYear) {
        activeYear = specifiedYear;
      } else if (
        _.has(selectedDocTypeData, 'documents') &&
        vm.selectedDocTypeData.documents.length &&
        vm.selectedDocTypeData.documents[0].length
      ) {
        activeYear = selectedDocTypeData.documents[0][0].year;
      }
      return activeYear;
    }

    function selectTab(selection) {
      vm.activeTab = selection;
    }

    function print() {
      vm.action = 'Print';
      openPrintDownloadDialog();
    }

    function download() {
      vm.action = 'Download';
      openPrintDownloadDialog();
    }

    function openPrintDownloadDialog() {
      vm.printType = _.find(vm.printTypes, obj => {
        return obj.title == vm.selectedDocument;
      });
      vm.showPrintDownloadDialog = true;
    }

    function print_download() {
      vm.showPrintDownloadDialog = false;
      var data = { type: vm.selectedDocument, years: vm.selectedDocTypeData.selectedYears };
      if (vm.action == 'Download') {
        window.location = `${vm.WORKFLOW_URL}/api/clients/${$stateParams.clientId}/transcripts/${
          vm.selectedVersion.id
        }/records/zip?filters=${JSON.stringify(data)}`;
      } else if (vm.action == 'Print') {
        // Open New window and print
        // Fixes dual-screen position                         Most browsers      Firefox
        let dualScreenTop = window.screenTop != undefined ? window.screenTop : screen.top;
        let screenWidth = window.screenWidth != undefined ? window.screenWidth : screen.width;

        const w = window.open(
          `${vm.WORKFLOW_URL}/api/clients/${$stateParams.clientId}/transcripts/${
            vm.selectedVersion.id
          }/records/print?filters=${JSON.stringify(data)}`,
          '_blank',
          `top=${dualScreenTop}, left=${screenWidth}, width=800, height=800`
        );
        w
          ? null
          : toasts.infoToast(
              "The print window didn't open! If you are using a pop-up blocking utility then it may have blocked it!",
              null,
              null,
              6000
            );
      }
    }

    function downloadAll() {
      if (vm.activeTab == 'reports')
        window.location = `${vm.WORKFLOW_URL}/api/clients/${vm.clientId}/transcripts/${vm.version}/reports/all/csv`;
      else
        window.location = `${vm.WORKFLOW_URL}/api/clients/${vm.clientId}/transcripts/${vm.version}/records/zip`;
    }

    function popOutWindow() {
      // Fixes dual-screen position                         Most browsers      Firefox
      let dualScreenTop = window.screenTop != undefined ? window.screenTop : screen.top;
      let screenWidth = window.screenWidth != undefined ? window.screenWidth : screen.width;

      let baseURL = window.location.href.includes('?') ? window.location.href.match(/.*[\?]/)[0] : window.location.href;
      baseURL = baseURL.replace('?', '').replace('%3F', '').replace('%3f', '');

      let popURL = `${baseURL}?version=${vm.version}&activeTab=${vm.activeTab}&selectedReportName=${
        vm.selectedReportName
      }&selectedDocument=${vm.selectedDocument}&activeYear=${vm.selectedYear}`;

      const w = window.open(
        popURL,
        '_blank',
        'top=' + dualScreenTop + ', left=' + screenWidth + ', width=1200, height=800'
      );
      w
        ? null
        : toasts.infoToast(
            "The print window didn't open! If you are using a pop-up blocking utility then it may have blocked it!",
            null,
            null,
            6000
          );
    }

    function getSubDocContentURL(URLValue) {
      return $sce.trustAsResourceUrl(URLValue);
    }

    $rootScope.$on('duScrollspy:becameActive', ($event, $element, $target) => {
      let id = $target[0].id;
      if (id) {
        vm.selectedYear = id.substring(0, 4);
        $timeout(() => $scope.$apply(), 0, false);
      }
    });

    $document.on('scroll', () => {
      $timeout(() => $scope.$apply(), 0, false);
    });

    //Moves the scrollSpy element on the documents view
    $scope.$watch(`vm.activeTab`, activeTab => {
      if (activeTab === 'documents') {
        $timeout(() => {
          //Give the element time to be rendered
          const documentContent = document.getElementById('transcript-doc-container');
          if (documentContent) {
            documentContent.addEventListener('scroll', () => {
              setleftScrollSpyMargin();
            });
          }
        }, 250);
      }
    });

    //Moves the scrollSpy element on the window resize
    angular.element(window).bind('resize', () => {
      if (document.getElementById('transcript-doc-container')) {
        setleftScrollSpyMargin();
      }
    });

    function setleftScrollSpyMargin() {
      vm.leftScrollSpyMargin = Math.min(0, $document.find('#transcript-doc-content').offset().left - 260);
      // Scroll listener does not trigger digests
      try {
        $scope.$apply();
      } catch (ex) {
        /* digest already in progress */
      }
    }

    $scope.$watch('vm.selectedVersion', (newVal, oldVal) => {
      if (vm.selectedVersion && newVal != oldVal) {
        $state.go('transcripts.view', { ...$stateParams, version: vm.selectedVersion.id });

        vm.version = vm.selectedVersion.id;
        vm.isLoaded = false;
        getReportsOfVersion(vm.selectedVersion.id, vm.selectedVersion.is_business);
        getRecordsOfVersion(vm.selectedVersion.id);
      }
    });
  },

  template,
});
