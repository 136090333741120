import angular from "angular";
import template from "./workpapers.template.html";

import "./workpapers.controller.js";
import "./workpapers.style.css";

import "angular/app/client/taxes/section-interactions/common-actions/change-status.directive.js";
import "angular/app/client/taxes/section-interactions/common-actions/due-date.directive.js";
import 'angular/common/directives/comments/comments-list-with-add-new.component.js';

angular.module('app.clients.taxes')
  .directive('cpWorkpapers', [
    function cpWorkpapers() {
      return {
        restrict: 'E',
        scope: {
          sectionId: '=',
          engagement: '='
        },
        template: template,
        controller: 'WorkpapersController',
        controllerAs: 'vm',
        bindToController: true
      }
    }
  ]);
