import angular from 'angular';
import { asyncStacktrace } from 'auto-trace';

import 'angular/resources/tasks.resource.js';

angular.module('app').factory('TasksService', [
  '$q',
  'TasksResource',
  function TasksService($q, TasksResource) {
    return {
      getTasks: client_id => {
        let deferred = $q.defer();

        TasksResource.getTasks(client_id)
          .then(function success(response) {
            deferred.resolve(response.data.tasks);
          })
          .catch(
            asyncStacktrace(response => {
              deferred.reject(response);
            })
          );

        return deferred.promise;
      },
    };
  },
]);
