import angular from "angular";
import { asyncStacktrace } from 'auto-trace';
import "angular/resources/files.resource.js";
import * as fileHelper from "angular/common/helpers/file.helper.js";
import toasts from "toast-service!sofe";

// DEPRECATED - use DocsService
angular.module('app')
  .factory('FilesService', ['$q', 'FilesResource',
    function FilesService($q, FilesResource) {
      var uploadDialogCallback;

      return {
        getIndex: function getIndex(params) {
          var deferred = $q.defer();

          FilesResource.index(params)
            .$promise.then(function success(response) {
              deferred.resolve(response.attachments);
            })
            .catch(asyncStacktrace(response => {
              deferred.reject(response)
              toasts.warningToast("Unable to retrieve the list of files, please try again");
            }));

          return deferred.promise;
        },

        getDeletedIndex: function getDeletedIndex(params) {
          params.onlyTrashed = true;
          return this.getIndex(params);
        },

        restoreFile: (params) => {
          var deferred = $q.defer();

          FilesResource.save(params, {
              attachments: {
                is_deleted: false
              }
            })
            .$promise.then(function success(response) {
              deferred.resolve(response.files);
            })
            .catch(asyncStacktrace(response => {
              deferred.reject(response)
              toasts.warningToast("Unable restore the file, please try again");
            }));

          return deferred.promise;
        },

        deleteFile: function deleteFile(params) {
          var deferred = $q.defer();

          FilesResource.remove(params)
            .$promise.then(function success(response) {
              deferred.resolve(response.clients);
            })
            .catch(asyncStacktrace(response => {
              deferred.reject(response)
              toasts.warningToast("Unable to delete the file, please try again");
            }));

          return deferred.promise;
        },

        getFileImage: fileHelper.getFileIcon,

        setupUploadDialog: (callback) => {
          uploadDialogCallback = callback;
        },

        showUploadDialog: () => {
          setTimeout(function() {
            if(uploadDialogCallback) uploadDialogCallback();
            else throw new Error("No registered file-upload.directive");
          })
        }
      }
    }
  ]);
