import angular from "angular";
import { asyncStacktrace } from 'auto-trace';

import "angular/resources/attachments.resource.js";
import _ from 'lodash';

angular.module('app.clients.taxes')
.factory('AttachmentsService', ['$q', 'AttachmentsResource',
  function AttachmentsService($q, AttachmentsResource) {

    return {
      getList: (params) => {
        let deferred = $q.defer();

        if(!params.clientId)
          throw new Error(`Must provide a client id when retrieving attachments, but was ${params.clientId}`)
        if (typeof params.pivot_type !== 'string' || params.pivot_type.length <= 0)
          throw new Error(`Must provide a pivot_type when retrieving attachments, but was ${params.pivot_type}`);
        if (!params.pivot_id)
          throw new Error(`Must provide a pivot_id when retrieving attachments, but was ${params.pivot_id}`);

        AttachmentsResource.getList(params)
        .then(function success(response) {
          deferred.resolve(response.data.attachments);
        })
        .catch(asyncStacktrace(response => {deferred.reject(response)} ));

        return deferred.promise;
      },

      getAttachment: (clientId, attachmentId) => {
        const deferred = $q.defer();

        if (!clientId)
          throw new Error(`client id is required to get attachment -- was ${clientId}`);
        if (!attachmentId)
          throw new Error(`attachmentId is required to get an attachment, but was ${attachmentId}`)

        AttachmentsResource.get(clientId, attachmentId)
        .then((response) => deferred.resolve(response.data.attachments))
        .catch(asyncStacktrace(response => {deferred.reject(response)} ));

        return deferred.promise;
      },

      delete: (params) => {
        let deferred = $q.defer();

        AttachmentsResource.delete(params)
        .then(function success(response) {
          deferred.resolve(response.data.attachments);
        })
        .catch(asyncStacktrace(response => {deferred.reject(response)} ));

        return deferred.promise;
      },

      getListForTenant: (params) => {
        let deferred = $q.defer();

        AttachmentsResource.getListForTenant(params)
        .then(function success(response) {
          deferred.resolve(response.data.attachments);
        })
        .catch(asyncStacktrace(response => {deferred.reject(response)} ));

        return deferred.promise;
      },

      deleteForTenant: (params) => {
        let deferred = $q.defer();

        AttachmentsResource.deleteForTenant(params)
        .then(function success(response) {
          deferred.resolve(response.data.attachments);
        })
        .catch(asyncStacktrace(response => {deferred.reject(response)} ));

        return deferred.promise;
      },

      patchAttachment: (clientId, attachment, notification) => {
        if (_.isEmpty(clientId))
          throw new Error(`can't patch attachment without client id (was ${clientId})`);
        if (!_.isPlainObject(attachment) || !attachment.id)
          throw new Error(`can't patch attachment without attachment.id`)

        const deferred = $q.defer();

        const payload = {
          attachments: attachment,
          notifications: notification
        };

        AttachmentsResource
        .patch(clientId, attachment.id, payload)
        .then((response) => deferred.resolve(response.data.attachments))
        .catch(asyncStacktrace(response => {deferred.reject(response)} ));

        return deferred.promise;
      },
    };

  }
]);
