import angular from 'angular';
import { featureEnabled } from 'feature-toggles!sofe';

angular.module('app.clients.taxes').controller('EngagementLayoutController', [
  '$scope',
  '$state',
  function engagementLayoutController($scope, $state) {
    var vm = this;

    vm.hideWorkflow = $state.current.url.includes('letter-generator') || $state.current.url.includes('{letterId}');

    if($state.current.url.includes('services')) {
      vm.hideWorkflow = featureEnabled('engagements_react_port')
    }

    if(featureEnabled('engagements_react_port') && $state.current.url.includes('programs')) {
      $state.go('engagement.layout.services')
    }

    $scope.$on('$stateChangeSuccess', function () {
      vm.hideWorkflow = $state.current.url.includes('letter-generator') || $state.current.url.includes('{letterId}');

      if($state.current.url.includes('services')) {
        vm.hideWorkflow = featureEnabled('engagements_react_port');
      }

      if(featureEnabled('engagements_react_port') && $state.current.url.includes('programs')) {
        $state.go('engagement.layout.services')
      }
    });
  },
]);
