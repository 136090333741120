import angular from "angular";

angular.module('app')
  .directive('cpSelectOnClick', function() {
    return {
      restrict: 'A',
      link: function(scope, element, attrs) {
        element.on('click', function() {
          // IE has issues selecting an element that has the potential of being hidden
          // This delays the event loop hoping and previous action will be complete
          setTimeout(function() {
            element.select();
          })
        });
      }
    };
  });
