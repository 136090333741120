import angular from "angular";
import canopyUrls from 'canopy-urls!sofe';

angular.module('app')
  .factory('UserResource', ['$http',
    function UserResource($http) {
      return {
        index(params) {
          return $http({
            method: "GET",
            url: `${canopyUrls.getAPIUrl()}/users`,
            params,
          });
        },
        get(params) {
          return $http.get(`${canopyUrls.getAPIUrl()}/users/${params.userId}`);
        },
        save(params, payload) {
          return $http({
            method: "POST",
            url: `${canopyUrls.getAPIUrl()}/users/${params.userId}`,
            data: payload,
          });
        },
        patch(params, payload) {
          return $http({
            method: "PATCH",
            url: `${canopyUrls.getAPIUrl()}/users/${params.userId}`,
            data: payload,
          });
        },
        remove(params) {
          return $http({
            method: "DELETE",
            url: `${canopyUrls.getAPIUrl()}/users/${params.userId}`,
          });
        }
      };
    }
  ]);
