import angular from "angular";
import _ from 'lodash';
import { catchAsyncStacktrace } from 'auto-trace';
import contextService from 'angular/bootstrap/context.service.js';

angular.module('app')
.factory('ErrorHandlingService', ['$state',
    function ErrorHandlingService($state) {
      var routeStatesToDebugDataFuncs = {};

      var service = {};
      service.startHandling = startHandling;
      service.bindRouteStateToDebugData = bindRouteStateToDebugData;
      service.routeStatesToDebugDataFuncs = routeStatesToDebugDataFuncs;

      return service;

      function startHandling(context) {
        window.System.import('sentry-error-logging!sofe')
        .then((sentryService) => sentryService.setUser(contextService.getContext().loggedInUser))
        .catch(catchAsyncStacktrace())
      }

      function bindRouteStateToDebugData($scope, route, funcToCalculateState) {
        if (!$scope) {
          throw new Error("A $scope must be provided");
        }

        if (!_.isString(route)) {
          throw new Error("A route string must be provided, but " + typeof route + " was given");
        }

        if (!_.isFunction(funcToCalculateState)) {
          throw new Error("A function funcToCalculateState must be provided, but " + typeof funcToCalculateState + " was given");
        }

        if (!routeStatesToDebugDataFuncs[route]) {
          routeStatesToDebugDataFuncs[route] = [];
        }

        routeStatesToDebugDataFuncs[route].push(funcToCalculateState);

        $scope.$on('$destroy', function() {
          _.pull(routeStatesToDebugDataFuncs[route], funcToCalculateState);
        });
      }
    }
]);
