import angular from "angular";
import _ from 'lodash';
import { asyncStacktrace } from 'auto-trace';
import SelectBoxHelpers from "angular/common/helpers/select-box.helpers.js";
import toasts from "toast-service!sofe";

import "angular/resources/user.resource.js";
import "angular/resources/user-client.resource.js";
import "angular/resources/emails-available.resource.js";

import { addHasAccessFunction } from './user-service.helpers.js';

angular.module('app')
  .factory('UserService', ['$rootScope', '$q', 'UserResource', 'UserClientResource', 'AvailableEmailsResource', 'cpLoginService',
    function UserService($rootScope, $q, UserResource, UserClientResource, AvailableEmailsResource, cpLoginService) {
      window.getLoggedInUser = getLoggedInUser;

      var service = {};

      service.getIndex = getIndex;
      service.getUser = getUser;
      service.getLoggedInUser = getLoggedInUser;
      service.postUsers = postUsers;
      service.patchUser = patchUser;
      service.deleteUser = deleteUser;
      service.getUsersClients = getUsersClients;
      service.transformPostUserRequest = transformPostUserRequest;
      service.transformPatchUser = transformPatchUser;
      service.verifyPassword = verifyPassword;
      service.getDesignations = getDesignations;
      service.checkEmail = checkEmail;

      return service;

      function getIndex(params) {
        var deferred = $q.defer();

        UserResource.index(params)
          .then(function success(response) {
            deferred.resolve(response.data.users);
          })
          .catch(asyncStacktrace(response => {deferred.reject(response)} ));

        return deferred.promise;
      }

      function getUser(params) {
        var deferred = $q.defer();

        UserResource.get(params)
          .then(function(response) {
            //if(angular.isDefined(response.users.irs) && angular.isDefined(response.users.irs.address)) response.users.irs.address.value.region = _.find(SelectBoxHelpers.states, {key : response.users.irs.address.value.region});
            deferred.resolve(_transformGetRequest(response.data));
          })
          .catch(asyncStacktrace(response => {deferred.reject(response)} ));

        return deferred.promise;
      }

      function _transformGetRequest(response) {
        if(angular.isDefined(response.users.irs) && angular.isDefined(response.users.irs.address)) response.users.irs.address.value.region = _.find(SelectBoxHelpers.states, {key : response.users.irs.address.value.region});
        response.users.time_zone = _.find(SelectBoxHelpers.timezones, {key : response.users.time_zone});

        return response.users;
      }

      function postUsers(params, payload) {

        var deferred = $q.defer();

        UserResource.save(params, payload)
          .then(function(response) {
            deferred.resolve(response.data.users);
          })
          .catch(asyncStacktrace(response => {deferred.reject(response)} ));
        return deferred.promise;
      }

      function patchUser(params, payload) {

        var deferred = $q.defer();

        if (!_.isUndefined(payload.users.irs) && !_.isUndefined(payload.users.irs.fax)) {
          payload.users.irs.fax.value = !_.isUndefined(payload.users.irs.fax.value) ? payload.users.irs.fax.value : '';
        }

        if (!_.isUndefined(payload.users.irs) && !_.isUndefined(payload.users.irs.phone)) {
          payload.users.irs.phone.value = !_.isUndefined(payload.users.irs.phone.value) ? payload.users.irs.phone.value : '';
        }

        if (!_.isUndefined(payload.users.primary) && !_.isUndefined(payload.users.primary.phone)) {
          payload.users.primary.phone.value = !_.isUndefined(payload.users.primary.phone.value) ? payload.users.primary.phone.value : '';
        }

        UserResource.patch(params, payload)
          .then(function(response) {

            if (!_.isUndefined(payload.users.password)) {
              // User is changing their password. Redirect to login so they can sign in again.
              // This flow will be reworked after directory has launched.
              cpLoginService.redirectToLogout();
              $rootScope.$destroy();
            }

            deferred.resolve(response.data.users);
          })
          .catch(deferred.reject);

        return deferred.promise;
      }

      function deleteUser(params) {

        var deferred = $q.defer();

        UserResource.remove(params)
          .then(function(response) {
            deferred.resolve(response.data.users);
          })
          .catch(asyncStacktrace(response => {deferred.reject(response)} ));

        return deferred.promise;
      }

      function getLoggedInUser() {
        const loggedInUserDeferred = $q.defer();

        const loggedInUser = addHasAccessFunction(_.cloneDeep(window.loggedInUser));
        loggedInUserDeferred.resolve(loggedInUser);

        return loggedInUserDeferred.promise;

      }

      function getUsersClients(params) {
        var deferred = $q.defer();

        UserClientResource.get(params)
          .then(function(response) {
            response.clientAccess = _getUsersClientAccess(response.data.clients);
            deferred.resolve(response);
          })
          .catch(asyncStacktrace(response => {deferred.reject(response)} ));

        return deferred.promise;
      }


      function transformPostUserRequest(emails, role, clients) {
        var transformedData, object;
        const permissions = {}

        if (angular.isArray(emails)) {
          transformedData = [];
          angular.forEach(emails, function(email) {
            var data = {
              "email": email,
              "role": role,
              "permissions": permissions
            };
            if (angular.isDefined(clients) && angular.isArray(clients)) {
              data.clients = clients;
            }

            this.push(data)

          }, transformedData);

          object = {
            "users": transformedData
          };

        } else {
          transformedData = {
            "email": emails,
            "role": role,
            "permissions": permissions,
            "clients": clients
          };

          object = {
            "users": transformedData,
            "notifications": {
              message: ''
            }
          };
        }

        return object;

      }


      function _getUsersClientAccess(clients) {

        var clientData = [];
        angular.forEach(clients, function (value) {
          if (value.has_access === true) {
            this.push(value.id);
          }

        }, clientData);

        return clientData;
      }


      function transformPatchUser(user) {

        var payload = {};

        payload.first_name = user.first_name;
        payload.last_name = user.last_name;
        payload.phone = user.phone;
        if(angular.isDefined(user.time_zone) && angular.isDefined(user.time_zone.key))payload.time_zone = user.time_zone.key ;
        payload.email = user.email;
        if (angular.isDefined(user.password) && user.password.length > 0) payload.password = user.password;

        // let's set the keys if they're not already
        if (angular.isDefined(user.primary) && user.primary != null) {
          if (angular.isDefined(user.primary.phone))
          {
            if (angular.isDefined(user.primary.phone.value) && angular.isUndefined(user.primary.phone.id)) {
              user.primary.phone.key = 'work';
            }

            if(angular.isUndefined(user.primary.phone.value) && angular.isUndefined(user.irs.fax.key))
            {
              user.primary.phone.value = '';
              user.primary.phone.delete = true;
            }
          }

          payload.primary = user.primary;
        }



        if (angular.isDefined(user.irs) && user.irs != null) {



          // clean up phones
          if(angular.isDefined(user.irs.phone))
          {
            if (angular.isDefined(user.irs.phone.value) && angular.isUndefined(user.irs.phone.key)) {
              user.irs.phone.key = 'work';
            }

            if (angular.isUndefined(user.irs.phone.value))
            {
              if (angular.isDefined(user.irs.phone.id))
              {
                user.irs.phone.value = '';
                user.irs.phone.delete = true;
              } else {
                // this gets rid of the phone object if it doesn't have a value or id
                delete user.irs.phone;
              }
            }

          }

          // clean up faxes
          if (angular.isDefined(user.irs.fax))
          {
            if (angular.isDefined(user.irs.fax.value) && angular.isUndefined(user.irs.fax.key)) {
              user.irs.fax.key = 'irs_fax';
            }

            if (angular.isUndefined(user.irs.fax.value))
            {

              if(angular.isDefined(user.irs.fax.id))
              {
                user.irs.fax.value = '';
                user.irs.fax.delete = true;
              } else {
                // this gets rid of the fax object if it doesn't have a value or id
                delete user.irs.fax;
              }
            }
          }

          // clean up addresses
          if(angular.isDefined(user.irs.address))
          {
            if (angular.isDefined(user.irs.address.value)) {

              if(angular.isUndefined(user.irs.address.key)) {
                user.irs.address.key = 'work';
              }

              if (angular.isObject(user.irs.address.value.region)) {
                user.irs.address.value.region = user.irs.address.value.region.key;
              }
            }

            if(angular.isUndefined(user.irs.address.value))
            {
              if(angular.isDefined(user.irs.address.id)) {
                user.irs.address.value = '';
                user.irs.address.delete = true;
              } else {
                // this gets rid of the address object if it doesn't have a value or id
                delete user.irs.address;
              }
            }

          }

          payload.irs = user.irs;
        }


        if (user.designation && user.designation.value) {
          if(user.designation.value === "None") user.designation.value = "";
          user.designation = user.designation.value;
        }
        payload.caf_number = user.caf_number;
        payload.license_number = user.license_number || "";
        payload.jurisdiction = user.jurisdiction || "";
        payload.designation = user.designation;
        payload.ptin = user.ptin || "";

        return payload;

      }

      function verifyPassword(password, confirmPassword) {

        if (angular.isUndefined(password) || password.length === 0) return true;

        if (password.length < 8) {
          toasts.warningToast("Please enter at least 8 characters for your password");
          return false;
        }

        if (password.length >= 8 && password === confirmPassword) return true;

        toasts.warningToast("Please make sure the passwords match.")
        return false;
      }

      function getDesignations() {
        return { designation: [ { "key": "", "value": "None" }, { "key": "a", "value": "Attorney" }, { "key": "b", "value": "Certified Public Accountant" }, { "key": "c", "value": "Enrolled Agent" }, { "key": "d", "value": "Officer" }, { "key": "e", "value": "Full-Time Employee" }, { "key": "f", "value": "Family Member" }, { "key": "g", "value": "Enrolled Actuary" }, { "key": "h", "value": "Unenrolled Return Preparer" }, { "key": "k", "value": "Student Attorney" }, { "key": "k", "value": "Student CPA" }, { "key": "r", "value": "Enrolled Retirement Plan Agent" } ] };
      }

      function checkEmail(params) {
        var deferred = $q.defer();

        AvailableEmailsResource.get(params)
          .then(function success(response) {
            deferred.resolve(response);
          })
          .catch(asyncStacktrace(response => {deferred.reject(response)} ));

        return deferred.promise;
      }
    }
  ]);
