import angular from 'angular';
import template from './view-note-modal.template.html';
import moment from 'moment';
import './view-note-modal.style.css';

angular
.module('app.clients.notes')
.component('viewNoteModal', {
  bindings: {
    clientId: '=',
    note: '=',
    showingModal: '=',
    hideModal: '&',
  },
  template,
  controllerAs: 'vm',
  controller(NotesService, $state) {
    const vm = this;

    let updated_time_stamp = moment(vm.note.updated_at);
    vm.updatedAtDay = updated_time_stamp.format('ll'); // Sep 30, 2016
    vm.updatedAtTime = updated_time_stamp.format('h:mmA'); // 4:57PM

    vm.copyNote = () => {
      NotesService.createNote({clientId: vm.clientId}, {title: vm.note.title, body: vm.note.body, visible_to_client: vm.note.visible_to_client})
        .then(note => {
          $state.go('edit-note', {clientId: vm.clientId, noteId: note.id});
        })
    }
  }
})
