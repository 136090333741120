import angular from "angular";
import { isString } from "lodash";

angular.module('app')
  .filter('cpEin', function() {
    return (value) => {
      if (value && isString(value) && value.length === 9) {
        return value.substring(0, 2) + '-' + value.substring(2, 9);
      }
      return value;
    }
  });
