import angular from "angular";

angular.module('app')
  .factory('FormHelpers', [ function() {
    return {
      hasError: function hasError(form, field) {
        if (!field) return false;
        return (form.displayRequiredFields && !!field.$validators.required && field.$error.required) || (!!field.$error.required && !!field.$touched);
      }
    }
  }]);


