import angular from 'angular';
import { featureEnabled } from 'feature-toggles!sofe';

import 'angular/app/transcripts/transcripts-onboarding.component.js';
import 'angular/app/transcripts/transcript-reports.component.js';
import 'angular/app/transcripts/transcript-reports-table.component.js';
import {
  REPORT_TYPE,
  TRANSCRIPT_TYPE,
} from 'angular/app/transcripts/transcript.constants.js';

angular.module('app.routes').config(function($stateProvider) {
  const newTranscriptsEnabled = featureEnabled('new_transcripts');

  $stateProvider
    .state('transcripts', {
      parent: 'app',
      url: '/transcripts',
      template: '<ui-view></ui-view>',
      abstract: true,
    })
    .state('transcripts.list', {
      url:
        '/list?{page}&{orderByColumn}&{orderDirection}&{teamMemberId}&{selected}',
      template: null,
    })

    .state('transcripts.create', {
      url: '/create-CAF',
      controller: [
        '$state',
        function($state) {
          $state.go('404');
        },
      ],
    })

    .state('transcripts.edit', {
      url: '/{clientId}/edit-CAF?{lastAvailable}',
      controller: [
        '$state',
        function($state) {
          $state.go('404');
        },
      ],
    })

    .state('transcripts.view', {
      url:
        '/{clientId}/reports?{version}&{activeTab}&{selectedReportName}&{selectedDocument}&{activeYear}',
      template: params =>
        !newTranscriptsEnabled
          ? `<cp-transcript-reports version="${
              params.version
            }" active-tab="${params.activeTab ||
              'reports'}" selected-report-name="${params.selectedReportName ||
              REPORT_TYPE.ACCOUNT_OVERVIEW}" selected-document="${params.selectedDocument ||
              TRANSCRIPT_TYPE.ACCOUNT}" active-year="${
              params.activeYear
            }"><cp-transcript-reports>`
          : null,
    })

    .state('transcripts.print', {
      url: '/{clientId}/print/report?{version}&{selectedReportName}',
      template: params =>
        !newTranscriptsEnabled
          ? `<cp-transcript-reports-table version="${
              params.version
            }" printing="true" selected-report-name="${params.selectedReportName ||
              REPORT_TYPE.ACCOUNT_OVERVIEW}" selected-version="" selected-table=""><cp-transcript-reports>`
          : null,
    });
});
