import angular from 'angular';
import { applicationProps } from 'entries/workflow-ui'
import './client-request-file.style.css';
import template from './client-request-file.template.html';
import * as fileHelper from "angular/common/helpers/file.helper.js";

angular
.module('app.clients.taxes')
.component('cpClientRequestFile', {
  bindings: {
    attachment: '=',
    downloadable: '=',
    deletable: '=',
    deleteAttachment: '&',
    clientRequest: '='
  },

  template,

  controllerAs: 'vm',

  controller: ['AttachmentsService', '$stateParams', '$scope',
    function(AttachmentsService, $stateParams, $scope) {
      const vm = this;

      vm.getAttachmentIcon = (fileName) => {
        return fileHelper.getFileIconPath(fileName);
      };

      vm.downloadAttachment = () => {
        const attachment = vm.attachment;

        SystemJS.import('docs-ui!sofe').then(m => {
          m.isFileSafeToOpen(attachment.file_id).then(({trust, virusStatuses, file}) => {
            if(trust){
              window.location = file.download_link
            }
            else {
              vm.container = document.getElementById(`file-${attachment.id}`)
              const parcel = applicationProps.mountParcel(m.VirusModalParcel, {
                virusFound: virusStatuses.infectedDocs.length > 0,
                domElement: vm.container,
                handleCloseModal() {
                  parcel.unmount()
                }
              })
            }
          })
        })
      }

      vm.deleteClicked = () => {
        vm.deleteAttachment({
          $attachmentId: vm.attachment.id
        });
      }


    }
  ],
});
