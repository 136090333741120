import angular from "angular";

angular.module('app')
  .directive('integer', [ '$timeout', function($timeout) {
    return {
      require: '?ngModel',
      link: function(scope, element, attrs, ngModelCtrl) {
        if (!ngModelCtrl) {
          return;
        }

        ngModelCtrl.$parsers.push(function(val) {
          if (val) {
            var clean = val.replace(/[^0-9]+/g, '');
            if (val !== clean) {
              ngModelCtrl.$setViewValue(clean);
              ngModelCtrl.$render();
            }
            return parseInt(clean, 10);
          } else {
            if (!ngModelCtrl.$validators.required) {
              $timeout(function() {
                scope.$apply(function() {
                  ngModelCtrl.$setValidity('parse', true);
                });
              }, 0, false);
            }
          }
        });

        element.bind('keypress', function(event) {
          if (event.keyCode === 32) {
            event.preventDefault();
          }
        });
      }
    };
  }]);
