import angular from "angular";
import dashboardTemplate from "./files-index-dashboard.template.html";
import "./files-index.controller.js";

angular.module('app.clients.files')
  .directive('filesIndex', [

    function filesIndex() {
      return {
        restrict: 'E',
        scope: {
          client: '=',
          state: '=',
          template: '@',
        }, // necessary so we don't overwrite parent scope
        template: dashboardTemplate,
        controller: 'FilesIndexController',
        controllerAs: 'vm',
        bindToController: true
      }
    }
  ]);
