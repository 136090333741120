import angular from 'angular';
import { asyncStacktrace } from 'auto-trace';

import 'angular/resources/client-request-description.resource.js';

angular.module('app.clients.taxes')
  .factory('ClientRequestDescriptionService', ['$q', 'ClientRequestDescriptionResource', '$stateParams',
    function ClientRequestDescriptionService($q, ClientRequestDescriptionResource, $stateParams) {

      return {

        deleteAttachment: (attachmentId) => {
          const deferred = $q.defer();

          if (!attachmentId)
            throw new Error('An attachment id must be provided');

          if (!$stateParams.clientId)
            throw new Error('Client id must be in the url');

          ClientRequestDescriptionResource
            .delete($stateParams.clientId, attachmentId)
            .then((response) => deferred.resolve(response.data.attachments))
            .catch(asyncStacktrace(response => {deferred.reject(response)} ));

          return deferred.promise;
        },

        getOneAttachment: (attachmentId) => {
          const deferred = $q.defer();

          if (!attachmentId)
            throw new Error('An attachment id must be provided');

          if (!$stateParams.clientId)
            throw new Error('Client id must be in the url');

          ClientRequestDescriptionResource
            .getAttachment($stateParams.clientId, attachmentId)
            .then((response) => deferred.resolve(response.data.attachments))
            .catch(asyncStacktrace(response => {deferred.reject(response)} ));

          return deferred.promise;
        },

        putRequest: (clientRequest) => {
          const deferred = $q.defer();

          if (!clientRequest)
            throw new Error('Client request must be provided');

          if (!$stateParams.clientId)
            throw new Error('Client id must be in the url');

          if (!$stateParams.engagementId)
            throw new Error('Engagement id must be in the url');

          if (!$stateParams.clientRequestId)
            throw new Error('Client request id must be in url');

          const body = {client_requests: clientRequest};

          ClientRequestDescriptionResource
            .put($stateParams.clientId, $stateParams.engagementId, $stateParams.clientRequestId, body)
            .then((response) => deferred.resolve(response.data.client_requests))
            .catch(asyncStacktrace(response => {deferred.reject(response)} ));

          return deferred.promise;
        }
      }
    }
  ]);
