import angular from "angular";
import "./truncated-copyable.style.css";
import template from "./truncated-copyable.template.html";

angular.module('app')
  .component('cpTruncatedCopyable', {
    bindings: {
      content: '@',
      href: '@?'
    },

    template,

    controllerAs: 'vm',

    controller: function TruncatedCopyable($scope, $timeout) {
      var vm = this;

      vm.copied = copied;

      function copied() {
        vm.justCopied = true;
        $timeout(() => {
          vm.justCopied = false;
        }, 1500);
      }
    }
  });
