import angular from 'angular';
import template from './active-notes-dashboard-list.template.html';
import { catchAsyncStacktrace } from 'auto-trace';
import './active-notes-dashboard-list.style.css';
import 'angular/app/client/general/notes/notes.service.js';

angular
  .module('app.clients.notes')
  .component('activeNotesDashboardList', {
    bindings: {
    },
    template,
    controllerAs: 'vm',
    controller(NotesService, $state, $sanitize) {
      const vm = this;
      vm.clientId = $state.params.clientId;
      vm.notes = [];
      vm.isLoaded = false;
      vm.noteShowingModal = null;
      vm.createNote = createNote;

      NotesService
        .getIndex({ clientId: vm.clientId })
        .then(notes => {
          vm.isLoaded = true;
          vm.notes = notes.map(note => {
            return {
              ...note,
              textOnlyBody: extractTextFromBody(note.body),
            };
          });
        })
        .catch(catchAsyncStacktrace())

      function extractTextFromBody(body) {
        const sanitized = $sanitize(body);
        const div = document.createElement("div");
        div.innerHTML = sanitized;
        return div.children.length > 0 ? div.children[0].textContent : div.textContent;
      }

      function createNote() {
        const clientId = $state.params.clientId;

        NotesService
          .createNote($state.params, { title: '', body: '', visible_to_client: false })
          .then(note => {
            $state.go('edit-note', { clientId, noteId: note.id });
          })
          .catch(catchAsyncStacktrace())
      }
    },
  });
