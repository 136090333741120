import angular from "angular";
import { asyncStacktrace } from 'auto-trace';
import "angular/resources/letter-templates.resource.js";

angular.module('app.clients.taxes')
  .factory('LetterTemplatesService', ['$q', 'LetterTemplatesResource',
    function LetterTemplatesService($q, LetterTemplatesResource) {
      return {
        getTemplates: (params) => {
          if (!params.template_type) {
            throw new Error(`Cannot get templates without a template_type`);
          }

          let deferred = $q.defer();

          LetterTemplatesResource.index(params)
            .then(function(response) {
              deferred.resolve(response.data[params.template_type]);
            })
            .catch(asyncStacktrace(response => {deferred.reject(response)} ));

          return deferred.promise;
        },

        getTemplate: (params) => {
          if (!params.template_id) {
            throw new Error(`Cannot get a template without a template_id`);
          }

          let deferred = $q.defer();

          LetterTemplatesResource.get(params)
            .then(function(response) {
              deferred.resolve(response.data);
            })
            .catch(asyncStacktrace(response => {deferred.reject(response)} ));

          return deferred.promise;
        },

        addTemplate: (params, template) => {
          let deferred = $q.defer();

          LetterTemplatesResource.post(params, template)
            .then(function(response) {
              deferred.resolve(response.data);
            })
            .catch(asyncStacktrace(response => {deferred.reject(response)} ));

          return deferred.promise;
        },

        updateTemplate: (params, template) => {
          if (!params.template_id) {
            throw new Error(`Cannot update a template without a template_id`);
          }

          let deferred = $q.defer();

          LetterTemplatesResource.put(params, template)
            .then(function(response) {
              deferred.resolve(response.data);
            })
            .catch(asyncStacktrace(response => {deferred.reject(response)} ));

          return deferred.promise;
        },

        deleteTemplate: (params) => {
          if (!params.template_id) {
            throw new Error(`Cannot delete a template without a template_id`);
          }

          let deferred = $q.defer();

          LetterTemplatesResource.delete(params)
            .then(function(response) {
              deferred.resolve(response.data);
            })
            .catch(asyncStacktrace(response => {deferred.reject(response)} ));

          return deferred.promise;
        },
      };
    }]
  )