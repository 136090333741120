import angular from 'angular';
import { catchAsyncStacktrace } from 'auto-trace';

angular
.module('app')
.directive('secondaryNav', [
  function SecondaryNavDirective() {
    return {
      compile: function(element, attrs) {
        element.addClass("cps-secondarynav");
        return {
          post(scope, element, attrs) {
            let isDestroyed = false, pnhDisposable;

            SystemJS
            .import('primary-navbar!sofe')
            .then(primaryNavbar => {
              if (isDestroyed)
                return;

              pnhDisposable = primaryNavbar
                .primaryNavHeightObs
                .subscribe(pnHeight =>  {
                  if (!isDestroyed) {
                    const topnavSecondaryHeight = attrs.hasTopnavSecondary === 'true' ? 56 : 0;
                    element[0].style.top = String(pnHeight + topnavSecondaryHeight) + "px";
                  }
                });

            })
            .catch(catchAsyncStacktrace());

            scope.$on('$destroy', () => {
              isDestroyed = true;
              if (pnhDisposable) {
                pnhDisposable.unsubscribe();
              }
            });
          }
        }
      },
    }
  }
]);
