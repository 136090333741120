import angular from 'angular';

import { navigateToLetters } from "./letters.helper.js";

import './letter-card.style.css';
import template from './letter-card.template.html';

angular.module('app.clients.taxes')
.component('cpLetterCard', {
  bindings: {
    letterDetails: '=',
    engagement: '<',
  },

  controllerAs: 'vm',

  controller: function ($stateParams, $scope) {
    const vm = this;

    vm.$onInit = () => {
      vm.clientId = $stateParams.clientId;
    };

    vm.deleteLetter = (letterDetails) => {
      $scope.$emit('cp:delete-letter', letterDetails);
    }

    vm.navigateToLetters = navigateToLetters;
  },

  template,
});