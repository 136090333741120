import angular from "angular";
import { isString } from "lodash";
import { formatPhoneNumber } from "angular/common/helpers/grammar.helpers.js";

angular.module('app')
  .filter('cpPhoneNumber', function() {
    return (value, USonly) => {
      if(value && isString(value)) {
        return formatPhoneNumber(value, USonly);
      }
      return value;
    }
  });
