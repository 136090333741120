import angular from "angular";

angular.module('app.admin')
.factory('ServiceTemplatesResource', ['$http',
  function ServiceTemplatesResource($http) {
    return {
      getList: (params) => $http.get(`/api/tenants/${params.tenantId}/servicetemplates`),

      post: (params, service_template) => $http.post(`/api/tenants/${params.tenantId}/servicetemplates`, {"service_templates": service_template}),

      get: (params, service_template) =>  $http.get(`/api/tenants/${params.tenantId}/servicetemplates/${service_template.id}`),

      put: (params, service_template) => $http.put(`/api/tenants/${params.tenantId}/servicetemplates/${service_template.id}`, {"service_templates": service_template}),

      delete: (params, service_template) => $http.delete(`/api/tenants/${params.tenantId}/servicetemplates/${service_template.id}`),

      // template steps
      postStep: (params, serviceTemplate, step) => $http.post(`/api/tenants/${params.tenantId}/servicetemplates/${serviceTemplate.id}/steps`, {"service_template_steps" : step}),

      getStep: (params, serviceTemplate, step) => $http.get(`/api/tenants/${params.tenantId}/servicetemplates/${serviceTemplate.id}/steps/${step.id}`),

      putStep: (params, serviceTemplate, step) => $http.put(`/api/tenants/${params.tenantId}/servicetemplates/${serviceTemplate.id}/steps/${step.id}`, {"service_template_steps" : step}),

      deleteStep: (params, serviceTemplate, step) => $http.delete(`/api/tenants/${params.tenantId}/servicetemplates/${serviceTemplate.id}/steps/${step.id}`),
    };
  }
]);
