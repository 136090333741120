import angular from 'angular';
import template from './archived-notes-single-note.template.html';
import './archived-notes-single-note.style.css';

import { catchAsyncStacktrace } from 'auto-trace';
import moment from 'moment';

angular
.module('app.clients.notes')
.component('archivedNotesSingleNote', {
  bindings: {
    note: '=',
    expanded: '=',
  },
  template,
  controllerAs: 'vm',
  controller(NotesService, $stateParams) {
    const vm = this;
    const clientId = $stateParams.clientId;
    const updated_time_stamp = moment(vm.note.updated_at);
    const archived_time_stamp = moment(vm.note.archived_at);
    vm.updatedAtDay = updated_time_stamp.format('ll'); // Sep 30, 2016
    vm.updatedAtTime = updated_time_stamp.format('h:mmA'); // 4:57PM
    vm.archivedAtDay = archived_time_stamp.format('l');

    vm.archivedAtText = 'Archived by ' + vm.note.archived_by + ' on ' + vm.archivedAtDay;

    vm.makeActive = note => {
      note.is_archived = false;
      note.archived_at = null;

      NotesService
        .saveNote({clientId: clientId, noteId: note.id}, note)
        .then(note => vm.note = note)
        .catch(catchAsyncStacktrace());
    }

    vm.deleteNote = note => {
      note.is_deleted = true;
      NotesService
        .deleteNote({clientId: clientId, noteId: note.id})
        .catch(catchAsyncStacktrace());
    }
  }
});
