import angular from "angular";

import 'angular/app/client/taxes/section-interactions/assistant.style.css';
import 'angular/app/client/taxes/section-interactions/assistant-shared.style.css';

angular.module('app.clients.taxes').component('cpAssistantQuestionCommunityPropertyState', {
  bindings: {},

  controllerAs: 'vm',

  controller: function () {
    const vm = this;
    vm;
  },

  template: ($element, $attrs) => {
    const TOOLTIP = `Arizona, California, Idaho, Louisiana, Nevada, New Mexico, Texas, Washington, and Wisconsin`;

    return `<strong>Did the client live in a <cps-tooltip cp-custom-element html="'${TOOLTIP}'"><a>community property state</a></cps-tooltip>?</strong>`;
  }
});
