angular.module('cp-horizontal-barchart', [])
  .directive('cpHorizontalBarchart', ['$timeout', function($timeout) {
    return {
      restrict: 'E',
      scope: {
        title: '@',
        minimal: '@',
        percent: '='
      },
      template: '\
      <div ng-style="containerStyle">\
        <div style="float: left;">{{title}}</div>\
        <div style="float: right;">{{percent | number}}%</div>\
        <div ng-style="outerBarStyle">\
          <div ng-style="innerBarStyle"></div>\
        </div>\
      </div>\
      ',
      link: function(scope, el, attr) {
        scope.containerStyle = {};
        scope.outerBarStyle = {};
        scope.innerBarStyle = {};

        scope.delayPercent = scope.delayPercent || 0;
        scope.$watch('percent', function(val) {

          scope.containerStyle = {
            height: "28px",
            'font-weight': scope.minimal ? 'normal' : 'bold',
            position: 'relative'
          };

          scope.outerBarStyle = {
            height: scope.minimal ? '2px': '4px',
            'background-color': '#dfdfdf',
            position: 'absolute',
            bottom: 0,
            width: '100%'
          };

          scope.innerBarStyle = {
            'background-color': 'var(--cps-color-primary);',
            transition: '1s ease-in width',
            height: '100%',
            width: '0'
          };

          if(!val) {
            return scope.delayPercent = 0;
          }

          $timeout(function() {
            scope.innerBarStyle.width = val + '%';
          },100);
        });
      }
    }
  }]);
