import angular from "angular";

import toasts from "toast-service!sofe";

angular.module('app.clients.taxes')
  .controller('EngagementLockController', ['$stateParams', 'EngagementService', '$filter',
    function($stateParams, EngagementService, $filter) {
      var vm = this;
      vm.loadState = 'loaded';
      vm.alerts = {};
      vm.step = 1;

      vm.lock = function(engagement) {
        EngagementService.updateEngagement($stateParams, {
          is_archived: true
        }).then(function(resp) {
          vm.engagement.is_archived = true;
          toasts.successToast("Engagement successfully locked");
        });
      }

      vm.unlock = function(engagement) {
        EngagementService.updateEngagement({
          engagementId: engagement.id,
          clientId: $stateParams.clientId
        }, {
          is_archived: false,
          is_deleted: false
        }).then(function(resp) {
          vm.engagement.is_archived = false;
          vm.engagement.is_deleted = false;
          toasts.successToast("Engagement successfully unlocked");
        });
      }
    }
  ]);
