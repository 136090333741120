import angular from "angular";

import 'angular/app/client/taxes/section-interactions/assistant.style.css';
import 'angular/app/client/taxes/section-interactions/assistant-shared.style.css';

import * as constants from 'angular/app/client/taxes/programs/innocent-spouse/assistant.constants.js';

import template from './multi-select.template.html';

angular.module('app.clients.taxes').component('cpAssistantQuestionMultiSelect', {
  bindings: {
    answer: '='
  },

  controllerAs: 'vm',

  controller: function (AssistantAnswersService, $scope) {
    const vm = this;
    vm.modalUnderstated = false;

    vm.selectOption = (answer) => {
      $scope.$emit('cp:assistant-answer:selected', constants.MULTI_SELECT, answer);
    };
  },

  template: template
});
