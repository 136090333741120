import angular from "angular";

angular.module('app.routes')
  .config(function($stateProvider) {
    $stateProvider
      .state('tenant', {
        parent: 'app',
        abstract: true,
        url: '/dashboard',
        template: '<ui-view></ui-view>'
      })

      .state('tenant.dashboard.engagements', {
        url: '/engagements',
        template: '<cp-engagements-dashboard/>'
      })
  });
