import angular from 'angular';
import { applicationProps } from 'entries/workflow-ui'
import { union, isString, remove, get, forEach } from 'lodash';
import {hasAccess} from 'cp-client-auth!sofe'
import Dropzone from 'dropzone';

import toasts from 'toast-service!sofe';
import canopyUrls from 'canopy-urls!sofe';
import { truncateFilename } from 'docs-ui!sofe';
import { asyncStacktrace, catchAsyncStacktrace } from 'auto-trace';

import 'angular/app/admin/service-templates/service-templates.service.js';
import context from 'angular/bootstrap/context.service.js';
import 'angular/common/services/attachments.service.js';
import * as fileHelper from 'angular/common/helpers/file.helper.js';

import './service-templates.shared.styles.css';
import template from './service-templates-template.template.html';

angular.module('app.admin').component('serviceTemplatesTemplate', {
  bindings: {},

  controllerAs: 'vm',

  controller: function (
    $stateParams,
    $state,
    ServiceTemplatesService,
    AttachmentsService,
    DocsResource,
    AttachmentsResource,
    $scope,
    $timeout,
    $location,
    $anchorScroll
  ) {
    if (!$stateParams.templateId) {
      throw new Error(`A service template cannot exist without an ID.`);
    }

    var vm = this;

    vm.context = context.getContext();
    vm.loggedInUser = vm.context.loggedInUser;

    vm.filesPermissions = hasAccess(vm.loggedInUser)('files_upload_move');

    vm.cancelEdit = cancelEdit;
    vm.deleteStep = deleteStep;
    vm.launchEditStep = launchEditStep;
    vm.launchAddStepModal = launchAddStepModal;
    vm.launchAddStepModalAndRender = launchAddStepModalAndRender
    vm.saveStep = saveStep;
    vm.stepMoved = stepMoved;
    vm.fetchAttachments = fetchAttachments;
    vm.downloadAttachment = downloadAttachment;
    vm.initDropZone = initDropZone;
    vm.deleteAttachment = deleteAttachment;
    vm.documentBody = document.body;
    vm.containers = {}

    vm.$onInit = () => {
      // Get the service template and its associated steps

      vm.isLoaded = false;
      ServiceTemplatesService.getServiceTemplate({ tenantId: vm.context.tenant.id }, { id: $stateParams.templateId })
        .then(service => {
          vm.service = service;
          vm.steps = service.template_steps || [];
          vm.isLoaded = true;
        })
        .catch(response => {
          throw new Error('Failed to retrieve the service template.');
        });
    };

    function launchAddStepModal() {
      vm.indexOfStepBeingEdited = null;
      vm.stepModel = Object.assign({}, vm.getEmptyStepTemplate());
      vm.addStepMode = true;
    }

    function launchAddStepModalAndRender(){
      launchAddStepModal();
      $scope.$apply();
    }

    function launchEditStep(index, step) {
      vm.indexOfStepBeingEdited = index;
      vm.idOfStepBeingEdited = step.id;

      vm.stepModel = Object.assign({}, vm.getEmptyStepTemplate(), step);

      vm.fetchAttachments(vm.stepModel);

      $timeout(vm.initDropZone, 0, true, vm.stepModel);

      $anchorScroll.yOffset = 144 + 50;
      $location.hash('edit' + index);
    }

    function saveStep(step) {
      vm.submittingStep = true;

      let stepToSubmit = Object.assign({}, vm.getEmptyStepTemplate(), step);

      delete stepToSubmit.attachments;

      if (stepToSubmit.id) {
        ServiceTemplatesService.updateServiceTemplateStep(
          { tenantId: vm.context.tenant.id },
          vm.service,
          stepToSubmit
        ).then(step_response => {
          vm.steps.splice(vm.steps.findIndex(element => element.id === step_response.id), 1, step_response);
        });
      } else {
        ServiceTemplatesService.addServiceTemplateStep(
          { tenantId: vm.context.tenant.id },
          vm.service,
          stepToSubmit
        ).then(step_response => {
          vm.steps.push(step_response);
          vm.addStepMode = false;
        });
      }

      vm.service.template_steps = vm.steps;

      vm.indexOfStepBeingEdited = null;
    }

    function cancelEdit() {
      vm.indexOfStepBeingEdited = null;
      Dropzone.forElement(`#filesForStep-${vm.idOfStepBeingEdited}`).destroy();
    }

    function deleteStep(step) {
      remove(vm.steps, s => {
        return s.id === step.id;
      })[0];

      confirmDeleteStep(step);

      vm.indexOfStepBeingEdited = null;
    }

    function confirmDeleteStep(step) {
      let timeout = setTimeout(() => {
        ServiceTemplatesService.deleteServiceTemplateStep({ tenantId: vm.context.tenant.id }, vm.service, step).then(
          () => { }
        );
      }, 5000);

      toasts.successToast(
        'Step deleted successfully',
        'Undo',
        () => {
          clearTimeout(timeout);
          $timeout(
            () => {
              $scope.$apply(() => {
                vm.steps = union(vm.steps, [step]);
                toasts.successToast('Step restored successfully');
              });
            },
            0,
            false
          );
        },
        5000
      );
    }

    function stepMoved(step, index) {
      vm.steps.splice(index, 1);

      ServiceTemplatesService.updateServiceTemplate({ tenantId: vm.context.tenant.id }, vm.service).then(() => { });
    }

    vm.getEmptyStepTemplate = () => ({
      name: '',
      description: '',
      is_removable: true,
    });

    //==========================================================================
    // Service Manipulation

    vm.launchAddEditServiceModal = serviceModel => {
      vm.editing = Object.assign({}, serviceModel);
      vm.showAddEditServiceModal = true;
      vm.submittingService = false;
    };

    vm.cancelEditService = () => {
      vm.showAddEditServiceModal = false;
      vm.submittingService = false;
    };

    vm.launchDeleteServiceModal = serviceModel => {
      vm.deleting = serviceModel;
      vm.showDeleteServiceModal = true;
    };

    vm.saveService = serviceModel => {
      vm.submittingService = true;

      let serviceToSubmit = Object.assign({}, vm.getEmptyServiceTemplate(), serviceModel);

      if (serviceToSubmit.id) {
        ServiceTemplatesService.updateServiceTemplate({ tenantId: vm.context.tenant.id }, serviceToSubmit)
          .then(service => {
            vm.service = serviceToSubmit;
            vm.showAddEditServiceModal = false;
            $state.go('admin.service-templates.template', {
              templateId: service.id,
              templateName: serviceToSubmit.name,
            });
          })
          .catch(
            asyncStacktrace(err => {
              vm.showAddEditServiceModal = false;
              toasts.warningToast('Unable to save changes');
              setTimeout(() => {
                throw err;
              });
            })
          );
      }
    };

    vm.deleteService = serviceModel => {
      ServiceTemplatesService.deleteServiceTemplate({ tenantId: vm.context.tenant.id }, serviceModel).then(() => {
        vm.showDeleteServiceModal = false;
        $state.go('admin.service-templates.index');
      });
    };

    vm.getEmptyServiceTemplate = () => ({
      name: '',
      description: '',
    });

    //==========================================================================

    function initDropZone(step) {
      Dropzone.autoDiscover = false;

      const dropzoneEl = document.querySelector(`#filesForStep-${step.id}`);

      if (!dropzoneEl) {
        //the route has changed already but we're still trying to init drop zone
        return;
      }

      if (dropzoneEl.dropzone) {
        //when the step changes but not the UI Router "state", the dropzone element from the old step already has dropzone attached
        delete dropzoneEl.dropzone;
      }

      DocsResource.getS3AttachmentTargetEndpoint('TENANT').then(response => {
        const fields = get(response, 'data.s3.fields', {})
        const s3URL = get(response, 'data.s3.url', '')
        const s3Prefix = fields.key.replace('${filename}','');

        let dropzoneOptions = {
          url: s3URL,
          withCredentials: false,
          uploadMultiple: false,
          parallelUploads: 1,
          maxFilesize: 250,
          addRemoveLinks: false,
          dictDefaultMessage: '',
          dictFallbackText: null,
          dictFileTooBig: 'This file exceeds the size limit of {{maxFilesize}}MB',
          clickable: vm.filesPermissions ? `#uploadForStep-${step.id}` : null,
          previewTemplate: `
            <div class="cps-slat +noclick cp-service-templates__dropzone__preview">
              <div class="cp-service-templates__dropzone__preview__progress"></div>
              <div class="cps-slat__badge">
                <img src="https://cdn.canopytax.com/static/workflow-ui/file_icons/na_icon.png" style="width: 3rem; padding-left: 1rem;" />
              </div>
              <div class="cps-slat__content">
                <div class="cps-slat__content__title cp-service-templates__filename" data-dz-name></div>
                <span class="cp-service-templates__filesize" data-dz-size></span>
              </div>
              <div class="cps-slat__actions">
                <div class="dz-success-mark"><i class="cps-icon cps-icon-check cps-primary-green"></i></div>
              </div>
              <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
              <div class="dz-error-message"><span data-dz-errormessage></span></div>
            </div>
          `,
          headers: {'Cache-Control': ''}
        };

        let dropzone = new Dropzone(`#filesForStep-${step.id}`, dropzoneOptions);

        dropzone.on('addedfile', () => {
          vm.inQueue++;
          try {
            $scope.$apply();
          } catch (ex) {
            /* digest already in progress */
          }
        });

        dropzone.on('sending', (file, xhr, formData) => {
          file.previewElement.querySelector('.cp-service-templates__dropzone__preview__progress').style.width = '2%';

          forEach(fields, (value, key) => {
            formData.append(key, value);
          })

          formData.set('key', s3Prefix + `${get(file, 'upload.uuid')}` + truncateFilename(file.name))
        });

        dropzone.on('uploadprogress', (file, progress, bytesSent) => {
          file.previewElement.querySelector(
            '.cp-service-templates__dropzone__preview__progress'
          ).style.width = `${Math.floor(progress)}%`;
        });

        dropzone.on('complete', file => {
          if (file.status !== 'error') {
            dropzone.removeFile(file);
            vm.inQueue--;
          }

          const path_to_s3 = s3Prefix + `${get(file, 'upload.uuid')}` + truncateFilename(file.name)

          DocsResource.reportFileUploaded('TENANT', {
            description: '',
            filesize: file.size,
            hidden: false,
            inbox: false,
            is_visible: false,
            mimetype: file.type,
            name: truncateFilename(file.name),
            path_to_s3: path_to_s3,
          })
            .then(resp =>
              AttachmentsResource.post(
                {
                  targetPath: AttachmentsResource.targetPathEnum.TENANTS,
                  targetId: vm.context.tenant.id,
                  pivotId: step.id,
                  pivotType: 'service_template_steps',
                },
                {
                  file: {
                    ...resp.data.file,
                    path_to_s3,
                  },
                }
              )
            )
            .then(resp => {
              vm.stepModel.attachments.push(resp.data.attachments[0]);
            })
            .catch(catchAsyncStacktrace());
        });

        dropzone.on('error', (file, message) => {
          var node, _i, _len, _ref, _results;

          file.previewElement.classList.add('dz-error');

          if (file.xhr) {
            message = 'Error uploading file';
          }

          if (!isString(message) && message.error) {
            message = message.error;
          }

          _ref = file.previewElement.querySelectorAll('[data-dz-errormessage]');
          _results = [];

          for (_i = 0, _len = _ref.length; _i < _len; _i++) {
            node = _ref[_i];
            _results.push((node.textContent = message));
          }

          return _results;
        });
      })
    }

    // File / Attachment operations

    function fetchAttachments(step) {
      AttachmentsService.getListForTenant({
        tenant_id: vm.context.tenant.id,
        pivot_type: 'service_template_steps',
        pivot_id: step.id,
      }).then(attachments => {
        step.attachments = attachments;
      });
    }

    vm.getAttachmentIcon = fileName => {
      return fileHelper.getFileIconPath(fileName);
    };

    function deleteAttachment(attachment) {
      remove(vm.stepModel.attachments, a => {
        return a.id === attachment.id;
      })[0];

      confirmDeleteAttachment(vm.stepModel, attachment);
    }

    function confirmDeleteAttachment(step, attachment) {
      let timeout = setTimeout(() => {
        AttachmentsService.deleteForTenant({
          tenant_id: vm.context.tenant.id,
          attachmentId: attachment.id,
        }).then(() => { });
      }, 5000);

      toasts.successToast(
        'You have deleted a workpaper. Click undo if you did not mean to delete this workpaper.',
        'Undo',
        () => {
          clearTimeout(timeout);
          $timeout(
            () => {
              $scope.$apply(() => {
                step.attachments = union(step.attachments, [attachment]);
                toasts.successToast('Workpaper restored successfully');
              });
            },
            0,
            false
          );
        },
        5000
      );
    }

    function downloadAttachment(attachment) {
      SystemJS.import('docs-ui!sofe').then(m => {
        m.isFileSafeToOpen(attachment.file_id).then(({trust, virusStatuses, file}) => {
          if(trust){
            window.location.href = file.download_link;
          }
          else {
            vm.containers[attachment.id] = document.getElementById(`file-${attachment.id}`)
            const parcel = applicationProps.mountParcel(m.VirusModalParcel, {
              virusFound: virusStatuses.infectedDocs.length > 0,
              domElement: vm.containers[attachment.id],
              handleCloseModal() {
                parcel.unmount()
              }
            })
          }
        })
      })
    }
  },

  template,
});
