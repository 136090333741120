import angular from "angular";
import moment from "moment";

angular.module("app.helpers").filter("cpTodayOrTomorrow", function() {
  return function(date) {
    if (moment().isSame(date, "day")) {
      return "Today";
    } else if (
      moment()
        .add(1, "days")
        .isSame(date, "day")
    ) {
      return "Tomorrow";
    } else {
      return date;
    }
  };
});
