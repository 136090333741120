import angular from "angular";

import * as outcomesHelper from 'angular/app/client/taxes/programs/penalty-abatements/assistant-outcomes.helper.js';
import 'angular/app/client/taxes/section-interactions/outcomes.style.css';

import template from './reasonable-cause.template.html';

angular.module('app.clients.taxes').component('cpOutcomesReasonableCause', {
  bindings: {
    outcomes: '='
  },

  controllerAs: 'vm',

  controller: function ($stateParams) {
    const vm = this;

    vm.firstTimeAbatementAvailable = () => outcomesHelper.firstTimeAbatementAvailable(vm.outcomes);
    vm.reasonableCauseAvailable = () => outcomesHelper.reasonableCauseAvailable(vm.outcomes);
    vm.reliefUnlikely = () => outcomesHelper.reliefUnlikely(vm.outcomes);
    vm.nonAssessment = () => outcomesHelper.nonAssessment(vm.outcomes);
    vm.cantCalculateOutcomes = () => outcomesHelper.cantCalculateOutcomes(vm.outcomes);

    vm.summaryRCExpanded = vm.summaryFTAExpanded = vm.bestPracticeFTAExpanded = false;

    vm.clientId = $stateParams.clientId;
    vm.engagementId = $stateParams.engagementId;
  },

  template: template
});
