import angular from "angular";

angular.module('app')
  .factory('SourcesResource', ['$http',
    function SourcesResource($http) {

      return {
        get: (sourceId, params={}) => $http.get(`/api/contact_sources/${sourceId}`, {params: params}),

        post: (payload, params={}) => $http.post(`/api/contact_sources`, payload, {params: params}),

        put: (sourceId, payload, params={}) => $http.put(`/api/contact_sources/${sourceId}`, payload, {params: params}),

        index: (params={}) => $http.get(`/api/contact_sources`, {params: params}),

        'patchIndex': (payload, params={}) => $http.patch(`/api/contact_sources`, payload, {params: params}),

        'delete': (sourceId, params={}) => $http.delete(`/api/contact_sources/${sourceId}`, {params: params}),

        'deleteContactSources': (contactId, sourceIds, params={}) => $http.delete(`/api/contacts/${contactId}/contact_sources/${sourceIds}`, {params: params}),

        'deleteSourceContacts': (sourceId, contactIds, params={}) => $http.delete(`/api/contact_sources/${sourceId}/contacts/${contactIds}`, {params: params}),
      };

    }
  ]);
