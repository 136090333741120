import angular from 'angular';
import _ from 'lodash';

import './client-request-comments-list.style.css';
import template from './client-request-comments-list.template.html';
import 'angular/common/directives/comments/comments-list.component.js';
import './client-request-file.component.js';

import context from 'angular/bootstrap/context.service.js';

angular
.module('app.clients.taxes')
.component('cpClientRequestCommentsList', {
  bindings: {
    comments: '=',
    clientRequestId: '=',
    attachments: '=',
    isEditable: '='
  },

  template,

  controllerAs: 'vm',

  controller: ['$scope',
    function($scope) {
      const vm = this;
      vm.loggedInUserId = context.getContext().loggedInUser.id;

      vm.attachments = [];
      vm.commentsWithAttachments = [];

      $scope.$watch('vm.attachments', recombineCommentsAttachments);
      $scope.$watch('vm.comments', recombineCommentsAttachments);
      $scope.$on('cp:client-requests:comments-changed', recombineCommentsAttachments);

      function recombineCommentsAttachments() {
        vm.commentsWithAttachments = vm.comments.map((comment) => ({
          comment,
          attachments: vm.attachments.filter((attachment) => comment.id.trim() === attachment.relationships.ancestor.id)
        }));
      }
    }
  ],
});
