import angular from 'angular';
import template from './active-notes-pinned.template.html';
import './active-notes-pinned.style.css';

angular
.module('app.clients.notes')
.component('activeNotesPinned', {
  bindings: {
    notes: '=',
    expanded: '=',
  },
  template,
  controllerAs: 'vm',
  controller() {
    const vm = this;
    vm.pinnedExist = () => (vm.notes || []).some(note => !!note.pinned_at && !note.archived_at);
  }

});
