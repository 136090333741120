import angular from "angular";
import moment from "moment-timezone";

/**
 *
 * A filter for getting a human readable time relative to the current time
 * Takes a date object or a time stamp
 *
 */
angular.module('app.helpers')
  .filter('cpTimeAgo', function() {
    return function(date) {
      if(window.loggedInUser.time_zone) {
        return moment.utc(date).tz(window.loggedInUser.time_zone).fromNow();
      } else {
        return moment.utc(date).fromNow();
      }
    }
  });
