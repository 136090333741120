import angular from "angular";
import { catchAsyncStacktrace } from 'auto-trace';

import 'angular/app/client/taxes/section-interactions/common-actions/change-status.directive.js';
import 'angular/app/client/taxes/section-interactions/common-actions/assign-section-envelope.component.js';
import 'angular/app/client/taxes/section-interactions/common-actions/due-date.directive.js';

import 'angular/app/client/taxes/programs/assistant/assistant-answers.service.js';

import "angular/app/client/taxes/section-interactions/assistant-shared.style.css";
import "angular/app/client/taxes/section-interactions/outcomes.style.css";

import './outcomes/community-property.component.js';
import './outcomes/equitable-relief.component.js';
import './outcomes/innocent-spouse.component.js';
import './outcomes/separation-of-liability.component.js';

import * as outcomesHelper from './assistant-outcomes.helper.js';
import * as assistantConstants from './assistant.constants.js';

import template from './assistant-outcomes.template.html';

angular.module('app.clients.taxes').component('cpOutcomesInnocentSpouse', {
  bindings: {},

  controllerAs: 'vm',

  controller: function ($scope, $stateParams, AssistantAnswersService) {
    const vm = this;

    vm.assistantConstants = assistantConstants;

    // when vm.outcomes === null, it means there are definitely no outcomes. undefined means we aren't sure yet
    vm.outcomes = undefined;

    let init = () => {
      vm.innocentSpouseAvailable = () => outcomesHelper.innocentSpouseAvailable(vm.outcomes);
      vm.separationOfLiabilityAvailable = () => outcomesHelper.separationOfLiabilityAvailable(vm.outcomes);
      vm.communityPropertyAvailable = () => outcomesHelper.communityPropertyAvailable(vm.outcomes);
      vm.equitableReliefAvailable = () => outcomesHelper.equitableReliefAvailable(vm.outcomes);

      vm.reliefUnlikely = () => outcomesHelper.reliefUnlikely(vm.outcomes);
      vm.cantCalculateOutcomes = () => outcomesHelper.cantCalculateOutcomes(vm.outcomes);

      AssistantAnswersService.getOutcomes()
        .then((outcomes) => {
          vm.outcomes = outcomes;

          vm.resolutionType = assistantConstants.INNOCENT_SPOUSE;

          if (vm.equitableReliefAvailable()) {
            vm.resolutionType = assistantConstants.EQUITABLE_RELIEF;
          }
          else if (vm.communityPropertyAvailable()) {
            vm.resolutionType = assistantConstants.COMMUNITY_PROPERTY;
          }
          else if (vm.separationOfLiabilityAvailable()) {
            vm.resolutionType = assistantConstants.SEPARATION_OF_LIABILITY;
          }
          else if (vm.innocentSpouseAvailable()) {
            vm.resolutionType = assistantConstants.INNOCENT_SPOUSE;
          }
        })
        .catch(catchAsyncStacktrace());
    };

    init();
  },

  template: template
});
