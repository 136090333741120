import angular from "angular";

angular.module('app')
  .factory('CommentsResource', ['$http',
    function CommentsResource($http) {

      return {
        getList: (params) => $http.get(`/api/clients/${params.clientId}/comments/?pivot_type=${params.pivotType}&pivot_id=${params.pivotId}`),

        post: (params, comment, notification) => $http.post(`/api/clients/${params.clientId}/comments/?pivot_type=${params.pivotType}&pivot_id=${params.pivotId}`, {"comments": comment, "notifications": notification}),

        get: (params) => $http.get(`/api/clients/${params.clientId}/comments/${params.commentId}`),

        put: (params, comment) => $http.put(`/api/clients/${params.clientId}/comments/${params.commentId}`, {"comments": comment}),

        delete: (params) => $http.delete(`/api/clients/${params.clientId}/comments/${params.commentId}`),
        createRequestToolComment: (clientRequestID, body) => $http.post(`/api/request-tools/${clientRequestID}/comments`, body),
      };
    }
  ]);
