import angular from 'angular';
import template from './right-side-pane.template.html';
import './right-side-pane.style.css';

angular.module('app.clients.taxes')
.component('cpRightSidePane', {
  bindings: {
    fixedFooterHeight: '@',
  },

  template,

  transclude: {
    header: 'sidePaneHeader',
    content: 'sidePaneContent',
    footer: 'sidePaneFooter',
  },

  controllerAs: 'vm',

  controller: ['$timeout', '$scope',
    function ($timeout, $scope) {
      const vm = this;

      vm.fixedFooterHeight = vm.fixedFooterHeight || '6.5rem';

      vm.sidePaneRight = '-34.4rem';
      $timeout(() => vm.sidePaneRight = '0');

      $scope.$on('cp:right-side-pane:do-hide', () => vm.hideSidePane());

      vm.hideSidePane = () => {
        vm.sidePaneRight = '-34.4rem';
        moveIntercomBack();
        $scope.$emit('cp:right-side-pane:is-hiding');
        $timeout(() => {
          $scope.$emit('cp:right-side-pane:was-hidden');
        }, 500);
      }

      $scope.$on('$destroy', () => {
        moveIntercomBack();
      });

      $timeout(moveIntercomOutOfTheWay);

      let intercomElement, intercomNotificationsElement;
      function getIntercomElement() {
        if (!intercomElement) {
          intercomElement = document.querySelector('#intercom-container .intercom-launcher-frame');
        }
        return intercomElement;
      }

      function getIntercomNotificationsElement() {
        if (!intercomNotificationsElement) {
          intercomNotificationsElement = document.querySelector('#intercom-container .intercom-launcher-badge-frame');
        }
        return intercomNotificationsElement;
      }

      function moveIntercomOutOfTheWay(attempt = 1) {
        if (getIntercomElement()) {
          getIntercomElement().oldRightPosition = getIntercomElement().style.right;
          getIntercomElement().style.setProperty('transition', '.5s', 'important');
          getIntercomElement().style.setProperty('right', '330px', 'important');

          if (getIntercomNotificationsElement()) {
            getIntercomNotificationsElement().oldRightPosition = getIntercomNotificationsElement().style.right;
            getIntercomNotificationsElement().style.setProperty('transition', '.5s', 'important');
            getIntercomNotificationsElement().style.setProperty('right', '330px', 'important');
          }
        } else if (attempt < 20) {
          setTimeout(() => moveIntercomOutOfTheWay(attempt + 1), 300);
        }
      }

      function moveIntercomBack() {
        if (getIntercomElement()) {
          const originalRightPosition = getIntercomElement().oldRightPosition || `10px`;
          getIntercomElement().style.setProperty('right', originalRightPosition, 'important');

          if (getIntercomNotificationsElement()) {
            const originalRightNotificationsPosition = getIntercomNotificationsElement().oldRightPosition || `12px`;
            getIntercomNotificationsElement().style.setProperty('right', originalRightNotificationsPosition, 'important');
          }
        }
      }
    }
  ],
});
