import angular from "angular";
import _ from 'lodash';

import template from "./client-select.directive.html";

angular.module('app')
  .directive('cpClientSelect', ['ContactResource',
    function clientSearch(ContactResource) {

      return {
        restrict: 'E',
        scope: {
          selectedClient: '=',
          selectedClientId: '=?',
          selectedClientData: '=?',
          options: '=?'
        },

        template: template,

        /**
         * Compiling is necessary so that we can pass input attributes from the parent
         * directive element down to the rendered input inside.
         */
        compile: function(tElem, tAttrs) {
          if(_.has(tAttrs, 'name')) {
            tElem.find('input').attr('name', tAttrs.name);
          }

          if(_.has(tAttrs, 'required')) {
            tElem.find('input').attr('required', true);
          }

          return {
            post: function(scope, el, attrs) {

              attrs.$observe('disabled', function (value) {
                if (_.has(attrs, 'ngDisabled')) {
                  scope.disabled = value;
                } else {
                  scope.disabled = _.has(attrs, 'disabled');
                }
              });

              scope.$watch('selectedClient', function(client) {
                if (client) {
                  scope._selectedClient = client;
                }
              });

              scope.getClients = function(viewValue) {
                var request = _.extend({
                  searchByName: viewValue
                }, scope.options);

                var results = ContactResource.index(request)
                  .then(function success(response) {

                    if(response.data.clients.length === 0) scope.noResults = true;
                    else scope.noResults = false;

                    return response.data.clients;
                  });

                return results;
              };

              scope.onSelect = function($item, $model, $label) {
                scope.selectedClient = $label;
                scope.selectedClientId = $item.id;
                scope.selectedClientData = $item;
              }


              scope.testValue = function() {
                scope.noResults = false;

                if (scope._selectedClient !== scope.selectedClient) {
                  scope._selectedClient = null;
                  scope.selectedClient = null;
                  scope.selectedClientId = null;
                  scope.selectedClientData = null;
                }
              }

              scope.noResults = false;

            }
          }
        },
      }


    }
  ]);
