/**
 * bsPersistedStorage
 *
 * A key value storage which will persist data in the client on browser reload.
 * Session storage is persistent only with the session whereas local storage
 * persists accross sessions.
 */

var map = {};

var storageSupported = isLocalStorageNameSupported();

/**
 * If the browser does not support local storage, this function will return a mock in memory storage.
 */
function getMockStorage() {
  var storage = {};
  return {
    getItem: function(key) {
      return storage[key];
    },
    setItem: function(key, value) {
      storage[key] = value;
    },
    removeItem: function(key) {
      delete storage[key];
    }
  }
}

/**
 * Safari's private browsing mode does not support localStorage. Check if it is available here
 */
function isLocalStorageNameSupported() {
  var testKey = 'test';
  var storage = window.sessionStorage;
  try {
    storage.setItem(testKey, '1');
    storage.removeItem(testKey);
    return true;
  } catch (error) {
    return false;
  }
}

/**
 * Get the storage mechanism, local or session storage, or an in memory implementation.
 */
function getStorage(options) {
  if(options.localStorage) {
    return storageSupported ? localStorage : getMockStorage();
  } else {
    return storageSupported ? sessionStorage : getMockStorage();
  }
}

var service = {
  /**
   * Save an item to persisted storage. By default all data will be saved into session not local storage.
   * Override this by calling bsPersistedStorage.set(key, value, {localStorage: true});
   *
   * @param {string} key - A unique key to save data
   * @param {object} value - Any object that you would like to persist. By default, this will be JSON stringified
   * @param {object} options - An options object which can take two properties: noJSON: true and localStorage: true
   */
  set: function(key, value, options) {
    options = options || {};
    value = options.noJSON ? value : JSON.stringify(value);
    getStorage(options).setItem(key, value);
  },

  /**
   * Retrieve an item from persisted storage.
   *
   * @param {string} key - A unique key by which to retrieve the data
   * @param {object} options - An options object which can take two properties: noJSON: true and localStorage: true
   */
  get: function(key, options) {
    options = options || {};
    var value = getStorage(options).getItem(key);
    return options.noJSON ? value : JSON.parse(value);
  },

  /**
   * Remove an item from persisted storage
   */
  remove: function(key, options) {
    options = options || {};
    delete map[key];
    getStorage(options).removeItem(key);
  }
}

export default service;
