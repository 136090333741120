// Outcomes
export const INNOCENT_SPOUSE = 'INNOCENT_SPOUSE';
export const SEPARATION_OF_LIABILITY = 'SEPARATION_OF_LIABILITY';
export const COMMUNITY_PROPERTY = 'COMMUNITY_PROPERTY';
export const EQUITABLE_RELIEF = 'EQUITABLE_RELIEF';

export const RELIEF_UNLIKELY = 'RELIEF_UNLIKELY';
export const OUTCOME_NOT_YET_CALCULABLE = null;

// Question slugs
export const INTRO = 'intro';                   //0
export const JOINT_RETURN = 'joint_return';             //1
export const MULTI_SELECT = 'multi_select';             //2
export const UNDERSTATEMENT = 'understatement';           //2a
export const UNDERPAYMENT = 'underpayment';             //2b
export const NEITHER_UNDERSTATEMENT_OR_UNDERPAYMENT = 'neither';  //2c
export const ERRONEOUS_ITEMS = 'erroneous_items';         //3
export const KNOW_UNDERSTATEMENT = 'know_understatement';     //4
export const SEPARATED = 'separated';               //5
export const PAID_TAX = 'paid_tax';                 //6
export const UNFAIR_7 = 'unfair_7';                 //7
export const TRANSFER_TO_SPOUSE = 'transfer_to_spouse';       //8
export const TRANSFER_TO_CLIENT = 'transfer_to_client';       //9
export const FRAUD_INTENT = 'fraud_intent';             //10
export const SPOUSE_ITEM = 'spouse_item';             //11
export const SAME_HOUSEHOLD = 'same_household';           //12
export const UNFAIR_13 = 'unfair_13';               //13
export const COMMUNITY_PROPERTY_STATE = 'community_property_state'; //14
export const INCLUDE_IN_GROSS_INCOME = 'include_in_gross_income'; //15
export const COMMUNITY_INCOME_RELATED = 'community_income_related'; //16
export const KNOW_COMMUNITY_INCOME = 'know_community_income';   //17
export const UNFAIR_18 = 'unfair_18';               //18
