import angular from "angular";

import 'angular/app/client/taxes/section-interactions/assistant.style.css';
import 'angular/app/client/taxes/section-interactions/assistant-shared.style.css';

angular.module('app.clients.taxes').component('cpAssistantQuestionPenaltyReason', {
  bindings: {},

  controllerAs: 'vm',

  controller: function () {
    const vm = this;
    vm;
  },

  template: ($element, $attrs) => {
    const TOOLTIP = 'These specific penalty codes are 6651(a)(1), 6651(a)(2), 6651(a)(3), 6656, 6698(a)(1), and 6699(a)(1)';

    return `<strong>Is the <cps-tooltip cp-custom-element html="'${TOOLTIP}'"><a>penalty</a></cps-tooltip> for Failure to File (FTF), Failure to Pay (FTP), or Failure to Deposit (FTD)?</strong>`;
  }
});
