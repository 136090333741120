import { isPlainObject } from "lodash";
import * as constants from './assistant.constants.js';

// -----------------------------------------------------
// create all of the recognized states of the finite state automaton
// -----------------------------------------------------
const states = {};

states.INITIAL_STATE =
  createBinaryState(constants.INTRO, 'Y_PAID_IN_FULL', 'Y_PAID_IN_FULL');

states.Y_PAID_IN_FULL =
  createBinaryState(constants.PAID_IN_FULL, {nextPlaceToGo: 'YY_DISPUTE_TAX', registerOutcomes: [constants.WITHDRAWAL, constants.RELEASE]}, {nextPlaceToGo: 'YN_PLANS_AND_MEANS', unregisterOutcomes: [constants.WITHDRAWAL, constants.RELEASE]});

states.YN_PLANS_AND_MEANS =
  createBinaryState(constants.PLANS_AND_MEANS, {nextPlaceToGo: 'YNY_DISPUTE_TAX', registerOutcomes: [constants.WITHDRAWAL, constants.RELEASE]}, {nextPlaceToGo: 'YNN_LIEN_WITHDRAWAL', unregisterOutcomes: [constants.WITHDRAWAL, constants.RELEASE]});


// Path A
states.YY_DISPUTE_TAX = states.YNY_DISPUTE_TAX =
  createBinaryState(constants.DISPUTE_TAX, {nextPlaceToGo: 'YYY_PURCHASE_PROPERTY', registerOutcomes: [constants.APPEAL]}, {nextPlaceToGo: 'YYN_PROPOSE_ALTERNATIVE', unregisterOutcomes: [constants.APPEAL]});

states.YYN_PROPOSE_ALTERNATIVE =
  createBinaryState(constants.PROPOSE_ALTERNATIVE, {nextPlaceToGo: 'YYNY_PURCHASE_PROPERTY', registerOutcomes: [constants.APPEAL]}, {nextPlaceToGo: 'YYNN_REASONABLE_CAUSE', unregisterOutcomes: [constants.APPEAL]});

states.YYNN_REASONABLE_CAUSE =
  createBinaryState(constants.REASONABLE_CAUSE, {nextPlaceToGo: 'YYNNY_PURCHASE_PROPERTY', registerOutcomes: [constants.APPEAL]}, {nextPlaceToGo: 'YYNNN_PURCHASE_PROPERTY', unregisterOutcomes: [constants.APPEAL]});


// Path B
states.YNN_LIEN_WITHDRAWAL =
  createBinaryState(constants.LIEN_WITHDRAWAL, {nextPlaceToGo: 'YNNY_DISPUTE_TAX', registerOutcomes: [constants.WITHDRAWAL]}, {nextPlaceToGo: 'YNNN_INSTALLMENT_LESS_THAN_25000', unregisterOutcomes: [constants.WITHDRAWAL]});

states.YNNN_INSTALLMENT_LESS_THAN_25000 =
  createBinaryState(constants.INSTALLMENT_LESS_THAN_25000, {nextPlaceToGo: 'YNNNY_DISPUTE_TAX', registerOutcomes: [constants.WITHDRAWAL]}, {nextPlaceToGo: 'YNNNN_INVALID_LIEN', unregisterOutcomes: [constants.WITHDRAWAL]});

states.YNNNN_INVALID_LIEN =
  createBinaryState(constants.INVALID_LIEN, {nextPlaceToGo: 'YNNNNY_DISPUTE_TAX', registerOutcomes: [constants.WITHDRAWAL]}, 'YNNNNN_BANKRUPTCY');

 states.YNNY_DISPUTE_TAX = states.YNNNY_DISPUTE_TAX = states.YNNNNY_DISPUTE_TAX =
  createBinaryState(constants.DISPUTE_TAX, {nextPlaceToGo: 'YNNYY_BANKRUPTCY', registerOutcomes: [constants.APPEAL]}, {nextPlaceToGo: 'YNNYN_PROPOSE_ALTERNATIVE', unregisterOutcomes: [constants.APPEAL]});

states.YNNYN_PROPOSE_ALTERNATIVE =
  createBinaryState(constants.PROPOSE_ALTERNATIVE, {nextPlaceToGo: 'YNNYNY_BANKRUPTCY', registerOutcomes: [constants.APPEAL]}, {nextPlaceToGo: 'YNNYNN_REASONABLE_CAUSE', unregisterOutcomes: [constants.APPEAL]});

states.YNNYNN_REASONABLE_CAUSE =
  createBinaryState(constants.REASONABLE_CAUSE, {nextPlaceToGo: 'YNNYNNY_BANKRUPTCY', registerOutcomes: [constants.APPEAL]}, {nextPlaceToGo: 'YNNYNNN_BANKRUPTCY', unregisterOutcomes: [constants.APPEAL]});

states.YNNYY_BANKRUPTCY = states.YNNNNN_BANKRUPTCY = states.YNNYNY_BANKRUPTCY = states.YNNYNNY_BANKRUPTCY = states.YNNYNNN_BANKRUPTCY =
  createBinaryState(constants.BANKRUPTCY, {nextPlaceToGo: 'YNNYYY_PURCHASE_PROPERTY', registerOutcomes: [constants.RELEASE]}, {nextPlaceToGo: 'YNNYYN_SURETY_BOND', unregisterOutcomes: [constants.RELEASE]});

states.YNNYYN_SURETY_BOND =
  createBinaryState(constants.SURETY_BOND, {nextPlaceToGo: 'YNNYYNY_PURCHASE_PROPERTY', registerOutcomes: [constants.RELEASE]}, {nextPlaceToGo: 'YNNYYNN_STATUTE_EXPIRED', unregisterOutcomes: [constants.RELEASE]});

states.YNNYYNN_STATUTE_EXPIRED =
  createBinaryState(constants.STATUTE_EXPIRED, {nextPlaceToGo: 'YNNYYNNY_PURCHASE_PROPERTY', registerOutcomes: [constants.RELEASE]}, {nextPlaceToGo: 'YNNYYNNN_PURCHASE_PROPERTY', unregisterOutcomes: [constants.RELEASE]});


// Paths A & B converge
states.YYY_PURCHASE_PROPERTY = states.YYNY_PURCHASE_PROPERTY = states.YYNNY_PURCHASE_PROPERTY = states.YYNNN_PURCHASE_PROPERTY =
states.YNNYYY_PURCHASE_PROPERTY = states.YNNYYNY_PURCHASE_PROPERTY = states.YNNYYNNY_PURCHASE_PROPERTY = states.YNNYYNNN_PURCHASE_PROPERTY =
  createBinaryState(constants.PURCHASE_PROPERTY, {nextPlaceToGo: 'YYYY_RESTRUCTURE', registerOutcomes: [constants.DISCHARGE]}, {nextPlaceToGo: 'YYYN_DOUBLE_VALUE', unregisterOutcomes: [constants.DISCHARGE]});

states.YYYN_DOUBLE_VALUE =
  createBinaryState(constants.DOUBLE_VALUE, {nextPlaceToGo: 'YYYNY_RESTRUCTURE', registerOutcomes: [constants.DISCHARGE]}, {nextPlaceToGo: 'YYYNN_SELL_PROPERTY', unregisterOutcomes: [constants.DISCHARGE]});

states.YYYNN_SELL_PROPERTY =
  createBinaryState(constants.SELL_PROPERTY, 'YYYNNY_PROCEEDS_TO_IRS', 'YYYNNN_RESTRUCTURE');

states.YYYNNY_PROCEEDS_TO_IRS =
  createBinaryState(constants.PROCEEDS_TO_IRS, {nextPlaceToGo: 'YYYNNYY_RESTRUCTURE', registerOutcomes: [constants.DISCHARGE]}, {nextPlaceToGo: 'YYYNNYN_LESS_THAN_ZERO', unregisterOutcomes: [constants.DISCHARGE]});

states.YYYNNYN_LESS_THAN_ZERO =
  createBinaryState(constants.LESS_THAN_ZERO, {nextPlaceToGo: 'YYYNNYNY_RESTRUCTURE', registerOutcomes: [constants.DISCHARGE]}, {nextPlaceToGo: 'YYYNNYNN_PROCEEDS_IN_ESCROW', unregisterOutcomes: [constants.DISCHARGE]});

states.YYYNNYNN_PROCEEDS_IN_ESCROW =
  createBinaryState(constants.PROCEEDS_IN_ESCROW, {nextPlaceToGo: 'YYYNNYNNY_RESTRUCTURE', registerOutcomes: [constants.DISCHARGE]}, {nextPlaceToGo: 'YYYNNYNNN_RESTRUCTURE', unregisterOutcomes: [constants.DISCHARGE]});

states.YYYY_RESTRUCTURE = states.YYYNY_RESTRUCTURE = states.YYYNNN_RESTRUCTURE = states.YYYNNYY_RESTRUCTURE =
states.YYYNNYNY_RESTRUCTURE = states.YYYNNYNNY_RESTRUCTURE = states.YYYNNYNNN_RESTRUCTURE =
  createBinaryState(constants.RESTRUCTURE, {nextPlaceToGo: [constants.SUBORDINATION], registerOutcomes: [constants.SUBORDINATION]}, {nextPlaceToGo: 'YYYYN_REFINANCING', unregisterOutcomes: [constants.SUBORDINATION]});

states.YYYYN_REFINANCING =
  createBinaryState(constants.REFINANCING, {nextPlaceToGo: [constants.SUBORDINATION], registerOutcomes: [constants.SUBORDINATION]}, {nextPlaceToGo: 'YYYYNN_LINE_OF_CREDIT', unregisterOutcomes: [constants.SUBORDINATION]});

states.YYYYNN_LINE_OF_CREDIT =
  createBinaryState(constants.LINE_OF_CREDIT, {nextPlaceToGo: [constants.SUBORDINATION], registerOutcomes: [constants.SUBORDINATION]}, {nextPlaceToGo: [constants.RELIEF_UNLIKELY], registerOutcomes: [constants.RELIEF_UNLIKELY], unregisterOutcomes: [constants.SUBORDINATION]});



function createBinaryState(questionSlug, stateIfYes, stateIfNo) {
  const result = function (value, previousQuestionSlug) {
    let nextPlaceToGo;

    if (value === true) {
      nextPlaceToGo = stateIfYes;

    } else if (value === false) {
      nextPlaceToGo = stateIfNo; 

    } else {
      return {
        reachedOutcome: false,
        outcomes: null,
        nextState: null,
        nextQuestionSlug: null,
        currentQuestionSlug: questionSlug,
        previousQuestionSlug,
      };
    }

    if (Array.isArray(nextPlaceToGo)) {
      return {
        reachedOutcome: true,
        outcomes: nextPlaceToGo,
        nextState: null,
        nextQuestionSlug: null,
        currentQuestionSlug: questionSlug,
        previousQuestionSlug,
      };

    } else if (isPlainObject(nextPlaceToGo)) {
      if (Array.isArray(nextPlaceToGo.nextPlaceToGo)) {
        return {
          reachedOutcome: true,
          outcomes: nextPlaceToGo.nextPlaceToGo,
          nextState: null,
          nextQuestionSlug: null,
          currentQuestionSlug: questionSlug,
          outcomesToRegister: nextPlaceToGo.registerOutcomes, 
          outcomesToUnregister: nextPlaceToGo.unregisterOutcomes, 
          previousQuestionSlug,
        };

      } else if (typeof nextPlaceToGo.nextPlaceToGo === 'string') {
        if (!states[nextPlaceToGo.nextPlaceToGo]) {
          throw new Error(`Could not find state by name of '${nextPlaceToGo.nextPlaceToGo}'`);
        }

        return {
          reachedOutcome: false,
          outcomes: null,
          nextState: states[nextPlaceToGo.nextPlaceToGo],
          nextQuestionSlug: states[nextPlaceToGo.nextPlaceToGo].questionSlug,
          currentQuestionSlug: questionSlug,
          outcomesToRegister: nextPlaceToGo.registerOutcomes, 
          outcomesToUnregister: nextPlaceToGo.unregisterOutcomes, 
          previousQuestionSlug,
        };

      } else {
        throw new Error(`Don't know how to create next state -- was given a(n) '${typeof nextPlaceToGo.nextPlaceToGo}' of value '${nextPlaceToGo.nextPlaceToGo}'`);
      }

    } else if (typeof nextPlaceToGo === 'string') {
      if (!states[nextPlaceToGo]) {
        throw new Error(`Could not find state by name of '${nextPlaceToGo}'`);
      }

      return {
        reachedOutcome: false,
        outcomes: null,
        nextState: states[nextPlaceToGo],
        nextQuestionSlug: states[nextPlaceToGo].questionSlug,
        currentQuestionSlug: questionSlug,
        previousQuestionSlug,
      };

    } else {
      throw new Error(`Don't know how to create next state -- was given a(n) '${typeof nextPlaceToGo}' of value '${nextPlaceToGo}'`);
    }
  }

  result.questionSlug = questionSlug;
  return result;
}

export function traverseStateMachine(answers, questionToStopAt) {
  const initialState = {
    reachedOutcome: false,
    outcomes: constants.OUTCOME_NOT_YET_CALCULABLE,
    nextState: states.INITIAL_STATE,
    nextQuestionSlug: constants.INTRO,
    currentQuestionSlug: null,
    previousQuestionSlug: null,
    nextQuestionIsOutcome: false,
  };
  answers = JSON.parse(JSON.stringify(answers));

  return traverse(answers, questionToStopAt, initialState);
}

function traverse(answers, questionToStopAt, currentState) {
  const newState = currentState.nextState(answers[currentState.nextQuestionSlug], currentState.currentQuestionSlug);
  if (currentState.nextQuestionSlug === questionToStopAt) {
    return createResultForNonOutcomeState(currentState, newState);
  } else {
    if (newState.reachedOutcome) {
      newState.previousQuestionSlug = newState.currentQuestionSlug;
      newState.currentQuestionSlug = null;
      return newState;
    } else if (!newState.nextState) {
      return newState;
    } else if (!newState.nextState) {
      return createResultForNonOutcomeState(newState);
    } else {
      return traverse(answers, questionToStopAt, newState);
    }
  }
}

function createResultForNonOutcomeState(state, newState) {
  return {
    reachedOutcome: false,
    outcomes: null,
    nextState: null,
    nextQuestionSlug: newState ? newState.nextQuestionSlug : null,
    nextQuestionIsOutcome: !!newState.reachedOutcome,
    currentQuestionSlug: state.nextQuestionSlug,
    previousQuestionSlug: state.currentQuestionSlug,
    outcomesToRegister: newState.outcomesToRegister || null,
    outcomesToUnregister: newState.outcomesToUnregister || null,
  };
}
