import * as constants from './assistant.constants.js';

export function cantCalculateOutcomes(outcomes) {
  return outcomes === constants.OUTCOME_NOT_YET_CALCULABLE;
}

export function reliefUnlikely(outcomes) {
  return !!(outcomes && outcomes.indexOf(constants.RELIEF_UNLIKELY) >= 0);
}

export function appealsAvailable(outcomes) {
  return !!(outcomes && outcomes.indexOf(constants.APPEALS) >= 0);
}

export function returningPropertyAvailable(outcomes) {
  return !!(outcomes && outcomes.indexOf(constants.RETURNING_PROPERTY) >= 0);
}

export function preventingLevyAvailable(outcomes) {
  return !!(outcomes && outcomes.indexOf(constants.PREVENTING_LEVY) >= 0);
}

export function releasingLevyAvailable(outcomes) {
  return !!(outcomes && outcomes.indexOf(constants.RELEASING_LEVY) >= 0);
}
