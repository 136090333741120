import angular from "angular";
import { asyncStacktrace } from 'auto-trace';

import "angular/resources/letters.resource.js";

angular.module('app')
.factory('LettersService', ['$q', 'LettersResource', '$stateParams',
  function LettersService($q, LettersResource, $stateParams) {
    return {
      getSignatures: (params) => {
        params = { ...$stateParams, ...params };
        let deferred = $q.defer();

        LettersResource.getSignatures(params)
        .then(function success(response) {
          deferred.resolve(response.data.signing_requests);
        })
        .catch(asyncStacktrace(response => {deferred.reject(response)} ));

        return deferred.promise;
      },
    }
  }
]);