import angular from "angular";
import canopyUrls from "canopy-urls!sofe";
import { catchAsyncStacktrace } from "auto-trace";

import template from "./tax-return-rows.template.html";

import "./tax-returns.service.js";

angular.module("app.clients").component("taxReturnRows", {
  bindings: {
    client: "=",
    isHomeWidget: "<",
  },
  template,
  controllerAs: "vm",
  controller: [
    "ClientTaxReturnsService",
    function taxReturnRows(ClientTaxReturnsService) {
      const vm = this;

      vm.$onInit = () => {
        vm.fetchTaxReturns();
      };

      vm.$postLink = () => {
        window.addEventListener("refresh-task-data", vm.fetchTaxReturns);
        window.addEventListener("cp:task-created", vm.fetchTaxReturns);
      };

      vm.$onDestroy = () => {
        window.removeEventListener("refresh-task-data", vm.fetchTaxReturns);
        window.removeEventListener("cp:task-created", vm.fetchTaxReturns);
      };

      vm.fetchTaxReturns = () => {
        vm.isLoaded = false;

        ClientTaxReturnsService.getTaxReturnsForClient(vm.client.id)
          .then(taxReturns => {
            vm.taxReturns = taxReturns;
            vm.isLoaded = true;
          })
          .catch(catchAsyncStacktrace());
      };

      vm.navigateToTaxReturn = taxReturnId => {
        canopyUrls.navigateToUrl(`/#/clients/${vm.client.id}/tax-returns/${taxReturnId}`);
      };
    },
  ],
});
