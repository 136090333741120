import angular from 'angular';
import template from './fake-cp-select-link.template.html';

// It looks like a cp-select but is just a link to do something onClick. Use it to create custom dropdowns.
angular.module('app')
  .component('cpFakeCpSelectLink', {

    bindings: {
      value: '=',
      placeholder: '@',
      allowClear: '=',
      autosizeInput: '=',
      doOnClick: '&',
      onClear: '&',
    },

    template,

    controllerAs: 'vm',

    controller: function FakeCpSelectLink($scope) {
      var vm = this;
      vm;
    }

  });
