import angular from 'angular';
import template from './archived-notes.template.html';
import './archived-notes.style.css';

import { catchAsyncStacktrace } from 'auto-trace';
import './archived-notes-list.component.js';
import './notes-search.component.js';
import { isArchived, isDeleted } from './notes.helpers.js';

angular
.module('app.clients.notes')
.component('archivedNotes', {
  template,
  controllerAs: 'vm',
  controller(ContactService, NotesService, $state, $timeout) {
    const vm = this;
    vm.isLoaded = false;
    vm.expandAll = false;

    vm.$onInit = () => {
      vm.activeClientSubscription = ContactService
        .cachedGetActiveClient($state.params.clientId)
        .subscribe(
          client => {
            $timeout(() => {
              vm.client = client;
            });
          },
          catchAsyncStacktrace()
        );

      vm.clientId = $state.params.clientId;
    }

    vm.$onDestroy = () => {
      vm.activeClientSubscription.unsubscribe();
    }

    vm.noArchivedNotes = () => vm.notes ? !vm.notes.some(note => isArchived(note) && !isDeleted(note)) : false;

    NotesService.getIndex({clientId: $state.params.clientId, onlyArchived: true})
          .then(notes => {
            vm.searchNotes = vm.notes = notes;
            vm.isLoaded = true;
          })
          .catch(catchAsyncStacktrace());
  }
});
