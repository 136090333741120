import angular from 'angular';

/**
 * The clients  module
 *
 * @type {angular.Module}
 */
angular.module('app.clients', [
  // projects
  'app.clients.dates',
  'app.clients.notes',
  'app.clients.requests',
  'app.clients.files',
  'app.clients.todos',

  // taxes
  'app.clients.taxes',
]);
