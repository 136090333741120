import angular from "angular";
import fetcher, { fetchWithSharedCache } from 'fetcher!sofe';
import canopyUrls from 'canopy-urls!sofe';

angular.module('app.clients.taxes')
  .factory('EngagementResource', ['$http', '$window',
    function EngagementResource($http, $window) {
      $window.$http = $http;
      return {
        get: (params, query) => {
          return $http.get(
            '/api/clients/' + params.clientId + '/engagements/' + params.engagementId, {
              params: query
            });
        },

        index: (params, query) => {
          return $http.get(
            '/api/contacts/' + params.clientId + '/engagements', {
              params: query
            });
        },

        create: (params, engagement) => {
          return $http.post(
            '/api/clients/' + params.clientId + '/engagements', engagement
          );
        },

        save: (params, engagement) => {
          return fetcher(`${canopyUrls.getWorkflowUrl()}/api/clients/${params.clientId}/engagements/${params.engagementId}`, {
            method: 'PUT',
            body: JSON.stringify(engagement),
          });
        },

        update: (params, engagement) => {
          return fetcher(`${canopyUrls.getWorkflowUrl()}/api/clients/${params.clientId}/engagements/${params.engagementId}`, {
            method: 'PATCH',
            body: JSON.stringify(engagement),
          });
        },

        remove: (params) => {
          return $http.delete(
            '/api/clients/' + params.clientId + '/engagements/' + params.engagementId
          );
        },

        fetchWithSharedCache(params) {
          return fetchWithSharedCache(`${canopyUrls.getWorkflowUrl()}/api/clients/${params.clientId}/engagements/${params.engagementId}`, `/engagements/${params.engagementId}`);
        }
      };
    }
  ]);
