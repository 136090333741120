import angular from 'angular';
import template from './notes-search.template.html';
import './notes-search.style.css';
import moment from 'moment';

angular
.module('app.clients.notes')
.component('notesSearch', {
  bindings: {
    notes: '=',
    searchNotes: '='
  },
  template,
  controllerAs: 'vm',
  controller($scope) {
    const vm = this;

    $scope.$watch('vm.searchText', (searchText) => {
      if (!searchText || searchText.length === 0) {
        vm.searchNotes = vm.notes; // reset the search to display all
        return;
      }

      searchText = searchText.toLowerCase();
      vm.searchNotes = vm.notes.filter(note => {
        return containsText(note.title)
            || containsText(note.body)
            || containsText(note.updated_by)
            || containsText(note.created_by)
            || containsText(note.archived_by)
            || matchesDate(note.created_at)
            || matchesDate(note.updated_at)
            || matchesDate(note.archived_at)
      });

      function containsText(text) {
        return text && text.trim().length > 0 && text.toLowerCase().indexOf(searchText) >= 0;
      }

      function matchesDate(date) {
        return date && moment(date).format('dddd, MMMM Do YYYY, M/D/YYYY h:mm a h:mma ha h a').toLowerCase().indexOf(searchText) >= 0;
      }
    });
  }
});
