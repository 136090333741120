import angular from 'angular';
import toasts from "toast-service!sofe";
import { catchAsyncStacktrace } from 'auto-trace';

import template from './letters-dashboard.template.html';

import "./engagement-letters.service.js";

import './letter-card.component.js';
import './create-new-letter.component.js';

angular
  .module('app.clients.taxes')
  .component('cpLettersDashboard', {
    bindings: {
      engagement: '=',
      client: '<',
    },

    controllerAs: 'vm',

    controller: function (EngagementLettersService, $stateParams, $scope, $timeout) {
      const vm = this;

      vm.initialized = false;

      vm.$onInit = () => {
        vm.setShowCreateLetterModal = setShowCreateLetterModal;

        vm.setShowCreateLetterModal(false);

        EngagementLettersService.getLetters({ ...$stateParams })
          .then(
            (letters) => {
              vm.letters = letters;
              vm.initialized = true;
            }
          ).catch(catchAsyncStacktrace())
        vm.initialized = true;
      }

      $scope.$on('cp:delete-letter', (event, letter) => {
        const removeIndex = vm.letters.indexOf(letter);

        if (removeIndex > -1) {
          vm.letters.splice(removeIndex, 1);
        }

        const timeout = setTimeout(() => {
          EngagementLettersService.deleteLetter({ ...$stateParams, letter_id: letter.id });
        }, 5000);

        toasts.successToast(`Letter deleted`, 'Undo', () => {
          clearTimeout(timeout);
          $timeout(() => {
            $scope.$apply(() => {
              vm.letters.splice(removeIndex, 0, letter);
              toasts.successToast('Letter restored successfully');
            })
          }, 0, false);
        }, 5000);

      });

      function setShowCreateLetterModal(shouldItShow) {
        vm.showCreateLetterModal = shouldItShow;
      }

    },

    template,

  });