import angular from "angular";
import _ from 'lodash';
import { catchAsyncStacktrace } from 'auto-trace';
import context from 'angular/bootstrap/context.service.js';

import "angular/app/client/taxes/engagement.service.js";
import toasts from "toast-service!sofe";

import { hasAccess } from 'cp-client-auth!sofe';

angular.module('app.clients.taxes')
  .controller('EngagementListController', ['$state', '$timeout', '$stateParams', 'EngagementService', 'ContactService', 'cpModalDialogService', '$scope', '$location',
    function EngagementListController($state, $timeout, $stateParams, EngagementService, ContactService, cpModalDialogService, $scope, $location) {

      var vm = this;
      vm.isLoaded = false;
      vm.alerts = {};
      vm.type = $state.current.url.substring(1);

      vm.context = context.getContext();
      vm.userHasAccess = hasAccess(vm.context.loggedInUser);

      if (!vm.userHasAccess('tasks_engagements')) {
        $state.go('403')
      }

      if ($stateParams.clientId) {
        EngagementService.getIndex($stateParams, vm.type)
          .then(function(resp) {
            $timeout(function() {
              vm.engagements = resp;
              vm.isLoaded = true;
              vm.clientId = $stateParams.clientId
            }, 500);
          })
          .catch(catchAsyncStacktrace());
      } else {
        window.console.log(`No clientId in the url -- cannot load engagement list`);
      }

      vm.create = function() {
        $state.go('engagements.create', {clientId: vm.client.id });
      }

      vm.unDelete = function(engagement, event) {
        event.preventDefault();
        event.stopPropagation();

        EngagementService.updateEngagement({
          engagementId: engagement.id,
          clientId: $stateParams.clientId
        }, {
          is_archived: false,
          is_deleted: false
        }).then(function(resp) {
          vm.engagements = _.without(vm.engagements, engagement);
          toasts.successToast("Engagement successfully restored");
        })
        .catch(catchAsyncStacktrace());
      }

      vm.archive = function(engagement, event) {
        event.preventDefault();
        event.stopPropagation();

        vm.engagements = _.without(vm.engagements, engagement);

        const clientId = $stateParams.clientId;

        var timeout = $timeout(function() {
          EngagementService.updateEngagement({
            engagementId: engagement.id,
            clientId: clientId
          }, {
            is_archived: true
          })
        }, 4000, false);

        toasts.successToast("Engagement successfully archived", "Undo", function() {
          $timeout.cancel(timeout);
          $timeout(function() {
            $scope.$apply(function() {
              vm.engagements.push(engagement);
              toasts.successToast('Engagement restored successfully');
            });
          }, 0, false);
        });

      }

      vm.unArchive = function(engagement, event) {
        event.preventDefault();
        event.stopPropagation();

        EngagementService.updateEngagement({
          engagementId: engagement.id,
          clientId: $stateParams.clientId
        }, {
          is_archived: false,
          is_deleted: false
        }).then(function(resp) {
          vm.engagements = _.without(vm.engagements, engagement);
          toasts.successToast("Engagement successfully restored");
        })
        .catch(catchAsyncStacktrace());
      }

      /**
       * Cancel the action to open the engagement if it is deleted
       */
      vm.testOpenEngagement = function(event) {
        if (vm.type === 'trash') {
          event.preventDefault();
        }
      }

      vm.deleteEngagement = function(engagement, event) {
        event.preventDefault();
        event.stopPropagation();

        vm.engagements = _.without(vm.engagements, engagement);

        const clientId = $stateParams.clientId;
        var timeout = $timeout(function() {
          EngagementService.deleteEngagement({
            engagementId: engagement.id,
            clientId: clientId
          });
        }, 4000, false);

        toasts.successToast("Engagement successfully deleted", "Undo", function() {
          $timeout.cancel(timeout);
          $timeout(function() {
            $scope.$apply(function() {
              vm.engagements.push(engagement);
              toasts.successToast("Engagement successfully restored");
            });
          }, 0, false);
        });
      }

    }
  ]);
