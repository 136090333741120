import angular from "angular";
import { pluck, map } from 'rxjs/operators'
import canopyUrls from 'canopy-urls!sofe';
import { fetchAsObservable } from 'fetcher!sofe'

export const headers = (typeof Headers !== "undefined") ? new Headers({
  'Content-Type': 'application/json',
}) : {};

angular.module('app.clients.files')
  .factory('DocsResource', ['$http',
    function DocsResource($http) {
      return {
        getRecent: function getRecent(contactId) {
          return fetchAsObservable(`${canopyUrls.getWorkflowUrl()}/api/docs/contacts/${contactId}/folders/0/files?view=recent`)
            .pipe(pluck("folder_files"))
        },

        getMeta: function getMeta(contactId, docId) {
          return fetchAsObservable(`${canopyUrls.getWorkflowUrl()}/api/docs/contacts/${contactId}/files/${docId}`)
            .pipe(map(resp => resp.files[0]))
        },

        getEsignDoc: function getEsignDocs(docId, logAsView=false) {
          const log_view = logAsView ? '?log_view=true' : '';
          return fetchAsObservable(`${canopyUrls.getWorkflowUrl()}/api/docs/esign-docs/${docId}${log_view}`)
            .pipe(pluck('esign_doc'))
        },

        getUploadUrl: function(clientId) {
          return fetchAsObservable(`${canopyUrls.getWorkflowUrl()}/api/docs/contacts/${clientId}/s3`).pipe(pluck('s3'))
        },

        getFilesRelatedTo: function(relatedIdMap){
          return fetchAsObservable(`${canopyUrls.getWorkflowUrl()}/api/docs/related-files`, {
            method: 'POST',
            headers,
            body: JSON.stringify({related_id_mappings: relatedIdMap})
          }).pipe(pluck('related_files_for'))
        },

        getS3AttachmentTargetEndpoint: (folderId) => $http.get(`/api/docs/folders/${folderId}/s3`),

        reportFileUploaded: (folderId, file) => $http.post(`/api/docs/folders/${folderId}/files`, {file}),
      };
    }
  ]);
