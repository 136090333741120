import angular from "angular";

angular.module('app.clients.taxes')
  .factory('EngagementHistoryResource', ['$http', '$window',
    function EngagementHistoryResource($http, $window) {
      $window.$http = $http;
      return {
        get: (params) => {
          return $http.get(
            '/api/clients/' + params.clientId + '/engagements/' + params.engagementId + '/history'
          );
        }
      };
    }
  ]);
