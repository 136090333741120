import purify from 'dompurify'

const acceptedTags = ['UL', 'OL', 'LI', 'B', 'U', 'I', 'H1', 'H2', 'H3', 'P', 'BR', 'FONT']
const convertTags = ['STRONG', 'EM', 'DIV']

export function removedTags(htmlString) {
  const removed = []
  const sanitized = purify.sanitize(htmlString)
  const doc = new DOMParser().parseFromString(sanitized, 'text/html')
  whatTagsWouldBeRemoved(doc, removed)
  return removed.filter(s => s && s !== 'HEAD')
}

export function stripHTML(htmlString) {
  const sanitized = purify.sanitize(htmlString)
  const doc = new DOMParser().parseFromString(sanitized, 'text/html')
  if (doc.body.innerHTML == undefined || doc.body.children.length === 0) {
    return sanitized
  } else {
    const fragment = recursiveCleanHTML(doc.body)
    const newBody = document.createElement('body')
    newBody.appendChild(fragment)
    return newBody.innerHTML
  }
}

function createAndTransferNodeChildren(newTag, oldElement) {
  var newEl = document.createElement(newTag)
  while (oldElement.childNodes.length > 0) {
    newEl.appendChild(oldElement.childNodes[0])
  }
  return newEl
}

function convertToBandicootTags(el) {
  if (el.tagName === 'EM' ) {
    return createAndTransferNodeChildren('i', el)
  }
  else if (el.tagName === 'STRONG') {
    return createAndTransferNodeChildren('b', el)
  }
  else if (el.tagName === 'DIV') {
    return createAndTransferNodeChildren('p', el)
  }
  throw new Error(`tag ${el.tagName} was not given a suitable tag to which to convert`)
}

function recursiveCleanHTML (node) {
  if (node.nodeName === '#text') {
    return node
  }
  let el = node
  if (convertTags.includes(el.tagName)) {
    el = convertToBandicootTags(el)
  }
  if (!el.hasChildNodes()) {
    if (acceptedTags.includes(el.tagName)) {
      el.removeAttribute('class')
      return el
    }
  } else {
    const children = [...el.childNodes].map(c => recursiveCleanHTML(c)).filter(c => c !== undefined)
      .filter(child => child.nodeName === '#text' || child.hasChildNodes()) // remove empty elements like <p></p>
    let parent = recursiveCleanHTML(el.cloneNode(false))
    if (parent === undefined) {
      parent = document.createDocumentFragment()
    }
    children.forEach(child => {
      parent.appendChild(child)
    })
    return parent
  }
}

export function whatTagsWouldBeRemoved(node, removed) {
  if (!node.hasChildNodes()) {
    if (!acceptedTags.includes(node.tagName) && !convertTags.includes(node.tagName) && !removed.includes(node.tagName)) {
      removed.push(node.tagName)
    }
  } else {
    [...node.childNodes].map(c => whatTagsWouldBeRemoved(c, removed))
  }
}
