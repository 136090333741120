import angular from 'angular';
import { cloneDeep, includes } from 'lodash';
import { catchAsyncStacktrace, asyncStacktrace } from 'auto-trace';
import toasts from 'toast-service!sofe';
import storage from "angular/common/helpers/persisted-storage.js";

import 'angular/resources/assistant-answers.resource.js';

import { service_slugs, assistantResources} from './assistant.constants.js';

angular.module('app.clients.taxes')
.factory('AssistantAnswersService', ['$q', 'AssistantAnswersResource', '$state', '$stateParams', '$timeout', '$rootScope',
  function AssistantAnswersService($q, AssistantAnswersResource, $state, $stateParams, $timeout, $rootScope) {

    let inMemoryAnswersCache = null;
    let keyForAnswersCache = null;
    let constants, stateMachine, questionHelper, total_num_questions;

    function reloadSpecificImportPayload() {
      if (includes(service_slugs, $stateParams.programSlug)) {
        constants = assistantResources[$stateParams.programSlug].constants;
        stateMachine = assistantResources[$stateParams.programSlug].stateMachine;
        questionHelper = assistantResources[$stateParams.programSlug].questionHelper;
        total_num_questions = assistantResources[$stateParams.programSlug].total_num_questions;
      }
    }

    $rootScope.$on('$stateChangeSuccess', (event, toState, toParams) => {
      if (toState.name.indexOf('engagement.layout.program.assistant') === 0) {
        storage.set(getLocalStorageVisitedKey(), {
          name: toState.name,
          params: toParams,
        });
      }

      reloadSpecificImportPayload();
    });

    reloadSpecificImportPayload();

    function getLocalStorageVisitedKey() {
      return `cp:${$stateParams.programSlug}:assistant-last-visited:engagement-${$stateParams.engagementId}`;
    }

    function getInitialState() {
      return {
        answers: {}
      };
    }

    function fetchAnswers() {
      const deferred = $q.defer();
      if (keyForAnswersCache != `${$stateParams.engagementId}__${$stateParams.programSlug}`) {
        keyForAnswersCache = `${$stateParams.engagementId}__${$stateParams.programSlug}`;
        inMemoryAnswersCache = null;
      }

      if (inMemoryAnswersCache) {
        deferred.resolve(inMemoryAnswersCache);
      } else {
        if (typeof $stateParams.engagementId === 'string' && typeof $stateParams.clientId === 'string') {
          const answersPromise = AssistantAnswersResource.fetchAnswers($stateParams.clientId, $stateParams.engagementId, $stateParams.programSlug)
          answersPromise.then((response) => {
            inMemoryAnswersCache = response.data.answers.answers ? response.data.answers : getInitialState()
            deferred.resolve(inMemoryAnswersCache);
          })
          .catch(asyncStacktrace(response => {deferred.reject(response)} ))
        } else {
          deferred.reject('Engagement Id and Client Id must be in the url -- cannot fetch answers to Assistant questions');
        }
      }

      return deferred.promise;
    }

    const service = {

      /* returns a promise that resolves to either an array of outcomes (string constants), or null if not
       * enough questions have been answered to determine the outcomes.
       */
      getOutcomes: () => {
        const deferred = $q.defer();

        fetchAnswers()
        .then((data) => {
          const endState = stateMachine.traverseStateMachine(data.answers);

          if (data.outcomes && data.outcomes.length > 0) {
            endState.outcomes = data.outcomes;
          }

          deferred.resolve(endState.outcomes);
        })
        .catch(asyncStacktrace(response => {deferred.reject(response)} ))

        return deferred.promise;
      },

      restartAssistant: () => {
        //reset all answers, then redirect to the first question. Then actually persist once the undo banner is gone.
        const oldAnswers = inMemoryAnswersCache;
        const engagementId = $stateParams.engagementId;
        const clientId = $stateParams.clientId
        const oldStateParams = cloneDeep($stateParams);
        const oldStateName = cloneDeep($state.current.name);

        function undoRestart() {
          $timeout(() => {
            inMemoryAnswersCache = oldAnswers;
            oldStateParams.resumeBanner = true;
            $state.go(oldStateName, oldStateParams);
            AssistantAnswersResource.putAnswers(clientId, engagementId, $stateParams.programSlug, inMemoryAnswersCache);
          }, 0);
        }

        if (engagementId && clientId) {
          inMemoryAnswersCache = getInitialState();
          inMemoryAnswersCache.answers[constants.INTRO] = true;
          toasts.successToast(`Great. We are restarting the assistant. Click undo if you didn't mean to restart.`, 'Undo', undoRestart, 10000);
          AssistantAnswersResource.putAnswers(clientId, engagementId, $stateParams.programSlug, inMemoryAnswersCache);
          $state.go('engagement.layout.program.assistant', {...oldStateParams, questionSlug: 'foo'});
        } else {
          throw new Error(`Cannot restart assistant when client id and/or engagement are not in the url.`);
        }
      },

      submitAnswer: (questionSlug, value, extraStateParams = {}) => {
        // persist the new answer, then redirect to the next question / completion page
        if (questionSlug != 'intro') {
          if (questionHelper.questions[questionSlug].type === 'checkboxes' && typeof value !== 'object') {
            throw new Error(`The circumstance question must have a checkbox (multivalued) answer`);

          } else if (questionHelper.questions[questionSlug].type === 'boolean' && typeof value !== 'boolean') {
            throw new Error(`Question ${questionSlug} must have a boolean answer`);
          }
        }

        if (!$stateParams.clientId || !$stateParams.engagementId || !$stateParams.programSlug) {
          throw new Error(`Cannot save a ${$stateParams.programSlug} answer without a client id and an engagement id and programSlug in the url`);
        }

        const oldStateParams = cloneDeep($stateParams); //if you don't save them before an asynchronous promise, they could change

        fetchAnswers()
        .then((data) => {
          data.answers[questionSlug] = value;

          const nextState = stateMachine.traverseStateMachine(data.answers, questionSlug);

          data.outcomes = data.outcomes || [];

          if (nextState.outcomesToRegister) {
            nextState.outcomesToRegister.forEach((outcome) => { data.outcomes.push(outcome) })
          }

          if (nextState.outcomesToUnregister) {
            nextState.outcomesToUnregister.forEach((outcome) => {
              let arrIndex = data.outcomes.findIndex((element, index, array) => outcome === element);
              if (arrIndex >= 0) {
                data.outcomes.splice(arrIndex, 1);
              }
            });
          }

          if (nextState.reachedOutcome) {
            $state.go('engagement.layout.program.assistant-outro', {
              ...oldStateParams,
              ...extraStateParams,
              sectionSlug: $stateParams.sectionSlug,
            });

          } else if (nextState.currentQuestionSlug) {
            const newStateParams = {
              ...oldStateParams,
              ...extraStateParams,
              questionSlug: nextState.nextQuestionSlug,
              sectionSlug: $stateParams.sectionSlug
            };

            $state.go('engagement.layout.program.assistant', newStateParams);
          } else {
            throw new Error(`Error - cannot determine which question to go to next`);
          }

          AssistantAnswersResource.putAnswers(oldStateParams.clientId, oldStateParams.engagementId, $stateParams.programSlug, data);
        })
        .catch(catchAsyncStacktrace());
      },

      /* return a promise that resolves a structure like the following:
       * {
       *  questionSlug: 'slug',
       *  answer: true|false,
       *  previousQuestionSlug: 'prevSlug',
       *  nextQuestionSlug: null | 'nextSlug', //depending on if the current question already has an answer
       *  percentage: 0.3,
       *  nextQuestionIsOutcome: false | true,
       * }
       */
      getQuestion: (questionSlug) => {
        const deferred = $q.defer();

        fetchAnswers()
        .then((data) => {
          const state = stateMachine.traverseStateMachine(data.answers, questionSlug);

          if (state.reachedOutcome && !$state.is('engagement.layout.program.assistant-outro')) {
            //don't resolve the promise
            $state.go('engagement.layout.program.assistant-outro', {sectionSlug: $stateParams.sectionSlug});
            return;
          }

          deferred.resolve({
            questionSlug: state.currentQuestionSlug,
            answer: data.answers[state.currentQuestionSlug],
            previousQuestionSlug: state.previousQuestionSlug,
            nextQuestionSlug: state.nextQuestionSlug,
            nextQuestionIsOutcome: !!state.nextQuestionIsOutcome,
            percentage: Object.keys(data.answers).length / (total_num_questions - 1), //the -1 is because the intro question doesn't really count
            outcomes: data.outcomes,
          });
        })
        .catch(asyncStacktrace(response => {deferred.reject(response)} ))

        return deferred.promise;
      },

      resumeAssistant: (sectionSlug) => {
        const lastVisitedState = storage.get(getLocalStorageVisitedKey()) || {params: {}};
        const stateGoOptions = {
          location: 'replace',
          sectionSlug: sectionSlug
        };

        if (typeof lastVisitedState.params.sectionSlug === 'undefined' || lastVisitedState.params.sectionSlug.length == 0) {
          lastVisitedState.params.sectionSlug = sectionSlug;
        }

        if (lastVisitedState && lastVisitedState.name && lastVisitedState.params) {
          const newStateParams = {
            ...lastVisitedState.params,
            resumeBanner: true
          };

          if (lastVisitedState.name !== 'engagement.layout.program.assistant' || lastVisitedState.params.questionSlug) {
            $state.go(lastVisitedState.name, newStateParams, stateGoOptions);

          } else {
            service.getQuestion()
            .then((question) => {
              newStateParams.questionSlug = question.questionSlug;
              $state.go(lastVisitedState.name, newStateParams, stateGoOptions)
            });
          }

        } else {
          service.getQuestion('foo').then((question) => {
            if (question.questionSlug === constants.INTRO) {
              $state.go('engagement.layout.program.assistant-intro', stateGoOptions);

            } else {
              $state.go('engagement.layout.program.assistant', {questionSlug: question.questionSlug}, stateGoOptions);
            }
          })
          .catch(catchAsyncStacktrace());
        }
      },

      goToQuestion: (questionSlug, extraStateParams = {}) => {
        if (!questionSlug) {
          throw new Error(`Error going to question '${questionSlug}'`);
        }

        $state.go('engagement.layout.program.assistant', {
          ...$stateParams,
          ...extraStateParams,
          sectionSlug: $stateParams.sectionSlug,
          questionSlug
        });
      },

      goToOutcome: () => {
        $state.go('engagement.layout.program.assistant-outro', $stateParams);
      }
    };

    return service;
  }
]);
