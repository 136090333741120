import angular from "angular";
import selectBoxHelpers from "angular/common/helpers/select-box.helpers.js";
import { find } from "lodash";

/**
 * This filter formats an address object into readable html
 *
 * Example:
 * {{ {"address_1":"2957 Broadway","locality":"Vancouver","region":"WA","postal_code":"893632","country":"US"} | address:"inline" }}
 *
 * Two address formats: inline & block
 */
angular.module('app.helpers')
  .filter('address', function() {
    return function(input, format) {
      let country = find(selectBoxHelpers.countries, {key: input.country});

      if (format === 'inline' && angular.isObject(input)) {
        let address = '';
        if (input.address_1) address = input.address_1 + ', ';
        if (input.address_2) address += input.address_2 + ', ';
        if (input.locality) address += input.locality + ', ';
        if (input.region) address += input.region + ', ';
        if (input.postal_code) address += input.postal_code;
        if (input.country && input.country != 'US') address += ', ' + (country && country.value ? country.value : input.country);

        return address;

      } else if (format === 'multiline' && angular.isObject(input)) {
        let address = '';
        if (input.address_1) address = input.address_1 + '\n';
        if (input.address_2) address += input.address_2 + '\n';
        if (input.locality) address += input.locality + ', ';
        if (input.region) address += input.region + ' ';
        if (input.postal_code) address += input.postal_code;
        if (input.country && input.country != 'US') address += '\n' + (country && country.value ? country.value : input.country);

        return address;
      }

      return input;
    }
  });
