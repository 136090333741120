import angular from 'angular';
import { applicationProps } from 'entries/workflow-ui'
import { filter, isString, get, forEach } from 'lodash';
import Dropzone from 'dropzone';
import { truncateFilename } from 'docs-ui!sofe';
import context from 'angular/bootstrap/context.service.js';
import * as fileHelper from 'angular/common/helpers/file.helper.js';
import { taxResServiceSlugs } from 'angular/app/client/taxes/engagement-summary.helper.js';

import { catchAsyncStacktrace } from 'auto-trace';
import "./files-upload.style.css";

import './workpapers.service.js';
import * as WorkpaperHelpers from './workpapers.helpers.js';
import 'angular/common/services/attachments.service.js';
import canopyUrls from 'canopy-urls!sofe';
import './common-actions/assign-section-envelope.component.js';

angular.module('app.clients.taxes').controller('WorkpapersController', [
  'EngagementService',
  'ProgramSectionsService',
  'WorkpapersService',
  'AttachmentsService',
  '$stateParams',
  '$scope',
  'ContactService',
  'SectionEnvelopesService',
  'DocsResource',
  'AttachmentsResource',
  '$timeout',
  function WorkpapersController(
    EngagementService,
    ProgramSectionsService,
    WorkpapersService,
    AttachmentsService,
    $stateParams,
    $scope,
    ContactService,
    SectionEnvelopesService,
    DocsResource,
    AttachmentsResource,
    $timeout
  ) {
    var vm = this;
    vm.downloadAttachment = downloadAttachment;
    vm.inQueue = 0;
    vm.regetActiveTeamMembers = regetActiveTeamMembers;
    vm.containers = {}

    function init() {
      vm.isEditable = vm.engagement.is_archived === false && vm.engagement.is_deleted === false;
      vm.requiredPermission = taxResServiceSlugs.find(slug => slug === $stateParams.programSlug)
        ? 'tax_resolution_services'
        : 'custom_services';

      ContactService.getClient({
        clientId: $stateParams.clientId,
        include: 'users',
      })
        .then(function(successResponse) {
          vm.activeTeamMembers = filter(successResponse.users, {
            is_activated: true,
            is_deleted: false,
            role: 'TeamMember',
          });
        })
        .catch(catchAsyncStacktrace());

      let currentContext = context.getContext();

      vm.loggedInUser = currentContext.loggedInUser;
      vm.tenant = currentContext.tenant;

      vm.editMode = false;
      vm.assignMode = false;
      vm.SECTION_TITLE_MAX_LENGTH = EngagementService.SECTION_TITLE_MAX_LENGTH;
      vm.SECTION_DESCRIPTION_MAX_LENGTH = EngagementService.SECTION_DESCRIPTION_MAX_LENGTH;

      ProgramSectionsService.get({
        engagementId: $stateParams.engagementId,
        clientId: $stateParams.clientId,
        sectionId: vm.sectionId,
      }).then(section => {
        vm.section = section;
        vm.revertSectionDetails();
        if (!vm.section.relationships.children) {
          vm.section.relationships.children = [];
        }
        if (vm.section.relationships.children.length === 0) {
          let workpaper = WorkpaperHelpers.createBlankWorkpaperStub(section.id);
          WorkpapersService.add($stateParams, workpaper).then(workpaperId => {
            workpaper.id = workpaperId;
            vm.workpaper = workpaper;
            vm.section.relationships.children.push(workpaper);
            workpaperIsLoaded();
          });
        } else {
          SectionEnvelopesService.getEnvelope({
            ...$stateParams,
            sectionId: section.id,
            envelopeId: section.relationships.children[0].references,
          }).then(envelope => {
            vm.workpaper = envelope;
            workpaperIsLoaded();
          });
        }
      });
    }

    function regetActiveTeamMembers() {
      ContactService.getClient({
        clientId: $stateParams.clientId,
        include: 'users',
      })
        .then(function(successResponse) {
          vm.activeTeamMembers = filter(successResponse.users, {
            is_activated: true,
            is_deleted: false,
            role: 'TeamMember',
          });
        })
        .catch(catchAsyncStacktrace());
    }

    function workpaperIsLoaded() {
      fetchAttachments();
      initDropZone();
    }

    function fetchAttachments() {
      AttachmentsService.getList({
        clientId: $stateParams.clientId,
        pivot_type: 'workpapers',
        pivot_id: vm.workpaper.id,
      }).then(attachments => {
        vm.workpaper.attachments = attachments;
      });
    }

    vm.revertSectionDetails = () => {
      vm.tempSectionName = vm.section ? vm.section.name : '';
      vm.tempSectionDescription = vm.section ? vm.section.description : '';
    };

    function initDropZone() {
      const dropzoneEl = document.querySelector('#workpaperFiles');
      if (!dropzoneEl) {
        //the route has changed already but we're still trying to init drop zone
        return;
      }
      if (dropzoneEl.dropzone) {
        //when the section changes but not the UI Router "state", the dropzone element from the old section already has dropzone attached
        delete dropzoneEl.dropzone;
      }

      DocsResource.getS3AttachmentTargetEndpoint(`CON${$stateParams.clientId}`).then(response => {
        const fields = get(response, 'data.s3.fields', {})
        const s3URL = get(response, 'data.s3.url', '')
        const s3Prefix = fields.key.replace('${filename}','');

        let dropzoneOptions = {
          url: s3URL,
          withCredentials: false,
          uploadMultiple: false,
          parallelUploads: 1,
          maxFilesize: 250,
          addRemoveLinks: false,
          dictDefaultMessage: '',
          dictFallbackText: null,
          dictFileTooBig: 'This file exceeds the size limit of {{maxFilesize}}MB',
          clickable: '#upload-a-workpaper',
          previewTemplate: `
            <div id="container">
              <div class="cps-slat +noclick cp-workpapers__dropzone__preview">
                <div class="cp-workpapers__dropzone__preview__progress"></div>
                <div class="cps-slat__badge">
                  <img src="https://cdn.canopytax.com/static/workflow-ui/file_icons/na_icon.png" style="width: 3rem; padding-left: 1rem;" id="cp-file-icon" />
                </div>
                <div class="cps-slat__content">
                  <div class="cps-slat__content__title cp-file-upload__filename" data-dz-name></div>
                  <span class="cp-file-upload__filesize" data-dz-size></span>
                  <div class="dz-error-message"><span data-dz-errormessage></span></div>
                </div>
                <div class="cps-slat__actions">
                  <div class="dz-success-mark"><i class="cps-icon cps-icon-check cps-primary-green"></i></div>
                  <div class="dz-error-mark cps-btn-icon"><a href="" class="cps-link" data-dz-remove><span class="cps-icon cps-icon-close cps-red" style="top: 0;"></span></a></div>
                </div>
                <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
              </div>
            </div>
          `,
          headers: {'Cache-Control': ''}
        };

        let dropzone = new Dropzone('#workpaperFiles', dropzoneOptions);

        dropzone.on('addedfile', () => {
          vm.inQueue++;
          try {
            $scope.$apply();
          } catch (ex) {
            /* digest already in progress */
          }
        });

        dropzone.on('sending', (file, xhr, formData) => {
          file.previewElement.querySelector('.cp-workpapers__dropzone__preview__progress').style.width = '2%';
          file.previewElement.querySelector('#cp-file-icon').src = vm.getAttachmentIcon(file.name);

          forEach(fields, (value, key) => {
            formData.append(key, value);
          })

          formData.set('key', s3Prefix + `${get(file, 'upload.uuid')}` + truncateFilename(file.name))
        });

        dropzone.on('uploadprogress', (file, progress, bytesSent) => {
          file.previewElement.querySelector('.cp-workpapers__dropzone__preview__progress').style.width = `${Math.floor(
            progress
          )}%`;
        });

        dropzone.on('complete', file => {
          if (file.status !== 'error') {
            dropzone.removeFile(file);
            vm.inQueue--;
          }

          const path_to_s3 = s3Prefix + `${get(file, 'upload.uuid')}` + truncateFilename(file.name)

          DocsResource.reportFileUploaded(`CON${$stateParams.clientId}`, {
            description: '',
            filesize: file.size,
            hidden: false,
            inbox: false,
            is_visible: false,
            mimetype: file.type,
            name: truncateFilename(file.name),
            path_to_s3: path_to_s3,
          })
            .then(resp =>
              AttachmentsResource.post(
                {
                  targetPath: AttachmentsResource.targetPathEnum.CLIENTS,
                  targetId: $stateParams.clientId,
                  pivotId: vm.workpaper.id,
                  pivotType: 'workpapers',
                },
                {
                  file: {
                    ...resp.data.file,
                    path_to_s3,
                  },
                }
              )
            )
            .then(resp => {
              vm.workpaper.attachments.push(resp.data.attachments[0]);
            })
            .catch(catchAsyncStacktrace());
        });

        dropzone.on('error', (file, message) => {
          var node, _i, _len, _ref, _results;

          file.previewElement.querySelector('.cp-file-upload__filesize').style.display = 'none';
          file.previewElement.querySelector('.cp-file-upload__filename').style.display = 'none';

          file.previewElement.classList.remove('dz-progress');
          file.previewElement.classList.add('dz-error');

          if (file.xhr) {
            message = `Your file ${file.name} failed to upload...`;
          }

          if (!isString(message) && message.error) {
            message = message.error;
          }

          _ref = file.previewElement.querySelectorAll('[data-dz-errormessage]');
          _results = [];

          for (_i = 0, _len = _ref.length; _i < _len; _i++) {
            node = _ref[_i];
            _results.push((node.textContent = message));
          }

          file.previewElement.querySelector('.cp-workpapers__dropzone__preview__progress').style.opacity = 0;

          let previewEl = file.previewElement.querySelector('.cp-workpapers__dropzone__preview');
          previewEl.style.color = '#ff0000';
          previewEl.style.backgroundColor = '#feecea';
          previewEl.style.borderTop = 'solid 1px #ECE1DF';
          previewEl.style.borderBottom = 'solid 1px #ECE1DF';

          return _results;
        });
      })
    }

    init();

    vm.getAttachmentIcon = fileName => {
      return fileHelper.getFileIconPath(fileName);
    };

    // handle the delete of a file/workpaper
    vm.deleteAttachment = attachment => {
      AttachmentsService.delete({
        clientId: $stateParams.clientId,
        attachmentId: attachment.id,
      }).then(() => {
        vm.workpaper.attachments.splice(vm.workpaper.attachments.findIndex(temp => temp.id === attachment.id), 1);
      });
    };

    //handle the update of workpaper container's status
    vm.updateStatus = status => {
      vm.workpaper.status = status;
      WorkpapersService.update(
        {
          clientId: $stateParams.clientId,
          engagementId: $stateParams.engagementId,
          sectionId: $stateParams.sectionSlug.replace(/\D/g, ''),
          workpaperId: vm.workpaper.id,
        },
        vm.workpaper
      );
    };

    // handle the edit of section details
    vm.saveSectionDetails = () => {
      if (vm.editSectionForm.$valid) {
        vm.section.name = vm.tempSectionName;
        vm.section.description = vm.tempSectionDescription;

        ProgramSectionsService.update(
          {
            clientId: $stateParams.clientId,
            engagementId: $stateParams.engagementId,
            sectionId: $stateParams.sectionSlug,
          },
          vm.section
        ).then(result => {
          let sectionSummary = EngagementService.getSectionFromEngagementWithIds(
            vm.engagement,
            vm.section.relationships.parent_program.uid,
            vm.section.id
          );
          if (!sectionSummary) {
            throw new Error(
              `Somehow we updated a section that apparently is not in the engagement?? section id == ${vm.section.id}`
            );
          }
          sectionSummary.name = vm.section.name;
          sectionSummary.description = vm.section.description;
        });

        vm.editMode = false;
      }
    };

    function downloadAttachment(attachment){
      SystemJS.import('docs-ui!sofe').then(m => {
        m.isFileSafeToOpen(attachment.file_id).then(({trust, virusStatuses, file}) => {
          if(trust){
            window.location.href = file.download_link;
          }
          else {
            vm.containers[attachment.id] = document.getElementById(`file-${attachment.id}`)
            const parcel = applicationProps.mountParcel(m.VirusModalParcel, {
              virusFound: virusStatuses.infectedDocs.length > 0,
              domElement: vm.containers[attachment.id],
              handleCloseModal() {
                parcel.unmount()
              }
            })
          }
        })
      })
    }
  },
]);
