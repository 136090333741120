export function createBlankWorkpaperStub(sectionId) {
  return {
    type: 'workpapers',
    title: null,
    description: null,
    status: 'in_progress',
    due_at: null,
    relationships: {
      assigned_to: {
        type: 'user',
        id: null
      },
      belongs_to: {
        type: "section",
        id: sectionId
      }
    }
  };
}
