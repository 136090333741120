import jquery from 'jquery';
import angular from 'angular';
window.jQuery = window.$ = jquery; // expose jquery on the window so that angular has access to it

//angular deps
import 'angular';
import 'angular-animate';
import 'angular-mocks';
import 'angular-resource';
import 'angular-sanitize';
import 'angular-ui-router';
import 'angular-drag-and-drop-lists';

import 'angular/a-modules/app-engagements.module.js';
import 'angular/a-modules/app-admin.module.js';
import 'angular/a-modules/app-clients-dates.module.js';
import 'angular/a-modules/app-clients-files.module.js';
import 'angular/a-modules/app-clients-items.module.js';
import 'angular/a-modules/app-clients-notes.module.js';
import 'angular/a-modules/app-clients-requests.module.js';
import 'angular/a-modules/app-clients-resolution.module.js';
import 'angular/a-modules/app-clients-taxes.module.js';
import 'angular/a-modules/app-clients-todos.module.js';
import 'angular/a-modules/app-clients.module.js';
import 'angular/a-modules/app-core.module.js';
import 'angular/a-modules/app-helpers.module.js';
import 'angular/a-modules/app-routes.module.js';
import 'angular/a-modules/app-tenant.module.js';
import 'angular/a-modules/app-tenant-dashboard.module.js';
import 'angular/a-modules/app-transcripts.module.js';
import 'angular/a-modules/app.module.js';

import 'angular/bootstrap/config/routes.config.js';
import 'angular/routes/bootstrap.routes.js';
import 'angular/routes/admin.routes.js';
import 'angular/routes/client.routes.js';
import 'angular/routes/engagements.routes.js';
import 'angular/routes/tenant.routes.js';
import 'angular/routes/transcripts.routes.js';

import 'angular/common/textAngular.config.js';
import 'angular/common/grid.config.js';

/** Import Common Directives **/
import 'angular/common/directives/date/time.directive.js';
import 'angular/common/directives/custom-element.directive.js';
import 'angular/common/directives/navbars/topnav-secondary.directive.js';
import 'angular/common/directives/navbars/secondary-nav.directive.js';
import 'angular/common/directives/navbars/nav-content.directive.js';
import 'angular/common/directives/navbars/use-nav-content-top.directive.js';
import 'angular/common/directives/navbars/use-remaining-height.directive.js';
import 'angular/common/directives/access/has-access.directive.js';
import 'angular/common/directives/date/date-input.directive.js';
import 'angular/common/directives/validation-rules/validEmail.js';
import 'angular/common/directives/hide-in-production.directive.js';
import 'angular/common/directives/cp-grid.component.js';
import 'angular/common/directives/integer.directive.js';
import 'angular/common/directives/modal-dialog.directive.js';
import 'angular/common/directives/on-focus.directive.js';
import 'angular/common/directives/on-enter.directive.js';
import 'angular/common/directives/radio-buttons.component.js';
import 'angular/common/directives/resizeable.directive.js';
import 'angular/common/directives/select-on-click.directive.js';
import 'angular/common/directives/show-errors/show-errors.directive.js';
import 'angular/common/directives/checklist-model.directive.js';
import 'angular/common/directives/body-click.directive.js';
import 'angular/common/directives/client-select.directive.js';
import 'angular/common/directives/focus-when.directive.js';
import 'angular/common/directives/contenteditable.directive.js';
import 'angular/common/directives/decimal-number.directive.js';
import 'angular/common/directives/whole-number.directive.js';
import 'angular/common/directives/progress-bar/progress-bar.directive.js';
import 'angular/common/directives/ignore-double-click.directive.js';
import 'angular/common/directives/truncated-copyable.component.js';
import 'angular/common/directives/tax-year-range-selector/tax-year-range-selector.directive.js';
import 'angular/common/directives/system-message.component.js';
import 'angular/common/directives/fake-cp-select-link.component.js';
import 'angular/common/directives/percentage-round/percentage-round.directive.js';
import 'angular/common/directives/compile-html.directive.js';
import 'angular/common/directives/clipboard.directive.js';
import 'angular/common/tables.style.css';

/** Import Common Filters **/
import 'angular/common/filters/address.filter.js';
import 'angular/common/filters/dates-today.filter.js';
import 'angular/common/filters/EmailFilter.js';
import 'angular/common/filters/newline2brtrustedFilter.js';
import 'angular/common/filters/RoundFilter.js';
import 'angular/common/filters/truncate.filter.js';
import 'angular/common/filters/lodash.filter.js';
import 'angular/common/filters/valueOf.filter.js';
import 'angular/common/filters/time-ago.filter.js';
import 'angular/common/filters/capitalize.filter.js';
import 'angular/common/filters/html-sanitize.filter.js';
import 'angular/common/filters/phone-number.filter.js';
import 'angular/common/filters/ssn.filter.js';
import 'angular/common/filters/ein.filter.js';
import 'angular/common/filters/greater-than.filter.js';
import 'angular/common/filters/format-bytes.filter.js';
import 'angular/common/filters/todayOrTomorrow.filter.js';

/** Other Angular Helpers **/
import 'angular/common/angular-helpers/exception-override.service.js';
import 'angular/common/angular-helpers/login.service.js';
import 'angular/common/angular-helpers/modal-dialog.service.js';
import 'angular/common/angular-helpers/url-state.service.js';
import 'angular/common/angular-helpers/form-helpers.service.js';

import 'angular/common/helpers/error-handling.service.js';
import 'angular/common/helpers/clean-html.helpers.js';
