import angular from "angular";

angular.module('app.helpers').factory('$exceptionHandler', function() {
  return function(exception, cause) {
    if (cause) {
      exception.message += ' (caused by "' + cause + '")';
    }
    throw exception;
  }
});
