import angular from "angular";

angular.module("app")
  .directive("cpOnEnter", [ '$timeout',
    function($timeout) {
      return {
        restrict: "A",
        link: function(scope, el, attr) {
          el.bind("keydown keypress", (e) => {
            if (e.which === 13) {
              $timeout(() => {
                scope.$apply(() => {
                  scope.$eval(attr.cpOnEnter);
                });
                e.preventDefault();
              }, 0, false);
            }
          });
        }
      }
    }
  ]);
