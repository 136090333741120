import angular from 'angular';
import { asyncStacktrace } from 'auto-trace';
import 'angular/resources/section-envelopes.resource.js';

angular.module('app.clients.taxes')
.factory('SectionEnvelopesService', ['SectionEnvelopesResource', '$q',
  function SectionEnvelopesService(SectionEnvelopesResource, $q) {
    return {
      getEnvelope: ({clientId, engagementId, sectionId, envelopeId}) => {
        if (window.location.hash.includes('#/403')) {
          // long story here but basically getEnvelope is called incorrectly
          // in nested promise in $scope.$watch (see assistang-intro.component).
          // if we're already redirecting to unauthorized, clientId will be null and will toast an error
          // which we want to avoid because the user is unauthorized anyhow
          const deferred = $q.defer();
          deferred.reject()
          return deferred.promise
        }
        if (!clientId)
          throw new Error(`Client id is required, but was '${clientId}'`);
        if (!engagementId)
          throw new Error(`Engagement id is required, but was '${engagementId}'`);
        if (!sectionId)
          throw new Error(`Section id is required, but was '${sectionId}'`);
        if (!envelopeId)
          throw new Error(`Envelope id is required, but was '${envelopeId}'`);

        const deferred = $q.defer();

        SectionEnvelopesResource.get({
          clientId,
          engagementId,
          sectionId,
          envelopeId,
        })
        .then((response) => {
          deferred.resolve(response.data.envelopes);
        })
        .catch(asyncStacktrace(response => {deferred.reject(response)} ))

        return deferred.promise;
      },

      updateEnvelope: ({clientId, engagementId, sectionId, envelopeId, envelope}) => {
        if (!clientId)
          throw new Error(`Client id is required, but was '${clientId}'`);
        if (!engagementId)
          throw new Error(`Engagement id is required, but was '${engagementId}'`);
        if (!sectionId)
          throw new Error(`Section id is required, but was '${sectionId}'`);
        if (!envelopeId)
          throw new Error(`Envelope id is required, but was '${envelopeId}'`);
        if (typeof envelope !== 'object')
          throw new Error(`Envelope must be an object, but was '${envelope}'`);

        const deferred = $q.defer();

        SectionEnvelopesResource.put({
          clientId,
          engagementId,
          sectionId,
          envelopeId,
        }, envelope)
        .then((response) => {
          deferred.resolve(response.data);
        })
        .catch(asyncStacktrace(response => {deferred.reject(response)} ))

        return deferred.promise;
      },
    };
  }
]);
