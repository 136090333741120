import { get } from 'lodash';

const codesToIgnore = [
  'br0',
  '4204',
  '4202',
];

export function shouldIgnore(rejection) {
  // These are the cases where we want custom UX to handle the rejection, so we don't want to log to sentry or show a toast
  return (
    get(rejection, 'data.errors.exception') === 'BillingBusinessRule'
  ) || (
    codesToIgnore.some(code => rejectionHasCode(rejection, code))
  ) || (
    rejection.status === 403 && rejection.config.url.includes('/api/contacts/')
  )
}

function rejectionHasCode(rejection, code) {
  return get(rejection, 'data.errors.code') === code;
}

