/**
 * Phone input widget
 *
 * Example <cp-phone-input model="someModel" name="hi" required></cp-phone-input>
 *
 * The widget model needs to be in the format 9999999999999
 * where the last 3 digits are the extension.
 *
 * The name attribute will get added to the primary input. The required flag will make
 * the primary phone input required, not the extension.
 *
 * Disable the extension filed with "no-extension" attribute
 */
import "angular-ui-mask-canopy/dist/mask.min.js";

angular.module('cp-phone-input', ["ui.mask"])
  .directive('cpPhoneInput', function() {
    return {
      restrict: 'E',
      scope: {
        model: '=',
        name: '@',
        required: '@',
        placeholder: '@'
      },
      link: function(scope, el, attr) {

        function buildModel() {
          if(scope.phone) {
            scope.model = scope.phone.substring(0, 3) + scope.phone.substring(3, 6) + scope.phone.substring(6, 10);
            if(scope.ext) {
              scope.model += scope.ext;
            }
          } else {
            scope.model = null;
          }
        }

        scope.$watch('model', function(value) {
          var regex = /(\d\d\d)(\d\d\d)(\d\d\d\d)(\d*)/

          if (value) {
            value = value.toString().replace(/\(|\)|\-|\s/g, '');

            if (regex.test(value)) {
              var parts = regex.exec(value);
              scope.phone = parts[1] + parts[2] + parts[3];

              if (parts.length === 5 && parts[4]) {
                scope.ext = parts[4];
              } else {
                scope.ext = '';
              }
            } else {
              scope.ext = '';
              scope.phone = '';
            }
          }
        });

        scope.$watch('phone', function(value) {
          buildModel();
        });

        scope.$watch('ext', function(value) {
          buildModel();
        });

        if (_.has(attr, 'required') && !_.isUndefined(attr.required)) {
          el.find('input').eq(0).attr('required', attr.required);
        }

        scope.hasExtension = !_.has(attr, 'noExtension');

        scope.placeholder = attr.placeholder;
      },
      template: " \
      <div class='cps-form-inline'>\
        <input ui-mask-placeholder name='{{name}}' class='cps-form-control input-sm' ng-model='phone' ui-mask='(999) 999-9999' placeholder='{{placeholder || \"Phone number\"}}' ng-required={{required}} type='text'/>\
        <label ng-show='hasExtension'>Ext.</label>\
        <input ng-show='hasExtension' ng-model='ext' type='text' size='4' class='cps-form-control input-sm'/>\
      </div>\
      "
    }
  });
