import angular from "angular";

import { forkJoin } from 'rxjs'
import { first } from 'rxjs/operators'

import { showPageNotFound } from 'canopy-urls!sofe';
import auth from 'cp-client-auth!sofe';

import "angular/bootstrap/app.controller.js";
import "angular/bootstrap/app.run.js";
import "angular/bootstrap/context.service.js";
import "angular/bootstrap/config/ajax-interceptors.config.js";
import "angular/bootstrap/config/debug-mode.config.js";

import "angular/app/admin/users/user.service.js";
import "angular/app/admin/tenant/tenant.service.js";

import "angular/app/layouts/logged-in-user-deleted.component.js";

import { cloneDeep } from 'lodash';
import { addHasAccessFunction } from 'angular/app/admin/users/user-service.helpers.js';

angular.module('app.routes')
  .config(function ($stateProvider, $urlMatcherFactoryProvider, $urlRouterProvider) {
    var context;

    //trailing slashes or no slashes at all is fine
    $urlMatcherFactoryProvider.strictMode(false);

    $stateProvider
      .state('base', {
        abstract: true,
        template: '<ui-view/>',
        controller: 'AppController as context',
        resolve: {
          context: function ($rootScope, $q, $window, $timeout, TenantService, UserService) {

            // @NOTE: remember to add any new context items to AppController
            // @NOTE: Consider whether any changes here should also be made to tax-resolution routes.

            $rootScope.$on('$viewContentLoaded', () => {
              window.dispatchEvent(new CustomEvent("cp:banner:please-update-classes"));
            });

            context = {};

            const userAndTenant = $q.defer();

            context.tenantUserSubscription = forkJoin(
              auth.getTenantAsObservable().pipe(first()),
              auth.getLoggedInUserAsObservable().pipe(first()),
            ).subscribe(([tenant, loggedInUser]) => {
              context.loggedInUser = addHasAccessFunction(cloneDeep(window.loggedInUser));
              context.tenant = cloneDeep(window.tenant);
              userAndTenant.resolve();
            });

            function success() {
              return context;
            }

            return userAndTenant.promise.then(success);
          }
        }
      })

      .state('app', {
        parent: 'base',
        abstract: true,
        template: '<ui-view />'
      })

      .state('app.redirect', {
        url: '/redirect?state&params',
        controller: ['$state',
          function ($state) {
            setTimeout(() => {
              $state.go(
                $state.params.state,
                JSON.parse($state.params.params || null)
              );
            })
          }
        ]
      })

      .state('deleted-user', {
        url: '/logged-in-user-deleted',
        template: '<logged-in-user-deleted />',
      })

      .state('non-active-user', {
        url: '/logged-in-user-not-active',
        template: '<logged-in-user-deleted />',
      })

      .state('404', {
        parent: 'app',
        controller() {
          if (!window.location.hash.includes('global-settings/expired')) {
            showPageNotFound();
          }
        }
      })

      .state('403', {
        parent: 'app',
        controller: function ($location) {
          $location.path('403').replace();
        }
      })
  });
