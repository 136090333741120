import angular from "angular";

angular
.module('app.clients.taxes')
.directive('collectionsProgramAnalytics', [
  function() {
    return {
      restrict: 'E',
      scope: {
      }, // necessary so we don't overwrite parent scope
      template: '',
      link: ['$stateParams',
        function($stateParams) {
          /* eslint-disable */

          // This has to stick around for backwards compatibility -- we've sent emails that point to this directive's route.
          window.location.href = `forms/#/clients/${stateParams.clientId}/engagements/${$stateParams.engagementId}/collections/analytics`;

          /* eslint-enable */
        }
      ]
    }
  }
]);
