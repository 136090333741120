import angular from "angular";
import canopyUrls from 'canopy-urls!sofe';

angular.module('app')
  .directive('cpHideInProduction', [
    function() {
      return {
        restrict: 'A',
        link: function(scope, el, attrs) {

          var environment;

          el.hide();
          environment = canopyUrls.getEnvironment();

          if (environment === canopyUrls.DEVELOPMENT_ENVIRONMENT) {
            el.show();
          } else {
            el.remove();
          }
        }
      }
    }
  ]);
