import { ReplaySubject, combineLatest } from 'rxjs'
import { property } from 'lodash';
import { catchAsyncStacktrace } from 'auto-trace';


export function navContentPostLink(cssProperty, callback, scope, element, attrs) {
  const SECONDARY_NAV_HEIGHT = 56;

  let disposable, isDestroyed = false;

  // A promise that returns an observable for the height of the primary-navbar
  const pnhObservablePromise = SystemJS
    .import('primary-navbar!sofe')
    .then(property("primaryNavHeightObs"));

  // A promise that returns an observable for the height of the secondary navbar (including contact menu)
  let secondaryNavHeightObservablePromise;
  // If we create our own observable/subject for the height, we'll need to clean it up when we're done
  let secondaryNavHeightSubject;

  if (attrs.hasTopnavSecondary === 'true') {
    secondaryNavHeightSubject = new ReplaySubject(1);
    secondaryNavHeightSubject.next(SECONDARY_NAV_HEIGHT);
    secondaryNavHeightObservablePromise = Promise.resolve(secondaryNavHeightSubject);
  } else if (attrs.contactMenuPossible === 'true') {
    if (window.loggedInUser.role === "Client") {
      // Clients do not see the contact menu
      secondaryNavHeightSubject = new ReplaySubject(1);
      secondaryNavHeightSubject.next(0);
      secondaryNavHeightObservablePromise = Promise.resolve(secondaryNavHeightSubject);
    } else {
      secondaryNavHeightObservablePromise = SystemJS
        .import('contact-menu!sofe')
        .then(property("contactMenuHeightObs"));
    }
  } else {
    // There is no secondary nav
    secondaryNavHeightSubject = new ReplaySubject(1);
    secondaryNavHeightSubject.next(0);
    secondaryNavHeightObservablePromise = Promise.resolve(secondaryNavHeightSubject);
  }

  Promise
    .all([pnhObservablePromise, secondaryNavHeightObservablePromise])
    .then(values => {
      const [primaryNavHeightObservable, secondaryNavHeightObservable] = values;
      // Now combine the two observables together.
      disposable = combineLatest(
        primaryNavHeightObservable,
        secondaryNavHeightObservable,
      )
        .subscribe(values => {
          if (isDestroyed)
            return;

          const [primaryNavHeight = 56, secondaryNavHeight = 56] = values;
          const heightStr = String(primaryNavHeight + secondaryNavHeight) + 'px';
          if (cssProperty) {
            element.css(cssProperty, heightStr);
          }
          callback(element, primaryNavHeight + secondaryNavHeight);
        });
    })
    .catch(catchAsyncStacktrace());

  scope.$on('$destroy', () => {
    isDestroyed = true;

    disposable && disposable.unsubscribe()

    if (secondaryNavHeightSubject && secondaryNavHeightSubject.onCompleted) {
      secondaryNavHeightSubject.complete();
    }
  })
}

