import angular from "angular";

import "angular/app/client/taxes/section-interactions/assistant-intro.style.css";
import "angular/app/client/taxes/section-interactions/assistant-shared.style.css";

import 'angular/app/client/taxes/programs/assistant/assistant-answers.service.js';

import * as assistantConstants from 'angular/app/client/taxes/programs/assistant/assistant.constants.js';

angular.module('app.clients.taxes').component('cpAssistantIntroLiens', {
  bindings: {
    questionSlugs: '='
  },

  controllerAs: 'vm',

  controller: function ($state, AssistantAnswersService) {
    const vm = this;

    vm.startAssistant = () => {
      AssistantAnswersService.submitAnswer(vm.questionSlugs.INTRO, true, {
        sectionEnvelope: vm.sectionEnvelope,
        section: vm.section,
        activeTeamMembers: vm.activeTeamMembers
      });
    };
  },

  template: ($element, $attrs) => {
    return `<div class="cps-flexible-focus cps-card cps-text-center cp-assistant-intro">
            <div class="cps-subheader cps-color-primary cp-assistant-intro__welcome">
              Welcome to Canopy's<br />
              Liens Assistant
            </div>

            <div class="cps-subheader-sm cps-medium-gray cp-assistant-intro__smart-recommend">
              A smart recommendation is only a few questions away
            </div>

            <div class="cp-assistant-intro__section--content cps-margin-bottom-8"><span class="cps-color-primary"><strong>Simply powerful</strong></span> - Answer simple questions and reduce your research burden.</div>
            <div class="cp-assistant-intro__section--content cps-margin-bottom-8"><span class="cps-color-primary"><strong>Smart and productive</strong></span> - Cover various scenarios so you don't miss anything.</div>
            <div class="cp-assistant-intro__section--content cps-margin-bottom-32"><span class="cps-color-primary"><strong>Custom recommendations</strong></span> - Access helpful resources tailored to meet your client's needs.</div>

            <cps-button action-type="primary" phat="true">
              <button id="start-assistant-liens" ng-class="['cp-assistant-intro__actions__start']" ng-click="vm.startAssistant()">Get started</button><br />
            </cps-button>

            <cps-button action-type="flat">
              <button id="direct-to-outcomes-liens" ui-sref="engagement.layout.program.section({sectionSlug: '${assistantConstants.LIENS_OUTCOMES_SLUG}'})" ng-class="['cps-light-gray', 'cps-margin-top-24', 'cp-assistant-intro__actions__outcome']">
                No thanks, take me to outcomes
              </button>
            </cps-button>
          </div>`;
  }
});
