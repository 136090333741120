import angular from 'angular';

angular.module('app').directive('cpWholeNumber', function($filter) {
  return {
    require: 'ngModel',
    restrict: 'A',
    link: function(scope, element, attrs, ngModel) {
      function parse(value) {
        let regex = attrs.allowZero === 'true' ? /^[0-9]*$/ : /^[1-9][0-9]*$/;
        let clean = regex.exec(value);
        if (!clean) {
          value = value.slice(0, -1);
        }
        ngModel.$setViewValue(value);
        ngModel.$render();
        return value;
      }
      ngModel.$parsers.push(parse);
    },
  };
});
