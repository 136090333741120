import angular from "angular";
import { asyncStacktrace } from 'auto-trace';

import "angular/resources/comments.resource.js";

import _ from "lodash";

angular.module('app')
.factory('CommentsService', ['$q', 'CommentsResource', '$stateParams',
  function CommentsService($q, CommentsResource, $stateParams) {

    return {
      getList: (params) => {
        params = { ...$stateParams, ...params };
        let deferred = $q.defer();

        CommentsResource.getList(params)
        .then(function success(response) {
          deferred.resolve(response.data.comments);
        })
        .catch(asyncStacktrace(response => {deferred.reject(response)} ));

        return deferred.promise;
      },

      add: (params, comment, notification) => {
        params = { ...$stateParams, ...params };
        if (comment.relationships && comment.relationships.for && comment.relationships.for.id) {
          comment.relationships.for.id = parseInt(comment.relationships.for.id);
        }
        let deferred = $q.defer();

        CommentsResource.post(params, comment, notification)
        .then(function success(response) {
          deferred.resolve(response.data.comments.id);
        })
        .catch(asyncStacktrace(response => {deferred.reject(response)} ));

        return deferred.promise;
      },

      update: (params, comment) => {
        params = { ...$stateParams, ...params };
        if (!params.commentId) {
          params.commentId = comment.id;
        }
        let payload = _.cloneDeep(comment);
        delete payload.id;
        delete payload.client_id;
        delete payload.created_at;

        let deferred = $q.defer();

        CommentsResource.put(params, payload)
        .then(function success(response) {
          deferred.resolve(response.data.comments);
        })
        .catch(asyncStacktrace(response => {deferred.reject(response)} ));

        return deferred.promise;
      },

      get: (params) => {
        params = { ...$stateParams, ...params };
        let deferred = $q.defer();

        CommentsResource.get(params)
        .then(function success(response) {
          deferred.resolve(response.data.comments);
        })
        .catch(asyncStacktrace(response => {deferred.reject(response)} ));

        return deferred.promise;
      },

      delete: (params) => {
        params = { ...$stateParams, ...params };
        let deferred = $q.defer();

        CommentsResource.delete(params)
        .then(function success(response) {
          deferred.resolve(response.data.comments);
        })
        .catch(asyncStacktrace(response => {deferred.reject(response)} ));

        return deferred.promise;
      },

      newCommentTemplate: (loggedInUser, parentObjType, parentObjId) => ({
        "body": "",
        "relationships": {
          "author": {
            "name": loggedInUser.name,
            "initials": loggedInUser.initials,
            "type": "user",
            "id": loggedInUser.id
          },
          "for": {
            "type": parentObjType,
            "id": parentObjId
          }
        }
      }),
    };

  }
]);
