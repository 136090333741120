import angular from 'angular';
import template from './active-notes-list.template.html'
import './active-notes-list.style.css'

angular
.module('app.clients.notes')
.component('activeNotesList', {
  bindings: {
    notes: '=',
    expanded: '=',
  },
  template,
  controllerAs: 'vm',
  controller($scope) {
    const vm = this;
    vm.nonPinnedExist = () => (vm.notes || []).some(note => !note.pinned_at);
  }
});
