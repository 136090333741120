import angular from "angular";
import $ from "jquery";

angular.module("app")
  .directive("cpBodyClick", ['$parse', '$timeout',
    function($parse, $timeout) {
      var id = 0;
      return {
        restrict: "A",
        link: function(scope, el, attr) {
          id++; // each instance needs a unique id for namespacing the event

          // Set a timeout because the current element may have been rendered with
          // a mouse click and we want to delay before registering the event listener
          $timeout(() => {
            $('body').on('click.cpBodyClick' + (id), function(event) {
              if (!$(event.target).closest(el).length) {
                scope.$apply(function() {
                  $parse(attr.cpBodyClick)(scope);
                })
              }
            });
          }, 0, false);

          scope.$on('$destroy', function() {
            $('body').off('click.cpBodyClick' + (id));
          });
        }
      }
    }
  ]);
