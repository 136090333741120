import angular from 'angular';
import { asyncStacktrace } from 'auto-trace';

import 'angular/app/clients/contact.service.js';
import 'angular/app/client/client.controller.js';
import mainClientTemplate from 'angular/app/client/layouts/main-client.layout.html';

import 'angular/app/client/general/dashboard/client-dashboard.directive.js';
import 'angular/app/client/general/files/files-index.directive.js';

// Old notes directives
import 'angular/app/client/general/notes/edit-note.directive.js';
// New notes directives
import 'angular/app/client/general/notes/active-notes.component.js';
import 'angular/app/client/general/notes/archived-notes.component.js';
import 'angular/app/client/general/notes/note-editor.component.js';

import 'angular/app/client/general/requests/dashboard-request-list.component.js';
import 'angular/app/client/general/requests/requests-index.component.js';

angular.module('app.routes').config(function ($stateProvider) {
  var getClientSubscription;

  const resolveClient = {
    client: [
      '$q',
      '$rootScope',
      '$stateParams',
      '$state',
      'context',
      'ContactService',
      function ($q, $rootScope, $stateParams, $state, context, ContactService) {
        var deferred = $q.defer();

        let numActiveClientResolves = 0;

        if (getClientSubscription) {
          getClientSubscription.unsubscribe();
        }

        const error = asyncStacktrace(e => {
          deferred.reject(e);
        });

        getClientSubscription = ContactService.cachedGetActiveClient($stateParams.clientId).subscribe(
          function newActiveClient(client) {
            if (context.loggedInUser.role === 'Client' && !client.is_active) {
              $state.go('non-active-user');
            }
            if (++numActiveClientResolves === 1) {
              deferred.resolve(client);
            } else {
              $rootScope.$broadcast('cp:contact-data-updated', client);
            }
          },

          function err(errorResponse) {
            if (errorResponse.status === 404) {
              $state.go('404');
            } else if (errorResponse.status === 403) {
              $state.go('403');
            } else {
              error(errorResponse);
            }
          }
        );

        return deferred.promise;
      },
    ],
  };

  $stateProvider
    .state('client', {
      parent: 'app',
      abstract: true,
      url: '/client/{clientId}',
      resolve: resolveClient,
      template: mainClientTemplate,
      controller: 'ClientController as vm',
    })

    .state('docs-ui', {
      parent: 'app',
      url: '/docs/clients/{clientId}',
      abstract: true,
      resolve: resolveClient,
      template: mainClientTemplate,
      controller: 'ClientController as vm',
    })

    .state('docs-ui.home', {
      url: '/{beginPath:.*}{endPath:.*}',
      template: '',
    })

    /*
     * Register communication states
     *
     */
    .state('communications', {
      parent: 'app',
      url: '/communications/clients/{clientId}',
      resolve: resolveClient,
      template: mainClientTemplate,
      controller: 'ClientController as vm',
    })

    .state('communications.home', {
      url: '/{beginPath:.*}{endPath:.*}',
      template: '',
    })

    /*
     *  Register general states
     *
     */
    .state('client.general', {
      abstract: true,
      url: '',
      template: '<ui-view/>',
    })

    .state('client.general.dashboard', {
      url: '?newContact',
      template: '<client-dashboard client="vm.client" state="$state"></client-dashboard>',
      reloadOnSearch: true,
    })

    /*
     * Register notes states
     *
     */

    .state('client.general.notes', {
      abstract: true,
      url: '/notes',
      template: () => null,
    })

    .state('client.general.notes.index', {
      url: '?note',
      reloadOnSearch: false,
      template: () => null,
    })

    /*
     * Register request states
     *
     */
    .state('client.general.requests', {
      url: '/requests',
      template: '<cp-requests-index></cp-requests-index>',
    })

    .state('client.general.requests.instance', {
      url: '/{clientRequestId}?signing',
      template: '<cp-requests-index></cp-requests-index>',
    })

    /*
     * Register files states
     *
     */
    .state('client.general.files', {
      abstract: true,
      template: '<ui-view/>',
    });
});
