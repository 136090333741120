import angular from "angular";

angular.module('app.clients.taxes')
  .factory('WorkpapersResource', ['$http',
    function WorkpapersResource($http) {
      
      return {
        getList: (params) => $http.get(`/api/clients/${params.clientId}/engagements/${params.engagementId}/sections/${params.sectionId}/workpapers`),
        
        post: (params, workpaper) => $http.post(`/api/clients/${params.clientId}/engagements/${params.engagementId}/sections/${params.sectionId}/workpapers/`, {"workpapers": workpaper}),

        get: (params) =>  $http.get(`/api/clients/${params.clientId}/engagements/${params.engagementId}/sections/${params.sectionId}/workpapers/${params.workpaperId}`),

        put: (params, workpaper) => $http.put(`/api/clients/${params.clientId}/engagements/${params.engagementId}/sections/${params.sectionId}/workpapers/${params.workpaperId}`, {"workpapers": workpaper}),

        delete: (params) => $http.delete(`/api/clients/${params.clientId}/engagements/${params.engagementId}/sections/${params.sectionId}/workpapers/${params.workpaperId}`)
      };
    }
  ]);
