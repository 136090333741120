import angular from "angular";
import canopyUrls from 'canopy-urls!sofe';

angular.module('app')
  .factory('TenantResource', ['$http',
    function TenantResource($http) {
      return {
        get(params) {
          return $http.get(`${canopyUrls.getAPIUrl()}/tenants/${params.tenantId}`);
        },

        patch(params, payload) {
          return $http.patch(
            `${canopyUrls.getAPIUrl()}/tenants/${params.tenantId}`,
            payload
          );
        }
      }
    }
  ]);
