import angular from "angular";
import canopyUrls from "canopy-urls!sofe";

angular.module('app')
  .config(['$urlRouterProvider', '$locationProvider',
    function ($urlRouterProvider, $locationProvider) {

      $locationProvider.html5Mode({
        enabled: false,
        requireBase: false
      });

      // and let's redirect any bad urls
      $urlRouterProvider.otherwise(function($injector, $location) {
        let $state = $injector.get('$state');
        switch ($location.path()) {
          case '/login':
          case '/forgot-password':
            window.location = canopyUrls.getAuthUrl() + $location.path();
            break;
          case '/accountant-signup':
            window.location = canopyUrls.getWorkflowUrl() + $location.path();
            break;
          case '':
          case '/':
            break;
          default:
            //accountant signup page sometimes has a referer code afterwards, and /r/ can have things after it to redirect to
            if ($location.path().indexOf('/accountant-signup') === 0 || $location.path().indexOf('/r/') === 0) {
              window.location = canopyUrls.getWorkflowUrl() + $location.path();
              return;
            }

            //we used to put the tenantId/app in the url, but we no longer do
            if ($location.url().match(/\/[0-9]+\/app\//)) {
              const indexOfWhereUrlReallyBegins = $location.url().indexOf('/app') + 4;
              return $location.url().substr(indexOfWhereUrlReallyBegins);
            }
            return $location.path();
        }
      });
    }]);
