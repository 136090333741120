import angular from 'angular';
import moment from 'moment';
import template from './note-editor.template.html';
import './note-editor.style.css';
import { asyncStacktrace, catchSyncStacktrace, catchAsyncStacktrace } from 'auto-trace';
import { isEmpty } from 'lodash';
import { stripHTML } from './note-editor.helper.js'

angular
.module('app.clients.notes')
.component('noteEditor', {
  bindings: {
  },
  template,
  controllerAs: 'vm',
  controller(NotesService, $scope, $state, $timeout, ContactService, textAngularManager, $window) {
    const vm = this;
    vm.saving = false;
    const clientId = $state.params.clientId;

    $scope.$watch('vm.note', () => {
      if (vm.note) {
        let updated_time_stamp = moment(vm.note.updated_at);
        vm.updatedAtDay = updated_time_stamp.format('ll'); // Sep 30, 2016
        vm.updatedAtTime = updated_time_stamp.format('h:mmA'); // 4:57PM
      }
    });

    vm.$onInit = () => {
      angular.element($window.document.getElementsByTagName('body')[0]).addClass('not-print');
      vm.activeClientSubscription = ContactService
        .cachedGetActiveClient($state.params.clientId)
        .subscribe(
          client => {
            $scope.$evalAsync(() => {
              vm.activeClient = client;
            });
          },
          catchAsyncStacktrace()
        );

      NotesService
      .getNote($state.params)
      .then(note => {
        vm.note = note;
        if (note.title.length === 0 && note.body.length === 0) {
          vm.focusTitle = true;
        } else {
          $timeout(() => {
            let editor = textAngularManager.retrieveEditor('noteEditor');
            if (editor) {
              const editorScope = editor.scope;
              editorScope.displayElements.text.trigger('focus');
            }
          });
        }
      })
      .catch(catchAsyncStacktrace());

      vm.clientId = $state.params.clientId;

      const bodyEl = document.querySelector('body');
      bodyEl.style.backgroundColor = 'white';
    }

    vm.$onDestroy = () => {
      const bodyEl = document.querySelector('body');
      bodyEl.style.backgroundColor = null;
      vm.activeClientSubscription.unsubscribe();
      angular.element($window.document.getElementsByTagName('body')[0]).removeClass('not-print');

      if (noteShouldBeDiscarded(vm.note)) {
        NotesService.deleteNote({clientId, noteId: vm.note.id});
      }
    }

    vm.customPaste = (html) => {
      return stripHTML(html)
    }

    vm.saveNote = () => {
      if (noteShouldBeDiscarded(vm.note)) {
        /* If the note should still be deleted when they navigate away, the $onDestroy will discard this note.
         * We need to avoid a race between deleting the note and saving the note.
         */
        return;
      }
      vm.saving = true;

      NotesService
      .saveNote({clientId: clientId, noteId: vm.note.id}, vm.note)
      .then(() => vm.saving = false)
      .catch(asyncStacktrace(err => {
        vm.saving = false;
        catchSyncStacktrace(err);
      }))
    }

    let timeout;

    vm.keyDown = () => {
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(vm.saveNote, 5000);
    }
  },
});

function noteShouldBeDiscarded(note) {
  return note && isEmpty(note.title) && isEmpty(note.body);
}
