import * as constants from './assistant.constants.js';

export function cantCalculateOutcomes(outcomes) {
  return outcomes === constants.OUTCOME_NOT_YET_CALCULABLE;
}

export function reliefUnlikely(outcomes) {
  return !!(outcomes && outcomes.indexOf(constants.RELIEF_UNLIKELY) >= 0);
}

export function appealAvailable(outcomes) {
  return !!(outcomes && outcomes.indexOf(constants.APPEAL) >= 0);
}

export function employmentTaxAvailable(outcomes) {
  return !!(outcomes && outcomes.indexOf(constants.EMPLOYMENT_TAX) >= 0);
}

export function employmentTaxWarningAvailable(outcomes) {
  return !!(outcomes && outcomes.indexOf(constants.EMPLOYMENT_TAX_WARNING) >= 0);
}

export function TFRPDefenseAvailable(outcomes) {
  return !!(outcomes && outcomes.indexOf(constants.TFRP_DEFENSE) >= 0);
}
