import angular from "angular";

// DEPRECATED - use DocsResource
angular.module('app.clients.files')
  .factory('FilesResource', ['$resource',
    function FilesResource($resource) {

      return $resource('/api/clients/:clientId/attachments/:fileId', {}, {
        update: {
          method: 'PUT'
        },
        index: {
          method: 'GET'
        },
        save: {
          method: 'PATCH'
        }
      });
    }
  ]);
