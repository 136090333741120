import angular from 'angular';
import { fromEvent } from 'rxjs'
import { debounceTime } from 'rxjs/operators'
import { filter } from 'lodash'
import { catchAsyncStacktrace } from 'auto-trace';
import template from './request-tool-side-pane.template.html';
import context from 'angular/bootstrap/context.service.js';
import '../client-sent-client-request.style.css';

import './request-tool-comments.component'
import './request-tool-footer.component'
import '../right-side-pane.component.js';
import '../client-request-header.component.js';
import '../client-request-due-date.component.js';

angular
  .module('app.clients.requests')
  .component('cpRequestToolSidePane', {
    bindings: {
      clientRequest: '=',
    },

    template,

    controllerAs: 'vm',

    controller: ['$scope', '$rootScope', 'ContactService', '$stateParams', '$timeout', 'RequestToolCommentsService',
      function RequestToolSidePane($scope, $rootScope, ContactService, $stateParams, $timeout, RequestToolCommentsService) {
        const vm = this;

        vm.parentObjType = ''
        vm.$stateParams = $stateParams;
        vm.isSigningRequest = false
        vm.comments = vm.clientRequest.relationships.comments
        vm.context = context.getContext();
        vm.loggedInUser = vm.context.loggedInUser;
        vm.userMap = RequestToolCommentsService.getUserMap()
        vm.requestToolMap = RequestToolCommentsService.getRequestToolMap()

        RequestToolCommentsService.setUsers(vm.comments.reduce((acc, c) => [...acc, {id: c.created_by_user_id, name: c.created_by_user_name}], []), vm.loggedInUser)

        $scope.$on('cp:request-tool:new-comment', (event, comment) => {
          vm.comments = [...vm.comments, comment];
        });

        function getFileAttachments() {
          const map = {
            request_tool: [vm.clientRequest.id],
          }
          if(vm.clientRequest.relationships.comments.length > 0){
            map.comment = vm.clientRequest.relationships.comments.map(c => c.id)
          }
          RequestToolCommentsService.getAllFilesRelatedTo(map).then(() => {
            $scope.$apply()
          })
        }

        $scope.$watch('vm.clientRequest', (newVal, oldVal) => {
          if(newVal.id !== oldVal.id){
            getFileAttachments()
          }
        });

        setTimeout(() => {
          const checkbox = document.getElementById('mark-complete')
          if(checkbox){
            const obs = fromEvent(checkbox, 'click').pipe(debounceTime(1000)).subscribe(
              val => {
                RequestToolCommentsService.toggleComplete(vm.clientRequest.id, vm.clientRequest.status)

              })

              $scope.$on('$destroy', () => {
                obs.unsubscribe()
              })
          }
        }, 500)
      }
    ],
  });
