import _ from 'lodash';

export function groupRequests(requests) {
  return _.groupBy(requests, function (value) {
    if (!value.first_sent_at) {
      return 'unsent'
    }
    return value.status;
  });
}

export function isViewable(container, child, offset = 0) {
  if (!container || !child)
    return true;
  container = container.getBoundingClientRect();
  child = child.getBoundingClientRect();
  return (container.bottom > child.top + offset);
}

export function scrollToTop(container, child, offset = 0) {
  if (!container || !child)
    return;
  let containerRect = container.getBoundingClientRect();
  let childRect = child.getBoundingClientRect();

  container.scrollTop += childRect.top - containerRect.top + offset;
}
