import angular from 'angular';
import { asyncStacktrace } from 'auto-trace';
import 'angular/resources/surveys-for-client.resource.js';

angular
.module('app.clients.taxes')
.factory('ClientSurveyMetadataService', ['$q', 'SurveysForClientResource',
  function ClientSurveyMetadataService($q, SurveysForClientResource) {
    return {getSurveysForClient};

    function getSurveysForClient(clientId) {
      if (!clientId) {
        throw new Error(`clientId is required`);
      }

      // We wrap the observable in a $q promise so that digest cycle is triggered.
      const deferred = $q.defer();
      const { resolve, reject, promise } = deferred;

      SurveysForClientResource
      .get(clientId)
      .subscribe(
        resolve,
        asyncStacktrace(reject)
      );

      return promise;
    }
  }
]);
