import * as questionSlugs from './assistant.constants.js';

// Questions
let questions = {};

questions[questionSlugs.ISSUED_NOTICE] = {
  questionText: 'Has the IRS already issued a Notice of Intent to Levy?',
  type: 'boolean'
};

questions[questionSlugs.ALREADY_LEVIED] = {
  questionText: 'Has the IRS already levied property of your client?',
  type: 'boolean'
};

questions[questionSlugs.LEVY_RELEASED] = {
  questionText: 'Has the levy been released and you now want the levied property returned?',
  type: 'boolean'
};

questions[questionSlugs.PROVE_FINANCIAL_HARDSHIP] = {
  questionText: 'Can you prove financial hardship?',
  type: 'boolean'
};

questions[questionSlugs.INCREASE_ABILITY_TO_PAY] = {
  questionText: `Will releasing the levy increase your client's ability to pay?`,
  type: 'boolean'
};

questions[questionSlugs.ISSUED_PREMATURELY] = {
  questionText: 'Was the levy issued prematurely?',
  type: 'boolean'
};

questions[questionSlugs.AGAINST_EXEMPT_PROPERTY] = {
  questionText: 'Was the levy issued against property exempt from seizure?',
  type: 'boolean'
};

questions[questionSlugs.VALUE_GREATER_THAN_OWED] = {
  questionText: `Is the value of the property greater than the amount owed and releasing the levy won't impede IRS collections?`,
  type: 'boolean'
};

questions[questionSlugs.OIC_OR_INSTALLMENT] = {
  questionText: 'Is an offer in compromise or installment agreement currently pending or in effect?',
  type: 'boolean'
};

questions[questionSlugs.RELEASE_FACILITATE] = {
  questionText: 'Will releasing the levy faciliate collection by the IRS?',
  type: 'boolean'
};

questions[questionSlugs.SATISFIED_LIABILITY] = {
  questionText: 'Has the taxpayer already satisfied the liability in full?',
  type: 'boolean'
};

questions[questionSlugs.FILED_FOR_BANKRUPTCY] = {
  questionText: 'Has the taxpayer filed for bankruptcy?',
  type: 'boolean'
};

questions[questionSlugs.COLLECTION_PERIOD_EXPIRED] = {
  questionText: 'Has the statutory collection period already expired?',
  type: 'boolean'
};

questions[questionSlugs.IDENTITY_THEFT] = {
  questionText: 'Was the taxpayer a victim of identity theft?',
  type: 'boolean'
};

questions[questionSlugs.ISSUED_ERRONEOUSLY] = {
  questionText: 'Was the levy issued erroneously in some other way?',
  type: 'boolean'
};

questions[questionSlugs.ISSUED_WRONGFULLY] = {
  questionText: 'Was the levy issued wrongfully?',
  type: 'boolean'
};

questions[questionSlugs.ABILITY_TO_PAY] = {
  questionText: 'Does your client have the ability to pay the entire remaining tax liability now?',
  type: 'boolean'
};

questions[questionSlugs.ABLE_TO_SATISFY] = {
  questionText: 'Is your client able to satisfy his or her entire tax liability via an Installment Agreement or Offer in Compromise?',
  type: 'boolean'
};

export default { questions };
