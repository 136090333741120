import angular from "angular";
import PermissionHelpers from "angular/common/helpers/permission.helpers.js";
import "angular/app/admin/users/user.service.js";

angular.module('app')
  .directive('cpHasAccess', ['UserService', '$animate',
    function(UserService, $animate) {
      return {
        restrict: 'A',
        transclude: 'element',
        priority: 700,
        terminal: true,

        link: function($scope, $element, $attr, ctrl, $transclude) {
          var block, childScope, previousElements;

          function getBlockNodes(nodes) {
            var node = nodes[0];
            var endNode = nodes[nodes.length - 1];
            var blockNodes = [node];

            do {
              node = node.nextSibling;
              if (!node) break;
              blockNodes.push(node);
            } while (node !== endNode);

            return angular.element(blockNodes);
          }

          $attr.$observe('cpHasAccess', function(value) {
            if (value) {
              var permissions = value.split(',');

              UserService.getLoggedInUser()
                .then(function(loggedInUser) {
                  if (PermissionHelpers.hasAccess(permissions, loggedInUser)) {
                    if (!childScope) {
                      $transclude(function(clone, newScope) {
                        childScope = newScope;
                        clone[clone.length++] = document.createComment(' end ngIf: ' + $attr.cpHasAccess + ' ');
                        // Note: We only need the first / last node of the cloned nodes.
                        // However, we need to keep the reference to the jqlite wrapper as it might be changed later
                        // by a directive with templateUrl when its template arrives.
                        block = {
                          clone: clone
                        };
                        $animate.enter(clone, $element.parent(), $element);
                      });
                    }
                  } else {
                    if (previousElements) {
                      previousElements.remove();
                      previousElements = null;
                    }
                    if (childScope) {
                      childScope.$destroy();
                      childScope = null;
                    }
                    if (block) {
                      previousElements = getBlockNodes(block.clone);
                      $animate.leave(previousElements).then(function() {
                        previousElements = null;
                      });
                      block = null;
                    }
                  }
                });
            }
          });
        }
      }
    }
  ]);
