import angular from "angular";
import { catchAsyncStacktrace } from 'auto-trace';

import "angular/app/client/taxes/programs/assistant/assistant-answers.service.js";

import * as assistantConstants from 'angular/app/client/taxes/programs/assistant/assistant.constants.js';

import * as outcomesHelper from "./assistant-outcomes.helper.js";
import template from "./assistant-outro.template.html";

angular.module('app.clients.taxes').component('cpAssistantOutroLiens', {
  bindings: {},

  controller: function ($state, $stateParams, AssistantAnswersService) {
    const vm = this;
    vm.assistantConstants = assistantConstants;

    //when vm.outcomes === null, it means there are definitely no outcomes. undefined means we aren't sure yet
    vm.outcomes = undefined;

    AssistantAnswersService.getOutcomes()
      .then((outcomes) => {
        vm.outcomes = outcomes;
        checkOutcomeIsKnown();
      })
      .catch(catchAsyncStacktrace());

    AssistantAnswersService.getQuestion()
      .then((question) => {
        vm.question = question;
        checkOutcomeIsKnown();
      })
      .catch(catchAsyncStacktrace());

    vm.appealAvailable = () => outcomesHelper.appealAvailable(vm.outcomes);
    vm.dischargeAvailable = () => outcomesHelper.dischargeAvailable(vm.outcomes);
    vm.releaseAvailable = () => outcomesHelper.releaseAvailable(vm.outcomes);
    vm.subordinationAvailable = () => outcomesHelper.subordinationAvailable(vm.outcomes);
    vm.withdrawalAvailable = () => outcomesHelper.withdrawalAvailable(vm.outcomes);

    vm.reliefUnlikely = () => outcomesHelper.reliefUnlikely(vm.outcomes);
    vm.cantCalculateOutcomes = () => outcomesHelper.cantCalculateOutcomes(vm.outcomes);

    function checkOutcomeIsKnown() {
      if (outcomesHelper.cantCalculateOutcomes(vm.outcomes) && vm.question) {
        $state.go('engagement.layout.program.assistant', {
          questionSlug: vm.question.currentQuestionSlug,
          sectionSlug: $stateParams.sectionSlug
        });
      }
    }

    vm.restartAssistant = AssistantAnswersService.restartAssistant;
  },

  controllerAs: 'vm',

  template: template
});
