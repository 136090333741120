import angular from "angular";

import * as outcomesHelper from 'angular/app/client/taxes/programs/innocent-spouse/assistant-outcomes.helper.js';
import 'angular/app/client/taxes/section-interactions/outcomes.style.css';

import template from './innocent-spouse.template.html';

angular.module('app.clients.taxes').component('cpOutcomesInnocentSpouseOutcome', {
  bindings: {
    outcomes: '='
  },

  controllerAs: 'vm',

  controller: function ($stateParams) {
    const vm = this;

    vm.modalUnderstatedTax = vm.modalSignedUnderDuress = vm.modalErroneousItems = false;
    vm.modalDidNotKnow = vm.modalNoReasonToKnow = vm.modalUnfair = false

    vm.innocentSpouseAvailable = () => outcomesHelper.innocentSpouseAvailable(vm.outcomes);
    vm.reliefUnlikely = () => outcomesHelper.reliefUnlikely(vm.outcomes);
    vm.cantCalculateOutcomes = () => outcomesHelper.cantCalculateOutcomes(vm.outcomes);

    vm.summaryExpanded = false;

    vm.clientId = $stateParams.clientId;
    vm.engagementId = $stateParams.engagementId;
  },

  template: template
});
