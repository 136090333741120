import angular from "angular";
import _ from "lodash";

function greaterThan(path, value, obj) {
  return _.property(path)(obj) > value;
}

angular.module('app')
  .filter('cpGreaterThan', function() {
    return function(obj, path, value) {
      if(_.isArray(obj)) {
        return _.filter(obj, _.partial(greaterThan, path, value));
      } else {
        return greaterThan(path, value, obj);
      }
    }
  });
