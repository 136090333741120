import angular from "angular";
import _ from 'lodash';
import { catchAsyncStacktrace } from 'auto-trace';

import template from "./comments-list.template.html";
import "./comment.component.js";
import "angular/common/services/comments.service.js";

angular.module('app')
.component('cpCommentsList', {
  bindings: {
    parentObjType: '@',
    parentObjId: '@',
    comments: '=',
    isEditable: '='
  },

  template,

  controllerAs: 'vm',

  controller: ['CommentsService',
    function(CommentsService) {
      const vm = this;

      vm.deleteComment = (comment) => {
        CommentsService.delete({commentId: comment.id}).then(() => {
          _.remove(vm.comments, (arrItem) => {return arrItem.id == comment.id;});
        })
        .catch(catchAsyncStacktrace());
      };
    }
  ]
});
