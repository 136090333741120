import angular from 'angular';

import './add-comment.style.css';
import template from './add-comment.template.html';
import context from 'angular/bootstrap/context.service.js';
import "angular/common/services/comments.service.js";
import "angular/app/client/taxes/client-requests/side-pane/request-tools/request-tool-comments-service"

import * as ClientRequestsHelper from 'angular/app/client/taxes/client-requests/engagement-client-requests.helper.js';

angular
.module('app')
.component('cpAddComment', {
  bindings: {
    comments: '=',
    parentObjType: '@',
    parentObjId: '@',
    parentObj: '=?',
    customAction: '&',
    customActionIcon: '@',
    customActionHtmlId: '@',
    forceShowActions: '=',
    createdCallback: '&',
  },

  transclude: true,

  template,

  controllerAs: 'vm',

  controller: ['CommentsService', '$scope', '$element', '$timeout', '$stateParams', 'cpUrlState', 'RequestToolCommentsService',
    function(CommentsService, $scope, $element, $timeout, $stateParams, cpUrlState, RequestToolCommentsService) {
      const vm = this;
      vm.loadingFiles = 0

      $scope.$on('$loading-files-changed', (event, files) => {
        vm.loadingFiles = files
      })

      $scope.$watchCollection('[vm.parentObjId, vm.parentObjType]', function() {
        vm.resetComment();
      });

      vm.initials = context.getContext().loggedInUser.initials;
      vm.showCommentControls = false;

      vm.postComment = () => {
        let notification = {};

        if (vm.parentObjType == 'client_requests' && vm.parentObj) {
          notification = {
            'teammember_url': cpUrlState.getUrlFromState('engagement.layout.client-requests.instance', {clientRequestId: vm.parentObjId, clientId: $stateParams.clientId, engagementId: vm.parentObj.relationships.for.id}),
            'client_url': cpUrlState.getUrlFromState('client.general.requests.instance', {clientRequestId: vm.parentObjId, clientId: $stateParams.clientId})
          };
        }

        CommentsService.add({pivotType: vm.parentObjType, pivotId: vm.parentObjId}, vm.newComment, notification).then(
          (commentId) => {
            vm.newComment.id = commentId;
            vm.newComment.created_at = Date.now();
            vm.comments.push(vm.newComment);
            vm.createdCallback({
              $comment: vm.newComment,
              $notification: notification
            });
            vm.resetComment();

            if (vm.parentObjType == 'client_requests') {
              // If not in the $timeout, then it doesn't make a full jump to comments. This is because this
              // would fire before the new comment was added in the digest
              $timeout(() => {
                ClientRequestsHelper.scrollToTop(
                  document.getElementById('cp-client-request-side-pane__content'),
                  document.getElementById('cp-client-request-comment-view-trigger')
                );
              });
            }
          }
        );
      };

      vm.cancel = () => {
        vm.resetComment();
        vm.showCommentControls = false;
        $scope.$emit('cp:add-comment:canceled');
      };

      vm.resetComment = () => {
        vm.newComment = CommentsService.newCommentTemplate(context.getContext().loggedInUser, vm.parentObjType, vm.parentObjId);
      };

      vm.resetComment();

      $scope.$watch('vm.newComment.body',
        (newValue) => {
          if (newValue.length > 0) {
            vm.showCommentControls = true;
          } else {
            vm.showCommentControls = false;
          }
        }
      );
    }
  ],
});
