var service = {
  setErrors: asyncErr => {
    return rawErr => {
      if (rawErr && rawErr.data) {
        let error = rawErr.data.error || rawErr.data.errors || {};
        if (rawErr.status) {
          error.status = rawErr.status;
        }
        return error;
      }
      return rawErr;
    }
  },

  generateError: function(message) {
    return {
      customError: true,
      errorMessage: message
    }
  },

  /**
   * Generate a response handler for a POST, PUT, PATCH request
   * This request handler should merge a SHA value from the server
   * back into the original object sent to the server.
   *
   * @param {string} type - The type of object being requested. Used to lookup the SHA property
   *  for example, response.data['credits']
   *
   * @return promise - the original request promise
   */
  mergeSha: function(type) {
    return function(object, promise) {
      promise.then(function(resp) {
        object[type].sha = resp.data[type].sha;
      });

      return promise;
    }
  }
}

export default service;
