import angular from "angular";
import "angular-ui-router";
import "angular-animate";
import "angular-resource";
import "angular-sanitize";
import "rangy";
import "rangy/lib/rangy-selectionsaverestore.js";
import "textangular-canopy";
import "textangular-canopy/dist/textAngular.css";
import "vendors/angular-bootstrap-ui/ui-bootstrap-custom-tpls-1.2.1.min.js";
import "vendors/scroll.js";
import "moment-timezone/builds/moment-timezone-with-data-2012-2022.min.js";

/**
 * The main app module
 *
 * @type {angular.Module}
 */
angular.module('app.core', [
  /**
   * Angular Modules
   *
   */
    'ngResource',
    'ngAnimate',
    'ngSanitize',

  /**
   * 3rd Party Modules
   *
   */
    'ui.bootstrap',
    'ui.router',
    'ui.scroll',
    'textAngular',
  ]
);
