import angular from 'angular';
import 'angular-time-pick';
import 'angular-ui-grid/ui-grid.js';
import 'angular-ui-grid/ui-grid.css';
import 'angular-ui-grid/fonts/ui-grid.eot';
import 'angular-ui-grid/fonts/ui-grid.svg';
import 'angular-ui-grid/fonts/ui-grid.ttf';
import 'angular-ui-grid/fonts/ui-grid.woff';
import 'cp-autoscale-input';
import 'cp-select';
import 'ngreact';
import 'cp-multi-selector';
import 'angular-scroll-canopy';
import 'vendors/cp-phone-input.js';
import 'vendors/cp-horizontal-barchart.js';
import 'vendors/cp-money.js';
import 'vendors/cp-inline-notification.js';
import InputControl from 'cpr-mask';
import MultiSelect from 'cpr-multiselect';
import 'cpr-multiselect/src/multi-selector.css';
import fetcherHttpProvider from '../common/angular-helpers/fetcher-http.provider.js';
import '../app/variables.css';

/**
 * The main app module
 *
 * @type {angular.Module}
 */
angular.module(
  'app',
  [
    'app.core',
    'app.routes', // order matters!
    'app.helpers',
    'app.admin',
    'app.clients',
    'app.tenant',
    'app.transcripts',
    'app.engagements',
    'bs-time-pick',
    'cp-autoscale-input',
    'cp-phone-input',
    'cp-multi-selector',
    'cp-horizontal-barchart',
    'cp-select',
    'cp-money',
    'cp-inline-notification',
    'ngSanitize',
    'dndLists',
    'react',
    'duScroll',
    'ui.grid',
    'ui.grid.infiniteScroll',
    'ui.grid.moveColumns',
    'ui.grid.autoResize',
    'ui.grid.pinning',
  ],
  fetcherHttpProvider
);

angular.module('app').value('InputControl', InputControl);
angular.module('app').value('MultiSelect', MultiSelect);
