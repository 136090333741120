import angular from "angular";

angular.module('app.transcripts')
  .factory('TranscriptReportsResource', ['$http',
    function TranscriptReportsResource($http) {

      return {

        getVersions:  (client_id) => $http.get(`/api/clients/${client_id}/transcripts`),

        getDocuments:  (client_id, version_id) => $http.get(`/api/clients/${client_id}/transcripts/${version_id}/records`),

        getRecord: (client_id, record_id) => $http.get(`/api/clients/${client_id}/transcripts/${record_id}/records`),
        
        getReport: (client_id, record_id, report_type_url, query) => $http.get(`/api/clients/${client_id}/transcripts/${record_id}/reports/${report_type_url}?${query}`),
        
      };
    }
  ]);
