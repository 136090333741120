import angular from "angular";

import * as outcomesHelper from 'angular/app/client/taxes/programs/innocent-spouse/assistant-outcomes.helper.js';
import 'angular/app/client/taxes/section-interactions/outcomes.style.css';

import template from './equitable-relief.template.html';

angular.module('app.clients.taxes').component('cpOutcomesEquitableRelief', {
  bindings: {
    outcomes: '='
  },

  controllerAs: 'vm',

  controller: function ($stateParams) {
    const vm = this;

    vm.modalUnderstatedTax = vm.modalUnpaidTax = vm.modalFraudulentScheme = vm.modalTimelyFiled = false;
    vm.modalTransfersOfPropertyToAvoidTax = vm.modalMaritalStatus = vm.modalEconomicHardship = false;
    vm.modalReasonToKnow = vm.modalLegalObligation = vm.modalSignificantBenefit = false;
    vm.modalCompliance = vm.modalHealth = false;

    vm.equitableReliefAvailable = () => outcomesHelper.equitableReliefAvailable(vm.outcomes);
    vm.reliefUnlikely = () => outcomesHelper.reliefUnlikely(vm.outcomes);
    vm.cantCalculateOutcomes = () => outcomesHelper.cantCalculateOutcomes(vm.outcomes);

    vm.summaryExpanded = vm.bestPracticesExpanded = false;

    vm.clientId = $stateParams.clientId;
    vm.engagementId = $stateParams.engagementId;
  },

  template: template
});
