import angular from "angular";
import _ from "lodash";
import { asyncStacktrace } from 'auto-trace';

import "angular/resources/workpapers.resource.js";


angular.module('app.clients.taxes')
  .factory('WorkpapersService', ['$q', 'WorkpapersResource', '$stateParams',
    function WorkpapersService($q, WorkpapersResource, $stateParams) {

      return {
        getList: (params) => {
          let deferred = $q.defer();

          WorkpapersResource.getList(params)
            .then(function success(response) {
              deferred.resolve(response);
            })
            .catch(asyncStacktrace(response => {deferred.reject(response)} ));

          return deferred.promise;
        },

        add: (params, workpaper) => {
          if (!params) {
            params = $stateParams;
          }

          if (!params.sectionId && params.sectionSlug) {
            params.sectionId = params.sectionSlug;
          }
          let deferred = $q.defer();

          WorkpapersResource.post(params, workpaper)
            .then(function success(response) {
              deferred.resolve(response.data.engagements.id);
            })
            .catch(asyncStacktrace(response => {deferred.reject(response)} ));

          return deferred.promise;
        },

        get: (params) => {
          Object.assign(params, $stateParams);
          if (!params.sectionId && params.sectionSlug) {
            params.sectionId = params.sectionSlug;
          }
          let deferred = $q.defer();

          WorkpapersResource.get(params)
            .then(function success(response) {
              deferred.resolve(response.data.workpapers);
            })
            .catch(asyncStacktrace(response => {deferred.reject(response)} ));

          return deferred.promise;
        },

        update: (params, workpaper) => {
          if (!params) {
            params = $stateParams;
          }
          if (!params.workpaperId) {
            params.workpaperId = workpaper.id;
          }
          if (!params.sectionId && params.sectionSlug) {
            params.sectionId = parseInt(params.sectionSlug);
          }
          let payload = _.cloneDeep(workpaper);
          if (!payload.relationships.assigned_to) {
            //don't send nulls or undefineds over the wire, the backend doesn't like it
            delete payload.relationships.assigned_to;
          }
          delete payload.attachments;
          let deferred = $q.defer();

          WorkpapersResource.put(params, payload)
            .then(function success(response) {
              deferred.resolve(response);
            })
            .catch(asyncStacktrace(response => {deferred.reject(response)} ));

          return deferred.promise;
        },

        delete: (params) => {
          let deferred = $q.defer();

          WorkpapersResource.delete(params)
            .then(function success(response) {
              deferred.resolve(response);
            })
            .catch(asyncStacktrace(response => {deferred.reject(response)} ));

          return deferred.promise;
        }
      };

    }
  ]);
