import angular from 'angular';

angular.module('app.clients').factory('TasksResource', [
  '$http',
  function TasksResource($http) {
    return {
      getTasks: client_id => {
        return $http.post(`/api/task_dashboard?page=0&limit=5&task=little&subtask=foot&client_id=${client_id}`);
      },
    };
  },
]);
