import angular from "angular";

angular.module('app.clients.taxes')
  .factory('LetterTemplatesResource', ['$http', '$window',
    function LetterTemplatesResource($http, $window) {
      $window.$http = $http;
      return {
        index: (params) => {
          return $http.get(
            `/api/templates/${params.template_type}`
          );
        },

        get: (params) => {
          return $http.get(
            `/api/templates/${params.template_type}/${params.template_id}`
          );
        },

        post: (params, template) => {
          return $http.post(
            `/api/templates/${params.template_type}`, {[params.template_type]: template}
          );
        },

        put: (params, template) => {
          return $http.put(
            `/api/templates/${params.template_type}/${params.template_id}`, {[params.template_type]: template}
          );
        },

        delete: (params) => {
          return $http.delete(
            `/api/templates/${params.template_type}/${params.template_id}`
          );
        },

      };
    }
  ]);