import angular from "angular";
import _ from 'lodash';
import { asyncStacktrace } from 'auto-trace';
import "angular/resources/client-participants.resource.js";
import "angular/app/admin/users/user.service.js";
import toasts from "toast-service!sofe";

angular.module('app.clients')
  .factory('ClientParticipantService', ['$q', 'UserService', 'ClientParticipantsResource', 'cpUrlState', 'ContactService',
    function ClientParticipantService($q, UserService, ClientParticipantsResource, cpUrlState, ContactService) {

      var teamMessage, clientMessage, service;

      teamMessage = 'Hi there. We’ll be using Canopy to share ideas, gather feedback, and track progress for this contact.';
      clientMessage = 'Hi there. We’ll be using Canopy to share ideas, gather feedback, and track progress during this engagement. Simply log in or create an account and you’ll be up and running in no time.';

      service = {

        setDefaultScope: setDefaultScope,

        adjustRows: adjustRows,

        addParticipants: addParticipants,

        createUsers: createUsers,

        getUsers: getUsers,

        getUsersOfRole: getUsersOfRole,

        updatePermissions: updatePermissions,

        deleteClientParticipant: deleteClientParticipant,

        populateParticipantData: populateParticipantData,

        resetMessage: resetMessage
      };

      return service;

      function setDefaultScope() {

        var defaults = {
          clientInvitees: [{}, {}, {}],
          teamInvitees: [{}, {}, {}],
          teamMessage: angular.copy(teamMessage),
          clientMessage: angular.copy(clientMessage),
          hasLicense: false,
          status: {
            inviteTeam: null,
            inviteClient: null
          }
        };
        return defaults;
      }

      function adjustRows(invitees) {
        var numberOfDrafts = 0;
        angular.forEach(invitees, function(invitee) {
          if (angular.isDefined(invitee.draft)) {
            numberOfDrafts++;
          }
        });
        return invitees.length - numberOfDrafts < 2 ? true : false;
      }

      function addParticipants(params, invitees, messages, sendNotification) {


          var newClientObj, newTeamMemberObj;

          // let's post the new clients first
          var deferredPost = $q.defer();
          var deferredPut = $q.defer();
          var deferredNewUserIds = $q.defer();

          // are there clients / team members to be created?
          newClientObj = _hasNewParticipants(invitees.clients);
          newTeamMemberObj = _hasNewParticipants(invitees.teamMembers);

          // this is for existing users only!!
          var userIds = _getInviteeIds(invitees);

        // let's make sure there is at least one user being added to the client
        if(userIds.length > 0 || newClientObj.hasNew || newTeamMemberObj.hasNew)
        {
          // first let's create new users, if necessary
          if (newClientObj.hasNew) {
            var users = UserService.transformPostUserRequest(newClientObj.emails, 'Client', [params.clientId * 1]);

            if (angular.isUndefined(sendNotification) || sendNotification.newUserEmail === true) {
              users.notifications = {
                url: cpUrlState.getUrlFromState('client.general.dashboard', {
                  clientId: params.clientId
                }),
                messages: {
                  clientMessage: angular.isDefined(messages) ? messages.clientMessage : null,
                }
              };
            }

            createUsers(users, {})
              .then(function(successResponse) {
                var allIds = [];
                allIds = userIds.concat(_.map(successResponse, 'id'));
                deferredNewUserIds.resolve(allIds);

                // we're just passing the existing user ids here. We add the new user ids to the object being returned below.
                deferredPost.resolve(userIds);
              },
              function(errorsResponse) {
                // if there is an error creating the users, let's still put the other ids and move on.
                deferredPost.resolve(userIds);
              })
              .catch(asyncStacktrace(response => {deferredPost.reject(response)} ));
          } else {
            deferredPost.resolve(userIds);
            deferredNewUserIds.resolve(userIds);
          }

          if (params && params.clientId) {
            // now let's attach the new users and existing users to this client
            deferredPost
            .promise.then(function(userIds) {
              if (userIds.length > 0) {
                var permissions = {
                  users: userIds
                };

                if(angular.isUndefined(sendNotification) || sendNotification.joinClientEmail === true) {
                  permissions.notifications =   {
                    url: cpUrlState.getUrlFromState('client.general.dashboard', {
                      clientId: params.clientId
                    }),
                    messages: {
                      teamMessage: angular.isDefined(messages) ? messages.teamMessage : null,
                      clientMessage: angular.isDefined(messages) ? messages.clientMessage : null,
                    }
                  }
                }

                updatePermissions(params, permissions)
                .then(function(successResponse) {
                  ContactService.bustContactCache(params.clientId);
                  deferredPut.resolve(successResponse);
                },
                function(errorsResponse) {
                  deferredPut.reject(errorsResponse);
                });

              } else {
                ContactService.bustContactCache(params.clientId);
                deferredPut.resolve();
              }
            })
            .catch(asyncStacktrace(response => {deferredPut.reject(response)} ));
          } else {
            deferredPut.resolve();
          }

          return {
            promise: deferredPut.promise,
            team: newTeamMemberObj,
            userIdsNewToClient: deferredNewUserIds.promise
          };

        } else {
          // and throw an error if there isn't at least one user being added to the client
          toasts.warningToast("Please include at least one person to invite");
          deferredPut.reject();
          return {
            promise: deferredPut.promise
          };
        }


      }

      function updatePermissions(params, payload) {
        var deferred = $q.defer();

        ClientParticipantsResource.post(params, payload)
          .$promise.then(function(response) {
            ContactService.bustContactCache(params.clientId);
            deferred.resolve(response.permissions);
          })
          .catch(asyncStacktrace(response => {deferred.reject(response)} ));

        return deferred.promise;
      }

      function createUsers(users, params) {
        var deferred = $q.defer();

        UserService.postUsers(params, users)
          .then(function(successResponse) {
              deferred.resolve(successResponse);
            },
            function(errorsResponse) {
              deferred.reject(errorsResponse);
            });

        return deferred.promise;
      }


      function getUsersOfRole(role, params) {
        var deferred = $q.defer();
        getUsers(params)
          .then(function(resp) {
            deferred.resolve(
              _.filter(resp, {
                role: role
              })
            )
          });

        return deferred.promise;
      }

      function getUsers(params) {
        var deferred = $q.defer();
        ClientParticipantsResource.get(params)
          .$promise.then(function(successResponse) {
            deferred.resolve(successResponse.users);
          })
          .catch(asyncStacktrace(response => {deferred.reject(response)} ));

        return deferred.promise;
      }

      function deleteClientParticipant(params) {
        var deferred = $q.defer();

        ClientParticipantsResource.delete(params)
          .$promise.then(function(response) {
            ContactService.bustContactCache(params.clientId);
            deferred.resolve(response);
          })
          .catch(asyncStacktrace(response => {deferred.reject(response)} ));

        return deferred.promise;
      }

      function _getInviteeIds(allInvitees) {
        var defaultArray = [];
        var existingTeamIds = [];
        var existingClientIds = [];

        angular.forEach(allInvitees.teamMembers, function(invitee) {
          if (angular.isDefined(invitee.addExistingUser)) {
            this.push(invitee.addExistingUser.id);
          }
        }, existingTeamIds);

        _.filter(allInvitees.clients, function(invitee) {
          if (angular.isDefined(invitee.addExistingUser)) {
            existingClientIds.push(invitee.addExistingUser.id);
          }
        });

        return defaultArray.concat(existingTeamIds, existingClientIds);
      }

      function _hasNewParticipants(invitees) {
        var numberOfParticipants = 0;
        var emails = [];
        var hasNew;

        angular.forEach(invitees, function(invitee) {
          if (angular.isDefined(invitee.addNewUser)) {
            this.push(invitee.addNewUser.email);
            numberOfParticipants++;
          }
        }, emails);
        hasNew = numberOfParticipants > 0 ? true : false;
        return {
          emails: emails,
          hasNew: hasNew
        };
      }

      function populateParticipantData(participantData, alerts) {
        var deferredTeam = $q.defer();

        var users = UserService.getUser({
          withTrashed: 'true',
          withArchived: 'true'
        });
        var loggedInUser = UserService.getLoggedInUser();

        $q.all([users, loggedInUser]).then(function(successResponse) {
            var userResponse = successResponse[0];
            var loggedInUser = successResponse[1];
            var activeTeamMembers = _.filter(userResponse, {
              'is_activated': true,
              'is_deleted': false,
              'role': 'TeamMember'
            });
            var activeClients = _.filter(userResponse, {
              'is_activated': true,
              'is_deleted': false,
              'role': 'Client'
            });

            // we're checking to make sure that this email doesn't belong to somebody in the other user group
            angular.forEach(activeTeamMembers, function(person, key) {
              if (person.id == loggedInUser.id) {
                activeTeamMembers.splice(key, 1);
              }
            });

            participantData.allPeople = userResponse;
            participantData.teamMembers = activeTeamMembers;
            participantData.clients = activeClients;
            deferredTeam.resolve();

          },
          function(errorsResponse) {
            alerts.errors = errorsResponse;
            deferredTeam.resolve();
          });

        return deferredTeam.promise;
      }

      function resetMessage(key) {

        if(key === 'teamMessage'){
          return teamMessage;
        }
        if(key === 'clientMessage')
        {
          return clientMessage;
        }
      }
    }
  ]);
