import auth from 'cp-client-auth!sofe';

var context;

var cpContext = {
  setContext: function(ctx) {
    if (ctx) ctx.CSRFToken = auth.getCSRFToken(); // Always get the current CSRF token from cp-client-auth
    context = ctx;
    window.ctx = ctx;
  },
  getContext: function() {
    // Calling this here will give us an updated CSRF token here, and in the window
    this.setContext(context);
    return context;
  }
};

export default cpContext;
