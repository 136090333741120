import angular from "angular";

angular.module('app.helpers')
  .directive('resizeable', [

    function resizeable() {

      return {
        restrict: 'A',
        link: function(scope, el, attrs) {

            scope.$on('resizeable.set.dimensions', function(evt, params) {
              el.removeAttr('class').addClass(params);
            }); // end on(resizeable.reset.height)
          } // end link
      }; // end return
    }
  ]); // end resizeable
