import angular from 'angular';
import template from './default-layout.template.html';

angular.module('app.admin')
.component('adminDefaultLayout', {
  bindings: {
    context: '='
  },

  controllerAs: 'vm',

  controller: function ($state, $timeout) {
    const vm = this;

    vm.isClient = !!vm.context.loggedInUser.role === "Client";
    vm.userId = vm.context.loggedInUser.id;
    vm.isTrial = vm.context.tenant.is_trial;
    vm.$state = $state;
    vm.location = window.location;
  },

  template,
});
