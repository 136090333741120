import angular from "angular";
import { asyncStacktrace } from 'auto-trace';
import 'angular/resources/client-requests.resource.js';
import _ from 'lodash';

angular.module('app.clients.taxes')
  .factory('ClientRequestsService', ['$q', 'ClientRequestsResource', '$stateParams', 'cpUrlState',
    function ClientRequestsService($q, ClientRequestsResource, $stateParams, cpUrlState) {

      // Because we rely on blurs that trigger some updates, such as the client request title, we run into race
      // conditions when someone blurs out of a title change by clicking the trash icon (triggering a delete)
      // The update may actually try to run last, which causes errors when the request has already been deleted
      // Enter, the queue! We queue the calls for actions in the side pane and run them in sequence
      const queue = {
        _q: [],
        push: (fn) => {
          queue._q.push(fn);

          if (queue._q.length === 1) {
            queue.execute();
          }
        },
        finished: () => {
          // Finished this one. So remove it
          queue._q.shift();
          // Now move onto the next one
          queue.execute();
        },
        execute: () => {
          if (queue._q.length) {
            queue._q[0]();
          }
        }
      };

      const convertTaskRequestToClientRequest = (taskRequests) => {
        return taskRequests.map(task => ({
          ...task,
          description: task.message,
          status: task.status_name,
          total_comments: 0,
          unread_comments: 0,
          pivot_type: task.tool_type,
          first_sent_at: task.sent_at,
          first_sent_by: task.sent_by,
          id: task.cr_id,
        }))
      };

      return {
        getEngagementRequests: (clientId = $stateParams.clientId, engagementId = $stateParams.engagementId, params) => {
          const deferred = $q.defer();

          if (!clientId)
            throw new Error(`Client id must be in url`);

          if (!engagementId)
            throw new Error(`Engagement id must be in the url`);

          ClientRequestsResource
          .getEngagementIndex(clientId, engagementId, params)
          .then((response) => {
            deferred.resolve(response.data.client_requests.map(req => ({
              ...req,
              reminders: req.reminders || {}
            })));
          })
          .catch(asyncStacktrace(response => {deferred.reject(response)} ))

          return deferred.promise;
        },

        getClientRequests: (completed = false) => {
          const deferred = $q.defer();

          if (!$stateParams.clientId)
            throw new Error(`Client id must be in url`);

          completed = !!completed;

          ClientRequestsResource
          .getClientIndex($stateParams.clientId, completed)
          .then((response) => {
            deferred.resolve([
              ...response.data.client_requests,
              ...convertTaskRequestToClientRequest(response.data.task_client_requests)
            ]);
          })
          .catch(asyncStacktrace(response => {deferred.reject(response)} ))

          return deferred.promise;
        },

        getClientRequest: (clientRequestId, engagementId = $stateParams.engagementId) => {
          const deferred = $q.defer();
          const clientId = $stateParams.clientId;

          if (!clientId)
            throw new Error(`Client id must be in url`);

          if (!clientRequestId)
            throw new Error(`Client request id must be provided`);

          if (!engagementId)
            throw new Error(`Engagement id must be provided or in url`);

          function runGet() {
            ClientRequestsResource.get(clientId, engagementId, clientRequestId)
              .then((response) => deferred.resolve({
                ...response.data.client_requests,
                reminders: response.data.client_requests.reminders || {},
              }))
              .catch(asyncStacktrace(response => {deferred.reject(response)} ))
              .finally(() => {queue.finished()});
          }

          queue.push(runGet);

          return deferred.promise;
        },

        getNonEngagementRequest: (requestToolId) => {
          const deferred = $q.defer();

          if(!requestToolId)
            throw new Error(`A request tool id must be provided`);

          function runGet() {
            ClientRequestsResource.getNonEngagementRequest(requestToolId)
              .then(response => {deferred.resolve(response.data)})
              .catch(asyncStacktrace(response => {deferred.reject(response)}))
              .finally(() => {queue.finished()})
          }

          queue.push(runGet);

          return deferred.promise;
        },

        createClientRequest: function () {
          const deferred = $q.defer();
          const clientId = $stateParams.clientId;
          const engagementId = $stateParams.engagementId;

          if (!clientId)
            throw new Error(`Client id must be in url`);

          if (!engagementId)
            throw new Error(`Engagement id must be in the url`);

          //always empty -- backend will create it
          const body = {
            client_requests: {}
          };

          function runCreate() {
            ClientRequestsResource.post(clientId, engagementId, body)
              .then((response) => deferred.resolve(response.data.client_requests))
              .catch(asyncStacktrace(response => {deferred.reject(response)} ))
              .finally(() => {queue.finished()});
          }

          queue.push(runCreate);

          return deferred.promise;
        },

        updateClientRequest: function (clientRequest, field, notifications, engagementId = $stateParams.engagementId) {
          const deferred = $q.defer();
          const clientId = $stateParams.clientId;

          if (!clientId)
            throw new Error(`Client id must be in url`);

          if (!engagementId)
            throw new Error(`Engagement id must be provided or in url`);

          if (!clientRequest)
            throw new Error(`Client request must be provided`);

          if (!clientRequest.id)
            throw new Error(`Client request must have an id`);

          const body = {
            client_requests: clientRequest
          };

          if (notifications) {
            body.notifications = notifications;
          }


          function runUpdate() {
            //In order to avoid overwriting the entire client request when two people are working on the
            // request at the same time, we get the client request from the server first, and then replace only
            // the updated property. We send that new object in the put
            ClientRequestsResource.get(clientId, engagementId, clientRequest.id)
              .then((response) => {
                let latestRequest = response.data.client_requests;

                if (field) {
                  latestRequest[field] = clientRequest[field];
                  body.client_requests = latestRequest;
                }

                const clientRequestId = clientRequest.id;
                ClientRequestsResource.put(clientId, engagementId, clientRequest.id, body)
                  .then((response) => {
                    if (clientRequest.id === clientRequestId) {
                      // One way the description is saved is when the user stops typing for 3 seconds. While that save is
                      // taking place, the user may start typing again. In order to avoid overwriting the into they just wrote,
                      // we omit the description from the response.
                      if (field === 'description') {
                        delete response.data.client_requests.description;
                      }
                      // Assign the response's client request to the original clientRequest passed in.
                      // Backend sometimes changes some properties such as reminders
                      Object.assign(clientRequest, response.data.client_requests);
                    }

                    deferred.resolve(response.data.client_requests);
                  })
                  .catch(asyncStacktrace(response => {deferred.reject(response)} ))
                  .finally(() => queue.finished());
              })
              .catch(asyncStacktrace(response => {
                deferred.reject(response)
                queue.finished();
              }));
          }

          queue.push(runUpdate);

          return deferred.promise;
        },

        deleteRequest: function (clientRequestId) {
          const deferred = $q.defer();
          const clientId = $stateParams.clientId;
          const engagementId = $stateParams.engagementId;

          if (!clientId)
            throw new Error(`Client id must be in url`);

          if (!engagementId)
            throw new Error(`Engagement id must be in the url`);

          if (!clientRequestId)
            throw new Error(`Client request id must be provided`);

          function runDelete() {
            ClientRequestsResource.delete(clientId, engagementId, clientRequestId)
              .then((response) => deferred.resolve(response.data))
              .catch(asyncStacktrace(response => {deferred.reject(response)} ))
              .finally(() => {queue.finished()});
          }

          queue.push(runDelete);

          return deferred.promise;
        },

        sendRequests: function (requestIdsArray, notifications = null) {
          const deferred = $q.defer();
          const clientId = $stateParams.clientId;

          if (!clientId)
            throw new Error(`Client id must be in url`);

          if (!Array.isArray(requestIdsArray) || requestIdsArray.length <= 0)
            throw new Error(`Must provide an array of client request ids`);

          const body = {
            client_requests: requestIdsArray.map((id) => ({
              id,
              sent: true,
              client_url: cpUrlState.getUrlFromState('client.general.requests.instance', {clientRequestId: id, clientId: clientId})
            })),
            client_url_to_requests: cpUrlState.getUrlFromState('client.general.requests', {clientId: clientId})
          };

          if (!_.isEmpty(notifications)) {
            body.notifications = notifications;
          }

          function runSend() {
            ClientRequestsResource.bulkSend(clientId, body)
              .then((response) => deferred.resolve(response.data))
              .catch(asyncStacktrace(response => {deferred.reject(response)} ))
              .finally(() => {queue.finished()});
          }

          queue.push(runSend);

          return deferred.promise;
        }
      }
    }
  ]);
