import angular from 'angular';
import { applicationProps } from 'entries/workflow-ui'
import '../client-request-file.style.css';
import template from './request-tool-file.template.html';
import * as fileHelper from "angular/common/helpers/file.helper.js";

angular
.module('app.clients.taxes')
.component('cpRequestToolFile', {
  bindings: {
    file: '=',
    deletable: '=',
    downloadable: '=',
    onDelete: '&',
    index: '=',
  },

  template,

  controllerAs: 'vm',
  controller: ['$stateParams', '$scope',
    function($stateParams, $scope) {
      const vm = this;

      vm.getAttachmentIcon = (fileName) => {
        return fileHelper.getFileIconPath(fileName);
      };

      vm.deleteClicked = function(i) {
        $scope.$emit('cp:request-tool:remove-file', i)
      }

      vm.downloadAttachment = () => {
        const file = vm.file

        SystemJS.import('docs-ui!sofe').then(m => {
          m.isFileSafeToOpen(file.id).then(({trust, virusStatuses, file}) => {
            if(trust){
              window.location = file.download_link
            }
            else {
              vm.container = document.getElementById(`file-${file.id}`)
              const parcel = applicationProps.mountParcel(m.VirusModalParcel, {
                virusFound: virusStatuses.infectedDocs.length > 0,
                domElement: vm.container,
                handleCloseModal() {
                  parcel.unmount()
                }
              })
            }
          })
        })
      }
    }
  ],
});
