import angular from 'angular';

import 'angular/app/admin/layouts/default-layout.component.js';
import 'angular/app/admin/service-templates/service-templates.directive.js';
import 'angular/app/admin/service-templates/service-templates-template.component.js';

angular.module('app.routes')
  .config(function($stateProvider) {

    $stateProvider
      .state('legacy-admin', {
        parent: 'app',
        url: '/admin',
        template: `<ui-view />`,
      })
      .state('admin', {
        parent: 'legacy-admin',
        abstract: true,
        url: '/global-settings',
        template: '<admin-default-layout context="context"></admin-default-layout>'
      })

    .state('admin.service-templates', {
      abstract: true,
      template: '<ui-view/>'
    })
      .state('admin.service-templates.index', {
        url: '/service-templates/',
        template: '<service-templates context="vm.context"></service-templates>'
      })
        .state('admin.service-templates.template', {
          url: '/service-templates/{templateId}/{templateName}',
          template: '<service-templates-template context="vm.context"></service-templates-template>'
        })

    .state('legacy-admin.redirect', {
      url: '/{path:.*}',
      controller() {
        if (window.location.hash.indexOf('/admin/global-settings') < 0) {
          /* They've clicked on an email notification or some old link that doesn't have
           * global-settings in the url like it is supposed to.
           */
          setTimeout(() => {
            window.location.hash = window.location.hash.replace('/admin', '/admin/global-settings');
          });
        }
      },
      template: `<ui-view />`,
    })

    .state('app.notifications-settings', {
      url: '/notifications/settings',
      template: '<admin-default-layout context="context"></admin-default-layout>'
    })

    .state('app.email-settings', {
      url: '/communications/global-settings/email',
      template: `<div><!-- porpoisely hidden so that workflow&#45;ui doesn't display page not found --></div>`
    })

    .state('app.email-settings.home', {
      url: '/{beginPath:.*}{endPath:.*}',
    })
  });
