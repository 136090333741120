import angular from "angular";
import jquery from "jquery";
window.jquery = window.$ = jquery;

/**
 * The admin  module
 *
 * @type {angular.Module}
 */
angular.module('app.admin', [
  'app.admin.users',
  'app.admin.tenant',
  'app.admin.licenses',
  'app.admin.letters',
]);

angular.module('app.admin.users', []);

angular.module('app.admin.tenant', []);

angular.module('app.admin.licenses', []);

angular.module('app.admin.letters', []);
