import angular from "angular";
import "./service-templates.controller.js";
import "./service-templates.shared.styles.css";
import template from "./service-templates.template.html";

angular.module('app.admin')
  .directive('serviceTemplates', [
    function serviceTemplates() {
      return {
        restrict: 'E',
        scope: {
          user: '=',
          context: '='
        },
        template: template,
        controller: 'ServiceTemplates',
        controllerAs: 'vm',
        bindToController: true
      }
    }
  ]);
