export function getFileExtension(filename) {
  filename = filename.toLowerCase();
  return filename.substring(filename.lastIndexOf('.') + 1).trim();
}

export function getFileIcon(filename) {
  let ext = getFileExtension(filename);
  let icons = {
    doc: 'doc_icon.png',
    docx: 'docx_icon.png',
    gif: 'gif_icon.png',
    jpeg: 'jpeg_icon.png',
    jpg: 'jpeg_icon.png',
    pdf: 'pdf_icon.png',
    png: 'png_icon.png',
    ppt: 'ppt_icon.png',
    pptx: 'pptx_icon.png',
    txt: 'txt_icon.png',
    xls: 'xls_icon.png',
    xlsx: 'xlsx_icon.png',
    tif: 'tiff_icon.png',
    tiff: 'tiff_icon.png'
  };

  return icons[ext] ? icons[ext] : 'na_icon.png';
}

export function getFileIconPath(fileName) {
  return "https://cdn.canopytax.com/static/workflow-ui/file_icons/" + getFileIcon(fileName);
}
