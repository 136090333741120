// Outcomes
export const APPEALS = 'APPEALS';
export const RETURNING_PROPERTY = 'RETURNING_PROPERTY';
export const PREVENTING_LEVY = 'PREVENTING_LEVY';
export const RELEASING_LEVY = 'RELEASING_LEVY';

export const RELIEF_UNLIKELY = 'RELIEF_UNLIKELY';
export const OUTCOME_NOT_YET_CALCULABLE = null;

// Question slugs
export const INTRO = 'intro';                       //0
export const ISSUED_NOTICE = 'issued_notice';               //1
export const ALREADY_LEVIED = 'already_levied';               //2
export const LEVY_RELEASED = 'levy_released';               //3
export const PROVE_FINANCIAL_HARDSHIP = 'prove_financial_hardship';     //4
export const INCREASE_ABILITY_TO_PAY = 'increase_ability_to_pay';     //5
export const ISSUED_PREMATURELY = 'issued_prematurely';           //6
export const AGAINST_EXEMPT_PROPERTY = 'against_exempt_property';     //7
export const VALUE_GREATER_THAN_OWED = 'value_greater_than_owed';     //8
export const OIC_OR_INSTALLMENT = 'oic_or_installment';           //9
export const RELEASE_FACILITATE = 'release_facilitate';           //10
export const SATISFIED_LIABILITY = 'satisfied_liability';         //11
export const FILED_FOR_BANKRUPTCY = 'filed_for_bankruptcy';         //12
export const COLLECTION_PERIOD_EXPIRED = 'collection_period_expired';   //13
export const IDENTITY_THEFT = 'identity_theft';               //14
export const ISSUED_ERRONEOUSLY = 'issued_erroneously';           //15
export const ISSUED_WRONGFULLY = 'issued_wrongfully';           //16
export const ABILITY_TO_PAY = 'ability_to_pay';               //17
export const ABLE_TO_SATISFY = 'able_to_satisfy';             //18
export const CIRCUMSTANCE = 'circumstance';
