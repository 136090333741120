import angular from 'angular';
import { applicationProps } from 'entries/workflow-ui'

import 'angular/common/directives/comments/add-comment.style.css';
import template from './request-tool-add-comment.template.html';
import context from 'angular/bootstrap/context.service.js';
import "angular/common/services/comments.service.js";
import "angular/app/client/taxes/client-requests/side-pane/request-tools/request-tool-comments-service"

angular
.module('app')
.component('cpRequestToolAddComment', {
  bindings: {
    comments: '=',
    parentObjType: '@',
    parentObjId: '@',
    parentObj: '=?',
    customAction: '&',
    customActionIcon: '@',
    customActionHtmlId: '@',
    forceShowActions: '=',
    createdCallback: '&',
    clientRequest: '=',
  },

  transclude: true,
  template,
  controllerAs: 'vm',

  controller: ['CommentsService', '$scope', '$element', '$timeout', '$stateParams', 'cpUrlState', 'RequestToolCommentsService',
    function(CommentsService, $scope, $element, $timeout, $stateParams, cpUrlState, RequestToolCommentsService) {
      const vm = this;
      vm.fileModel = []
      vm.loadingFiles = false

      SystemJS.import('docs-ui!sofe').then(m => {
        const parcel = applicationProps.mountParcel(m.NewFileUploadParcel, {
          uploadFilesAction: function(files){
            $scope.$emit('cp:request-tool:files-done', files)
            vm.loadingFiles = false
            vm.fileModel = vm.fileModel.concat(files)
          },
          setLoadingFiles: function(numberOfFiles){
            vm.loadingFiles = true
            $scope.$emit('cp:request-tool:loading-placeholder-files', numberOfFiles)
          },
          domElement: document.getElementById(`comment-${vm.parentObjId}`),
          config: {
            folderId: `CON${$stateParams.clientId}`,
            days_until_expiration: 1,
            hidden: true,
            callback: function(files){
              vm.showCommentControls = true
              $scope.$emit('cp:request-tool:add-files', files)
            }
          }
        })

        $scope.$on('$destroy', () => {
          parcel.getStatus() === 'MOUNTED' && parcel.unmount()
        })
      })


      $scope.$watchCollection('[vm.parentObjId, vm.parentObjType]', function() {
        vm.resetComment();
      });

      $scope.$on('cp:request-tool:remove-file', (event, index) => {
        vm.fileModel.splice(index, 1)
        if(vm.fileModel.length === 0 && vm.newComment.body.length === 0){
          vm.showCommentControls = false
        }
      })

      vm.initials = context.getContext().loggedInUser.initials;
      vm.showCommentControls = false;

      vm.postComment = () => {
        let notification = {};

        let body = vm.newComment.body
        vm.createdCallback({
          $comment: {
            created_at: new Date(),
            created_by_user_id: context.getContext().loggedInUser.id,
            created_by_user_name: context.getContext().loggedInUser.name,
            body: vm.newComment.body,
            file_ids: []
          },
          $notification: {}
        })
        vm.resetComment();
        vm.showCommentControls = false;
        RequestToolCommentsService.createComment(vm.parentObjId, {body, file_ids: vm.fileModel.map(f => f.id)}).then(() => {
          vm.cancel()
        })
      };

      vm.cancel = () => {
        vm.resetComment();
        vm.showCommentControls = false;
        vm.fileModel = []
        $scope.$emit('cp:add-comment:canceled');
      };

      vm.resetComment = () => {
        vm.newComment = CommentsService.newCommentTemplate(context.getContext().loggedInUser, vm.parentObjType, vm.parentObjId);
      };

      vm.resetComment();

      $scope.$watch('vm.newComment.body',
        (newValue) => {
          if(vm.fileModel.length === 0 && newValue.length === 0){
            vm.showCommentControls = false
          }
          else {
            vm.showCommentControls = true
          }
        }
      );
    }
  ],
});
