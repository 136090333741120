import angular from 'angular';
import urls from 'canopy-urls!sofe';
import {shouldIgnore} from './ajax-interceptors.helpers.js';
import toasts from 'toast-service!sofe';
import {captureMessage} from 'sentry-error-logging!sofe';

angular
  .module('app')
  .config(function ($httpProvider) {
    // http://blog.thoughtram.io/angularjs/2015/01/14/exploring-angular-1.3-speed-up-with-applyAsync.html
    $httpProvider.useApplyAsync(true);

    $httpProvider.interceptors.push(['$q', '$templateCache', 'cpLoginService', function($q, $templateCache, cpLoginService) {
      return {
        request(config) {
          if (config.url.startsWith('template')) {
            //only our vendors use templates, and they don't need to go to the network to get a template (they are preloaded)
            return config;
          } else {
            let oldUrl = config.url;

            if (config.url.indexOf('/') !== 0 && config.url.indexOf('http') !== 0) {
              config.url = '/' + config.url;
            }
            if (config.url.indexOf("https") < 0) {
              config.url = urls.getWorkflowUrl() + config.url;
            }

            // If config.url is in $templateCache, changing the url will break the cache and cause the app to
            // attempt an ajax request for the template (which obviously fails). Here, we add an additional key to
            // $templateCache for the updated config.url so the template can still be retrieved from the cache.
            let cachedTemplate = $templateCache.get(oldUrl);
            if (cachedTemplate) {
              $templateCache.put(config.url, cachedTemplate);
            }

            return config;
          }
        },
        responseError(rejection) {
            if (shouldIgnore(rejection)) {
              // Don't show toast or log to sentry, these are handled with custom UX
            }
            else if (rejection.status === 402) {
              const userMessage = 'Your Canopy account is locked. If you believe this is in error, please contact support.';
              toasts.warningToast(userMessage);
              // Log to sentry as info message
              captureMessage("Locked Canopy Account", {level: 'info', tags: {toastMessage: userMessage}});
            }
            else if (rejection.status === 403 && rejection.data == 'User deleted or not active') {
              const userMessage = 'You do not have sufficient permissions.';
              toasts.warningToast(userMessage);
              captureMessage(userMessage, {level: 'info', tags: {toastMessage: userMessage}});
              setTimeout(function () {
                cpLoginService.redirectToLogout();
              }, 3000);
            } else {
              let userMessage = "Error communicating with Canopy";
              if (rejection.status == 404 && rejection.config.method == 'GET') {
                const anchor = document.createElement('a');
                anchor.setAttribute('href', rejection.config.url);
                const pathPart = anchor.hash ? anchor.hash : anchor.pathname
                const matches = pathPart.match(/\/([A-z]+?)s?\/?([0-9a-zA-Z]+)?$/) || [];

                if (matches[1] === 'e' && matches[2] === 'ngagements') {
                  userMessage = "Could not retrieve the list of engagements for the specified contact";

                } else if (matches[1] && matches[2]) {
                  userMessage = `Could not find the ${matches[1]} with id ${matches[2]}`;

                } else if (matches[1]) {
                  userMessage = `Could not find ${matches[1]}`;

                } else {
                  userMessage = "Error communicating with Canopy -- page not found";
                }

              } else if (rejection && rejection.data && rejection.data.errors && rejection.data.errors.validationErrors) {
                for (var error in rejection.data.errors.validationErrors){

                  //Pretty print the error name
                  var errorName = error.replace('_', ' ');
                  errorName = errorName.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function(match, index) {
                    if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
                      return index == 0 ? match.toUpperCase() : ' ' + match.toUpperCase();
                  });
                }
              } 

              toasts.generalToast(userMessage);

              let rejectionString = '';
              let rejectionURL = '';
              try {
                rejectionString = JSON.stringify(rejection);
                rejectionURL = rejection.config.url;
              } catch(err){
                // Nothing to do
              }
              //Use rejectionURL as the sentry message if the user message was "Error communicating with Canopy"
              const sentryMessage = userMessage === "Error communicating with Canopy" && rejectionURL ? rejectionURL : userMessage;
              captureMessage(sentryMessage, {level: 'info', tags: {toastMessage: userMessage, rejection: rejectionString}});
            }

            return $q.reject(rejection);
        },
        response(resp) {
          return resp;
        }
      };
    }])
  })
