import angular from 'angular';
import './client-request-mark-complete.style.css';
import template from './client-request-mark-complete.template.html';
import { includes } from 'lodash';

angular
  .module('app.clients.requests')
  .component('cpClientRequestMarkComplete', {
    bindings: {
      clientRequest: '=',
    },

    template,

    controllerAs: 'vm',

    controller: ['$scope', '$element', 'ClientRequestsService',
      function ClientRequestMarkComplete($scope, $element, ClientRequestsService) {
        const vm = this;

        $scope.$watch('vm.clientRequest.id', () => {
          vm.isSigningRequest = includes(vm.clientRequest.pivot_type, 'esign_doc');
        });

        vm.updateRequest = () => {
          if (vm.clientRequest.relationships && vm.clientRequest.relationships.for.type === 'engagement') {
            ClientRequestsService.updateClientRequest(vm.clientRequest, 'status', null, vm.clientRequest.relationships.for.id);
          }
        }
      }
    ],
  });
