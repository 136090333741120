import angular from "angular";
import indexTemplate from "./engagement-list.template.html";
import dashboardTemplate from "./engagement-list-dashboard.template.html";
import "./engagement-list.controller.js";
import "./engagement-list.style.css";

angular.module('app.clients.taxes')
  .directive('engagementList', [

    function() {
      return {
        restrict: 'E',
        scope: {
          client: '=',
          template: "@"
        }, // necessary so we don't overwrite parent scope
        template: function(elem, attr) {
          if(attr.template === 'dashboard') {
            return dashboardTemplate;
          } else if(attr.template === 'index') {
            return indexTemplate;
          }
          throw new Error("Invalid template parameter " + attr.template);
        },
        controller: 'EngagementListController',
        controllerAs: 'vm',
        bindToController: true
      }
    }
  ]);
