import angular from "angular";
import { asyncStacktrace } from 'auto-trace';
import 'angular/resources/transcript-reports.resource.js'

angular.module('app.transcripts')
  .factory('TranscriptReportsService', ['$q', 'TranscriptReportsResource',
    function TranscriptReportsService($q, TranscriptReportsResource) {

      return {

        getDocuments: function(clientId, versionId) {
          var deferred = $q.defer();
          TranscriptReportsResource.getDocuments(clientId, versionId)
          .then(function success(response){
            deferred.resolve(response.data);
          })
          .catch(asyncStacktrace(response => {deferred.reject(response)} ));

          return deferred.promise;
        },

        getVersions: function(clientId) {

          var deferred = $q.defer();
          TranscriptReportsResource.getVersions(clientId)
          .then(function success(response){
            deferred.resolve(response.data);
          })
          .catch(asyncStacktrace(response => {deferred.reject(response)} ));

          return deferred.promise;
        },

        getRecord: function(clientId, recordId){
          var deferred = $q.defer();
          TranscriptReportsResource.getRecord(clientId, recordId)
          .then(function success(response){
            deferred.resolve(response.data);
          })
          .catch(asyncStacktrace(response => {deferred.reject(response)} ));

          return deferred.promise;

        },

        getReport: function(clientId, recordId, report_type_url, sub_report_type = ''){
          var deferred = $q.defer();

          let query = sub_report_type ? `reportType=${sub_report_type}` : '';

          TranscriptReportsResource.getReport(clientId, recordId, report_type_url, query)
          .then(function success(response){
            deferred.resolve(response.data);
          })
          .catch(asyncStacktrace(response => {deferred.reject(response)} ));

          return deferred.promise;
        },

      };
    }
  ]);
