import angular from "angular";
import { fetchAsObservable } from "fetcher!sofe";
import { pluck } from "rxjs/operators";

angular.module("app").factory("TaxReturnsResource", [
  function TaxReturnsResource() {
    return {
      fetchClientReturns(clientId) {
        return fetchAsObservable(`/clients/${clientId}/client-returns`).pipe(
          pluck("client-returns")
        );
      },
    };
  },
]);
