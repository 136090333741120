// Outcomes
export const APPEAL = 'APPEAL';
export const EMPLOYMENT_TAX = 'EMPLOYMENT_TAX';
export const EMPLOYMENT_TAX_WARNING = 'EMPLOYMENT_TAX_WARNING';
export const TFRP_DEFENSE = 'TFRP_DEFENSE';

export const RELIEF_UNLIKELY = 'RELIEF_UNLIKELY';
export const OUTCOME_NOT_YET_CALCULABLE = null;

// Question slugs
export const INTRO = 'intro';                     //0
export const UNPAID_EMPL_TAX = 'unpaid_empl_tax';           //1
export const CLIENT_TYPE = 'client_type';               //2
export const CLIENT_PRINCIPAL = 'client_principal';           //2a
export const CAN_PREVENT = 'can_prevent';               //3
export const PAY_BALANCE_24_MO = 'pay_balance_24_mo';         //4
export const BALANCE_LESS = 'balance_less';               //5
export const FULLY_COMPLIANT = 'fully_compliant';           //6
export const RECEIVED_1058 = 'received_1058';             //7
export const RECEIPT_OF_NOTICE = 'receipt_of_notice';         //8
export const FINANCIALLY_INCAPABLE = 'financially_incapabale';      //9
export const RECEIVED_1153 = 'received_1153';             //10
export const DAYS_LAPSED = 'days_lapsed';               //11
export const DISPUTE_ASSESSED_PENALTY = 'dispute_assessed_penalty';   //12
export const RESPONSIBLE_PERSON = 'responsible_person';         //13
export const COMPLETED_4180 = 'completed_4180';             //14
export const OTHER_PERSON = 'other_person';               //15
export const SIGNER_ON_ACCOUNT = 'signer_on_account';         //16
export const RECEIVED_3586 = 'received_3586';             //17
