import angular from "angular";

angular.module('app.clients.notes')
  .factory('NotesResource', ['$http',
    function NotesResource($http) {

      return {
        index: (params) => $http.get(`/api/clients/${params.clientId}/notes/`, {params: params || {}}),
        get: (params) => $http.get(`/api/clients/${params.clientId}/notes/${params.noteId}`),
        update: (params, data) => $http.put(`/api/clients/${params.clientId}/notes/${params.noteId}`, data),
        create: (params, data) => $http.post(`/api/clients/${params.clientId}/notes`, data),
        save: (params, data) => $http.patch(`/api/clients/${params.clientId}/notes/${params.noteId}`, data),
        remove: (params) => $http.delete(`/api/clients/${params.clientId}/notes/${params.noteId}`)
      }
    }
  ]);
