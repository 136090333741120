import angular from 'angular';
import { catchAsyncStacktrace } from 'auto-trace';
import template from './dashboard-request-list.template.html';
import 'angular/app/clients/contact.service.js';
import './dashboard-request-list.style.css';
import 'angular/app/client/taxes/client-requests/engagement-client-requests.service.js';

angular
  .module('app.clients.requests')
  .component('cpDashboardRequestList', {
    bindings: {
      client: '=',
    },

    template,

    controllerAs: 'vm',

    controller: ['ClientRequestsService',
      function DashboardRequestList(ClientRequestsService) {
        const vm = this;

        ClientRequestsService.getClientRequests()
        .then((requests) => {
          vm.requests = requests;
        })
        .catch(catchAsyncStacktrace());
      }
    ],
  });
