import angular from "angular";

import * as outcomesHelper from 'angular/app/client/taxes/programs/innocent-spouse/assistant-outcomes.helper.js';
import 'angular/app/client/taxes/section-interactions/outcomes.style.css';

import template from './separation-of-liability.template.html';

angular.module('app.clients.taxes').component('cpOutcomesSeparationOfLiability', {
  bindings: {
    outcomes: '='
  },

  controllerAs: 'vm',

  controller: function ($stateParams) {
    const vm = this;

    vm.modalSameHousehold = vm.modalActualKnowledge = vm.modalTransferOfProperty = false;

    vm.separationOfLiabilityAvailable = () => outcomesHelper.separationOfLiabilityAvailable(vm.outcomes);
    vm.reliefUnlikely = () => outcomesHelper.reliefUnlikely(vm.outcomes);
    vm.cantCalculateOutcomes = () => outcomesHelper.cantCalculateOutcomes(vm.outcomes);

    vm.summaryExpanded = false;

    vm.clientId = $stateParams.clientId;
    vm.engagementId = $stateParams.engagementId;
  },

  template: template
});
