import angular from 'angular';
import _ from 'lodash'
import { catchSyncStacktrace } from 'auto-trace';
import template from './requests-index.template.html';
import '../../taxes/client-requests/side-pane/client-sent-client-request.component.js';
import '../../taxes/client-requests/side-pane/request-tools/request-tool-side-pane.component';
import './requests-index.style.css';
import toasts from 'toast-service!sofe';
import "angular/common/services/comments.service.js";

angular
  .module('app.clients.requests')
  .component('cpRequestsIndex', {
    bindings: {},

    template,

    controllerAs: 'vm',

    controller: ['$scope', '$stateParams', '$state', '$timeout', 'ClientRequestsService', 'RequestToolCommentsService',
      function RequestsIndex($scope, $stateParams, $state, $timeout, ClientRequestsService, RequestToolCommentsService) {
        const vm = this;

        vm.isLoaded = false;
        vm.noRequests = false;
        vm.horizontalMargin = '0rem';
        vm.isTaskClientRequest = false;
        vm.requestToolClientRequest = {}
        vm.filterOptions = [
          {
            key: 'incomplete',
            value: 'Incomplete Requests',
          },
          {
            key: 'complete',
            value: 'Complete Requests',
          }
        ];
        vm.requestToolMap = RequestToolCommentsService.getRequestToolMap()
        vm.selectedFilter = vm.filterOptions[0];

        // Initial load
        vm.requests = [];
        ClientRequestsService.getClientRequests().then((requests) => {
          vm.requests = requests;
          if ($stateParams && $stateParams.clientRequestId) {
            vm.goToClientRequest($stateParams.clientRequestId);
          }

          // Also check for any completed requests
          ClientRequestsService.getClientRequests(true).then((requests) => {
            if (!vm.requests.length && !requests.length) {
              vm.noRequests = true;
            }
            vm.isLoaded = true;
          });
        });

        // Filter complete/incomplete requests
        $scope.$watch('vm.selectedFilter', function(filter, oldVal) {
          if (filter !== oldVal) {
            ClientRequestsService.getClientRequests(filter.key === 'complete')
              .then((requests) => {
                vm.requests = requests;
              });
          }
        });

        // Handle side pane show/hide
        $scope.$watch('vm.viewingRequest', sidePaneToggled);
        $scope.$watch('vm.requestToolClientRequest.status', (newVal, old) => {
          if(newVal !== old && vm.requestToolClientRequest && vm.requests.length){
            let currentClientRequest = vm.requests.find((req) => req.id == vm.requestToolClientRequest.id);
            currentClientRequest && Object.assign(currentClientRequest, vm.requestToolClientRequest);
          }
        });

        $scope.$on('cp:right-side-pane:is-hiding', () => vm.horizontalMargin = 0);

        $scope.$on('cp:right-side-pane:was-hidden', () => {
          vm.viewingRequest = false;
          $state.go('client.general.requests');
        });

        vm.goToClientRequest = (clientRequestId) => {
          vm.requestToolClientRequest = {}
          vm.activeClientRequest = vm.requests.find((request) => request.id == clientRequestId);
          vm.isTaskClientRequest = !!(vm.activeClientRequest && vm.activeClientRequest.task_id);

          if (typeof vm.activeClientRequest !== 'undefined') {
            vm.viewingRequest = true
            if(vm.activeClientRequest.task_id && vm.activeClientRequest.pivot_type === 'simple'){
              ClientRequestsService.getNonEngagementRequest(vm.activeClientRequest.tool_id)
                .then(response => {
                  vm.requestToolClientRequest = response
                  let currentClientRequest = vm.requests.find((req) => req.id == response.id);
                  Object.assign(currentClientRequest, response);
                  response.due_at = currentClientRequest.due_at
                  setRequestTool(response)

              $timeout(() => {
                $state.go('client.general.requests.instance', {clientRequestId}, {notify: false})
              })

              });
            }
            else {
              if(vm.activeClientRequest.task_id){
                ClientRequestsService.getNonEngagementRequest(vm.activeClientRequest.tool_id)
                  .then(response => {
                  vm.requestToolClientRequest = response
                  vm.viewingRequest = true
                  let currentClientRequest = vm.requests.find((req) => req.id == response.id);
                  Object.assign(currentClientRequest, response);

                  $timeout(() => {
                    $state.go('client.general.requests.instance', {clientRequestId}, {notify: false})
                  })

                  });
              }
              else {
                $state.go('client.general.requests.instance', {clientRequestId}, {notify: false})
                .then(() => {
                  vm.viewingRequest = true;
                  if (vm.activeClientRequest.relationships && vm.activeClientRequest.relationships.for.type === 'engagement') {
                    ClientRequestsService.getClientRequest(vm.activeClientRequest.id, vm.activeClientRequest.relationships.for.id)
                      .then((request) => {
                        let currentClientRequest = vm.requests.find((req) => req.id == request.id);
                        request.viewed_at = Date.now();
                        // These both need to trigger a digest, separately. This way, the footer can capture the actual unread comment count
                        $timeout(() => Object.assign(currentClientRequest, request));
                        // Set to 0 so green icon turns gray in client requests table
                        $timeout(() => currentClientRequest.unread_comments = 0);
                      })
                      .catch((ex) => {
                        if (ex.status === 404) {
                          $scope.$broadcast('cp:right-side-pane:do-hide');
                          toasts.warningToast('Sorry, that request no longer exists.');
                          _.remove(vm.requests, (request) => request.id === vm.activeClientRequest.id);
                          delete vm.activeClientRequest;
                        } else {
                          catchSyncStacktrace(ex);
                        }
                      });
                  }
                });
              }
            }
          }
        };

        vm.slatIsActive = (id) => {
          return ($stateParams.clientRequestId == id);
        };

        vm.shouldSquish = () => {
          return vm.viewingRequest;
        };

        function setRequestTool(request){
          const map = {
            request_tool: [request.id],
          }
          if(request.relationships.comments.length > 0){
            map.comment = request.relationships.comments.map(c => c.id)
          }
          RequestToolCommentsService.getAllFilesRelatedTo(map).then(() => {
            $scope.$apply()
          })
        }

        function sidePaneToggled() {
          $timeout(() => {
            vm.horizontalMargin = !!vm.viewingRequest ? '33.6rem' : '0';
          }, 0);
        }
      }
    ],
  });
