import angular from 'angular';
import { catchAsyncStacktrace } from 'auto-trace';
import moment from 'moment';
import { from } from 'rxjs'
import { switchMap } from 'rxjs/operators'

import './tasks-index.style.css';
import "angular/common/services/tasks.service.js";
import template from './tasks-index.template.html';
import {hasAccess as userHasAccess} from 'cp-client-auth!sofe';
import { featureEnabled } from 'feature-toggles!sofe';

angular.module('app.clients').component('tasksIndex', {
  bindings: {
    client: '=',
  },
  template,
  controllerAs: 'vm',
  controller: ('TaskIndex', ['$scope', '$window', 'TasksService', function($scope, $window, TasksService){
    const vm = this;

    vm.hasAccess = userHasAccess(window.loggedInUser);

    // The query string is being removed due to the editService url not being configured inside ui-router. This code prevents the query string from being removed and navigates to the url
    $scope.$on('$locationChangeStart', function(event, next, prev){
      let x = next.split('?')
      let y = prev.split('?')
      if(y.length > 1 && x[0] === y[0]){
        event.preventDefault()
      }
    })

    vm.$onInit = () => {
      vm.obsSubscriptions = [];

      vm.fetchTasks();
    };

    vm.$postLink = () => {
      window.addEventListener('refresh-task-data', vm.fetchTasks);
      window.addEventListener('cp:task-created', vm.fetchTasks);
    };

    vm.$onDestroy = () => {
      window.removeEventListener('refresh-task-data', vm.fetchTasks);
      window.removeEventListener('cp:task-created', vm.fetchTasks);

      vm.obsSubscriptions.forEach(subscription => {
        if (subscription.unsubscribe) {
          subscription.unsubscribe();
        }
      });
    };

    vm.fetchTasks = () => {
      TasksService.getTasks(vm.client.id).then(tasks => {
        vm.tasks = tasks;
      });
    };

    vm.triggerCreateTaskModal = () => {
      vm.triggerTaskModal();
    };

    vm.triggerTaskModal = (task = {}) => {
      const { id: task_id, service_type: task_service_type, type: task_type, parent_task_id, contact_id } = task
      const isIndependentClientRequestDraft = task.status_id === "DRAFT" && task.type === "Client Request" && !task.parent_task_id
      const editService =
        task_id &&
        (task_service_type === 'generic' || task_service_type === 'notice') &&
        task_type !== 'eSign Request' &&
        !isIndependentClientRequestDraft;
      const editEsignRequest = task_id && task_type === 'eSign Request'

      if (editService){
        $window.location = `/#/task/${parent_task_id ? parent_task_id : task_id}${contact_id ? `/client/${contact_id}`: ''}${parent_task_id ? `?subtask=${task_id}` : ''}`
      }
      else {
        const taskModal$ = from(SystemJS.import('tasks-ui!sofe')).pipe(
          switchMap(tasksUI => {
            if (isIndependentClientRequestDraft) {
              return from(tasksUI.getClientRequestModalObservable())
                .pipe(switchMap(showClientRequestModal => showClientRequestModal({taskId: task.id})))
            }
            return (!editEsignRequest)
            ? from(tasksUI.callServiceModalObservable())
              .pipe(switchMap(callServiceModal => callServiceModal({contactId: vm.client.id})))
            : from(tasksUI.createEditTaskObservable())
              .pipe(switchMap(createEditTaskModal => createEditTaskModal(task_id, !!task_id, vm.client.id)))
          })
        )
        vm.obsSubscriptions.push(
          taskModal$.subscribe(() => vm.fetchTasks(), catchAsyncStacktrace())
        )
      }
    };

    vm.isOverdue = dueDate => {
      return moment(dueDate).isBefore(moment(), 'day');
    };

    vm.formattedTaskDate = date => {
      return date ? moment(date).format('M/D/YYYY') : date;
    }
  }])
});
