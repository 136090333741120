import angular from "angular";

angular.module('app')
  .config(function($provide) {

    $provide.decorator('taOptions', function($delegate) {

      $delegate.toolbar = [
        ['h1', 'h2', 'h3', 'p'],
        ['bold', 'italics', 'underline'],
        ['ul', 'ol'],
        ['justifyLeft', 'justifyCenter', 'justifyRight']
      ];

      $delegate.forceTextAngularSanitize = false;

      return $delegate;
    });


    $provide.decorator('taTools', ['$delegate', function (taTools) {
      taTools.bold.iconclass = 'cps-icon cps-icon-bold';
      taTools.italics.iconclass = 'cps-icon cps-icon-italics';
      taTools.underline.iconclass = 'cps-icon cps-icon-underline';
      taTools.ul.iconclass = 'cps-icon cps-icon-list';
      taTools.ol.iconclass = 'cps-icon cps-icon-number';
      taTools.h1.iconclass = 'cps-icon cps-icon-header1';
      taTools.h1.buttontext = '';
      taTools.h2.iconclass = 'cps-icon cps-icon-header2';
      taTools.h2.buttontext = '';
      taTools.h3.iconclass = 'cps-icon cps-icon-header3';
      taTools.h3.buttontext = '';
      taTools.redo.iconclass = 'cps-icon cps-icon-redo';
      taTools.undo.iconclass = 'cps-icon cps-icon-undo';
      taTools.justifyLeft.iconclass = 'cps-icon cps-icon-left-aligned';
      taTools.justifyCenter.iconclass = 'cps-icon cps-icon-center-aligned';
      taTools.justifyRight.iconclass = 'cps-icon cps-icon-right-aligned';
      return taTools;
    }]);
  });
