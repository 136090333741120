import { cloneDeep } from 'lodash';

export function getProgramProgress(program, loggedInUser = null) {

  let progressData = {
    'inProgress': [],
    'needReview': [],
    'totalSections': 0,
    'completedSections': 0
  };

  if (program.visible && program.sections) {

    let statusesToExamine = ["in_progress", "needs_review", "complete", null];

    for (let statusToExamine of statusesToExamine) {
      if (program.sections.some(section => section.status == statusToExamine)){
        program.sections.forEach(function(section) {

          let item = cloneDeep(section);
          item.programSlug = program.slug;
          item.programName = program.name;
          item.type = 'step';

          if (typeof item.aggregate_updated_at === 'undefined') {
            item.aggregate_updated_at = item.updated_at;
          }

          if (item.status == statusToExamine && item.slug != 'adminforms' && item.slug != 'irsforms') {
            if (statusToExamine == 'in_progress') {
              progressData.inProgress.push(item);
            } else if (statusToExamine == 'needs_review') {
              progressData.needReview.push(item);
            } else if (statusToExamine == 'complete') {
              progressData.completedSections++;
            }

            if (!!statusToExamine) progressData.totalSections++;
          }

          if (!item.updated_at) {
            // Should only be set on a newly created section
            item.updated_at = (new Date()).getTime();
          }

          if (!item.updated_by && loggedInUser) {
            // Should only be set on a newly created section which should be the currently logged in user
            item.updated_by = loggedInUser.name;
          }
        })
      }
    }
  }

  return progressData;

}

export function getRequestsProgress(requests) {

  let progressData = {
    'inProgress': [],
    'needReview': [],
    'totalSections': 0,
    'completedSections': 0
  };

  requests.forEach(function(item) {
    item.type = 'request';
    item.name = item.title;

    if (item.first_sent_at && item.status) {
      if (item.status == 'in_progress') {
        progressData.inProgress.push(item);
      } else if (item.status == 'needs_review') {
        progressData.needReview.push(item);
      } else if (item.status == 'complete') {
        progressData.completedSections++;
      }

      progressData.totalSections++;
    }
  });

  return progressData;
}

export const taxResServiceSlugs = [
  'collections',
  'innocent_spouse',
  'liens',
  'levies',
  'trust_fund_recovery',
  'penalty_abatement',
];
