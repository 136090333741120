import angular from "angular";
import template from "./engagement-details.template.html";

import "./engagement-details.controller.js";
import "./engagement-details.style.css";

angular.module('app.clients.taxes')
  .directive('cpEngagementDetails', [
    function cpEngagementDetails() {
      return {
        restrict: 'E',
        scope: {
          engagement: '='
        },
        template: template,
        controller: 'EngagementDetailsController',
        controllerAs: 'vm',
        bindToController: true
      }
    }
  ]);
