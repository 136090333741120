import angular from "angular";
import { isEmpty } from "lodash";

angular.module('app.clients.taxes')
.controller('EngagementSectionController', ['$stateParams', '$scope', '$state',
  function EngagementSectionController($stateParams, $scope, $state) {
    var vm = this;

    if (!$stateParams.programSlug) {
      throw new Error(`An engagement section cannot exist without a program that has a slug`);
    }

    if (isEmpty($stateParams.sectionSlug)) {
      $state.go('engagement.layout.program');
      return;
    }

    $scope.$watch('vm.engagement', () => {
      //we have to wait for the engagement to be loaded
      if (vm.engagement) {
        init();
      }
    });

    function init() {
      let program;
      for (let i=0; i<vm.engagement.program_data.programs.length; i++) {
        if (vm.engagement.program_data.programs[i].slug === $stateParams.programSlug) {
          program = vm.engagement.program_data.programs[i];
          break;
        }
      }

      if (!program) {
        program = {sections: []};
      }

      for (let i=0; i<program.sections.length; i++) {
        if (program.sections[i].slug === $stateParams.sectionSlug) {
          vm.section = program.sections[i];
          break;
        }
      }

      if (!vm.section) {
        vm.section = {
          id: $stateParams.sectionSlug,
          slug: $stateParams.sectionSlug,
        };
      }

      const {clientId, engagementId} = $state.params;

      //we do some of the conditional logic here instead of in the template so we get good error messages
      switch (vm.section.slug) {

        case 'collection_survey':
          setTimeout(() => {
            /* We redirect people to the new client survey when they're trying to get to the old collections survey
            */
            window.location.hash = `#/forms/clients/${clientId}/engagements/${engagementId}/survey`;
          })
          break;
        case 'poa_survey':
          setTimeout(() => {
            if (window.loggedInUser.role === "Client") {
              /* We redirect clients to a landing page that explains why the survey is no longer necessary
               *
               */
              $state.go('oldSurvey');
            } else {
              /* We redirect people to the new Form 2848 when they're trying to get to the old poa survey
               */
              window.location.hash = `#/forms/clients/${clientId}/engagements/${engagementId}/sourceforms/CRZ1X/sections/0`;
            }
          })
          break;

        case 'compromise':
        case 'payment':
        case 'notcollectible':
        case 'analytics':
          setTimeout(() => {
            /* We redirect people to the new analytics when they're trying to get to the old analytics
             */
            window.location.hash = `#/forms/clients/${clientId}/engagements/${engagementId}/collections/analytics/offer`;
          })
          break;

        case 'irsforms':
        case 'adminforms':
          setTimeout(() => {
            /* We redirect people to the 404 page when they try to go to the old landing page for the
             * Planning Administrative Forms section or the Collections IRS Forms section.
             */
            $state.go('404')
          })
          break;

        case 'abatement_assistant':
        case 'innocent_spouse_assistant':
        case 'liens_assistant':
        case 'levies_assistant':
        case 'trust_fund_recovery_assistant':
          vm.sectionType = 'assistant';
          break;

        case 'abatement_resolution':
        case 'innocent_spouse_resolution':
        case 'liens_resolution':
        case 'levies_resolution':
        case 'trust_fund_recovery_resolution':
          vm.sectionType = 'outcomes';
          break;

        default:
          try {
            parseInt(vm.section.slug);
            vm.sectionType = 'workpaper';
          } catch (ex) {
            throw new Error(`Unknown section slug '${vm.section.slug}'`);
          }
          break;
      }
    }
  }
]);
