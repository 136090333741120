import angular from "angular";
import _ from "lodash";

angular.module('app')
  .filter('cpCapitalize', function() {
    return (value) => {
      if(value && _.isString(value)) {
        if(value.length === 1) {
          return value.toUpperCase();
        } else {
          return value.substring(0, 1).toUpperCase() + value.substring(1);
        }
      }
    }
  });
