import angular from 'angular';
import { catchAsyncStacktrace } from 'auto-trace';
import { truncateFilename } from 'docs-ui!sofe';
import context from 'angular/bootstrap/context.service.js';
import './client-request-footer.style.css';
import template from './client-request-footer.template.html';
import 'angular/common/directives/comments/add-comment.component.js';
import canopyUrls from "canopy-urls!sofe";

import Dropzone from "dropzone";
import * as dropzoneHelper from 'angular/common/helpers/dropzone.helper.js';

import './client-request-file.component.js';
import './client-request-file-uploading.style.css';
import * as ClientRequestsHelper from 'angular/app/client/taxes/client-requests/engagement-client-requests.helper.js';
import _ from 'lodash';

angular
.module('app.clients.taxes')
.component('cpClientRequestFooter', {
  bindings: {
    comments: '=',
    clientRequest: '='
  },

  template,

  controllerAs: 'vm',

  controller: ['$timeout', 'AttachmentsService', 'DocsResource', 'AttachmentsResource', '$scope', '$stateParams', '$q',
    function($timeout, AttachmentsService, DocsResource, AttachmentsResource, $scope, $stateParams, $q) {

      let sidePaneEl;
      let commentsEl;
      let haveSeenComments; // boolean for whether or not the pane has been scrolled far enough to view comments

      const vm = this;
      vm.loggedInUserId = context.getContext().loggedInUser.id;

      vm.savingStatus = '';
      vm.isSaving = false;
      vm.commentFiles = [];
      vm.loadedUnreadComments = 0;
      vm.filesLoading = []

      $scope.$watch('vm.clientRequest.id', function () {
        if (vm.clientRequest) {
          vm.loadedUnreadComments = vm.clientRequest.unread_comments;
          determineIfSeenComments();
        }
      });

      $scope.$watch('vm.clientRequest.total_comments', () => {
        if (vm.clientRequest) {
          // Watch total comments because a change is triggered when the `get` in 'vm.goToClientRequest' in
          // 'engagement-client-requests.component.js' uses Object.assign();
          // We do not modify 'total_comments' as we do 'unread_comments' so watching this makes more sense
          vm.loadedUnreadComments = vm.clientRequest.unread_comments;
          determineIfSeenComments();
        }
      });

      $scope.$on('cp-client-request:saving', () => {
        vm.savingStatus = 'Saving Changes...';
        vm.isSaving = true;
      });

      $scope.$on('cp-client-request:save-complete', () => {
        vm.savingStatus = 'Saved!';
        $timeout(() => {
          vm.isSaving = false;
        }, 1500);
      });

      $scope.$on('$destroy', () => {
        try {
          Dropzone.forElement('#cp-client-request-footer-dz').destroy();
        } catch (ex) {
          ex; //do nothing with it
        }
      });

      $scope.$on('cp:add-comment:canceled', () => {
        vm.commentFiles.forEach((commentFile) => {
          AttachmentsService
          .delete({clientId: $stateParams.clientId, attachmentId: commentFile.id})
        });
        vm.commentFiles = [];
      });

      vm.commentCreated = (comment, notification) => {
        const promises = [];
        for (let i=0; i<vm.commentFiles.length; i++) {
          const attachment = vm.commentFiles[i];
          const attachmentSummary = {
            id: attachment.id,
            pivot_type: 'client_requests',
            pivot_id: Number(vm.clientRequest.id),
            ancestor_id: comment.id,
            ancestor_type: 'comment'
          };
          attachment.relationships.ancestor.type = 'comment';
          attachment.relationships.ancestor.id = comment.id;

          promises.push(AttachmentsService.patchAttachment($stateParams.clientId, attachmentSummary, notification))
        }

        $q.all(promises)
        .then(() => {
          $scope.$emit('cp:client-requests:new-comment', comment, vm.commentFiles);
          vm.commentFiles = [];
        })
        .catch(() => vm.commentFiles = []); // if there is an error, a toast will be thrown because of the http status code. But we still need to clean up the ui
      };

      vm.canJumpToComments = () => {
        return (!haveSeenComments && !commentsViewableInSidePane(40));
      };

      vm.jumpToComments = () => {
        haveSeenComments = true;
        ClientRequestsHelper.scrollToTop(sidePaneEl, commentsEl);
      };

      function determineIfSeenComments() {
        if (vm.clientRequest) {
          if (vm.clientRequest.total_comments) {
            sidePaneEl = document.getElementById('cp-client-request-side-pane__content');
            commentsEl = document.getElementById('cp-client-request-comment-view-trigger');

            if (commentsViewableInSidePane(40)) {
              haveSeenComments = true;
            }
            else {
              haveSeenComments = false;
              sidePaneEl.removeEventListener('scroll', scrollListener);
              sidePaneEl.addEventListener('scroll', scrollListener);
            }
          }
          else {
            haveSeenComments = true;
          }
        }
      }

      function commentsViewableInSidePane(offset) {
        return ClientRequestsHelper.isViewable(sidePaneEl, commentsEl, offset);
      }

      function scrollListener() {
        if (commentsViewableInSidePane(40)) {
          $timeout(() => {
            haveSeenComments = true;
          });
          sidePaneEl.removeEventListener('scroll', scrollListener);
        }
      }

      vm.deleteAttachment = (attachmentId) => {
        _.remove(vm.commentFiles, (attachment) => attachment.id === attachmentId);
        AttachmentsService
        .delete({clientId: $stateParams.clientId, attachmentId})
        .catch(catchAsyncStacktrace());
      };

      $timeout(initDropzone);

      function initDropzone() {
        DocsResource.getS3AttachmentTargetEndpoint(`CON${$stateParams.clientId}`).then(response => {
          const fields = _.get(response, 'data.s3.fields', {})
          const s3URL = _.get(response, 'data.s3.url', '')
          const s3Prefix = fields.key.replace('${filename}','');

          let dropzone = new Dropzone('#cp-client-request-footer-dz', {
            url: s3URL,
            withCredentials: false,
            uploadMultiple: false,
            parallelUploads: 1,
            maxFilesize: 100, //MB
            clickable: '#cp-client-request-footer-dz-trigger',
            previewTemplate: document.getElementById('cp-client-request-footer-dropzone-preview').innerHTML,
            dictFileTooBig: 'File is too large.',
            dictResponseError: 'Upload Failed...',
            headers: {'Cache-Control': ''},
            accept: function (file, done){
              vm.filesLoading.push({name: 'placeholder'})
              $scope.$broadcast('$loading-files-changed', vm.filesLoading.length)
              done()
            }
          });

          dropzoneHelper.setup401Retries(dropzone);

          dropzone.on("sending", (file, xhr, formData) => {
            _.forEach(fields, (value, key) => {
              formData.append(key, value);
            })

            formData.set('key', s3Prefix + `${_.get(file, 'upload.uuid')}` + truncateFilename(file.name))
          });

          dropzone.on("complete", (file) => {
            if (file.status !== 'error') {
              dropzone.removeFile(file);
            }

            const path_to_s3 = s3Prefix + `${_.get(file, 'upload.uuid')}` + truncateFilename(file.name)

            DocsResource.reportFileUploaded(`CON${$stateParams.clientId}`, {
              description: '',
              filesize: file.size,
              hidden: false,
              inbox: false,
              is_visible: false,
              mimetype: file.type,
              name: truncateFilename(file.name),
              path_to_s3: path_to_s3,
            })
              .then(resp =>
                AttachmentsResource.post(
                  {
                    targetPath: AttachmentsResource.targetPathEnum.CLIENTS,
                    targetId: $stateParams.clientId,
                    pivotId: $stateParams.clientRequestId,
                    pivotType: 'request_comment',
                  },
                  {
                    file: {
                      ...resp.data.file,
                      path_to_s3,
                    },
                  }
                )
              )
              .then(resp => {
                vm.filesLoading.pop()
                $scope.$broadcast('$loading-files-changed', vm.filesLoading.length)
                vm.commentFiles.push(resp.data.attachments[0]);
              })
              .catch(catchAsyncStacktrace());
          });
        })
      }
    }
  ]
});
