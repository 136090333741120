import angular from 'angular';
import template from './assign-section-envelope.template.html';
import 'cp-multi-selector';
import './section-envelopes.service.js';
import './assign-section-envelope.style.css';
import { hasAccess } from 'cp-client-auth!sofe';

import { find } from 'lodash';

angular.module('app.clients.taxes')
.component('cpAssignSectionEnvelope', {
  bindings: {
    sectionEnvelope: '=', //optional. Will be fetched if not provided
    activeTeamMembers: '=',
    regetActiveTeamMembers: '&',
    requiredPermission: '@',
  },


  controllerAs: 'vm',

  template,

  controller: ('cpAssignSectionEnvelope', ['SectionEnvelopesService', '$scope', '$stateParams',
    function AssignSectionEnvelopeController(SectionEnvelopesService, $scope, $stateParams) {
      const vm = this;

      vm.assigneeCustomIconTemplate = `<span class="cps-icon"></span>`;
      vm.inviteTeamMember = () => {
        SystemJS.import('invite-team-member!sofe')
          .then(({inviteTeamMember}) => inviteTeamMember({
            isFreeTrial: window.tenant.is_trial,
            closeCallback: vm.regetActiveTeamMembers,
            clientId: Number($stateParams.clientId),
          }))
          .catch(err => {setTimeout(() => {throw err;})})
      }

      let assigneeCustomIconTemplate = `<div class="cps-label-square +small cp-assign-section__icon"><div class="cps-center-vertical">{{ vm.assignees[0].initials }}</div></div>`;
      let nonAssignedCustomIconTemplate = `<span class="cps-icon cps-icon-add-person"></span>`;

      vm.addCollaboratorHTML = `<div><a cp-has-access='company_settings_team_members' ng-click='vm.inviteTeamMember()' class='cps-pull-left cps-caption cps-margin-top-8 cps-margin-bottom-4'>+ Create a new team member</a></div>`;

      $scope.$watch('vm.sectionEnvelope', () => {
        if (vm.sectionEnvelope && !vm.assignees) {
          vm.assignees = [];
          if (vm.sectionEnvelope.relationships.assigned_to.type == 'user' && vm.sectionEnvelope.relationships.assigned_to.id && vm.activeTeamMembers)
            vm.assignees = [find(vm.activeTeamMembers, (teamMember) => teamMember.id === vm.sectionEnvelope.relationships.assigned_to.id)];
        }
      });

      $scope.$watch('vm.assignees', () => {
        if (vm.assignees) {
          if (vm.assignees.length > 0) {
            vm.assigneeCustomIconTemplate = assigneeCustomIconTemplate;
          } else {
            vm.assigneeCustomIconTemplate = nonAssignedCustomIconTemplate;
          }
        }
      });

      $scope.$watch('vm.activeTeamMembers', setFilteredTeamMembers);

      vm.$onInit = () => {
        setFilteredTeamMembers();
      };

      vm.assigneesChanged = () => {
        if (vm.sectionEnvelope) {
          vm.sectionEnvelope.relationships.assigned_to = {
            type: 'user',
            id: vm.assignees.length === 0 ? null : vm.assignees[0].id
          };
          SectionEnvelopesService.updateEnvelope({
            ...$stateParams,
            sectionId: vm.sectionEnvelope.relationships.belongs_to.id,
            envelopeId: vm.sectionEnvelope.id,
            envelope: {
              description: vm.sectionEnvelope.description,
              due_at: vm.sectionEnvelope.due_at,
              id: vm.sectionEnvelope.id,
              relationships: vm.sectionEnvelope.relationships,
              status: vm.sectionEnvelope.status,
              title: vm.sectionEnvelope.title,
              type: vm.sectionEnvelope.type,
            }
          });
        }
      }

      function setFilteredTeamMembers() {
        if (!vm.activeTeamMembers) return;
        vm.filteredTeamMembers = vm.activeTeamMembers.filter(user => {
          const userHasAccess = hasAccess(user);
          return (
            !vm.requiredPermission ||
            userHasAccess([vm.requiredPermission, 'tasks_engagements', 'contacts_not_assigned'], true)
          )
        });
      }
    }]
  ),
});
