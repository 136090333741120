import angular from 'angular';
import template from './active-notes-single-note.template.html';
import './active-notes-single-note.style.css';
import 'angular/common/directives/stop-prop.directive.js';
import 'angular/common/directives/prevent-default.directive.js';
import './view-note-modal.component.js';
import { get } from 'lodash';

import { catchAsyncStacktrace } from 'auto-trace';
import moment from 'moment';

angular.module('app.clients.notes').component('activeNotesSingleNote', {
  bindings: {
    note: '=',
    expanded: '=',
  },
  template,
  controllerAs: 'vm',
  controller(NotesService, $stateParams, $state) {
    const vm = this;
    vm.clientId = $stateParams.clientId;
    vm.showModal = false;

    const updated_time_stamp = moment(vm.note.updated_at);
    vm.updatedAtDay = updated_time_stamp.format('ll'); // Sep 30, 2016
    vm.updatedAtTime = updated_time_stamp.format('h:mmA'); // 4:57PM

    if (!vm.note.created_at) {
      // old notes did not have a created_at, so substitute with updated_at
      vm.createdAtDay = updated_time_stamp.format('l'); // 9/30/2016
    } else {
      const created_time_stamp = moment(vm.note.created_at);
      vm.createdAtDay = created_time_stamp.format('l'); // 9/30/2016
    }

    let saveNote = note => {
      NotesService.saveNote({ clientId: vm.clientId, noteId: note.id }, note)
        .then(note => {
          Object.assign(vm.note, note);
        })
        .catch(catchAsyncStacktrace());
    };

    vm.pinNote = note => {
      if (note.pinned_at) {
        note.pinned_at = null;
        note.is_pinned = false;
      } else {
        note.pinned_at = new Date().getTime();
        note.is_pinned = true;
      }

      saveNote(note);
    };

    vm.archiveNote = note => {
      if (note.archived_at) {
        note.archived_at = null;
        note.is_archived = false;
      } else {
        note.archived_at = new Date().getTime();
        note.is_archived = true;
      }

      saveNote(note);
    };

    vm.createdAtText =
      'Created by ' +
      (vm.note.created_by ? vm.note.created_by : vm.note.updated_by) +
      ' on ' +
      vm.createdAtDay;
  },
});
