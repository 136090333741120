import angular from "angular";

angular.module('app.clients.taxes')
  .factory('LettersResource', ['$http', '$window',
    function LettersResource($http, $window) {
      $window.$http = $http;

      return {
        getSignatures: (params) => {
          return $http.get(
            `/api/letters/${params.letterId || 0}/esign_docs/${params.signingId}/signing_requests`
          );
        },
      }
    }
  ]
);