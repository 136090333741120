import angular from 'angular';
import template from './radio-buttons.template.html';
import './radio-buttons.style.css';

angular.module('app')
  .component('cpRadioButtons', {

    bindings: {
      options: '=',
      selectedValue: '=',
      onChange: '&?',
      horizontal: '=?',
    },

    template,

    controllerAs: 'vm',

    controller: function RadioButtons($scope) {
      var vm = this;

      vm.$onInit = () => {
        // If not divisible by 12, just make it take up the whole row
        vm.horizontalColClass = 12 % vm.options.length == 0 ? 'cps-col-xs-' + 12 / vm.options.length : 'cps-col-xs-12';
      }

      $scope.$watch('vm.selectedValue', (newVal, oldVal) => {
        if (newVal != oldVal && typeof vm.onChange === 'function') {
          vm.onChange();
        }
      });
    },

  });
