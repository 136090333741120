import angular from "angular";
import { map } from 'rxjs/operators'
import responseHelpers from "angular/common/helpers/response.helpers.js";
import { asyncStacktrace } from 'auto-trace';
import "angular/resources/engagement.resource.js";

angular.module('app.clients.taxes')
  .factory('EngagementService', ['$q', 'EngagementResource', '$state',
    function EngagementService($q, EngagementResource, $state) {
      const SECTION_TITLE_MAX_LENGTH = 38;
      const SECTION_DESCRIPTION_MAX_LENGTH =  512; //TODO verify this with back end

      return {
        SECTION_TITLE_MAX_LENGTH: SECTION_TITLE_MAX_LENGTH, //TODO verify this with back end

        SECTION_DESCRIPTION_MAX_LENGTH: SECTION_DESCRIPTION_MAX_LENGTH, //TODO verify this with back end

        getIndex: function getIndex(params, type) {
          if (!params.clientId) {
            throw new Error(`Cannot get engagements without a client id`);
          }
          let deferred = $q.defer();
          let query = {};

          if (type === 'trash') {
            query.onlyTrashed = true;
          } else if (type === 'archived') {
            query.onlyArchived = true;
          }

          EngagementResource.index(params, query)
            .then(function success(response) {
              deferred.resolve(response.data.engagements);
            })
            .catch(asyncStacktrace(response => {deferred.reject(response)} ));

          return deferred.promise;
        },

        create: (params, engagement) => {
          let deferred = $q.defer();

          EngagementResource.create(params, {
              engagements: engagement
            })
            .then(function(response) {
              deferred.resolve(response.data.engagements);
            })
            .catch(asyncStacktrace(response => {deferred.reject(response)} ));

          return deferred.promise;
        },

        saveEngagement: (params, engagement) => {
          let deferred = $q.defer();
          // this PUT doesnt accept the archived_at property
          delete engagement.archived_at;  

          EngagementResource.save(params, {
              engagements: engagement
            })
            .then(response => {
              if (response.ok) {
                response
                .json()
                .then(json => deferred.resolve(json.engagements))
                .catch(deferred.reject);
              } else {
                deferred.reject(response);
              }
            })
            .catch(asyncStacktrace(response => {deferred.reject(response)} ))

          return deferred.promise;
        },

        updateEngagement: (params, engagement) => {
          let deferred = $q.defer();

          EngagementResource.update(params, {
              engagements: engagement
            })
            .then((response) => {
              if (response.ok) {
                response
                .json()
                .then(json => deferred.resolve(json.engagements))
                .catch(deferred.reject);
              } else {
                deferred.reject(response);
              }
            })
            .catch(asyncStacktrace(response => {deferred.reject(response)} ))

          return deferred.promise;
        },

        deleteEngagement: function (params, engagement) {
          // let deferred = $q.defer();

          // This is a temporary fix for deleting an engagement because the backend
          // currently does not remove the "is_archived" flag when deleting an engagmenet
          // as a result deleted engagements show up in both the archived and deleted lists
          return this.updateEngagement(params, {
            is_archived: false,
            is_deleted: true
          });
        },

        getEngagement: (params, query) => {
          if (!params.clientId)
            throw new Error(`Cannot get engagement without a client id param`);
          if (!params.engagementId)
            throw new Error(`Cannot get engagement without an engagement id param`);
          let deferred = $q.defer();

          EngagementResource.get(params, query)
            .then((response) => {
              let engagement = response.data.engagements;

              massageAPIEngagement(engagement);

              deferred.resolve(engagement);
            })
            .catch((response) => {
              const {status} = response
              if ([403, 404].includes(status)) {
                console.info('Engagement is not found or is restricted')
                $state.go(`${status}`);
              } else {
                //One of the few places that we don't want to use auto-trace
                //As a middleware, responseHelpers.setErrors is a higher order function
                //To call it directly we use responseHelpers.setErrors()(response)
                deferred.reject(responseHelpers.setErrors()(response));
              }
            });

          return deferred.promise;
        },

        getSectionFromEngagementWithSlugs: (engagement, programSlug, sectionSlug) => {
          let program = engagement.program_data.programs.find((program) => program.slug === programSlug);
          if (!program) {
            return undefined;
          }

          return program.sections.find((section) => section.slug === sectionSlug);
        },

        getSectionFromEngagementWithIds: (engagement, programUid, sectionId) => {
          let program = engagement.program_data.programs.find((program) => program.uid === programUid);
          if (!program) {
            return undefined;
          }

          return program.sections.find((section) => section.id === sectionId);
        },

        createBlankProgram: (allPrograms, programName) => {
          let uid = 1;
          while (allPrograms.find((program) => program.uid === uid.toString())) {
            uid++;
          }
          uid = uid.toString();
          return {
            uid: uid,
            slug: uid,
            name: programName,
            is_removable: true,
            sections: [],
            visible: true
          }
        },

        getActiveEngagement() {
          if (!$state.params.clientId)
            throw new Error(`Cannot get engagement without a client id param`);
          if (!$state.params.engagementId)
            throw new Error(`Cannot get engagement without an engagement id param`);

          const observable = EngagementResource
            .fetchWithSharedCache($state.params)
            .pipe(map(json => {
              const engagement = json.engagements;

              massageAPIEngagement(engagement);

              return engagement;
            }))

          return observable;
        },
      };

      function massageAPIEngagement(engagement) {
        if (engagement.amount_due === null) engagement.amount_due = '0.00';

        if (engagement.program_data && engagement.program_data.programs) {
          engagement.program_data.programs = engagement.program_data.programs.map((program) => ({
            ...program,
            sections: program.sections && program.sections.map((section) => ({...section, description: section.description ? section.description.replace(/&#10;/g, " | ") : ""})),
            template_steps: program.template_steps && program.template_steps.map((template_step) => ({...template_step, description: template_step.description ? template_step.description.replace(/&#10;/g, " | ") : ""}))
          }));
        }
      }
    }
  ]);
