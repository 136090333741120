import angular from "angular";
import template from "./modal-dialog.template.html";

angular.module('app')
  .directive('cpModalDialog', function() {
    return {
      restrict: 'E',
      scope: {
        show: '=',
        noHide: '@',
        onClose: '=',
        fullPage: '@',
        width: '@',
        height: '@',
        action: '='
      },
      replace: true,
      transclude: true,
      link: function(scope, element, attrs) {
        scope.show = true;
        scope.dialogStyle = {};

        if (attrs.width) {
          scope.dialogStyle.width = attrs.width;
        }

        if (attrs.height) {
          scope.dialogStyle.height = attrs.height;
        }

        scope.hideModal = function() {
          if (scope.noHide === 'true') return;
          scope.show = false;
          scope.onClose();
        };
      },
      template: template
    };
  });
