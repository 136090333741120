import './cp-inline-notification.style.css';

angular.module('cp-inline-notification', []).directive('cpInlineNotification', function () {
  return {
    restrict: 'E',
    scope: {
      model: '=',
      message: '=',
    },
    template:
      " \
        <div class='inline-notification'> \
          <div class='inline-notification__card'> \
            <i class='cps-icon cps-icon-error cps-padding-right-16' /> \
            <div class='inline-notification__message'> \
              {{message}} \
            </div>\
          </div>\
        </div>\
      ",
  };
});
