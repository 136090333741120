import angular from "angular";
import { asyncStacktrace } from 'auto-trace';
import { map } from "lodash";
import "angular/resources/sources.resource.js";

angular.module('app')
  .factory('SourcesService', ['$q', 'SourcesResource',
    function SourcesService($q, SourcesResource) {

      var service = {};

      service.getIndex = getIndex;
      service.getRankedIndex = getRankedIndex;
      service.getSource = getSource;
      service.saveSource = saveSource;
      service.saveSourcesForOne = saveSourcesForOne;
      service.saveSourcesForMany = saveSourcesForMany;
      service.updateSourceName = updateSourceName;
      service.deleteSources = deleteSources;
      service.mergeSources = mergeSources;
      service.deleteContactSources = deleteContactSources;
      service.deleteSourceContacts = deleteSourceContacts;

      return service;

      function getIndex(params={}) {
        var deferred = $q.defer();

        SourcesResource.index(params)
        .then((resp) => {
          deferred.resolve(resp.data.contact_sources);
        })
        .catch(asyncStacktrace(response => {deferred.reject(response)} ))

        return deferred.promise;
      }

      function getRankedIndex() {
        return service.getIndex({
          rank: true,
        });
      }

      function getSource(sourceId, params={}) {
        var deferred = $q.defer();

        SourcesResource.get(sourceId, params)
        .then((resp) => {
          deferred.resolve(resp.data.contact_sources);
        })
        .catch(asyncStacktrace(response => {deferred.reject(response)} ))

        return deferred.promise;
      }

      // Saves a source (with a contact if given)
      // If the source already exists, it adds the given contact to the existing source
      // Else, it creates a new source, with a contact if given
      function saveSource(sourceName, contactId=null, params={}) {
        var deferred = $q.defer();

        let relationship = contactId
        ? {
          contact_id: contactId,
        }
        : {};

        let payload = {
          contact_sources: {
            name: sourceName.trim(),
            ...relationship
          }
        };

        SourcesResource.post(payload, params)
        .then((resp) => {
          deferred.resolve(resp.data.contact_sources);
        })
        .catch(asyncStacktrace(response => {deferred.reject(response)} ))

        return deferred.promise;
      }

      // Saves multiple sources to one entity
      function saveSourcesForOne(sourceNames, contactId=null, params={}) {
        var deferred = $q.defer();
        var promises = map(sourceNames, (sourceName) => service.saveSource(sourceName, contactId, params));

        $q.all(promises)
        .then((resp) => {
          deferred.resolve(resp);
        })
        .catch(asyncStacktrace(response => {deferred.reject(response)} ))

        return deferred.promise;
      }

      // Saves multiple sources for multiple entities
      function saveSourcesForMany(sources, params={}) {
        var deferred = $q.defer();

        let payload = {
          contact_sources: sources,
        }

        SourcesResource.post(payload, params)
        .then((resp) => {
          deferred.resolve(resp);
        })
        .catch(asyncStacktrace(response => {deferred.reject(response)} ))

        return deferred.promise;
      }

      function updateSourceName(sourceId, newSourceName, params={}) {
        var deferred = $q.defer();

        let payload = {
          contact_sources: {
            name: newSourceName.trim(),
            id: sourceId,
          }
        }

        SourcesResource.put(sourceId, payload, params)
        .then((resp) => {
          deferred.resolve(resp.data.contact_sources);
        })
        .catch(asyncStacktrace(response => {deferred.reject(response)} ))

        return deferred.promise;
      }

      function deleteSources(sources, params={}) {
        var deferred = $q.defer();

        let sourceIds = map(sources, (source) => source.id).join(',');

        SourcesResource.delete(sourceIds, params)
        .then((resp) => {
          deferred.resolve(resp.data.contact_sources);
        })
        .catch(asyncStacktrace(response => {deferred.reject(response)} ))

        return deferred.promise;
      }

      function mergeSources(sources, newName, params={}) {
        var deferred = $q.defer();

        let mergedSourceIds = map(sources, (source) => source.id);
        let payload = {
          contact_sources: {
            name: newName.trim(),
            merges: mergedSourceIds,
          }
        }

        SourcesResource.patchIndex(payload, params)
        .then((resp) => {
          deferred.resolve(resp.data.contact_sources);
        })
        .catch(asyncStacktrace(response => {deferred.reject(response)} ))

        return deferred.promise;
      }

      // Remove multiple sources from a contact
      function deleteContactSources(sourceIds, contactId, params={}) {
        var deferred = $q.defer();

        SourcesResource.deleteContactSources(contactId, sourceIds.join(','), params)
        .then((resp) => {
          deferred.resolve(resp.data.contact_sources);
        })
        .catch(asyncStacktrace(response => {deferred.reject(response)} ))

        return deferred.promise;
      }

      // Remove multiple contacts from a source
      function deleteSourceContacts(sourceId, contactIds, params={}) {
        var deferred = $q.defer();

        SourcesResource.deleteSourceContacts(sourceId, contactIds.join(','), params)
        .then((resp) => {
          deferred.resolve(resp.data.contact_sources);
        })
        .catch(asyncStacktrace(response => {deferred.reject(response)} ))

        return deferred.promise;
      }

    }
  ]);
