import angular from "angular";

angular.module('app')
  .run(['$rootScope', '$state', '$stateParams', '$http',
    function($rootScope, $state, $stateParams, $http) {

      $rootScope.$state = $state;
      $rootScope.$stateParams = $stateParams;
      $rootScope.$on('$stateChangeError', (event, toState, toParams, fromState, fromParams, error) => {
        window.console.error(`Error transitioning from "${fromState.name}" state to "${toState.name}" state`);
        throw error;
      });

      // Attach $http service to the window to help in debugging
      window.$http = $http;

      // // ui -router debugging
      // $rootScope.$on('$stateChangeStart',function(event, toState, toParams, fromState, fromParams){
      //   console.log('$stateChangeStart to '+toState.to+'- fired when the transition begins. toState,toParams : \n',toState, toParams);
      // });
      // $rootScope.$on('$stateChangeError',function(event, toState, toParams, fromState, fromParams){
      //   console.log('$stateChangeError - fired when an error occurs during transition.');
      //   console.log(arguments);
      // });
      // $rootScope.$on('$stateChangeSuccess',function(event, toState, toParams, fromState, fromParams){
      //   console.log('$stateChangeSuccess to '+toState.name+'- fired once the state transition is complete.');
      // });
      // // $rootScope.$on('$viewContentLoading',function(event, viewConfig){
      // //   // runs on individual scopes, so putting it in "run" doesn't work.
      // //   console.log('$viewContentLoading - view begins loading - dom not rendered',viewConfig);
      // // });
      // $rootScope.$on('$viewContentLoaded',function(event){
      //   console.log('$viewContentLoaded - fired after dom rendered',event);
      // });
      // $rootScope.$on('$stateNotFound',function(event, unfoundState, fromState, fromParams){
      //   console.log('$stateNotFound '+unfoundState.to+'  - fired when a state cannot be found by its name.');
      //   console.log(unfoundState, fromState, fromParams);
      // });

      window.console.rage = function(m) {
        var s = 'background: red; color: white';
        window.console.log('%c (╯°□°）╯︵ ┻━┻ ' + m, s);
      };

      window.console.chill = function(m) {
        var s = 'background: aqua; color: white';
        window.console.log('%c (⌐■_■) ..[It\'s cool, ' + m + '... Be cool...]', s);
      };
    }
  ]);
