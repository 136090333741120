import angular from "angular";

angular.module('app.helpers')
  .filter('email', function() {
    return function(value) {
      var EMAIL_REGEXP, regex, valid;
      // Regex code is obtained from HTML Spec: https://html.spec.whatwg.org/multipage/forms.html#e-mail-state-(type=email)
      EMAIL_REGEXP = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      regex = new RegExp(EMAIL_REGEXP);
      valid = regex.test(value);
      return valid;

    }
  });
