import angular from "angular";

import template from './assistant.template.html';

angular.module('app.clients.taxes').component('cpAssistantLiens', {
  bindings: {
    question: '='
  },

  controller: function ($state, $stateParams) {
    const vm = this;
    vm;
  },

  controllerAs: 'vm',

  template: template
});