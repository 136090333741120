import angular from 'angular';
import { catchAsyncStacktrace } from 'auto-trace';
import template from './practitioner-sent-client-request.template.html';
import context from 'angular/bootstrap/context.service.js';
import './right-side-pane.component.js';
import './client-request-description.component.js';
import './client-request-due-date.component.js';
import './client-request-reminders.component.js';
import './client-request-header.component.js';
import './client-request-footer.component.js';
import './client-request-comments-list.component.js';
import './practitioner-sent-client-request.style.css';
import "angular/common/services/comments.service.js";

angular
.module('app.clients.taxes')
.component('cpPractitionerSentClientRequest', {
  bindings: {
    clientRequest: '=',
    engagement: '=',
    activeTeamMembers: '='
  },

  template,

  controllerAs: 'vm',

  controller: ['$scope', 'CommentsService', '$stateParams', '$timeout', 'AttachmentsService',
    function ($scope, CommentsService, $stateParams, $timeout, AttachmentsService) {
      const vm = this;
      vm.comments = []; //initialized inside of comments-list directive
      vm.parentObjType = ''
      vm.$stateParams = $stateParams;

      if (!$stateParams.clientRequestId)
        $timeout(() => {throw new Error(`Can't edit a client request without a clientRequestId in the url`)});

      $scope.$on('cp:client-requests:new-comment', (event, comment, newAttachments) => {
        vm.attachments = [...vm.attachments, ...newAttachments];
        $scope.$broadcast('cp:client-requests:comments-changed');
      });

      $scope.$watch('vm.clientRequest', () => {
        if (vm.clientRequest) {
          CommentsService.getList({
            pivotType: 'client_requests',
            pivotId: vm.clientRequest.id,
          })
          .then((comments) => vm.comments = comments)
          .catch((ex) => null);

          AttachmentsService.getList({
            clientId: $stateParams.clientId,
            pivot_type: 'client_requests',
            pivot_id: $stateParams.clientRequestId,
          })
          .then((attachments) => {
            vm.attachments = attachments;
          })
          .catch((ex) => {
            vm.attachments = [];

            SystemJS.import('sentry-error-logging!sofe')
            .then((sentry) => {
              sentry.setUser(context.getContext().loggedInUser);
              sentry.captureException(ex);
            })
            .catch(catchAsyncStacktrace());
          });
        }
      });

      $scope.$on('cp:client-request-component:do-hide', () => {
        $scope.$broadcast('cp:right-side-pane:do-hide');
      });

    }
  ],
});
