import angular from "angular";

import 'angular/app/client/taxes/section-interactions/assistant.style.css';
import 'angular/app/client/taxes/section-interactions/assistant-shared.style.css';

angular.module('app.clients.taxes').component('cpAssistantQuestionCompliance', {
  bindings: {},

  controllerAs: 'vm',

  controller: function () {
    const vm = this;
    vm;
  },

  template: ($element, $attrs) => {
    const TOOLTIP = 'If the taxpayer is not currently in compliance, they are ineligible at this time. However, there is an opportunity to pursue this again as soon as they are in compliance.';

    return `<strong>Has your client filed all currently required returns, or has she/he filed the appropriate extensions (i.e., is your client in <cps-tooltip cp-custom-element html="'${TOOLTIP}'"><a>compliance</a></cps-tooltip> for their filing requirements)?</strong>`;
  }
});
